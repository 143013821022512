/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import { fetchData, ThemeContext } from "../context/ContextFile";
import { Link } from "react-router-dom";
import ListingOverlayChild from "../Components/ui/ListingOverlayChild";
import Swal from "sweetalert2";
import axios from "axios";
import BASE_URL from "../Components/auth/Baseurl";
import "./features.css";
const Feature = () => {
  const [datas, setDatas] = useState([]);
  const { theme, setFilterListing, shuffledDatas, setShuffledDatas } =
    useContext(ThemeContext);

  const handleFav = async (id) => {
    const formData = new FormData();
    formData.append("listing_id", id);
    try {
      const response = await axios.post(
        `${BASE_URL}/add-to-favourite`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(response);
      fetchData().then((res) => {
        const shuffledIds = shuffledDatas?.map((item) => item.id);
        const shuffledArray = shuffledIds?.map((id) =>
          res?.data?.data?.find((item) => item.id === id)
        );
        setShuffledDatas(shuffledArray);
        setDatas(res.data?.data);
        setFilterListing(res.data?.data);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const hasShuffled = useRef(false);
  const shuffleArray = (array) => {
    const shuffledArray = array.slice();
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  };

  useEffect(() => {
    if (!hasShuffled.current && datas.length > 0) {
      setShuffledDatas(shuffleArray(datas));
      hasShuffled.current = true;
    }
  }, [datas]);

  useEffect(() => {
    fetchData().then((res) => {
      setDatas(res.data?.data);
    });
  }, []);

  const handleRemove = async (id, currentCompare) => {
    try {
      const response = await axios.get(`${BASE_URL}/listing-compare/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const menuToggleCheckbox = document.getElementById("UniqueMenuToggle");
      if (!menuToggleCheckbox.checked) {
        menuToggleCheckbox.click();
      }

      if (response.status === 200) {
        setFilterListing(response.data?.data);
      }
      fetchData().then((res) => {
        const shuffledIds = shuffledDatas?.map((item) => item.id);
        const shuffledArray = shuffledIds?.map((id) =>
          res?.data?.data?.find((item) => item.id === id)
        );
        setShuffledDatas(shuffledArray);
        setDatas(res.data?.data);
        setFilterListing(res.data?.data);
      });
    } catch (error) {
      console.error("Error making API call:", error);
    }
  };

  const handleClick = async (id, currentCompare) => {
    try {
      const response = await axios.get(`${BASE_URL}/listing-compare/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const menuToggleCheckbox = document.getElementById("UniqueMenuToggle");

      // Only toggle the menu open if it's not already checked
      if (!menuToggleCheckbox.checked) {
        menuToggleCheckbox.click();
      }

      if (response.status === 200) {
        setFilterListing(response.data?.data);
        console.log(response.data, "responsefilter");
      }
      fetchData().then((res) => {
        const shuffledIds = shuffledDatas?.map((item) => item.id);
        const shuffledArray = shuffledIds?.map((id) =>
          res?.data?.data?.find((item) => item.id === id)
        );
        setShuffledDatas(shuffledArray);
        setFilterListing(res.data?.data);
      });
    } catch (error) {
      console.error("Error making API call:", error);
    }
  };

  const handleCompare = (id, currentCompare) => {
    const filterCompare = shuffledDatas?.filter((e) => e?.is_compare === 0);
    console.log(filterCompare, "filterCompare");
    if (filterCompare?.length <= 4) {
      handleClick(id, currentCompare);
    } else {
      Swal.fire({
        position: "center",
        html: `<div style="display: flex; flex-direction: column; align-items:center;">
                     <svg
                            viewBox="0 0 1024 1024"
                            height="4em"
                            width="4em"
                            style="margin-bottom: 10px;"
                        >
                            <!-- Outer circle with color #8ec639 -->
                            <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                            
                            <!-- Check mark with color #A2c66b -->
                            <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
                        </svg>
                      <p style="margin: 0; font-size: 1.2em;">Profile update successful.</p>
                  </div>
              `,
        title: "You can only compare 5 listings",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <>
      <Link style={{ color: "#3B4249" }} to="/faq" target="_blank">
        <button className="color2-bg my-3 text-white bg-[#8EC639] rounded-md font-medium w-28 h-10">
          FAQ
        </button>
      </Link>

      <div className="content py-20">
        <h2 className="text-2xl text-center font-bold">Featured Homes</h2>
        <p className="text-xl font-medium text-center">
          Great selection of quality spaces
        </p>

        <div className="container mx-auto  ">

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 px-4 rounded-lg">

            {theme?.length === 0
              ? shuffledDatas?.map((e) => {
                return (
                  <ListingOverlayChild
                    e={e}
                    handleFav={handleFav}
                    handleCompare={handleCompare}
                    handleRemove={handleRemove}
                  />
                );
              })
              : theme?.listings?.map((e) => {
                return (
                  <div className="">
                    <div className="">
                      <ListingOverlayChild

                        e={e}
                        handleFav={handleFav}
                        handleCompare={handleCompare}
                        handleRemove={handleRemove}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Feature;
