/* eslint-disable */
import React, { useEffect, useState } from "react";
import axios from "axios";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { useFormContext } from '../../../../../providers';
import { TextEditor } from "../Editor";
import BASE_URL from "../../../../auth/Baseurl";

const times = [
    "6:00AM",
    "6:30AM",
    "7:00AM",
    "7:30AM",
    "8:00AM",
    "8:30AM",
    "9:00AM",
    "9:30AM",
    "10:00AM",
    "10:30AM",
    "11:00AM",
    "11:30AM",
    "12:00PM",
    "12:30PM",
    "1:00PM",
    "1:30PM",
    "2:00PM",
    "2:30PM",
    "3:00PM",
    "3:30PM",
    "4:00PM",
    "4:30PM",
    "5:00PM",
    "5:30PM",
    "6:00PM",
    "6:30PM",
    "7:00PM",
    "7:30PM",
    "8:00PM",
    "8:30PM",
    "9:00PM",
];

export const CancellationPolicy = () => {
    const { errors, handleChange, watch, setValue } = useFormContext();

    const [cancellation_policy, setCancellationPolicy] = useState([]);

    const GetData = () => {
        axios
            .get(`${BASE_URL}/cancellation-policy`, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                setCancellationPolicy(res.data?.data || []);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        GetData();
    }, []);

    const handleRangeChange = (type, value) => {
        if (type === 'infant') {
            setValue('infant_ages',
                {
                    min_age: value[0],
                    max_age: value[1]
                }
            )
        } else {
            setValue('children_ages',
                {
                    min_age: value[0],
                    max_age: value[1]
                }
            )
        }

    }

    // Handle selection change
    // const handleSelectChange = (event) => {
    //     const selectedPolicy = event.target.value;
    //     // Update only the 'cancellation_policy' field while preserving the rest of the form data
    //     setValue('cancellation_policy', selectedPolicy);
    // };

    return (
        <div className="md:col-span-12 text-left">
            <div className="md:col-span-12 text-left">
                <label>
                    Short Cancellation Policy
                    <i className="fal fa-times-circle" />
                </label>
                <select
                    data-placeholder="cancellation_policy_short"
                    className={`border border-input px-3 py-3 rounded-md gap-4 items-center w-full `}
                    name="cancellation_policy_short"
                    value={watch('cancellation_policy_short') || ""}
                    onChange={handleChange}
                >
                    <option disabled value="">
                        Select
                    </option>
                    {cancellation_policy?.map((item, index) => (
                        <option key={index} value={item.id}>
                            {item.name}
                        </option>
                    ))}
                </select>
                {errors.cancellation_policy_short && (
                    <span style={{ color: "red" }}>{errors.cancellation_policy_short.message}</span>
                )}
            </div>

            <div className="md:col-span-12 text-left mt-3">
                <label>
                    Long Cancellation Policy
                    <i className="fal fa-times-circle" />
                </label>
                <select
                    data-placeholder="cancellation_policy_long"
                    className={`border border-input px-3 py-3 rounded-md gap-4 items-center w-full `}
                    name="cancellation_policy_long"
                    value={watch('cancellation_policy_long') || ""}
                    onChange={handleChange}
                >
                    <option disabled value="">
                        Select
                    </option>
                    {cancellation_policy?.map((item, index) => (
                        <option key={index} value={item.id}>
                            {item.name}
                        </option>
                    ))}
                </select>
                {errors.cancellation_policy_long && (
                    <span style={{ color: "red" }}>{errors.cancellation_policy_long.message}</span>
                )}
            </div>


            {/* ---------------------------------------------------------------------- */}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
                <div className="text-left">
                    <label>Minimum Days of a Booking</label>
                    <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full">
                        <i className="fal fa-calendar-minus" />
                        <input
                            type="number"
                            name="min_day_booking"
                            value={watch('min_day_booking')}
                            onChange={handleChange}
                            placeholder="Minimum Days of a Booking"
                        />
                    </div>
                    {errors.min_day_booking && (
                        <span style={{ color: "red" }}>
                            {errors.min_day_booking.message}
                        </span>
                    )}
                </div>

                <div className="text-left">
                    <label>Maximum Days of a Booking</label>
                    <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full">
                        <i className="fal fa-calendar-plus" />
                        <input
                            name="max_day_booking"
                            value={watch('max_day_booking')}
                            onChange={handleChange}
                            type="number"
                            placeholder="Maximum Days of a Booking"
                        />
                    </div>
                    {errors.max_day_booking && (
                        <span style={{ color: "red" }}>
                            {errors.max_day_booking.message}
                        </span>
                    )}
                </div>
            </div>



            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
                <div className="text-left">
                    <label>
                        Check In After
                        <i className="fal fa-clock" />
                    </label>
                    <select
                        value={watch('check_in_after')}
                        name="check_in_after"
                        onChange={handleChange}
                        data-placeholder="Apartments"
                        className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full"
                    >
                        <option>Select</option>
                        {times.map((time, index) => (
                            <option key={index} value={time}>
                                {time}
                            </option>
                        ))}
                    </select>
                    {errors?.check_in_after && (
                        <p style={{ color: "red" }}>
                            {errors?.check_in_after?.message}
                        </p>
                    )}
                </div>

                <div className="text-left">
                    <label>
                        Check Out Before
                        <i className="fal fa-clock" />
                    </label>
                    <select
                        value={watch('check_in_before')}
                        name="check_in_before"
                        onChange={handleChange}
                        data-placeholder="Apartments"
                        className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full"
                    >
                        <option>Select</option>
                        {times.map((time, index) => (
                            <option key={index} value={time}>
                                {time}
                            </option>
                        ))}
                    </select>
                    {errors?.check_in_after && (
                        <p style={{ color: "red" }}>
                            {errors?.check_in_after?.message}
                        </p>
                    )}
                </div>
            </div>


            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
                <div className="text-left">
                    <label>
                        Smoking Allowed
                        <i className="fal fa-clock" />
                    </label>
                    <div className="listsearch-input-item">
                        <select
                            value={watch('smoking_allowed')}
                            name="smoking_allowed"
                            onChange={handleChange}
                            data-placeholder="smoking_allowed"
                            className={`flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full `}
                        >
                            <option disabled value={""}>
                                Select
                            </option>
                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                        </select>
                        {errors.smoking_allowed && (
                            <span style={{ color: "red" }}>
                                {errors.smoking_allowed.message}
                            </span>
                        )}
                    </div>
                </div>
                <div className="text-left">
                    {watch('smoking_allowed') === 1 ? (
                        <>
                            <label>Write your conditions here.</label>
                            <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full">
                                <i className="fal fa-briefcase" />
                                <input
                                    type="text"
                                    name="smoking_rules"
                                    placeholder="Write your conditions here."
                                    onChange={handleChange}
                                />
                            </div>
                        </>
                    ) : <></>}
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
                <div className="text-left">
                    <label>
                        Pets Allowed
                        <i className="fal fa-clock" />
                    </label>
                    <select
                        value={watch('rules_pet_allowed')}
                        name="rules_pet_allowed"
                        onChange={handleChange}
                        data-placeholder="rules_pet_allowed"
                        className={`flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full `}
                    >
                        <option disabled value={""}>
                            Select
                        </option>
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                    </select>
                    {errors.rules_pet_allowed && (
                        <span style={{ color: "red" }}>{errors.rules_pet_allowed.message}</span>
                    )}
                </div>
                <div className="text-left">
                    {watch('rules_pet_allowed') === 1 ? (
                        <>
                            <label>Write your conditions here.</label>
                            <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full">
                                <i className="fal fa-briefcase" />
                                <input
                                    type="text"
                                    placeholder="Write your conditions here."
                                    name="pet_rules"
                                    onChange={handleChange}
                                />
                            </div>
                        </>
                    ) : <></>}
                </div>
            </div>


            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
                <div className="text-left">
                    <label>
                        Parties Allowed
                        <i className="fal fa-clock" />
                    </label>
                    <select
                        value={watch('party_allowed')}
                        name="party_allowed"
                        onChange={handleChange}
                        data-placeholder="party_allowed"
                        className={`flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full `}
                    >
                        <option disabled value={""}>
                            Select
                        </option>
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                    </select>
                    {errors.party_allowed && (
                        <span style={{ color: "red" }}>{errors.party_allowed?.message}</span>
                    )}
                </div>
                <div className="text-left">
                    {+watch('party_allowed') === 1 ? (
                        <>
                            <label>Write your conditions here.</label>
                            <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full">
                                <i className="fal fa-briefcase" />
                                <input
                                    type="text"
                                    placeholder="Write your conditions here."
                                    name="party_rules"
                                    onChange={handleChange}
                                />
                            </div>
                        </>
                    ) : <></>}
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
                <div className="text-left">
                    <label>
                        Children Allowed
                        <i className="fal fa-clock" />
                    </label>
                    <select
                        value={watch('children_allowed')}
                        name="children_allowed"
                        onChange={handleChange}
                        data-placeholder="Apartments"
                        // className={`flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full ${errors.children_allowed ? "error" : ""
                        //     }`}
                        className={`flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full`}
                    >
                        <option disabled value={""}>
                            Select
                        </option>
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                    </select>
                    {errors.children_allowed && (
                        <span style={{ color: "red" }}>
                            {errors.children_allowed.message}
                        </span>
                    )}
                </div>
                <div className="text-left">
                    {+watch('children_allowed') === 1 ? (
                        <>
                            <label>Write your conditions here.</label>
                            <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full">
                                <i className="fal fa-briefcase" />
                                <input
                                    type="text"
                                    placeholder="Write your conditions here."
                                />
                            </div>
                        </>
                    ) : <></>}
                </div>
            </div>

            {
                +watch('children_allowed') === 1 ? (
                    <>
                        <div className="md:col-span-12 text-left">
                            <label>
                                Infants ages:{watch('infant_ages').min_age || 0} - {watch('infant_ages').max_age || 24} months
                            </label>
                            <div className="pt-5">
                                <RangeSlider
                                    min={0}
                                    max={24}
                                    name={'infant_min_age'}
                                    defaultValue={[0, 24]}
                                    onInput={(e) => handleRangeChange('infant', e)}
                                />
                            </div>
                        </div>

                        <div className="md:col-span-12 text-left">
                            <label>
                                Children ages: {watch('children_ages').min_age || 2} - {watch('children_ages').max_age || 17} years
                            </label>
                            <div className="pt-5">
                                <RangeSlider
                                    min={2}
                                    max={17}
                                    name={'infant_max_age'}
                                    defaultValue={[2, 17]}
                                    onInput={(e) => handleRangeChange('children', e)}
                                />
                            </div>
                        </div>
                    </>
                ) : null
            }


            <div className="md:col-span-12 text-left mt-2">
                <label>
                    Rules
                    <i className="fal fa-clock" />
                </label>
                <select
                    name="rules"
                    placeholder="Rules"
                    value={watch('rules')}
                    onChange={handleChange}
                    className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full"
                >
                    <option disabled value="">
                        Choose as many rules as you would like. Feel free to edit
                        or add your own.
                    </option>
                    <option value="Quiet Hours: Quiet hours are from [specific time] to [specific time]. Please respect the neighbors.">
                        Quiet Hours: Quiet hours are from [specific time] to
                        [specific time]. Please respect the neighbors.
                    </option>
                    <option value="Respect the Neighborhood: Please be mindful of noise and parking, and respect the property and surrounding community.">
                        Respect the Neighborhood: Please be mindful of noise and
                        parking, and respect the property and surrounding
                        community.
                    </option>

                    <option value="Report Any Damages: Please report any accidental damages immediately. Any unreported damages may result in fees.">
                        Report Any Damages: Please report any accidental damages
                        immediately. Any unreported damages may result in fees.
                    </option>
                    <option value="No Unregistered Guests: Only registered guests listed in the booking are allowed to stay at the property. No additional overnight guests.">
                        No Unregistered Guests: Only registered guests listed in
                        the booking are allowed to stay at the property. No
                        additional overnight guests.
                    </option>
                    <option value="Use of Amenities: Please use all amenities (pool, hot tub, etc.) responsibly. Follow safety rules, and use at your own risk.">
                        Use of Amenities: Please use all amenities (pool, hot tub,
                        etc.) responsibly. Follow safety rules, and use at your
                        own risk.
                    </option>
                    <option value="Cleaning: Please tidy up before checkout. Washing dishes and taking out the trash would be appreciated.">
                        Cleaning: Please tidy up before checkout. Washing dishes
                        and taking out the trash would be appreciated.
                    </option>
                    <option value="Key/Entry Instructions: Guests are responsible for keeping the keys and access codes safe. A fee may apply for lost keys or access codes.">
                        Key/Entry Instructions: Guests are responsible for keeping
                        the keys and access codes safe. A fee may apply for lost
                        keys or access codes.
                    </option>
                    <option value="Trash and Recycling: Guests are required to follow the trash and recycling procedures. Please dispose of garbage properly.">
                        Trash and Recycling: Guests are required to follow the
                        trash and recycling procedures. Please dispose of garbage
                        properly.
                    </option>
                    <option value="Energy Conservation: Please turn off lights, AC/heating, and appliances when not in use.">
                        Energy Conservation: Please turn off lights, AC/heating,
                        and appliances when not in use.
                    </option>
                    <option value="Parking: Parking is limited to [insert number] spaces. Guests are responsible for following the parking rules.">
                        Parking: Parking is limited to [insert number] spaces.
                        Guests are responsible for following the parking rules.
                    </option>
                    <option value="Children: Children are welcome, but supervision is required, especially around [mention any safety concerns like pools or balconies].">
                        Children: Children are welcome, but supervision is
                        required, especially around [mention any safety concerns
                        like pools or balconies].
                    </option>
                    <option value="Security Cameras: For your safety and security, there are [exterior] cameras monitoring the property.">
                        Security Cameras: For your safety and security, there are
                        [exterior] cameras monitoring the property.
                    </option>
                    <option value="House Access: Guests should only access the areas of the home specified in the listing. Certain parts of the property may be off-limits.">
                        House Access: Guests should only access the areas of the
                        home specified in the listing. Certain parts of the
                        property may be off-limits.
                    </option>
                    <option value="Local Laws: Please adhere to all local laws and regulations during your stay.">
                        Local Laws: Please adhere to all local laws and
                        regulations during your stay.
                    </option>
                </select>
                {errors?.rules && (
                    <p style={{ color: "red" }}>
                        {errors?.rules?.message}
                    </p>
                )}
            </div>


            <div className="md:col-span-12 text-left mt-2">
                <label>Edit Rules</label>
            </div>
            <div className="md:col-span-12 text-left mt-2">
                <TextEditor field={'rules'} />
            </div>
            <div className="md:col-span-12 text-left mt-5">
                {errors?.rules && (
                    <p style={{ color: "red" }}>
                        {errors?.rules?.message}
                    </p>
                )}
            </div>

            <div className="md:col-span-12 text-left mt-2">
                <label>
                    Welcome and Instructions - this message will be sent to your
                    guest once the reservation is confirmed and paid.
                    <i className="fal fa-clock" />
                </label>
                <select
                    name="welcome_message"
                    placeholder="Rules"
                    value={watch('welcome_message')}
                    onChange={handleChange}
                    className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full"
                >
                    <option disabled value={""}>
                        Choose as many instructions as you would like. Feel free
                        to edit or add your own.
                    </option>
                    <option value="We’re delighted to have you stay with us! To make your arrival seamless, here’s everything you need to know:">
                        We’re delighted to have you stay with us! To make your
                        arrival seamless, here’s everything you need to know:
                    </option>
                    <option
                        style={{ fontWeight: "bolder" }}
                        value="1. Check-in Instructions:"
                    >
                        1. Check-in Instructions:
                    </option>
                    <option value="Address: [Insert full address with any special notes, like landmarks].">
                        Address: [Insert full address with any special notes, like
                        landmarks].
                    </option>
                    <option value="Entry Code: Use [insert code] at the front door keypad to unlock. Ensure you press [insert instructions] after entering the code.">
                        Entry Code: Use [insert code] at the front door keypad to
                        unlock. Ensure you press [insert instructions] after
                        entering the code.
                    </option>
                    <option value="Check-in Time: [Insert time, e.g., 3:00 PM].">
                        Check-in Time: [Insert time, e.g., 3:00 PM].
                    </option>
                    <option value="Parking: [Provide parking details, such as designated spots or street parking tips].">
                        Parking: [Provide parking details, such as designated
                        spots or street parking tips].
                    </option>

                    <option
                        style={{ fontWeight: "bolder" }}
                        value="2. Wi-Fi Information:"
                    >
                        2. Wi-Fi Information:
                    </option>
                    <option value="Network Name: [Insert Wi-Fi name].">
                        Network Name: [Insert Wi-Fi name].
                    </option>
                    <option value="Password: [Insert password].">
                        Password: [Insert password].
                    </option>

                    <option
                        style={{ fontWeight: "bolder" }}
                        value="3. Appliance Use:"
                    >
                        3. Appliance Use:
                    </option>
                    <option value="Thermostat: [Explain how to use it and preferred temperature settings].">
                        Thermostat: [Explain how to use it and preferred
                        temperature settings].
                    </option>
                    <option value="Kitchen Appliances: Feel free to use all appliances. Please ensure the oven and stovetop are turned off after use.">
                        Kitchen Appliances: Feel free to use all appliances.
                        Please ensure the oven and stovetop are turned off after
                        use.
                    </option>
                    <option value="Washer/Dryer: [Insert operating instructions if available for use].">
                        Washer/Dryer: [Insert operating instructions if available
                        for use].
                    </option>

                    <option
                        style={{ fontWeight: "bolder" }}
                        value="4. Emergency Contacts:"
                    >
                        4. Emergency Contacts:
                    </option>
                    <option value="Host Contact: [Insert phone number or message option].">
                        Host Contact: [Insert phone number or message option].
                    </option>
                    <option value="Local Emergency Services: Dial [insert number, e.g., 911].">
                        Local Emergency Services: Dial [insert number, e.g., 911].
                    </option>
                    <option value="Nearest Hospital/Clinic: [Insert address and contact].">
                        Nearest Hospital/Clinic: [Insert address and contact].
                    </option>

                    <option
                        style={{ fontWeight: "bolder" }}
                        value="5. Check-Out Instructions:"
                    >
                        5. Check-Out Instructions:
                    </option>
                    <option value="Check-Out Time: [Insert time, e.g., 11:00 AM].">
                        Check-Out Time: [Insert time, e.g., 11:00 AM].
                    </option>
                    <option value="Cleaning Up: Please leave used towels in the bathroom and ensure all dishes are washed or placed in the dishwasher.">
                        Cleaning Up: Please leave used towels in the bathroom and
                        ensure all dishes are washed or placed in the dishwasher.
                    </option>
                    <option value="Trash: [Insert instructions for trash disposal, e.g., place bags in the outdoor bin].">
                        Trash: [Insert instructions for trash disposal, e.g.,
                        place bags in the outdoor bin].
                    </option>
                    <option value="Keys: [Insert details if a key is used, e.g., leave it in the lockbox or on the counter].">
                        Keys: [Insert details if a key is used, e.g., leave it in
                        the lockbox or on the counter].
                    </option>

                    <option
                        style={{ fontWeight: "bolder" }}
                        value="6. Local Recommendations:"
                    >
                        6. Local Recommendations:
                    </option>
                    <option value="Grocery Stores: [Insert name and distance of the nearest store].">
                        Grocery Stores: [Insert name and distance of the nearest
                        store].
                    </option>
                    <option value="Restaurants: Check out [insert 2-3 restaurant suggestions].">
                        Restaurants: Check out [insert 2-3 restaurant
                        suggestions].
                    </option>
                    <option value="Activities: We recommend [insert local attractions].">
                        Activities: We recommend [insert local attractions].
                    </option>

                    <option
                        style={{ fontWeight: "bolder" }}
                        value="7. Additional Notes:"
                    >
                        7. Additional Notes:
                    </option>
                    <option value="Welcome Basket: Enjoy a small welcome basket with [insert items, e.g., snacks, water bottles].">
                        Welcome Basket: Enjoy a small welcome basket with [insert
                        items, e.g., snacks, water bottles].
                    </option>
                    <option value="Guidebook: There’s a digital or physical guidebook provided with more detailed instructions and recommendations.">
                        Guidebook: There’s a digital or physical guidebook
                        provided with more detailed instructions and
                        recommendations.
                    </option>
                    <option value="Respect Our Neighbors: Please be considerate of our community.">
                        Respect Our Neighbors: Please be considerate of our
                        community.
                    </option>
                    <option value="Thank you for choosing to stay with us! We hope you have a fantastic visit. Feel free to reach out with any questions or concerns.">
                        Thank you for choosing to stay with us! We hope you have a
                        fantastic visit. Feel free to reach out with any questions
                        or concerns.
                    </option>
                </select>
                {errors?.welcome_message && (
                    <p style={{ color: "red" }}>
                        {errors?.welcome_message?.message}
                    </p>
                )}
            </div>
            <div className="md:col-span-12 text-left mt-2">
                <label>Edit Information</label>
            </div>

            <div className="md:col-span-12 text-left mt-2">
                <TextEditor field={'welcome_message'} />
            </div>
            <div className="md:col-span-12 text-left mt-5">
                {errors?.welcome_message && (
                    <p style={{ color: "red" }}>
                        {errors?.welcome_message?.message}
                    </p>
                )}
            </div>
        </div>
    );
};
