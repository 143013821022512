/* eslint-disable */
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import BASE_URL from "../auth/Baseurl";
import axios from "axios";
import { ThemeContext } from "../../context/ContextFile";
import DatePicker from "react-datepicker";
import { FaDotCircle } from "react-icons/fa";
import Swal from "sweetalert2";
import { MdBorderClear } from "react-icons/md";
import SearchModal from "./SearchModal";
import { IoSearchOutline } from "react-icons/io5";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { errorPopup } from "../../helpers";

const SearchLocation = ({ filterItem, setFilterItem }) => {
  const [filters, setFilters] = useState(false);
  const [handle, setHandle] = useState();
  console.log(handle, "handle");
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  let { SetTheme, setSelectedLocation2 } = useContext(ThemeContext);
  const [query, setQuery] = useState("");
  const [query1, setQuery1] = useState("");

  const [quantity, setQuantity] = useState();

  const handleChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value > 0) {
      setQuantity(value);
    } else {
      setQuantity(1);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp") {
      setQuantity((prevQuantity) => prevQuantity + 1);
    } else if (e.key === "ArrowDown") {
      setQuantity((prevQuantity) => Math.max(1, prevQuantity - 1));
    }
  };
  console.log(quantity, "num");
  const formData = new FormData();
  formData.append("byAddress", query);
  formData.append("byNoOfGuest", quantity);
  formData.append("byListingType", handle);
  if (startDate) {
    const formattedArrivalDate = startDate.toISOString().split("T")[0];
    formData.append("byCheckin", formattedArrivalDate);
  }

  // Departure Date
  if (endDate) {
    const formattedDepartureDate = endDate.toISOString().split("T")[0];
    formData.append("byCheckout", formattedDepartureDate);
  }

  const isFormValid = () => {
    return quantity || query || startDate || endDate || handle;
  };

  console.log(handle, "handlw");

  const GetData = () => {
    if (!isFormValid()) {
      console.log("Please fill out all required fields.");
      return;
    }

    axios
      .post(`${BASE_URL}/listing-search`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        SetTheme(res?.data);

        // alert(res?.data)
        navigate(`/map/${query}`);
        Swal.fire({
          position: "center",
          html: `  
            <div style="display: flex; flex-direction: column; align-items: center;">
<svg
                      viewBox="0 0 1024 1024"
                      height="4em"
                      width="4em"
                      style="margin-bottom: 10px;"
                  >
                      <!-- Outer circle with color #8ec639 -->
                      <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                      
                      <!-- Check mark with color #A2c66b -->
                      <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
                  </svg>
                <p style="margin: 0; font-size: 1.2em;">Profile update successful.</p>
            </div>
        `,
          title: res?.data?.message,
          showConfirmButton: true,
          timer: 1500,
        });
      })
      .catch((err) => {
        SetTheme(err);
        // console.log(err);
        errorPopup('', err?.response?.data?.message);

      });
  };

  const autoCompleteRef = useRef(null);
  let autoComplete;

  const handleScriptLoad = (updateQuery, autoCompleteRef) => {
    try {
      autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current,
        {
          // types: ["(cities)"],
        }
      );

      autoComplete.addListener("place_changed", () => {
        handlePlaceSelect(updateQuery);
      });
    } catch (exception) {
      console.log(exception);
    }
  };

  const handlePlaceSelect = async (updateQuery) => {
    const addressObject = await autoComplete.getPlace();
    const query = addressObject?.formatted_address;
    updateQuery(query);
    console.log({ query });
    const latLng = {
      lat: addressObject?.geometry?.location?.lat(),
      lng: addressObject?.geometry?.location?.lng(),
    };
    console.log(latLng);
    // setSelectedLocation(latLng);
  };

  useEffect(() => {
    try {
      handleScriptLoad(setQuery, autoCompleteRef);
    } catch (exception) {
      console.log(exception)
    }
  }, [setQuery, autoCompleteRef]);

  const autoCompleteRef1 = useRef(null);
  let autoComplete1;

  const handleScriptLoad1 = (updateQuery1, autoCompleteRef1) => {
    try {
      autoComplete1 = new window.google.maps.places.Autocomplete(
        autoCompleteRef1.current,
        {}
      );

      autoComplete1.addListener("place_changed", () => {
        handlePlaceSelect1(updateQuery1);
      });
    } catch (exception) {
      console.log(exception);
    }
  };

  const handlePlaceSelect1 = async (updateQuery1) => {
    const addressObject1 = await autoComplete1?.getPlace();
    if (!addressObject1 || !addressObject1.geometry) {
      console.error("Place selection returned no geometry.");
      return;
    }

    const latestQuery1 = addressObject1.formatted_address || "";
    updateQuery1(latestQuery1);

    const latLng1 = {
      lat1: addressObject1.geometry.location.lat(),
      lng1: addressObject1.geometry.location.lng(),
    };

    setSelectedLocation2(latLng1);
  };

  useEffect(() => {
    try {
      handleScriptLoad1(setQuery1, autoCompleteRef1);
    } catch (exception) {
      console.log(exception);
    }
  }, [autoCompleteRef1]);

  const increment = () => {
    setQuantity((prev) =>
      prev !== "" && Number(prev) > 0 ? Number(prev) + 1 : 1
    );
  };

  const decrement = () => {
    setQuantity((prev) =>
      prev !== "" && Number(prev) > 0 ? Number(prev) - 1 : 0
    );
  };

  return (
    <>
      <div className="absolute md:top-[12%] md:left-32 top-[10%] left-32">
        <input
          className="p-3 w-48 border-none rounded-md"
          type="text"
          value={query1}
          ref={autoCompleteRef1}
          onChange={(e) => setQuery1(e.target.value)}
          placeholder="Google Map Search"
        />
      </div>
      <div className="flex justify-center items-center">
        <div className="grid md:grid-cols-7 grid-cols-3 absolute md:bottom-20 bg-[#ffffffcf] rounded-lg bottom-20 md:gap-0 gap-2">
          <div className="main-search-input-item location autocomplete-container">
            <input
              type="text"
              ref={autoCompleteRef}
              placeholder="Destination"
              className="autocomplete-input pac-target-input border-none"
              onChange={(e) => setQuery(e.target.value)}
              value={query}
            />
            <Link href="#">
              <i className="fa fa-dot-circle-o" />
            </Link>
          </div>
          <div className="main-search-input-item">
            <DatePicker
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
                setEndDate(null);
              }}
              className="border-none"
              placeholderText="Arrival Date"
            />
          </div>
          <div className="main-search-input-item">
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              placeholderText="Departure Date"
              minDate={startDate}
              className="border-none"
            />
          </div>
          <div className="main-search-input-item input-containers">
            <input
              style={{ width: "100%" }}
              type="number"
              name="quantity"
              placeholder="Guests"
              className="input-field"
              value={quantity}
              onChange={handleChange}
            />
            <div className="custom-buttons-second">
              <button
                type="button"
                style={{ borderRadius: "12px 12px 12px 12px" }}
                className="increment"
                onClick={increment}
              >
                <IoMdArrowDropup />
              </button>
              <button
                type="button"
                style={{ borderRadius: "12px 12px 12px 12px" }}
                className="decrement"
                onClick={decrement}
              >
                <IoMdArrowDropdown />
              </button>
            </div>
          </div>
          <div className="main-search-input-item">
            <select
              style={{ fontWeight: 800, fontSize: "13px" }}
              data-placeholder="Dwelling Type"
              className="nice-select chosen-select no-search-select border-none"
              onChange={(e) => setHandle(e.target.value)}
            >
              <option style={{ textAlign: "left" }}>Lodging Type</option>
              <option value={"Apt. / Condo / Loft"}>Apt. / Condo / Loft</option>
              <option value={"Bed & Breakfast"}>Bed & Breakfast</option>
              <option value={"Cabin"}>Cabin</option>
              <option value={"Campsite"}>Campsite</option>
              <option value={"Farm"}>Farm</option>
              <option value={"Hangar"}>Hangar</option>
              <option value={"Hangar Home"}>Hangar Home</option>
              <option value={"House"}>House</option>
              <option value={"Mansion"}>Mansion</option>
              <option value={"Novelty"}>Novelty</option>
              <option value={"RV"}>RV</option>
              <option value={"RV Pad"}>RV Pad</option>
              <option value={"Tiny Home"}>Tiny Home</option>
            </select>
          </div>
          <div className="main-search-input-item">
            <button
              className="color2-bg mt-0 text-white bg-[#8EC639] rounded-md font-medium w-full h-full"
              onClick={() => setFilters(!filters)}
            >
              Filter
            </button>
          </div>
          <div className="flex justify-center items-center">
            <button
              className="color2-bg mt-0 text-white bg-[#8EC639] rounded-md font-medium w-full h-full flex justify-center items-center md:py-0 py-3"
              onClick={GetData}
              disabled={!isFormValid()}
            >
              Search
              <IoSearchOutline />
            </button>
          </div>
        </div>
      </div>

      <div className="absolute top-0">
        <div className="md:flex hidden justify-between items-center px-3 bg-white md:w-8/12 mx-auto">
          <div>
            <h3 className="text-md text-nowrap font-bold">
              Distance From Runway
            </h3>
          </div>
          <button
            className="custom-button"
            onClick={() => setFilterItem("0 miles")}
          >
            <FaDotCircle className="icon" color="#8ec639" size={"20px"} />
            <span className="button-text">0 miles away</span>
          </button>
          <button
            className="custom-button"
            onClick={() => setFilterItem("0-1 miles")}
          >
            <FaDotCircle className="icon" color="#ffff00" size={"20px"} />
            <span className="button-text">Within 1 Mile</span>
          </button>
          <button
            className="custom-button"
            onClick={() => setFilterItem("1.1-3 miles")}
          >
            <FaDotCircle className="icon" color="#f17489" size={"20px"} />
            <span className="button-text">Within 3 Miles</span>
          </button>
          <button
            className="custom-button"
            onClick={() => setFilterItem("3.1-7 miles")}
          >
            <FaDotCircle className="icon" color="#ff9900" size={"20px"} />
            <span className="button-text">Within 7 Miles</span>
          </button>
          <button
            className="custom-button"
            onClick={() => setFilterItem("7 miles")}
            style={{ borderRadius: "0px 0px 7px 0px" }}
          >
            <FaDotCircle className="icon" color="#52c4d7" size={"20px"} />
            <span className="button-text">Over 7 Miles away</span>
          </button>
        </div>
      </div>
      <SearchModal filters={filters} setFilters={setFilters} />
    </>
  );
};

export default SearchLocation;
