import React from "react";
import Layout from "../Components/layout/Layout";

const Testimonials = () => {
  return (
    <Layout>
      <div className="container mx-auto md:px-20 px-4">
        <div className="container">
          <h2
            style={{
              fontSize: "30px",
              paddingBottom: "30px",
              fontWeight: " 700",
            }}
          >
            {" "}
            Testimonials
          </h2>
          {/* <span className="section-separator" /> */}
          <h2
            style={{
              textAlign: "center",
              fontSize: "20px",
              fontWeight: " 700",
            }}
          >
            Hear From Our Hosts and Guests
          </h2>
          <span style={{ fontSize: "15px", marginBottom: "30px" }}>
            Our greatest reward is your satisfaction. Share your experience with
            us!
          </span>
        </div>
        <div className="clearfix" />
        <div
          className="row"
          style={{ display: "flex", flexWrap: "wrap", marginTop: "50px" }}
        >
          <div className="col-md-4">
            <div className="testi-item fl-wrap ">
              <div className="testi-avatar">
                <img src="image/testimonial.jpg" alt="" />
              </div>
              <div className="testimonilas-text fl-wrap">
                <p className="imp">
                  Fly-Inn.com provides the best service. It's is like a curated
                  list of places to stay that includes only the properties that
                  would be considered 5-star on the fun and all-around awesome
                  scale...
                </p>

                <div className="testimonilas-avatar fl-wrap">
                  <h3 className="imp">Kendra M</h3>
                  <h4>Fly-Inn Guest</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="testi-item fl-wrap ">
              <div className="testi-avatar">
                <img src="image/testimonial1.jpg" alt="" />
              </div>
              <div className="testimonilas-text fl-wrap">
                <p className="imp">
                  I travel for work often and I like to commute in my own plane.
                  It's great that Fly-Inn specializes in unique spots, like
                  hangar homes and places near small airports. I love that I can
                  fly my plane to the destination closest to where I will be
                  working, park in the hangar and have a car there waiting for
                  me! Ingenious!
                </p>

                <div className="testimonilas-avatar fl-wrap">
                  <h3 className="imp">David M</h3>
                  <h4>Fly-Inn Guest</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="testi-item fl-wrap ">
              <div className="testi-avatar">
                <img src="image/testimonial2.jpg" alt="" />
              </div>
              <div className="testimonilas-text fl-wrap">
                <p className="imp">
                  I love meeting fun-loving people, so hosting is a blast for
                  me. My husband and I are pilots and we have been guests at
                  various Fly-Inn listings. I've never had a bad stay and all
                  our guests have been great. Great community, it feels like
                  family!
                </p>

                <div className="testimonilas-avatar fl-wrap">
                  <h3 className="imp">Jeannie B</h3>
                  <h4>Fly-Inn Host</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="testi-item fl-wrap ">
              <div className="testi-avatar">
                <img src="image/testimonial3.jpg" alt="" />
              </div>
              <div className="testimonilas-text fl-wrap">
                <p className="imp">
                  I have been staying at places on Fly-Inn for a few years now.
                  I enjoy seeing the inventory grow more and more. At first I
                  stayed at whatever was available, just for fun. Now there are
                  so many listings it's making me want to host, too!
                </p>

                <div className="testimonilas-avatar fl-wrap">
                  <h3 className="imp">Mike N</h3>
                  <h4>Fly-Inn Guest</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="testi-item fl-wrap ">
              <div className="testi-avatar">
                <img src="image/testimonial4.jpg" alt="" />
              </div>
              <div className="testimonilas-text fl-wrap">
                <p className="imp">
                  I'm not a pilot, but I do live across the street from a
                  municipal airport. When I heard about Fly-Inn, I saw a great
                  opportunity for me to make some extra cash from the pilot
                  community since my house is not in a desirable location for
                  other rental websites. It has worked out great for me. Very
                  clean people.
                </p>

                <div className="testimonilas-avatar fl-wrap">
                  <h3 className="imp">Gary S</h3>
                  <h4>Fly-Inn Host</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="testi-item fl-wrap ">
              <div className="testi-avatar">
                <img src="image/testimonial5.jpg" alt="" />
              </div>
              <div className="testimonilas-text fl-wrap">
                <p className="imp">
                  Fly-Inn.com lets me stay at the most fun places and there is
                  always something for my family to do. I have stayed at places
                  that even offer ATVs and boats! Our host even gave us
                  transportation to and from the airport!
                </p>

                <div className="testimonilas-avatar fl-wrap">
                  <h3 className="imp">Alexis H</h3>
                  <h4>Fly-Inn Guest</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Testimonials;
