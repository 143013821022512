import React, { useRef, useState } from "react";
import { useFormContext } from "../../../../../providers";

export const ElevationInput = ({ index }) => {
    const { watch, setValue, errors } = useFormContext();

    const [feetX, setFeetX] = useState("");
    const [feetY, setFeetY] = useState("");
    const [metersX, setMetersX] = useState("");
    const [metersY, setMetersY] = useState("");

    const feetYRef = useRef(null);
    const metersXRef = useRef(null);
    const metersYRef = useRef(null);

    const handleFeetXChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,4}$/.test(value)) {
            setFeetX(value);
            if (value.length === 4) {
                feetYRef.current.focus();
            }
        }
    };
    const handleFeetYChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,1}$/.test(value)) {
            setFeetY(value);
            if (value.length === 1) {
                metersXRef.current.focus();
            }
        }
    };

    const handleMetersXChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,4}$/.test(value)) {
            setMetersX(value);
            if (value.length === 4) {
                metersYRef.current.focus();
            }
        }
    };

    const handleMetersYChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,1}$/.test(value)) {
            setMetersY(value);
            updateFinalValue(feetX, feetY, metersX, value);
        } else {
            updateFinalValue(feetX, feetY, metersX, metersY);
        }
    };

    const updateFinalValue = (feetX, feetY, metersX, metersY) => {
        const finalFeetValue = parseFloat(`${feetX}.${feetY}`);
        const finalMeterValue = parseFloat(`${metersX}.${metersY}`);

        console.log(finalFeetValue, finalMeterValue)

        const fields = [...watch('airports')];
        fields[index]["elevation_feet"] = finalFeetValue;
        fields[index]["elevation_meter"] = finalMeterValue;

        setValue('airports', fields);
    };

    return (
        <>
            <label>Elevation</label>
            <div className="flex border border-input rounded-md gap-4 items-center px-2">
                <input
                    type="number"
                    value={feetX}
                    onChange={handleFeetXChange}
                    className="h-10 border-none shadow-none z-0 w-10"
                />
                <span style={{ fontWeight: "bolder" }}>.</span>
                <input
                    type="number"
                    ref={feetYRef}
                    value={feetY}
                    onChange={handleFeetYChange}
                    className="h-10 border-none shadow-none z-0 w-10"
                />
                <span>ft./</span>
                <input
                    type="number"
                    ref={metersXRef}
                    value={metersX}
                    onChange={handleMetersXChange}
                    className="h-10 border-none shadow-none z-0 w-10"
                />
                <span>.</span>
                <input
                    type="number"
                    ref={metersYRef}
                    value={metersY}
                    className="h-10 border-none shadow-none z-0 w-10"
                    onChange={handleMetersYChange}
                />
                <span>m</span>
            </div>
            {(errors?.airports?.[index]?.elevation_feet || errors?.airports?.[index]?.elevation_meter || watch('airports')?.[index]?.elevation_feet === 0 || watch('airports')?.[index]?.elevation_meter === 0) && (
                <p style={{ color: "red" }}>
                    {errors?.airports?.[index]?.elevation_feet || errors?.airports?.[index]?.elevation_meter || 'Airport Elevation is required.'}
                </p>
            )}
        </>
    );
};
