import React from 'react'
import { Link } from 'react-router-dom'

export const GeneralAgreement = ({ blog }) => {
    return (
        <div className="flex flex-wrap">
            <div className="w-full md:w-1/3 p-4">
                <div className="ab_text">
                    <div className="ab_text-title fl-wrap">
                        <h3>Fly-Ins</h3>
                        <span className="section-separator fl-sec-sep" />
                    </div>
                    <Link to={"/the-most-fun-fly-ins-in-america"}>
                        <p className="text-sm text-black underline">
                            The Most Fun Fly-Ins in America
                        </p>
                    </Link>
                </div>
            </div>

            <div className="w-full md:w-1/3 p-4">
                <div className="ab_text">
                    <div className="ab_text-title fl-wrap">
                        <h3>Air Shows</h3>
                        <span className="section-separator fl-sec-sep" />
                    </div>
                    <Link to={"/most-popular-air-shows-in-the-united-states"}>
                        <p className="text-sm text-black underline">
                            11 Most Popular Air Shows in the United States
                        </p>
                    </Link>
                </div>
            </div>

            <div className="w-full md:w-1/3 p-4">
                <div className="ab_text">
                    <div className="ab_text-title fl-wrap">
                        <h3>Air Parks</h3>
                        <span className="section-separator fl-sec-sep" />
                    </div>
                    <Link to={"/airpark-communities-in-the-usa"}>
                        <p className="text-sm text-black underline">
                            4 of the Grooviest Airpark Communities in the USA!
                        </p>
                    </Link>
                </div>
            </div>

            <div className="w-full md:w-1/3 p-4">
                <div className="ab_text">
                    <div className="ab_text-title fl-wrap">
                        <h3>Aviation Homes</h3>
                        <span className="section-separator fl-sec-sep" />
                    </div>
                    <Link to={"/innovative-ideas-for-a-hangar-home"}>
                        <p className="text-sm text-black underline">
                            Innovative Ideas for a Hangar Home
                        </p>
                    </Link>
                </div>
            </div>

            <div className="w-full md:w-1/3 p-4">
                <div className="ab_text">
                    <div className="ab_text-title fl-wrap">
                        <h3>Travel</h3>
                        <span className="section-separator fl-sec-sep" />
                    </div>
                    <Link to={"/ultimate-travel-packing-list-for-ladies"}>
                        <p className="text-sm text-black underline">
                            Ultimate Travel Packing List For Ladies
                        </p>
                    </Link>
                    <p className="text-sm text-black underline">
                        Ultimate Travel Packing List for Pilots
                    </p>
                    <p className="text-sm text-black underline">
                        Ultimate Travel Pack List for Parents of Young Children
                    </p>
                    <p className="text-sm text-black underline">
                        Ultimate Travel Pack List for Pet Parents
                    </p>
                    <p className="text-sm text-black underline">
                        Ultimate Airplane Camping List
                    </p>
                    <p className="text-sm text-black underline">
                        Ultimate Pilot Flight Bag List
                    </p>
                </div>
            </div>

            <div className="w-full md:w-1/3 p-4">
                <div className="ab_text">
                    <div className="ab_text-title fl-wrap">
                        <h3>Travel Tips</h3>
                        <span className="section-separator fl-sec-sep" />
                    </div>
                    <p className="text-sm text-black underline">
                        Ultimate Travel Packing List for Gentlemen
                    </p>
                </div>
            </div>

            {blog?.map((e) => (
                <div className="w-full md:w-1/3 p-4" key={e?.id}>
                    <div className="ab_text">
                        <div className="ab_text-title fl-wrap">
                            <h3>{e?.heading}</h3>
                            <span className="section-separator fl-sec-sep" />
                        </div>
                        <Link
                            to={"/most-popular-air-shows-in-the-united-states"}
                            state={{ data: e }}
                        >
                            <p className="text-sm text-black underline">{e?.sub_heading}</p>
                        </Link>
                    </div>
                </div>
            ))}
        </div>
    )
}
