/* eslint-disable */
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import ReactStars from "react-rating-stars-component";
const AllReviews = (props) => {
    const { reviewOpen, setReviewOpen, data } = props
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            overflow: "hidden",
            top: "-40px"
        }}
            className='modal-overlay-custom'
        >
            <Modal
                show={reviewOpen}
                onHide={() => setReviewOpen(false)}
                style={{ height: "600px", overflowY: "hidden", top: "-20px" }}
                keyboard={false}

            >
                <Modal.Header >
                    <Modal.Title>All Reviews</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height: "400px", overflowY: "scroll" }}>
                    <section id="custom-testimonials">
                        <h3 className="title text-start " style={{ display: "flex", alignItems: "center" }}>
                            <ReactStars
                                count={1}
                                value={1}
                                size={30}
                                activeColor="#ffd700"

                            /> {data?.average_review?.overall_average}
                        </h3>
                        <div class="custom-testimonial-box-container">
                            {data?.booking_reviews?.map((e) => (
                                <div class="custom-testimonial-box">
                                    <div class="custom-box-top">
                                        <div class="custom-profile">
                                            <div class="custom-profile-img">
                                                <img src={e?.user_image} />
                                            </div>
                                            <div class="custom-name-user">
                                                <strong>{e?.user_username}</strong>
                                                {/* <span>@liammendes</span> */}
                                            </div>
                                        </div>
                                        <div class="custom-reviews">
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                        </div>
                                    </div>
                                    <div class="custom-client-comment">
                                        <p>{e?.comment}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </section>

                </Modal.Body>

            </Modal>
        </div>
    )
}

export default AllReviews