/* eslint-disable */
import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";

// import required modules
import { Pagination, FreeMode } from "swiper/modules";
import { Link } from "react-router-dom";
import FindUsImage from "../ui/FindUsImage";

const AboutSection = () => {
  const iframeRef = useRef(null);
  const data = [
    {
      path: "images/avatar/swiper5.jpg",
      title: "Food!",
      des: "Some homes are in extremely remote, secluded and private locations and being able to get food might be a challenge. When this is the case, many hosts offer catering or a fully stocked kitchen with your favorite staples and treats!",
    },
    {
      path: "images/avatar/swiper6.jpg",
      title: "Optional transportation!",
      des: "Some hosts are commercial pilots who offer rides for a fee. Others simply offer free rides. They love it because not only do they get to meet their guests, they also get to give them a bird’s-eye view of what fun awaits them when they land!",
    },
    {
      path: "images/avatar/swiper1.jpg",
      title: "Runway!",
      des: "Every listing on Fly-Inn either has a landing strip or helipad conveniently located on the property or is situated within 10 minutes of an airport you are likely to pilot into yourself. Most homes are either hangar homes, airpark homes or have their own private runway. Some homes even offer a water landing!",
    },
    {
      path: "images/avatar/swiper2.jpg",
      title: "Aircraft Storage!",
      des: "Whether it be access to a hangar, tie-downs, etc. on the property itself, or whether it be storage services the nearby airport offers, you are sure to have a place where you can securely store your aircraft.",
    },
    {
      path: "images/avatar/swiper3.jpg",
      title: "Cars!",
      des: "What good is it to land if you can’t get around once you arrive? All our hosts know this and provide either a free loaner or a nice car to rent or at least transportation to a nearby car rental.If the airport is a few minutes from the property, transportation will be made available to you at the airport upon your arrival.",
    },
    {
      path: "images/avatar/swiper4.jpg",
      title: "Toys!",
      des: "Optional goodies are up to your host and the area. Some hosts even offer other aircraft, ATVs, watercraft, scooters, bikes, dirt bikes and more! Be sure to check the listing to see what is included in your rental.",
    },
  ];
  useEffect(() => {
    const iframe = iframeRef.current;
    const src = iframe.src;
    iframe.src = src + "&autoplay";
  }, []);
  return (
    <>
      <section className="h-screen">
        <div className="video-container">
          <iframe
            ref={iframeRef}
            width="1583"
            height="890.438"
            src="https://www.youtube.com/embed/_dTY5DbOCWM?si=igffRsdXps3juXKX"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="section-title" style={{ color: "#3B4249" }}>
            <h2> All-inclusive, aviation-centric lodging.</h2>
            {/* <span className="section-separator" /> */}
            <p
              style={{
                textAlign: "center",
                color: "black",
                fontWeight: "700",
                fontSize: "16px",
              }}
            >
              Why waste a good hangar home or airpark home?
            </p>
          </div>
          {/*about-wrap */}
          <div className="about-wrap">
            <div className="row">
              <div className="col-md-6">
                <div className="ab_text">
                  <div className="ab_text-title fl-wrap">
                    <h3 style={{ fontWeight: "700", fontSize: "14px" }}>
                      GUESTS
                    </h3>

                    {/* <span className="section-separator fl-sec-sep" /> */}
                  </div>
                  <p style={{ fontWeight: "500", color: "#3B4249" }}>
                    Are you looking for a fun place to fly where you can safely
                    hangar or tie down your aircraft? Maybe you are looking for
                    a fun getaway or maybe you are on a business trip and a
                    Fly-Inn would be much more convenient than a hotel. Do you
                    need to rent a car or a different airplane when you land?
                    Are you craving adventure?
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="ab_text">
                  <div className="ab_text-title fl-wrap">
                    <h3 style={{ fontWeight: "700", fontSize: "14px" }}>
                      HOSTS
                    </h3>

                    {/* <span className="section-separator fl-sec-sep" /> */}
                  </div>
                  <p style={{ fontWeight: "500", color: "#3B4249" }}>
                    Are you looking into renting out your home that has access
                    to a runway or landing strip? Maybe you have a spacious
                    hangar home with a spare room. Maybe your home is
                    conveniently by an airport. Are you wishing you could rent
                    it and share it with your fellow pilots who have the same
                    sense of respect and responsibility you do?
                  </p>
                </div>
              </div>
            </div>
            <div className="section-title" style={{ paddingBottom: "0px" }}>
              {/* <span className="section-separator" /> */}
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#3B4249",
                }}
              >
                Look no further…Fly-Inn has you covered.
              </p>
            </div>
          </div>
          {/* about-wrap end  */}
        </div>
      </section>

      <section>
        <div className="container">
          <h2
            className="fontquick"
            style={{
              fontSize: "28px",
              lineHeight: "40px",
              marginBottom: "30px",
              fontFamily: '"Quicksand", sans-serif',
              color: "#3b4249",
            }}
          >
            What's in store.
          </h2>
          <div className="row">
            {/* team-item */}
            {data?.map((e) => {
              return (
                <>
                  <div style={{ marginBottom: "30px" }} className="col-md-4">
                    <div style={{ borderRadius: "0px" }}>
                      <img src={e?.path} alt="" className="respimg" />
                    </div>
                    <div className="team-info fl-wrap">
                      <h3
                        style={{
                          fontSize: "18px",
                          lineHeight: "24px",
                          fontWeight: "700",
                          fontFamily: "Quicksand",
                          marginTop: "12px",
                          color: "#3b4249",
                        }}
                      >
                        {e?.title}
                      </h3>
                      <p
                        style={{
                          fontFamily: '"Quicksand", sans-serif',
                          fontSize: "18px",
                          lineHeight: "24px",
                          fontWeight: 500,
                          color: "#3B4249",
                        }}
                      >
                        {e.des}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="section-title">
            <h2
              style={{
                fontWeight: "700",
                fontSize: "28px",
                color: "#3b4249",
                fontFamily: "Quicksand",
                display: "flex",
                marginBottom: "30px",
                color: "#3B4249",
              }}
            >
              Our Values.
            </h2>
          </div>
          <div className="row">
            {/* team-item */}
            <div className="col-md-4">
              <div style={{ borderRadius: "0px" }}>
                <img src="images/bg/2.jpg" alt="" className="respimg" />
              </div>
              <div className="team-info fl-wrap">
                <h3
                  style={{
                    fontSize: "18px",
                    lineHeight: "24px",
                    fontWeight: "700",
                    fontFamily: "Quicksand",
                    marginTop: "12px",
                    color: "#3b4249",
                  }}
                >
                  Teamwork
                </h3>
                <p style={{ color: "#3B4249" }}>
                  It takes all three of us to provide a great experience. <br />
                  Pilot-Inn is committed to timely and effective communication
                  and making sure you are completely satisfied with your
                  experience. <br />
                  Hosts are committed to your satisfaction, by contract. <br />
                  Guests are also committed by contract to taking care of the
                  space entrusted to them and to being safe.
                </p>
              </div>
            </div>
            {/* team-item  end*/}
            {/* team-item */}
            <div className="col-md-4">
              <div style={{ borderRadius: "0px" }}>
                <img src="images/bg/4value.jpg" alt="" className="respimg" />
              </div>
              <div className="team-info fl-wrap">
                <h3
                  style={{
                    fontSize: "18px",
                    lineHeight: "24px",
                    fontWeight: "700",
                    fontFamily: "Quicksand",
                    marginTop: "12px",
                    color: "#3b4249",
                  }}
                >
                  Integrity and respect
                </h3>
                <p style={{ color: "#3B4249" }}>
                  The very reason Pilot-Inn is so successful is that as a
                  community we know we can trust each other’s level of respect
                  and integrity. We are a family of like-minded, respectful
                  individuals and we understand each other. We take pride in
                  treating each other according to the Golden Rule.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div style={{ borderRadius: "0px" }}>
                <img src="images/bg/3value.jpg" alt="" className="respimg" />
              </div>
              <div className="team-info fl-wrap">
                <h3
                  style={{
                    fontSize: "18px",
                    lineHeight: "24px",
                    fontWeight: "700",
                    fontFamily: "Quicksand",
                    marginTop: "12px",
                    color: "#3b4249",
                  }}
                >
                  Quality and innovation
                </h3>
                <p style={{ color: "#3B4249" }}>
                  As a team, we are all committed to the highest quality rentals
                  and service. We love coming up with new ways to make your
                  stays better and better over time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <FindUsImage />
      </section>
    </>
  );
};

export default AboutSection;
