/* eslint-disable */
import axios from 'axios';
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import BASE_URL from './auth/Baseurl';
import ReactStars from "react-rating-stars-component";
import Swal from 'sweetalert2';

const BookingEditModal = ({ isOpen2, setIsOpen2, selectedId }) => {
    const handleClose = () => setIsOpen2(false);
    const [reviewValue, setReviewValue] = useState('');
    const [chatValue, setChatValue] = useState('');

    const [overallSatisfaction, setOverallSatisfaction] = useState(0);
    const [cleanliness, setCleanliness] = useState(0);
    const [accuracy, setAccuracy] = useState(0);
    const [communication, setCommunication] = useState(0);
    const [location, setLocation] = useState(0);
    const [checkIn, setCheckIn] = useState(0);
    const [value, setValue] = useState(0);
    console.log(cleanliness, "newRating")
    const ratingChanged = (newRating, category) => {
        switch (category) {
            case "Overall Satisfaction":
                setOverallSatisfaction(newRating);
                break;
            case "Cleanliness":
                setCleanliness(newRating);
                ;

                break;
            case "Accuracy":
                setAccuracy(newRating);
                break;
            case "Communication":
                setCommunication(newRating);
                break;
            case "Location":
                setLocation(newRating);
                break;
            case "Check-in":
                setCheckIn(newRating);
                break;
            case "Value":
                setValue(newRating);
                break;
            default:
                break;
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!reviewValue || !selectedId || !overallSatisfaction || !cleanliness || !accuracy || !communication || !location || !checkIn || !value) {
            alert("Please fill in all required fields.");
            return;
        }
        const review = new FormData();
        review.append("comment", reviewValue);
        review.append("feedback_msg", chatValue);
        review.append("overall_satisfaction", overallSatisfaction);
        review.append("cleanliness", cleanliness);
        review.append("accuracy", accuracy);
        review.append("communication", communication);
        review.append("location", location);
        review.append("checkin", checkIn);
        review.append("value", value);
        review.append("booking_id", selectedId);
        review.append("status", "New");
        try {
            const response = await axios.post(`${BASE_URL}/booking-review`, review, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });

            Swal.fire({
                position: "center",
                html: `
                    <div style="display: flex; flex-direction: column; align-items: center;">
         <svg
                      viewBox="0 0 1024 1024"
                      height="4em"
                      width="4em"
                      style="margin-bottom: 10px;"
                  >
                      <!-- Outer circle with color #8ec639 -->
                      <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                      
                      <!-- Check mark with color #A2c66b -->
                      <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
                  </svg>
                        <p style="margin: 0; font-size: 1.2em;">Review submitted successfully.</p>
                    </div>
                `,
                showConfirmButton: true,
                // timer: 1500  // Close after 1.5 seconds
            });
            handleClose();
        } catch (err) {
            Swal.fire({
                icon: "error",
                text: err?.response?.data?.message,
            });
            handleClose();
        }
    };

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            overflow: "hidden",
            top: "-40px"

        }}>
            <Modal
                style={{ height: "600px", overflowY: "hidden", top: "-20px" }}
                show={isOpen2}
                onHide={handleClose}
                keyboard={false}
            >
                <Modal.Header >
                    <Modal.Title>Review</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height: "400px", overflowY: "scroll" }}>
                    <div className="">
                        <div className="listsearch-input-item">
                            <label>Overall Satisfaction</label>
                            <br />
                            <span style={{ fontSize: "10px" }}>How much you enjoyed the experience of staying at this property</span>
                            <ReactStars
                                count={5}
                                onChange={(newRating) => ratingChanged(newRating, "Overall Satisfaction")}
                                size={30}
                                activeColor="#ffd700"
                            />
                        </div>
                        <div className="listsearch-input-item ">
                            <label>Cleanliness</label>
                            <br />
                            <span style={{ fontSize: "10px" }}>How clean and tidy the property was upon arrival</span>
                            <ReactStars
                                count={5}
                                onChange={(newRating) => ratingChanged(newRating, "Cleanliness")}
                                size={30}
                                activeColor="#ffd700"
                            />
                        </div>
                        <div className="listsearch-input-item ">
                            <label>Accuracy</label>
                            <br />
                            <span style={{ fontSize: "10px" }}>How well the listing description and photos matched the property</span>
                            <ReactStars
                                count={5}
                                onChange={(newRating) => ratingChanged(newRating, "Accuracy")}
                                size={30}
                                activeColor="#ffd700"
                            />
                        </div>
                        <div className="listsearch-input-item ">
                            <label>Communication</label>
                            <br />
                            <span style={{ fontSize: "10px" }}>How responsive and clear your Host's communication was</span>
                            <ReactStars
                                count={5}
                                onChange={(newRating) => ratingChanged(newRating, "Communication")}
                                size={30}
                                activeColor="#ffd700"
                            />
                        </div>
                        <div className="listsearch-input-item ">
                            <label>Location</label>
                            <br />
                            <span style={{ fontSize: "10px" }}>How convenient and appealing the property's location was</span>
                            <ReactStars
                                count={5}
                                onChange={(newRating) => ratingChanged(newRating, "Location")}
                                size={30}
                                activeColor="#ffd700"
                            />
                        </div>
                        <div className="listsearch-input-item ">
                            <label>Check-in</label>
                            <br />
                            <span style={{ fontSize: "10px" }}>How easy and smooth the check-in process was</span>
                            <ReactStars
                                count={5}
                                onChange={(newRating) => ratingChanged(newRating, "Check-in")}
                                size={30}
                                activeColor="#ffd700"
                            />
                        </div>
                        <div className="listsearch-input-item ">
                            <label>Value</label>
                            <br />
                            <span style={{ fontSize: "10px" }}>How you perceived the value of your stay relative to the price you paid</span>
                            <ReactStars
                                count={5}
                                onChange={(newRating) => ratingChanged(newRating, "Value")}
                                size={30}
                                activeColor="#ffd700"
                            />
                        </div>
                    </div>
                    <form id="add-comment" className="add-comment  custom-form" name="rangeCalc">
                        <fieldset>
                            <div className="list-single-main-item_content fl-wrap">
                                <textarea
                                    cols={40}
                                    rows={3}
                                    onChange={(e) => setReviewValue(e.target.value)}
                                    placeholder="Written Review: Please provide a written account of your experience, highlighting positive aspects and any areas for improvement."
                                    defaultValue={""}
                                />
                            </div>
                        </fieldset>
                        <div style={{ display: "flex", justifyContent: "flex-end", paddingBottom: "20px" }}>

                        </div>
                    </form>
                    <form id="add-comment" className="add-comment  custom-form" name="rangeCalc">
                        <fieldset>
                            <div className="list-single-main-item_content fl-wrap">
                                <textarea
                                    cols={40}
                                    rows={3}
                                    onChange={(e) => setChatValue(e.target.value)}
                                    placeholder="Optional - Private Feedback. The public will not see this."
                                    defaultValue={""}
                                />
                            </div>
                        </fieldset>
                        <div style={{ display: "flex", justifyContent: "flex-end", paddingBottom: "20px" }}>
                            <Button variant="primary" onClick={handleSubmit}>Submit</Button>
                        </div>
                    </form>
                </Modal.Body>

            </Modal>
        </div>
    )
}

export default BookingEditModal