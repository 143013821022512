/* eslint-disable */

import BtnLoader from "./BtnLoader/BtnLoader";
import React, { useEffect, useRef, useState } from "react";
import { MdClose } from "react-icons/md";
import { FaCheck } from "react-icons/fa6";
import axios from "axios";
import BASE_URL from "./auth/Baseurl";
import { FaCheckCircle, FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, Navigate, useNavigate } from "react-router-dom";
import getProfile from "./getProfile";
import { SlSocialFacebook, SlSocialYoutube } from "react-icons/sl";
import { FaCamera } from "react-icons/fa";
import { TfiVimeo } from "react-icons/tfi";
import { CiLinkedin } from "react-icons/ci";
import { LiaTripadvisor } from "react-icons/lia";
import Swal from "sweetalert2";
import { infoPopupMessage, warningPopup } from "../helpers";

function ProfileRight() {
  const [loaders, setLoaders] = useState(false);
  const [pageCome, setPageCome] = useState(true);
  // const [apiData, setApiData] = useState({});

  const [data, setData] = useState(localStorage.getItem("data") || null);

  const navigate = useNavigate();


  useEffect(() => {
    getProfile().then((res) => {

      // Check if there's form data in sessionStorage
      // const savedFormData = sessionStorage.getItem("formdata");

      // If data exists in sessionStorage, use that data, otherwise use res
      // if (savedFormData) {
      //   setFormdata(JSON.parse(savedFormData)); // Set sessionStorage data
      //   setData(JSON.parse(savedFormData));
      // } else if (res?.user) {
      setData(res?.user);
      setFormdata(res?.user); // Use data from res
      // }


      if (res?.user?.complete === "99%") {
        infoPopupMessage(`
            <div style="display: flex; flex-direction: column; align-items: center;">
            <p style="text-align: center">Your account is now under review. Please give the system some time to verify.</p>
            <p style="text-align: center">
              Verification completes your profile to 100%. The process may take up to 60 minutes. 
            </p>
            <p style="text-align: center">
            You will receive an email notification when the process is complete. </p>
            </div>
        `)
        setPageCome(false);

      }
      else if (res?.user?.complete !== "100%" && res?.user?.complete !== "99%") {
        warningPopup(`
            <div style="display: flex; flex-direction: column; align-items: center;">
            <p style="text-align: center">Please complete your profile to 100%. You will not be able to host or book until it is complete.</p>
            <p style="text-align: center">
             Once we have verified your identity, you will receive a message letting you know you are all set.
            </p>
            <p style="text-align: center">
             To complete your profile properly, watch the Registration video on our <br/> <a  target="_blank" style="color: #af2322; text-decoration: underline;" href="https://youtube.com/@fly-inn">YouTube Channel</a>.
            </p>
            </div>
        `)
        setPageCome(false);

      }
    });
  }, []);

  const [formdata, setFormdata] = useState({
    first_name: data?.first_name || "",
    last_name: data?.last_name || "",
    email: data?.email || "",
    username: data?.username || "",
    complete: data?.complete || "",
    password: "",
    image: data?.image || "",
    given_name: data?.given_name || "",
    native_language: data?.native_language || "",
    other_language: data?.other_language || "",
    other_phone: data?.other_phone || "",
    phone: data?.phone || "",
    role: data?.role || "",
    street_address: data?.street_address || "",
    apt_suit: data?.apt_suit || "",
    city: data?.city || "",
    state: data?.state || "",
    zip_code: data?.zip_code || "",
    neighbourhood: data?.neighbourhood || "",
    country: data?.country || "",
    additional_email: data?.additional_email || "",
    phone: data?.phone || "",
    contact_name: data?.contact_name || "",
    contact_relationship: data?.contact_relationship || "",
    top_advisor_url: data?.top_advisor_url || "",
    airbnb_url: data?.airbnb_url || "",
    vimeo_url: data?.vimeo_url || "",
    youtube_url: data?.youtube_url || "",
    pinterest_url: data?.pinterest_url || "",
    instagram_url: data?.instagram_url || "",
    vrbo_url: data?.vrbo_url || "",
    linkedin_url: data?.linkedin_url || "",
    twitter_url: data?.twitter_url || "",
    facebook_url: data?.facebook_url || "",
    contact_phone: data?.contact_phone || "",
    contact_email: data?.contact_email || "",
    pay_we_bank_name: data?.pay_we_bank_name || "",
    pay_we_acc_number: data?.pay_we_acc_number || "",
    pay_we_routing_number: data?.pay_we_routing_number || "",
    pay_you_cvv: data?.pay_you_cvv || "",
    pay_you_zip_code: data?.pay_you_zip_code || "",
    pay_you_expiry_date: data?.pay_you_expiry_date || "",
    pay_you_credit_card: data?.pay_you_credit_card || "",
    pay_you_card_name: data?.pay_you_card_name || "",

    // Add billing fields if they are separate
    billing_street_address: data?.billing_street_address || "",
    billing_apt_suit: data?.billing_apt_suit || "",
    billing_city: data?.billing_city || "",
    billing_state: data?.billing_state || "",
    billing_zip_code: data?.zip_code || "",
    billing_neighbourhood: data?.billing_neighbourhood || "",
    billing_country: data?.billing_country || "",
    bio: data?.bio || "",
  });

  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    username: "",
    given_name: "",
    native_language: "",
    other_language: "",
    other_phone: "",
    phone: "",
    street_address: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
    phone: "",
    contact_name: "",
    contact_relationship: "",
    contact_phone: "",
    contact_email: "",
    pay_you_cvv: "",
    pay_you_zip_code: "",
    pay_you_expiry_date: "",
    pay_you_credit_card: "",
    pay_you_card_name: "",
    billing_street_address: "",
    billing_city: "",
    billing_state: "",
    billing_zip_code: "",
    billing_country: "",
    bio: "",
    // pay_we_acc_number: "",
    // pay_we_routing_number: "",
    // pay_we_bank_name: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormdata((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value === "" ? "Required" : "",
    }));
  };
  // mailing address End  //

  // Billing address start  //
  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setFormdata({
        ...formdata,
        billing_street_address: formdata.street_address,
        billing_apt_suit: formdata.apt_suit,
        billing_city: formdata.city,
        billing_state: formdata.state,
        billing_zip_code: formdata.zip_code,
        billing_neighbourhood: formdata.neighbourhood,
        billing_country: formdata.country,
      });
    } else {
      // Clear billing address fields when checkbox is unchecked
      setFormdata({
        ...formdata,
        billing_street_address: "",
        billing_apt_suit: "",
        billing_city: "",
        billing_state: "",
        billing_zip_code: "",
        billing_neighbourhood: "",
        billing_country: "",
      });
    }
  };
  // Billing address End  //

  const [image, setImage] = useState(data?.image || null);
  const [secondImg, setSecondImg] = useState(data?.air_men || null);
  const [secImg, setSecImg] = useState("");
  const [secondImgBack, setSecondImgBack] = useState(
    data?.air_men_back || null
  );
  const [secImgBack, setSecImgBack] = useState("");
  const [newImageFile, setNewImageFile] = useState(null);
  const [thirdImg, setThirdImg] = useState(data?.driving_license || null);
  const [thirFile, setThirFile] = useState("");
  const [thirdImgBack, setThirdImgBack] = useState(
    data?.driving_license_back || null
  );
  const [thirFileBack, setThirFileBack] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isProfileImageRequired, setIsProfileImageRequired] = useState(false);
  const [isFileRequired, setIsFileRequired] = useState(false);
  const [isFileRequiredBack, setIsFileRequiredBack] = useState(false);
  const [isFileRequiredTwo, setIsFileRequiredTwo] = useState(false);
  const [isFileRequiredTwoBack, setIsFileRequiredTwoBack] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const fileInputRef = useRef(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const objectURL = URL.createObjectURL(file);
      setImage(objectURL);
      setNewImageFile(file);
    }
  };
  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };
  const handleDeleteImage = () => {
    setImage(null);
    setNewImageFile(null);
    fileInputRef.current.value = null;
  };

  const handleSecondImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const objectURL = URL.createObjectURL(file);
      setSecondImg(objectURL);
      setSecImg(file);
      setIsFileRequiredTwo(false);
    }
  };
  const handleSecondImageChangeBack = (event) => {
    const file = event.target.files[0];
    if (file) {
      const objectURL = URL.createObjectURL(file);
      setSecondImgBack(objectURL);
      setSecImgBack(file);
      setIsFileRequiredTwoBack(false);
    }
  };
  // For third image
  const handleThirdImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const objectURL = URL.createObjectURL(file);
      setThirdImg(objectURL);
      setThirFile(file);
      setIsFileRequired(false);
    }
  };

  const handleThirdImageChangeBack = (event) => {
    const file = event.target.files[0];
    if (file) {
      const objectURL = URL.createObjectURL(file);
      setThirdImgBack(objectURL);
      setThirFileBack(file);
      setIsFileRequiredBack(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasError = false;
    if (!image && !newImageFile) {
      setIsProfileImageRequired(true);
      hasError = true;
    } else {
      setIsProfileImageRequired(false);
    }
    if (!thirdImg && !thirFile) {
      setIsFileRequired(true);
      hasError = true;
    } else {
      setIsFileRequired(false);
    }
    if (!thirdImgBack && !thirFileBack) {
      setIsFileRequiredBack(true);
      hasError = true;
    } else {
      setIsFileRequiredBack(false);
    }

    if (!secondImg && !secImg) {
      setIsFileRequiredTwo(true);
      hasError = true;
    } else {
      setIsFileRequiredTwo(false);
    }
    if (!secondImgBack && !secImgBack) {
      setIsFileRequiredTwoBack(true);
      hasError = true;
    } else {
      setIsFileRequiredTwoBack(false);
    }
    const newErrors = {};
    const fields = [
      "first_name",
      "last_name",
      "email",
      "username",
      "given_name",
      "native_language",
      "other_language",
      "other_phone",
      "phone",
      "street_address",
      "city",
      "state",
      "zip_code",
      "country",
      "contact_name",
      "contact_relationship",
      "contact_phone",
      "pay_you_card_name",
      "contact_email",
      "pay_you_cvv",
      "pay_you_zip_code",
      "pay_you_expiry_date",
      "pay_you_credit_card",
      "billing_street_address",
      "billing_city",
      "billing_state",
      "billing_zip_code",
      "billing_country",
      "bio",
    ];

    fields.forEach((field) => {
      if (!formdata[field]) {
        newErrors[field] = "This field is required";
        hasError = true;
      }
    });

    // Set errors if there are any missing fields
    setErrors(newErrors);
    console.log(newErrors, "checkingError");

    // Show alert if there are errors
    if (hasError) {
      // console.log(hasError,"checkingError");
      Swal.fire({
        icon: "error",
        text: "Please complete all required fields.",
      });
      return;
    }
    setLoaders(true);
    console.log(formdata, "formdata in all");
    const formData = new FormData();
    formData.append("image", newImageFile);
    formData.append("role", formdata?.role);
    formData.append(
      "password",
      newPassword && confirmPassword === newPassword
        ? newPassword
        : data?.password
    );
    formData.append("first_name", formdata.first_name);
    formData.append("last_name", formdata.last_name);
    formData.append("email", formdata.email);
    formData.append("username", formdata.username);
    formData.append("given_name", formdata?.given_name);
    formData.append("bio", formdata?.bio);
    formData.append("native_language", formdata?.native_language);
    formData.append("other_language", formdata?.other_language);
    formData.append("other_phone", formdata?.other_phone);
    formData.append("phone", formdata?.phone);
    formData.append("street_address", formdata?.street_address);
    formData.append("apt_suit", formdata?.apt_suit);
    formData.append("city", formdata?.city);
    formData.append("state", formdata?.state);
    formData.append("zip_code", formdata?.zip_code);
    formData.append("neighbourhood", formdata?.neighbourhood);
    formData.append("country", formdata?.country);
    formData.append("additional_email", formdata?.additional_email);
    formData.append("phone", formdata?.phone);
    formData.append("contact_name", formdata?.contact_name);
    formData.append("contact_relationship", formdata?.contact_relationship);
    formData.append("top_advisor_url", formdata?.top_advisor_url);
    formData.append("airbnb_url", formdata?.airbnb_url);
    formData.append("vimeo_url", formdata?.vimeo_url);
    formData.append("pay_you_cvv", formdata?.pay_you_cvv);
    formData.append("pay_you_zip_code", formdata?.pay_you_zip_code);
    formData.append("pay_you_expiry_date", formdata?.pay_you_expiry_date);
    formData.append("pay_you_credit_card", formdata?.pay_you_credit_card);
    formData.append("pay_you_card_name", formdata?.pay_you_card_name);
    formData.append("youtube_url", formdata?.youtube_url);
    formData.append("pinterest_url", formdata?.pinterest_url);
    formData.append("contact_email", formdata?.contact_email);
    formData.append("contact_phone", formdata?.contact_phone);
    formData.append("instagram_url", formdata?.instagram_url);
    formData.append("pay_we_bank_name", formdata?.pay_we_bank_name);
    formData.append("pay_we_acc_number", formdata?.pay_we_acc_number);
    formData.append("pay_we_routing_number", formdata?.pay_we_routing_number);
    formData.append("vrbo_url", formdata?.vrbo_url);
    formData.append("linkedin_url", formdata?.linkedin_url);
    formData.append("twitter_url", formdata?.twitter_url);
    formData.append("facebook_url", formdata?.facebook_url);
    formData.append("billing_apt_suit", formdata?.billing_apt_suit);
    formData.append("billing_city", formdata?.billing_city);
    formData.append("billing_country", formdata?.billing_country);
    formData.append("billing_neighbourhood", formdata?.billing_neighbourhood);
    formData.append("billing_state", formdata?.billing_state);
    formData.append("billing_street_address", formdata?.billing_street_address);
    formData.append("billing_zip_code", formdata?.billing_zip_code);

    typeof secImg === "object" && formData.append("air_men", secImg);
    typeof secImgBack === "object" &&
      formData.append("air_men_back", secImgBack);

    typeof thirFile === "object" &&
      formData.append("driving_license", thirFile);
    typeof thirFileBack === "object" &&
      formData.append("driving_license_back", thirFileBack);

    try {
      const response = await axios.post(
        `${BASE_URL}/user/${data.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const res = await getProfile();
      console.log(res, "loocaldata");
      setFormdata(res?.user);
      localStorage.setItem("data", JSON.stringify(res?.user));
      setNewPassword();
      setConfirmPassword();
      setLoaders(false);
      Swal.fire({
        position: "center",
        html: `
            <div style="display: flex; flex-direction: column; align-items: center;">
               <svg
                      viewBox="0 0 1024 1024"
                      height="4em"
                      width="4em"
                      style="margin-bottom: 10px;"
                  >
                      <!-- Outer circle with color #8ec639 -->
                      <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                      
                      <!-- Check mark with color #A2c66b -->
                      <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
                  </svg>
                <p style="margin: 0; font-size: 1.2em;">${res?.user?.complete === "99%"
            ? "Your account is now under review. Please give the system some time to verify. Verification completes your profile to 100%. The process may take up to 60 minutes. You will receive an email notification when the process is complete."
            : "Profile update successful."
          }</p>
            </div>
        `,
        showConfirmButton: true,
      });
    } catch (err) {
      setLoaders(false);
      errorPopup('', err?.response?.data?.message);

    }
  };


  // Save form data to sessionStorage whenever it changes
  // useEffect(() => {
  //   sessionStorage.setItem("formdata", JSON.stringify(formdata));
  // }, [formdata]);


  // Function to handle the browser refresh or navigation
  const handleBeforeUnload = (event) => {
    return (event.returnValue = "Are you sure you want to leave?");
  };

  // Add event listener on mount and clean up on unmount
  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formdata]); // Re-run when form data changes

  // create new password start code //
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");

  const toggleCurrentVisibility = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordConfirm = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleNewPasswordChange = (e) => {
    const value = e.target.value;
    setNewPassword(value);
    if (confirmPassword && value !== confirmPassword) {
      setErrorMessage("Confirm password is not correct");
    } else {
      setErrorMessage("");
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    if (newPassword === value) {
      setErrorMessage("");
    } else {
      setErrorMessage("Confirm password is not correct");
    }
  };

  console.log(currentPassword, "passow");
  // mailing address start //
  const [selectedLocation, setSelectedLocation] = useState(null);
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    if (autoCompleteRef.current && window.google) {
      const autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current,
        { types: ["geocode"] }
      );
      const listener = autoComplete.addListener("place_changed", () =>
        handlePlaceSelect(autoComplete)
      );

      return () => {
        listener.remove();
      };
    }
  }, [autoCompleteRef]);

  const handlePlaceSelect = async (autoComplete) => {
    try {
      const addressObject = autoComplete.getPlace();

      if (!addressObject || !addressObject.geometry) {
        console.error("No details available for input.");
        return;
      }

      const query = addressObject.formatted_address;

      setFormdata((prevData) => ({
        ...prevData,
        street_address: query,
      }));

      const latLng = {
        lat: addressObject.geometry.location.lat(),
        lng: addressObject.geometry.location.lng(),
      };
      setSelectedLocation(latLng);

      const addressComponents = addressObject.address_components;

      let updatedData = {};
      addressComponents.forEach((component) => {
        const types = component.types;

        if (types.includes("locality")) {
          updatedData.city = component.long_name;
        }

        if (types.includes("administrative_area_level_1")) {
          updatedData.state = component.short_name;
        }

        if (types.includes("postal_code")) {
          updatedData.zip_code = component.long_name;
        }

        if (types.includes("country")) {
          updatedData.country = component.long_name;
        }
      });

      setFormdata((prevData) => ({
        ...prevData,
        ...updatedData,
      }));
    } catch (error) {
      console.error("Error handling place select:", error);
    }
  };

  // mailing address end //

  // Billing address start //

  const [selectedLocation1, setSelectedLocation1] = useState(null);
  const autoCompleteRef1 = useRef(null);

  useEffect(() => {
    if (autoCompleteRef1.current) {
      const autoComplete1 = new window.google.maps.places.Autocomplete(
        autoCompleteRef1.current,
        { types: ["geocode"] }
      );

      autoComplete1.addListener("place_changed", () =>
        handlePlaceSelect1(autoComplete1)
      );
    }
  }, [autoCompleteRef1.current]);

  const handlePlaceSelect1 = async (autoComplete1) => {
    const addressObject1 = autoComplete1.getPlace();
    const query1 = addressObject1?.formatted_address;

    setFormdata((prevData) => ({
      ...prevData,
      billing_street_address: query1,
    }));

    const latLng = {
      lat: addressObject1?.geometry?.location.lat(),
      lng: addressObject1?.geometry?.location.lng(),
    };
    setSelectedLocation1(latLng);

    const addressComponents = addressObject1?.address_components;

    let updatedBillingData = {};
    addressComponents?.forEach((component) => {
      const types = component.types;

      if (types.includes("locality")) {
        updatedBillingData.billing_city = component.long_name;
      }

      if (types.includes("administrative_area_level_1")) {
        updatedBillingData.billing_state = component.short_name;
      }

      if (types.includes("postal_code")) {
        updatedBillingData.billing_zip_code = component.long_name;
      }

      if (types.includes("country")) {
        updatedBillingData.billing_country = component.long_name;
      }
    });

    // Merge updated billing data only
    setFormdata((prevData) => ({
      ...prevData,
      ...updatedBillingData,
    }));
  };
  // Billing address End //

  const labelIcons = {
    "First Name": "fal fa-user",
    "Last Name": "fal fa-user",
    Username: "fal fa-user",
    "Display Name": "fal fa-user",
    "Native / Fluent Languages": "fal fa-language",
    "Other Languages": "fal fa-language",
    "Cell Phone Number": "fal fa-phone",
    "Other Phone Number": "fal fa-phone",
    Email: "fal fa-envelope",
    "Additional Email (optional)": "fal fa-envelope",
    "Airmen Certificate": "fal fa-id-badge",
    "Driver License": "fal fa-id-card",
    Bio: "fal fa-info-circle",
    "Street Address": "fal fa-map-marker-alt",
    "Apt, Suite": "fal fa-building",
    City: "fal fa-city",
    State: "fal fa-map",
    "ZIP / Postal code": "fal fa-mail-bulk",
    Neighborhood: "fal fa-home",
    Country: "fal fa-globe",
    "Contact Name": "fal fa-user",
    Relationship: "fal fa-handshake",
    Email: "fal fa-envelope",
    Phone: "fal fa-phone",
    "Your name as it appears on the card": "fal fa-user",
    "Credit card number": "fal fa-credit-card",
    "Expiration date": "fal fa-calendar",
    CVV: "fal fa-lock",
    "ZIP / Postal code": "fal fa-mail-bulk",
    "Name of Bank": "fal fa-university",
    "Routing number": "fal fa-random",
    "Account number": "fal fa-money-check",
    "Facebook URL": "fab fa-facebook-f",
    "Instagram URL": "fab fa-instagram",
    "Airbnb URL": "fab fa-airbnb", // stays as is
    "VRBO URL": "far fa-house-damage", // lighter home-like icon for VRBO
    "YouTube URL": "fab fa-youtube-square", // lighter YouTube icon
    "X (Formerly Twitter) URL": "fab fa-x", // updated to the new X logo
    "LinkedIn URL": "fab fa-linkedin-in", // lighter LinkedIn
    "Pinterest URL": "fab fa-pinterest-p", // lighter Pinterest
    "Vimeo URL": "fab fa-vimeo-v", // lighter Vimeo
    "Trip Advisor URL": "fas fa-compass", // cleaner Trip Advisor icon
  };

  const getIconClass = (label) => labelIcons[label] || "fal fa-question-circle";

  // useEffect(() => {
  // getProfile();
  // }, [data]);

  const [isChecked, setIsChecked] = useState(false);

  const handleToggle = () => {
    setIsChecked(!isChecked);
    if (isChecked) {
      Swal.fire({
        position: "center",
        html: `
            <div style="display: flex; flex-direction: column; align-items: center;">
               <svg
                      viewBox="0 0 1024 1024"
                      height="4em"
                      width="4em"
                      style="margin-bottom: 10px;"
                  >
                      <!-- Outer circle with color #8ec639 -->
                      <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                      
                      <!-- Check mark with color #A2c66b -->
                      <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
                  </svg>
                <p style="margin: 0; font-size: 1.2em;">You have successfully opted in for receiving text message notifications. Great Choice! Now, you won't miss a beat!</p>
            </div>
        `,
        showConfirmButton: true,
      });
    }
  };

  return (
    <>
      <div style={{ marginTop: "30px" }} className="col-md-12 profile-form-container">
        <div className="dashboard-title fl-wrap flex flex-start gap-5">
          <h3 style={{ color: "#3B4249" }}>Profile</h3>
          <h4 style={{ textDecoration: "underline", paddingTop: "6px" }}>
            Note : Please complete your profile to 100% by completing all
            required fields. You will not be allowed to host or be a guest
            unless the profile is 100% complete.
          </h4>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="profile-edit-container fl-wrap block_box">
              <div className="profile_first flex justify-between">
                <h3>Photo</h3>
                <Link
                  to={"/pic"}
                  state={{
                    data: formdata,
                    email: data.email,
                  }}
                >
                  <h3 className="view_profile">View Profile</h3>{" "}
                </Link>
              </div>

              <div className="dashboard-title fl-wrap row">
                <div className="col-md-3">
                  <div className="profile-pic">
                    <label className="-label" htmlFor="file">
                      {!image && (
                        <>
                          <FaCamera
                            className="profile_btn_inner"
                            style={{
                              position: "absolute",
                              top: "23px",
                              color: "white",
                            }}
                          />
                          <span
                            className="profile_btn_inner"
                            style={{ color: "white" }}
                          >
                            Change Image
                          </span>
                        </>
                      )}
                    </label>
                    <input
                      id="file"
                      type="file"
                      ref={fileInputRef}
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                      required
                    />
                    {image && <img src={image} alt="Profile" width="200" />}
                  </div>
                </div>

                <div>
                  <p>
                    Minimum size 100 x 100 px
                    <br />
                    Maximum size of 3.1 MB
                    <br />
                    Allowed *.jpeg, *.jpg, *.png, *.webp
                  </p>
                  <div className="flex justify-between gap-4">
                    <div className="flex gap-4">
                      <button
                        className="border border-[#3b4249]
                        font-medium bg-[#8ec639] rounded-md text-white px-8 py-3"
                        onClick={handleBrowseClick}
                      >
                        Browse
                      </button>
                      <button
                        className="border border-[#3b4249]
                        font-medium bg-[#3B4249] rounded-md text-white px-8 py-3"
                        onClick={handleDeleteImage}
                      >
                        Delete
                      </button>
                    </div>
                    <div>
                      <button
                        className="border border-[#3b4249]
                        font-medium bg-[#8ec639] rounded-md text-white px-8 py-3"
                        onClick={handleSubmit}
                      >
                        {loaders ? <BtnLoader /> : <>Save</>}
                      </button>
                    </div>
                  </div>
                  {!image && !newImageFile && isProfileImageRequired && (
                    <span style={{ color: "red" }}>Required</span>
                  )}
                </div>
              </div>
            </div>
            <div className="profile-edit-container fl-wrap block_box">
              <div className="custom-form">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className="dashboard-title"
                >
                  <h3>Change Password</h3>
                </div>
                <div className="row">
                  <div className="col-sm-12 relative">
                    <label>
                      Current Password
                      <i className="fal fa-lock" />
                    </label>
                    <input
                      type={showCurrentPassword ? "text" : "password"}
                      placeholder="Current Password"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                    <span
                      className="absolute md:top-10 md:right-8 top-12 right-8"
                      onClick={toggleCurrentVisibility}
                    >
                      {showCurrentPassword ? <FaEye /> : <FaEyeSlash />}
                    </span>
                  </div>

                  {/* New Password Field */}
                  <div className="col-sm-6 relative">
                    <label>
                      New Password <i className="fal fa-lock" />
                    </label>
                    <input
                      type={showPassword ? "text" : "password"}
                      value={newPassword}
                      onChange={handleNewPasswordChange}
                      placeholder="New Password"
                      disabled={
                        currentPassword === data?.password ? false : true
                      }
                      required
                    />
                    <span
                      onClick={togglePasswordVisibility}
                      className="absolute md:top-10 md:right-8 top-28 right-8"
                    >
                      {showPassword ? <FaEye /> : <FaEyeSlash />}
                    </span>
                  </div>

                  {/* Confirm Password Field */}
                  <div className="col-sm-6 relative">
                    <label>
                      Confirm Password <i className="fal fa-lock" />
                    </label>
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      placeholder="Confirm Password"
                      disabled={
                        currentPassword === data?.password ? false : true
                      }
                      required
                    />
                    <span
                      onClick={togglePasswordConfirm}
                      className="absolute md:top-10 md:right-8 top-48 right-8"
                    >
                      {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                    </span>
                  </div>
                  {currentPassword !== data?.password &&
                    currentPassword.length > 0 && (
                      <div className="error-message" style={{ color: "red" }}>
                        Please make sure your current password is correct.
                      </div>
                    )}
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button className="btn" onClick={handleSubmit}>
                    {loaders ? (
                      <BtnLoader />
                    ) : (
                      <>
                        <span style={{ color: "white", fontWeight: "700" }}>
                          Update Password{" "}
                        </span>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className="profile-edit-container fl-wrap block_box">
              <div className="custom-form">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className="dashboard-title"
                >
                  <h3>Information</h3>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <label>
                      First Name <i className={getIconClass("First Name")} />
                    </label>
                    <input
                      type="text"
                      name="first_name"
                      onChange={handleInputChange}
                      value={formdata.first_name}
                      placeholder="First Name"
                    />
                    {errors.first_name ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </div>
                  <div className="col-sm-6">
                    <label>
                      Last Name <i className={getIconClass("Last Name")} />
                    </label>
                    <input
                      type="text"
                      placeholder="Last Name"
                      name="last_name"
                      onChange={handleInputChange}
                      value={formdata.last_name}
                    />
                    {errors.last_name ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </div>
                  <div className="col-sm-6" style={{ marginTop: "12px" }}>
                    <label>
                      Username <i className={getIconClass("Username")} />
                    </label>
                    <input
                      type="text"
                      placeholder="Username"
                      name="username"
                      disabled
                      onChange={handleInputChange}
                      value={formdata.username}
                    />
                    {errors.username ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </div>

                  <div className="col-sm-6" style={{ marginTop: "12px" }}>
                    <label>
                      Display Name
                      <i className={getIconClass("Display Name")} />
                    </label>
                    <input
                      type="text"
                      placeholder="Display Name"
                      name="given_name"
                      onChange={handleInputChange}
                      value={formdata.given_name}
                    />
                    {errors.given_name ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </div>
                  <div className="col-sm-6" style={{ marginTop: "12px" }}>
                    <label>
                      Native / Fluent Languages
                      <i
                        className={getIconClass("Native / Fluent Languages")}
                      />
                    </label>
                    <input
                      type="text"
                      placeholder="Native / Fluent Languages"
                      name="native_language"
                      onChange={handleInputChange}
                      value={formdata.native_language}
                    />
                    {errors.native_language ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </div>
                  <div className="col-sm-6" style={{ marginTop: "12px" }}>
                    <label>
                      Other Languages
                      <i className={getIconClass("Other Languages")} />
                    </label>
                    <input
                      type="text"
                      placeholder="Other Languages"
                      name="other_language"
                      onChange={handleInputChange}
                      value={formdata.other_language}
                    />
                    {errors.other_language ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </div>
                  <div className="col-sm-6" style={{ marginTop: "12px" }}>
                    <label>
                      Cell Phone Number{" "}
                      <i className={getIconClass("Cell Phone Number")} />
                    </label>
                    <input
                      type="number"
                      placeholder="Cell phone number"
                      name="phone"
                      onChange={handleInputChange}
                      value={formdata.phone}
                    />
                    {errors.phone ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </div>
                  <div className="col-sm-6" style={{ marginTop: "12px" }}>
                    <label>
                      Other Phone Number
                      <i className={getIconClass("Other Phone Number")} />
                    </label>
                    <input
                      type="number"
                      placeholder="Other phone"
                      name="other_phone"
                      onChange={handleInputChange}
                      value={formdata.other_phone}
                    />
                    {errors.other_phone ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </div>
                  <div className="col-sm-12" style={{ marginTop: "12px" }}>
                    <label>
                      Opting in is very important so you never miss a beat. You
                      will receive notifications regarding bookings, changes to
                      the site and policies, and more. By opting in you agree to
                      our{" "}
                      <Link
                        style={{ cursor: "pointer", color: "#af2322" }}
                        to="/privacy_policy"
                      >
                        Privacy Policy
                      </Link>
                      .
                    </label>
                    <div className="onoffswitch">
                      <input
                        type="checkbox"
                        name="onoffswitch"
                        className="onoffswitch-checkbox"
                        id="myonoffswitch11"
                        checked={!isChecked}
                        onChange={handleToggle}
                      />
                      <label
                        className="onoffswitch-label"
                        htmlFor="myonoffswitch11"
                      >
                        <span className="onoffswitch-inner"></span>
                        <span className="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-6" style={{ marginTop: "12px" }}>
                    <label>
                      Email
                      <i className={getIconClass("Email")} />
                    </label>
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      onChange={handleInputChange}
                      value={formdata.email}
                    />
                    {errors.email ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </div>
                  <div className="col-sm-6" style={{ marginTop: "12px" }}>
                    <label>
                      Additional Email (optional)
                      <i
                        className={getIconClass("Additional Email (optional)")}
                      />
                    </label>
                    <input
                      type="email"
                      placeholder="Additional Email (optional)"
                      name="additional_email"
                      onChange={handleInputChange}
                      value={formdata.additional_email}
                    />
                  </div>
                  <div className="col-sm-12 font-medium flex items-center">
                    <input type="checkbox" />
                    <p>Send communication to this email also?</p>
                  </div>

                  <div className="col-sm-6" style={{ marginTop: "12px" }}>
                    {/* <div className="clearfix" /> */}
                    <label>Airmen Certificate Front</label>
                    {/* <div className="clearfix" /> */}
                    <div className="listsearch-input-item fl-wrap">
                      <div className="fuzone">
                        <form>
                          {secondImg ? (
                            <img
                              src={secondImg}
                              alt="Profile"
                              width="300"
                              height="200"
                            />
                          ) : (
                            <div className="fu-text">
                              <span>
                                <i
                                  className={getIconClass("Airmen Certificate")}
                                />{" "}
                                Click here or drop files to upload
                              </span>
                              <div className="photoUpload-files fl-wrap" />
                            </div>
                          )}
                          <input
                            type="file"
                            className="upload"
                            onChange={handleSecondImageChange}
                            multiple=""
                          />
                          {!secondImg && !secImg && isFileRequiredTwo && (
                            <span style={{ color: "red" }}>Required</span>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6" style={{ marginTop: "12px" }}>
                    {/* <div className="clearfix" /> */}
                    <label>Driver License Front</label>
                    {/* <div className="clearfix" /> */}
                    <div className="listsearch-input-item fl-wrap">
                      <div className="fuzone">
                        <form>
                          {thirdImg ? (
                            <img
                              src={thirdImg}
                              width="300"
                              height="200"
                              alt="Profile"
                            />
                          ) : (
                            <div className="fu-text">
                              <span>
                                <i className={getIconClass("Driver License")} />{" "}
                                Click here or drop files to upload
                              </span>
                              <div className="photoUpload-files fl-wrap" />
                            </div>
                          )}
                          <input
                            type="file"
                            className="upload"
                            onChange={handleThirdImageChange}
                            multiple=""
                          />
                          {!thirdImg && !thirFile && isFileRequired && (
                            <span style={{ color: "red" }}>Required</span>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6" style={{ marginTop: "12px" }}>
                    {/* <div className="clearfix" /> */}
                    <label>Airmen Certificate Back</label>
                    {/* <div className="clearfix" /> */}
                    <div className="listsearch-input-item fl-wrap">
                      <div className="fuzone">
                        <form>
                          {secondImgBack ? (
                            <img
                              src={secondImgBack}
                              alt="Profile"
                              width="300"
                              height="200"
                            />
                          ) : (
                            <div className="fu-text">
                              <span>
                                <i
                                  className={getIconClass("Airmen Certificate")}
                                />{" "}
                                Click here or drop files to upload
                              </span>
                              <div className="photoUpload-files fl-wrap" />
                            </div>
                          )}
                          <input
                            type="file"
                            className="upload"
                            onChange={handleSecondImageChangeBack}
                            multiple=""
                          />
                          {!secondImgBack &&
                            !secImgBack &&
                            isFileRequiredTwoBack && (
                              <span style={{ color: "red" }}>Required</span>
                            )}
                        </form>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6" style={{ marginTop: "12px" }}>
                    {/* <div className="clearfix" /> */}
                    <label>Driver License Back</label>
                    {/* <div className="clearfix" /> */}
                    <div className="listsearch-input-item fl-wrap">
                      <div className="fuzone">
                        <form>
                          {thirdImgBack ? (
                            <img
                              src={thirdImgBack}
                              width="300"
                              height="200"
                              alt="Profile"
                            />
                          ) : (
                            <div className="fu-text">
                              <span>
                                <i className={getIconClass("Driver License")} />{" "}
                                Click here or drop files to upload
                              </span>
                              <div className="photoUpload-files fl-wrap" />
                            </div>
                          )}
                          <input
                            type="file"
                            className="upload"
                            onChange={handleThirdImageChangeBack}
                            multiple=""
                          />
                          {!thirdImgBack &&
                            !thirFileBack &&
                            isFileRequiredBack && (
                              <span style={{ color: "red" }}>Required</span>
                            )}
                        </form>
                      </div>
                    </div>
                  </div>

                  <textarea
                    name="bio"
                    value={formdata.bio}
                    onChange={handleInputChange}
                    placeholder="Bio"
                  />
                  {errors.bio ? (
                    <span style={{ color: "red" }}>Required</span>
                  ) : null}
                </div>

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button
                    className="btn"
                    onClick={handleSubmit}
                  // disabled={!isFormValid}
                  >
                    {loaders ? (
                      <BtnLoader />
                    ) : (
                      <>
                        <span style={{ color: "white", fontWeight: "700" }}>
                          Save{" "}
                        </span>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>

            <div className="profile-edit-container fl-wrap block_box">
              <div className="custom-form">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className="dashboard-title"
                >
                  <h3>Mailing Address</h3>
                </div>
                <div className="row">
                  <div className="col-sm-8">
                    <label>
                      Street Address{" "}
                      <i className={getIconClass("Street Address")} />
                    </label>
                    <input
                      type="text"
                      name="street_address"
                      ref={autoCompleteRef}
                      onChange={handleInputChange}
                      value={formdata.street_address || ""}
                      placeholder="Enter street address"
                    />
                    {errors.street_address ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </div>

                  <div className="col-sm-4">
                    <label>
                      Apartment/Suite (optional){" "}
                      <i className={getIconClass("Apt, Suite")} />
                    </label>
                    <input
                      type="text"
                      placeholder="Apartment/Suite (optional)"
                      name="apt_suit"
                      onChange={handleInputChange}
                      value={formdata.apt_suit || ""}
                    />
                  </div>

                  <div className="col-sm-4" style={{ marginTop: "12px" }}>
                    <label>
                      City <i className={getIconClass("City")} />
                    </label>
                    <input
                      type="text"
                      placeholder="City"
                      name="city"
                      onChange={handleInputChange}
                      value={formdata.city || ""}
                    />
                    {errors.city ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </div>

                  <div className="col-sm-4" style={{ marginTop: "12px" }}>
                    <label>
                      State <i className={getIconClass("State")} />
                    </label>
                    <input
                      type="text"
                      placeholder="State"
                      name="state"
                      onChange={handleInputChange}
                      value={formdata.state || ""}
                    />
                    {errors.state ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </div>

                  <div className="col-sm-4" style={{ marginTop: "12px" }}>
                    <label>
                      ZIP / Postal code{" "}
                      <i className={getIconClass("ZIP / Postal code")} />
                    </label>
                    <input
                      type="number"
                      placeholder="ZIP / Postal code"
                      name="zip_code"
                      onChange={handleInputChange}
                      value={formdata.zip_code || ""}
                    />
                    {errors.zip_code ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </div>

                  <div className="col-sm-6" style={{ marginTop: "12px" }}>
                    <label>
                      Area (optional){" "}
                      <i className={getIconClass("Neighborhood")} />
                    </label>
                    <input
                      type="text"
                      placeholder="Area (optional)"
                      name="neighbourhood"
                      onChange={handleInputChange}
                      value={formdata.neighbourhood || ""}
                    />
                  </div>

                  <div className="col-sm-6" style={{ marginTop: "12px" }}>
                    <label>
                      Country <i className={getIconClass("Country")} />
                    </label>
                    <input
                      type="text"
                      placeholder="Country"
                      name="country"
                      onChange={handleInputChange}
                      value={formdata.country || ""}
                    />
                    {errors.country ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    paddingTop: "20px",
                    alignItems: "center",
                  }}
                >
                  <input type="checkbox" onChange={handleCheckboxChange} />
                  <p>Same as billing address.</p>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button className="btn" onClick={handleSubmit}>
                    {loaders ? (
                      <BtnLoader />
                    ) : (
                      <>
                        <span style={{ color: "white", fontWeight: "700" }}>
                          Save{" "}
                        </span>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className="profile-edit-container fl-wrap block_box">
              <div className="custom-form">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className="dashboard-title"
                >
                  <h3>Billing Address</h3>
                </div>
                <div className="row">
                  <div className="col-sm-8">
                    <label>
                      Street Address{" "}
                      <i className={getIconClass("Street Address")} />
                    </label>
                    <input
                      type="text"
                      ref={autoCompleteRef1}
                      name="billing_street_address"
                      onChange={handleInputChange}
                      value={formdata.billing_street_address || ""}
                      placeholder="Enter Billing Street Address"
                    />
                    {errors.billing_street_address ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </div>

                  <div className="col-sm-4">
                    <label>
                      Apartment/Suite (optional){" "}
                      <i className={getIconClass("Apt, Suite")} />
                    </label>
                    <input
                      type="text"
                      placeholder="Billing Apartment/Suite (optional)"
                      name="billing_apt_suit"
                      onChange={handleInputChange}
                      value={formdata.billing_apt_suit}
                    />

                  </div>

                  <div className="col-sm-4" style={{ marginTop: "12px" }}>
                    <label>
                      City <i className={getIconClass("City")} />
                    </label>
                    <input
                      type="text"
                      placeholder="Billing City"
                      name="billing_city"
                      onChange={handleInputChange}
                      value={formdata.billing_city}
                    />
                    {errors.billing_city ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </div>

                  <div className="col-sm-4" style={{ marginTop: "12px" }}>
                    <label>
                      State <i className={getIconClass("State")} />
                    </label>
                    <input
                      type="text"
                      placeholder="Billing State"
                      name="billing_state"
                      onChange={handleInputChange}
                      value={formdata.billing_state}
                    />
                    {errors.billing_state ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </div>

                  <div className="col-sm-4" style={{ marginTop: "12px" }}>
                    <label>
                      ZIP / Postal code{" "}
                      <i className={getIconClass("ZIP / Postal code")} />
                    </label>
                    <input
                      type="number"
                      placeholder="Billing  ZIP / Postal code"
                      name="billing_zip_code"
                      onChange={handleInputChange}
                      value={formdata.billing_zip_code}
                    />
                    {errors.billing_zip_code ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </div>

                  <div className="col-sm-6" style={{ marginTop: "12px" }}>
                    <label>
                      Area (optional){" "}
                      <i className={getIconClass("Neighborhood")} />
                    </label>
                    <input
                      type="text"
                      placeholder="Billing Area (optional)"
                      name="billing_neighbourhood"
                      onChange={handleInputChange}
                      value={formdata.billing_neighbourhood}
                    />
                  </div>

                  <div className="col-sm-6" style={{ marginTop: "12px" }}>
                    <label>
                      Country <i className={getIconClass("Country")} />
                    </label>
                    <input
                      type="text"
                      placeholder="Billing Country"
                      name="billing_country"
                      onChange={handleInputChange}
                      value={formdata.billing_country}
                    />
                    {errors.billing_country ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </div>
                </div>

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button className="btn" onClick={handleSubmit}>
                    {loaders ? (
                      <BtnLoader />
                    ) : (
                      <>
                        <span style={{ color: "white", fontWeight: "700" }}>
                          Save{" "}
                        </span>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className="profile-edit-container fl-wrap block_box">
              <div className="custom-form">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className="dashboard-title"
                >
                  <h3>Emergency Contact</h3>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <label>
                      Contact Name{" "}
                      <i className={getIconClass("Contact Name")} />
                    </label>
                    <input
                      type="text"
                      name="contact_name"
                      onChange={handleInputChange}
                      value={formdata.contact_name}
                      placeholder="Contact Name"
                    />
                    {errors.contact_name ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </div>

                  <div className="col-sm-6">
                    <label>
                      Relationship{" "}
                      <i className={getIconClass("Relationship")} />
                    </label>
                    <input
                      type="text"
                      placeholder="Relationship"
                      name="contact_relationship"
                      onChange={handleInputChange}
                      value={formdata.contact_relationship}
                    />
                    {errors.contact_relationship ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </div>

                  <div className="col-sm-6" style={{ marginTop: "12px" }}>
                    <label>
                      Email <i className={getIconClass("Email")} />
                    </label>
                    <input
                      type="email"
                      placeholder="Email"
                      name="contact_email"
                      onChange={handleInputChange}
                      value={formdata.contact_email}
                    />
                    {errors.contact_email ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </div>

                  <div className="col-sm-6" style={{ marginTop: "12px" }}>
                    <label>
                      Phone <i className={getIconClass("Phone")} />
                    </label>
                    <input
                      type="number"
                      placeholder="Phone"
                      name="contact_phone"
                      onChange={handleInputChange}
                      value={formdata.contact_phone}
                    />
                    {errors.contact_phone ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </div>
                </div>

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button className="btn" onClick={handleSubmit}>
                    {loaders ? (
                      <BtnLoader />
                    ) : (
                      <>
                        <span style={{ color: "white", fontWeight: "700" }}>
                          Save{" "}
                        </span>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className="profile-edit-container fl-wrap block_box">
              <div className="custom-form">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className="dashboard-title"
                >
                  <h3>How You Pay Us</h3>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <label>
                      Your name as it appears on the card{" "}
                      <i
                        className={getIconClass(
                          "Your name as it appears on the card"
                        )}
                      />
                    </label>
                    <input
                      type="text"
                      name="pay_you_card_name"
                      onChange={handleInputChange}
                      value={formdata.pay_you_card_name}
                      placeholder="Your name as it appears on the card"
                    />
                    {errors.pay_you_card_name ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </div>

                  <div className="col-sm-6">
                    <label>
                      Credit card number{" "}
                      <i className={getIconClass("Credit card number")} />
                    </label>
                    <input
                      type="number"
                      name="pay_you_credit_card"
                      onChange={handleInputChange}
                      value={formdata.pay_you_credit_card}
                      placeholder="Credit card number"
                    />
                    {errors.pay_you_credit_card ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </div>

                  <div className="col-sm-6">
                    <label>
                      Expiration date{" "}
                      <i className={getIconClass("Expiration date")} />
                    </label>
                    <input
                      type="number"
                      placeholder="Expiration date"
                      name="pay_you_expiry_date"
                      onChange={handleInputChange}
                      value={formdata.pay_you_expiry_date}
                    />
                    {errors.pay_you_expiry_date ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </div>

                  <div className="col-sm-6" style={{ marginTop: "12px" }}>
                    <label>
                      CVV <i className={getIconClass("CVV")} />
                    </label>
                    <input
                      type="number"
                      placeholder="CVV"
                      name="pay_you_cvv"
                      onChange={handleInputChange}
                      value={formdata.pay_you_cvv}
                    />
                    {errors.pay_you_cvv ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </div>

                  <div className="col-sm-6" style={{ marginTop: "12px" }}>
                    <label>
                      ZIP / Postal code{" "}
                      <i className={getIconClass("ZIP / Postal code")} />
                    </label>
                    <input
                      type="number"
                      placeholder="ZIP / Postal code"
                      name="pay_you_zip_code"
                      onChange={handleInputChange}
                      value={formdata.pay_you_zip_code}
                    />
                    {errors.pay_you_zip_code ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </div>
                </div>

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button className="btn" onClick={handleSubmit}>
                    {loaders ? (
                      <BtnLoader />
                    ) : (
                      <>
                        <span style={{ color: "white", fontWeight: "700" }}>
                          Save{" "}
                        </span>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className="profile-edit-container fl-wrap block_box">
              <div className="custom-form">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className="dashboard-title"
                >
                  <h3>How We Pay You</h3>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <label>
                      Name of Bank{" "}
                      <i className={getIconClass("Name of Bank")} />
                    </label>
                    <input
                      type="text"
                      name="pay_we_bank_name"
                      onChange={handleInputChange}
                      value={formdata.pay_we_bank_name}
                      placeholder="Name of Bank"
                    />
                    {/* {errors.pay_we_bank_name ? <span style={{ color: 'red' }}>Required</span> : null} */}
                  </div>

                  <div className="col-sm-6">
                    <label>
                      Routing number{" "}
                      <i className={getIconClass("Routing number")} />
                    </label>
                    <input
                      type="number"
                      placeholder="Routing number"
                      name="pay_we_routing_number"
                      onChange={handleInputChange}
                      value={formdata.pay_we_routing_number}
                    />
                    {/* {errors.pay_we_routing_number ? <span style={{ color: 'red' }}>Required</span> : null} */}
                  </div>

                  <div className="col-sm-12" style={{ marginTop: "12px" }}>
                    <label>
                      Account number{" "}
                      <i className={getIconClass("Account number")} />
                    </label>
                    <input
                      type="number"
                      placeholder="Account number"
                      name="pay_we_acc_number"
                      onChange={handleInputChange}
                      value={formdata.pay_we_acc_number}
                    />
                    {/* {errors.pay_we_acc_number ? <span style={{ color: 'red' }}>Required</span> : null} */}
                  </div>
                </div>

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button className="btn" onClick={handleSubmit}>
                    {loaders ? (
                      <BtnLoader />
                    ) : (
                      <>
                        <span style={{ color: "white", fontWeight: "700" }}>
                          Save{" "}
                        </span>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>

            <div className="profile-edit-container fl-wrap block_box">
              <div className="custom-form">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className="dashboard-title"
                >
                  <h3>Social Media</h3>
                </div>
                <div className="row">
                  <div className="col-sm-6" style={{ marginTop: "12px" }}>
                    <label>
                      Facebook URL{" "}
                      <SlSocialFacebook
                        style={{
                          position: "absolute",
                          top: "40px",
                          left: "14px",
                          fontSize: "14px",
                        }}
                      />
                    </label>
                    <input
                      type="text"
                      name="facebook_url"
                      onChange={handleInputChange}
                      value={formdata.facebook_url || "https://facebook.com/"}
                      placeholder="Facebook URL"
                    />
                  </div>
                  <div className="col-sm-6" style={{ marginTop: "12px" }}>
                    <label>
                      Instagram URL{" "}
                      <i className={getIconClass("Instagram URL")} />
                    </label>
                    <input
                      type="text"
                      name="instagram_url"
                      onChange={handleInputChange}
                      value={formdata.instagram_url || "https://instagram.com/"}
                      placeholder="Instagram URL"
                    />
                  </div>
                  <div className="col-sm-6" style={{ marginTop: "12px" }}>
                    <label>
                      Airbnb URL <i className={getIconClass("Airbnb URL")} />
                    </label>
                    <input
                      type="text"
                      placeholder="Airbnb URL"
                      name="airbnb_url"
                      onChange={handleInputChange}
                      value={formdata.airbnb_url || "https://airbnb.com/"}
                    />
                  </div>
                  <div className="col-sm-6" style={{ marginTop: "12px" }}>
                    <label>
                      VRBO URL <i className={getIconClass("VRBO URL")} />
                    </label>
                    <input
                      type="text"
                      placeholder="VRBO URL"
                      name="vrbo_url"
                      onChange={handleInputChange}
                      value={formdata.vrbo_url || "https://vrbo.com/"}
                    />
                  </div>
                  <div className="col-sm-6" style={{ marginTop: "12px" }}>
                    <label>
                      YouTube URL
                      <SlSocialYoutube
                        style={{
                          position: "absolute",
                          top: "41px",
                          left: "14px",
                          fontSize: "14px",
                        }}
                      />
                    </label>
                    <input
                      type="text"
                      placeholder="YouTube URL"
                      name="youtube_url"
                      onChange={handleInputChange}
                      value={formdata.youtube_url || "https://youtube.com/"}
                    />
                  </div>
                  <div className="col-sm-6" style={{ marginTop: "12px" }}>
                    <label>
                      X (Formerly Twitter) URL{" "}
                      <i className={getIconClass("X (Formerly Twitter) URL")} />
                    </label>
                    <input
                      type="text"
                      placeholder="X (Formerly Twitter) URL"
                      name="twitter_url"
                      onChange={handleInputChange}
                      value={formdata.twitter_url || "https://twitter.com/"}
                    />
                  </div>
                  <div className="col-sm-6" style={{ marginTop: "12px" }}>
                    <label>
                      LinkedIn URL{" "}
                      <CiLinkedin
                        style={{
                          position: "absolute",
                          top: "39px",
                          left: "14px",
                          fontSize: "18px",
                        }}
                      />
                    </label>
                    <input
                      type="text"
                      placeholder="LinkedIn URL"
                      name="linkedin_url"
                      onChange={handleInputChange}
                      value={formdata.linkedin_url || "https://linkedin.com/"}
                    />
                  </div>
                  <div className="col-sm-6" style={{ marginTop: "12px" }}>
                    <label>
                      Pinterest URL{" "}
                      <i className={getIconClass("Pinterest URL")} />
                    </label>
                    <input
                      type="text"
                      placeholder="Pinterest URL"
                      name="pinterest_url"
                      onChange={handleInputChange}
                      value={
                        formdata.pinterest_url || "https://www.pinterest.com/"
                      }
                    />
                  </div>
                  <div className="col-sm-6" style={{ marginTop: "12px" }}>
                    <label>
                      Vimeo URL{" "}
                      <TfiVimeo
                        style={{
                          position: "absolute",
                          top: "41px",
                          left: "14px",
                          fontSize: "14px",
                        }}
                      />
                    </label>
                    <input
                      type="text"
                      placeholder="Vimeo URL"
                      name="vimeo_url"
                      onChange={handleInputChange}
                      value={formdata.vimeo_url || "https://vimeo.com/"}
                    />
                  </div>
                  <div className="col-sm-6" style={{ marginTop: "12px" }}>
                    <label>
                      Trip Advisor URL{" "}
                      <LiaTripadvisor
                        style={{
                          position: "absolute",
                          top: "40px",
                          left: "14px",
                          fontSize: "18px",
                        }}
                      />
                    </label>
                    <input
                      type="text"
                      placeholder="Trip Advisor URL"
                      name="top_advisor_url"
                      onChange={handleInputChange}
                      value={
                        formdata.top_advisor_url ||
                        "https://www.tripadvisor.com/"
                      }
                    />
                  </div>
                </div>

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button className="btn" onClick={handleSubmit}>
                    {loaders ? (
                      <BtnLoader />
                    ) : (
                      <>
                        <span style={{ color: "white", fontWeight: "700" }}>
                          Save{" "}
                        </span>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="profile-edit-container fl-wrap block_box">
              <div
                style={{ marginBottom: "0px" }}
                className="dashboard-title fl-wrap"
              >
                <h3
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    color: "#af2322",
                  }}
                >
                  Profile Completed
                </h3>
                <h1
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    color: "#8ec639",
                  }}
                >
                  {formdata?.complete || data?.complete}
                </h1>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingTop: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <h4>Profile Picture</h4>
                    <p style={{ paddingTop: data.image ? "10px" : "0px" }}>
                      {data.image ? (
                        <FaCheck
                          style={{ color: "#8ec639", fontSize: "17px" }}
                        />
                      ) : (
                        <MdClose
                          style={{
                            color: "#8ec639",
                            fontSize: "20px",
                            paddingTop: "3px",
                          }}
                        />
                      )}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <h4>Email Address</h4>
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        color: "#8ec639",
                      }}
                    >
                      {formdata?.status == 1 || data?.status == 1 ? (
                        <FaCheck
                          style={{ color: "#8ec639", fontSize: "17px" }}
                        />
                      ) : (
                        <MdClose
                          style={{
                            color: "#8ec639",
                            fontSize: "20px",
                            paddingTop: "3px",
                          }}
                        />
                      )}
                      VERIFIED
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileRight;
