/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import ListenRight from "../Components/ListenRight";
import SidebarLayout from "../Components/sidebar/SidebarLayout";
import { AddListingFragment } from "../fragments";

function AddListing() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  const Check = () => {
    const Token = localStorage.getItem("token");
    if (Token) {
      return true;
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    Check(); // Call the Check function
  }, []); // Pass an empty dependency array

  return (
    <>
      <SidebarLayout>
        <AddListingFragment />
        {/* <ListenRight /> */}
      </SidebarLayout>
    </>
  );
}

export default AddListing;
