import { useContext, useEffect, useState } from "react";
import {
  Dialog,
  DialogPanel,
  Disclosure,
  PopoverGroup,
} from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import { fetchData, ThemeContext } from "../../context/ContextFile";
import ModalLogin from "../../Pages/ModalLogin";
import RegisterModal from "../RegisterModal";
import ProfileMenu from "./ProfileMenu";

export default function TailwindHeader(props) {
  const { logo = false, profile = false } = props || {};
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { isModalOpen, setIsModalOpen, isRegister, setIsRegister } =
    useContext(ThemeContext);

  const GetData = (name, key) => {
    navigate(`/map_listing/${name}/${key}`);
  };

  const [isFixed, setIsFixed] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header
        className={`bg-white py-5 w-full  transition-all duration-300 ${isFixed ? "fixed top-0 shadow-md" : "relative"
          }`}
        style={{ zIndex: 100 }}
      >
        <nav
          aria-label="Global"
          className="mx-auto flex max-w-7xl items-center justify-between px-4 lg:px-8"
        >
          <div className="lg:flex-1 md:flex hidden">
            {logo && (
              <Link to={"/"}>
                <img
                  className="w-20 h-20"
                  src="/image/fly-inn-logo.svg"
                  alt=""
                />
              </Link>
            )}
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="size-6" />
            </button>
          </div>
          <PopoverGroup className="hidden lg:flex lg:gap-x-12 relative z-[1000]">
            <div className="nav-holder main-menu relative z-[1000]">
              <nav>
                <ul className="no-list-style">
                  <li>
                    <Link to="/" className="link">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="">
                      Explore <i className="fa fa-caret-down" />
                    </Link>
                    <ul>
                      <li>
                        <Link to="/by-state-airport">By State / Airport</Link>
                      </li>
                      <li>
                        <Link to="#">
                          By Lodging
                          <i className="fa fa-caret-down" />
                        </Link>
                        <ul
                          className="specific-ul"
                          style={{ overflow: "hidden scroll", height: "60vh" }}
                        >
                          <li
                            onClick={() =>
                              GetData("Apt_Condo_Loft", "byDwelling")
                            }
                          >
                            <Link to={"#"}>Apartment/Condo/Loft</Link>
                          </li>
                          <li
                            onClick={() =>
                              GetData("bed_breakfast", "byDwelling")
                            }
                          >
                            <Link to={"#"}>Bed &amp; Breakfast</Link>
                          </li>
                          <li onClick={() => GetData("cabin", "byDwelling")}>
                            <Link to={"#"}>Cabin</Link>
                          </li>
                          <li onClick={() => GetData("campsite", "byDwelling")}>
                            <Link to={"#"}>Campsite</Link>
                          </li>
                          <li onClick={() => GetData("farm", "byDwelling")}>
                            <Link to={"#"}>Farm</Link>
                          </li>
                          <li onClick={() => GetData("hangar", "byDwelling")}>
                            <Link to={"#"}>Hangar</Link>
                          </li>
                          <li
                            onClick={() => GetData("hangar_home", "byDwelling")}
                          >
                            <Link to={"#"}>Hangar Home </Link>
                          </li>
                          <li onClick={() => GetData("house", "byDwelling")}>
                            <Link to={"#"}>House</Link>
                          </li>
                          <li onClick={() => GetData("mansion", "byDwelling")}>
                            <Link to={"#"}>Mansion</Link>
                          </li>
                          <li onClick={() => GetData("novelty", "byDwelling")}>
                            <Link to={"#"}>Novelty</Link>
                          </li>
                          <li onClick={() => GetData("rv", "byDwelling")}>
                            <Link to={"#"}>RV</Link>
                          </li>
                          <li onClick={() => GetData("rv_pad", "byDwelling")}>
                            <Link to={"#"}>RV Pad</Link>
                          </li>
                          <li
                            onClick={() => GetData("tiny_home", "byDwelling")}
                          >
                            <Link to={"#"}>Tiny Home</Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="#">
                          By Space
                          <i className="fa fa-caret-down" />
                        </Link>
                        {/*third  level  */}
                        <ul>
                          <li
                            onClick={() =>
                              GetData("entireplace", "byTypeOfSpace")
                            }
                          >
                            <Link to={`#`}>Entire Place</Link>
                          </li>
                          <li
                            onClick={() =>
                              GetData("private room", "byTypeOfSpace")
                            }
                          >
                            <Link to={`#`}>Private Room</Link>
                          </li>
                          <li
                            onClick={() =>
                              GetData("shared room", "byTypeOfSpace")
                            }
                          >
                            <Link to={`#`}>Shared Room</Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      onClick={() => fetchData()}
                      to="/listing"
                      className="link"
                    >
                      Stays
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="">
                      Info <i className="fa fa-caret-down" />
                    </Link>
                    <ul>
                      <li>
                        <Link to="/about">About</Link>
                      </li>
                      <li>
                        <Link to="/faq" target="_blank">
                          FAQ
                        </Link>
                      </li>
                      <li>
                        <Link to="/philanthropy">Philanthropy</Link>
                      </li>
                      <li>
                        <Link to="/squawks">Squawks</Link>
                      </li>
                      <li>
                        <Link to="/agreements">
                          Agreements
                          <i className="fa fa-caret-down" />
                        </Link>
                        <ul>
                          <li>
                            <Link to={"/terms-of-service"}>
                              Terms of Service
                            </Link>
                          </li>
                          <li>
                            <Link to={"/privacy_policy"}>Privacy Policy</Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/gallery">Gallery</Link>
                      </li>
                      <li>
                        <Link to="/testimonials">Testimonials</Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <Link to="/contact" className="link">
                      Contact
                    </Link>
                  </li>
                  <li>
                    <div>
                      <Link
                        style={{
                          color: "#fff",
                          backgroundColor: "#af2322",
                          top: "0px",
                        }}
                        to="/login"
                        className={`add-list color-bg `}
                      >
                        Become a Host
                        <span style={{ paddingLeft: "0px" }}>
                          <i
                            style={{ color: "#fff" }}
                            className="fal fa-layer-plus"
                          ></i>
                        </span>
                      </Link>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
          </PopoverGroup>
          <div className="lg:flex lg:flex-1 lg:justify-end md:block hidden">
            {profile && <ProfileMenu />}
          </div>
        </nav>
        <Dialog
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
          className="lg:hidden"
        >
          <div className="fixed inset-0 z-10" />
          <DialogPanel className="fixed inset-y-0 right-0 z-[1000] w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between relative z-50">
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="size-6" />
              </button>
            </div>

            <div className="mt-6 flow-root">
              <nav>
                <ul className="no-list-style space-y-4">
                  {/* Home Section */}
                  <li>
                    <Link className="text-black text-lg font-bold" to="/">
                      Home
                    </Link>
                  </li>

                  {/* Explore Section */}
                  <li>
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="w-full text-left bg-gray-200 p-2 rounded-md flex items-center justify-between">
                            <span>Explore</span>
                            <i
                              className={`fa ${open ? "fa-chevron-up" : "fa-chevron-down"
                                }`}
                            ></i>
                          </Disclosure.Button>
                          <Disclosure.Panel>
                            <ul className="pl-4 space-y-2">
                              {/* By Lodging */}
                              <li>
                                <Disclosure>
                                  {({ open }) => (
                                    <>
                                      <Disclosure.Button className="w-full text-left bg-gray-100 p-2 rounded-md flex items-center justify-between">
                                        <span>By Lodging</span>
                                        <i
                                          className={`fa ${open
                                            ? "fa-chevron-up"
                                            : "fa-chevron-down"
                                            }`}
                                        ></i>
                                      </Disclosure.Button>
                                      <Disclosure.Panel>
                                        <ul className="pl-4 space-y-1">
                                          <li
                                            onClick={() =>
                                              GetData(
                                                "Apt_Condo_Loft",
                                                "byDwelling"
                                              )
                                            }
                                          >
                                            <Link to="#" className="text-black">
                                              Apt. / Condo / Loft
                                            </Link>
                                          </li>
                                          <li
                                            onClick={() =>
                                              GetData(
                                                "bed_breakfast",
                                                "byDwelling"
                                              )
                                            }
                                          >
                                            <Link to="#" className="text-black">
                                              Bed &amp; Breakfast
                                            </Link>
                                          </li>
                                          <li
                                            onClick={() =>
                                              GetData("cabin", "byDwelling")
                                            }
                                          >
                                            <Link to="#" className="text-black">
                                              Cabin
                                            </Link>
                                          </li>
                                          {/* Add remaining options */}
                                        </ul>
                                      </Disclosure.Panel>
                                    </>
                                  )}
                                </Disclosure>
                              </li>

                              {/* By Space */}
                              <li>
                                <Disclosure>
                                  {({ open }) => (
                                    <>
                                      <Disclosure.Button className="w-full text-left bg-gray-100 p-2 rounded-md flex items-center justify-between">
                                        <span>By Space</span>
                                        <i
                                          className={`fa ${open
                                            ? "fa-chevron-up"
                                            : "fa-chevron-down"
                                            }`}
                                        ></i>
                                      </Disclosure.Button>
                                      <Disclosure.Panel>
                                        <ul className="pl-4 space-y-1">
                                          <li
                                            onClick={() =>
                                              GetData(
                                                "entireplace",
                                                "byTypeOfSpace"
                                              )
                                            }
                                          >
                                            <Link to="#" className="text-black">
                                              Entire Place
                                            </Link>
                                          </li>
                                          <li
                                            onClick={() =>
                                              GetData(
                                                "private room",
                                                "byTypeOfSpace"
                                              )
                                            }
                                          >
                                            <Link to="#" className="text-black">
                                              Private Room
                                            </Link>
                                          </li>
                                          <li
                                            onClick={() =>
                                              GetData(
                                                "shared room",
                                                "byTypeOfSpace"
                                              )
                                            }
                                          >
                                            <Link to="#" className="text-black">
                                              Shared Room
                                            </Link>
                                          </li>
                                        </ul>
                                      </Disclosure.Panel>
                                    </>
                                  )}
                                </Disclosure>
                              </li>
                            </ul>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </li>

                  {/* Info Section */}
                  <li>
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="w-full text-left bg-gray-200 p-2 rounded-md flex items-center justify-between">
                            <span>Info</span>
                            <i
                              className={`fa ${open ? "fa-chevron-up" : "fa-chevron-down"
                                }`}
                            ></i>
                          </Disclosure.Button>
                          <Disclosure.Panel>
                            <ul className="pl-4 space-y-2">
                              <li>
                                <Link className="text-black" to="/about">
                                  About
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/faq"
                                  className="text-black"
                                  target="_blank"
                                >
                                  FAQ
                                </Link>
                              </li>
                              <li>
                                <Link className="text-black" to="/philanthropy">
                                  Philanthropy
                                </Link>
                              </li>
                              <li>
                                <Link className="text-black" to="/squawks">
                                  Squawks
                                </Link>
                              </li>
                              <li>
                                <Disclosure>
                                  {({ open }) => (
                                    <>
                                      <Disclosure.Button className="w-full text-left bg-gray-100 p-2 rounded-md flex items-center justify-between">
                                        <span>Agreements</span>
                                        <i
                                          className={`fa ${open
                                            ? "fa-chevron-up"
                                            : "fa-chevron-down"
                                            }`}
                                        ></i>
                                      </Disclosure.Button>
                                      <Disclosure.Panel>
                                        <ul className="pl-4 space-y-1">
                                          <li>
                                            <Link
                                              className="text-black"
                                              to="/terms-of-service"
                                            >
                                              Terms of Service
                                            </Link>
                                          </li>
                                          <li>
                                            <Link
                                              className="text-black"
                                              to="/privacy_policy"
                                            >
                                              Privacy Policy
                                            </Link>
                                          </li>
                                        </ul>
                                      </Disclosure.Panel>
                                    </>
                                  )}
                                </Disclosure>
                              </li>
                              <li>
                                <Link className="text-black" to="/gallery">
                                  Gallery
                                </Link>
                              </li>
                              <li>
                                <Link className="text-black" to="/testimonials">
                                  Testimonials
                                </Link>
                              </li>
                            </ul>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </li>

                  {/* Contact Section */}
                  <li>
                    <Link className="text-black" to="/contact">
                      Contact
                    </Link>
                  </li>

                  {/* Become a Host Section */}
                  <li>
                    <Link
                      to="/login"
                      style={{
                        color: "#fff",
                        backgroundColor: "#af2322",
                        padding: "10px 20px",
                        borderRadius: "5px",
                      }}
                      className="flex items-center justify-center text-center"
                    >
                      Become a Host
                      <i className="fal fa-layer-plus pl-2 text-white"></i>
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </DialogPanel>
        </Dialog>
      </header>

      {isModalOpen ? (
        <>
          <ModalLogin
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            isRegister={isRegister}
            setIsRegister={setIsRegister}
          />
        </>
      ) : null}
      {isRegister ? (
        <>
          <RegisterModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            isRegister={isRegister}
            setIsRegister={setIsRegister}
          />
        </>
      ) : null}
    </>
  );
}
