/* eslint-disable */
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BASE_URL from "./auth/Baseurl";
import { MaterialReactTable } from "material-react-table";
import { FiEye, FiTrash2 } from "react-icons/fi";
function FavoriteR() {
  const [data, setData] = useState([]);
  const fetchData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/listing-favourite`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      console.log(response, "getfav");
      setData(response.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleDelete = async (id) => {
    const formData = new FormData();
    formData.append("listing_id", id);
    try {
      const response = await axios.post(
        `${BASE_URL}/add-to-favourite`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      fetchData();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 150,
      },
      {
        accessorKey: "listing_images",
        header: "Image",
        size: 150,
        Cell: ({ row }) => (
          <img
            src={row.original.listing_images?.[0].image}
            alt="Host"
            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
          />
        ),
      },

      {
        accessorKey: "title",
        header: "Title",
        size: 200,
      },

      {
        accessorKey: "no_of_bedroom",
        header: "Bedrooms",
        size: 150,
      },
      {
        accessorKey: "no_of_bathroom",
        header: "Bathrooms",
        size: 150,
      },
      {
        accessorKey: "no_of_guest",
        header: "Guests",
        size: 150,
      },

      {
        accessorKey: "action",
        header: "Action",
        size: 200,
        Cell: ({ row }) => (
          <div style={{ display: "flex", gap: "10px" }}>
            <Link to={`/details/${row.original.id}`}>
              <FiEye
                size={20}
                style={{ cursor: "pointer", color: "rgb(175, 35, 34)" }}
                // onClick={() => handleView(row.original)}
              />
            </Link>
            <FiTrash2
              size={20}
              style={{ cursor: "pointer", color: "rgb(175, 35, 34)" }}
              onClick={() => handleDelete(row.original.id)}
            />
          </div>
        ),
      },
    ],
    []
  );

  return (
    <div className="px-4">
      <div className="dashboard-title my-4">
        <h3>My Favorites</h3>
      </div>
      <div>
        <MaterialReactTable
          columns={columns}
          data={data}
          muiPaginationProps={{
            rowsPerPageOptions: [
              { value: 10, label: "10" },
              { value: 25, label: "25" },
              { value: 50, label: "50" },
              { value: 100, label: "100" },
              { value: data?.length, label: "All" },
            ],
            showFirstButton: false,
            showLastButton: false,
          }}
        />
      </div>
    </div>
  );
}

export default FavoriteR;
