/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-key */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable arrow-body-style */
/* eslint-disable */
import React, { useEffect, useState } from "react";
// import DeleteIcon from '@mui/icons-material/Delete';
import "./message.css";
import BASE_URL from "./auth/Baseurl";
import { FiSmile, FiX } from "react-icons/fi";
import EmojiPicker from "emoji-picker-react";
import { MdDelete } from "react-icons/md";
import axios from "axios";
import Loading from "./loader/Loading";
import ModalImage from "./modal/ModalImage";
import { createPortal } from "react-dom";
import { CiUnread } from "react-icons/ci";
const Message = () => {
  const currentUser = JSON.parse(localStorage.getItem("data"));
  const [loading, setLoading] = useState(false);
  const [allChat, setAllChat] = useState([]);
  const [activeChatId, setActiveChatId] = useState(null);
  const [getChatById, setGetChatById] = useState([]);
  const [chatValue, setChatValue] = useState("");
  const [chatId, setChatId] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [openImage, setOpenImage] = useState(false);
  const [saveImage, setSaveImage] = useState('');

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const handleEmojiClick = (emoji) => {
    setChatValue((prev) => prev + emoji.emoji);
  };
  console.log(saveImage, "saveImage");
  const handleIconClick = () => {
    document.getElementById("imageUpload").click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSendImage = () => {
    if (selectedImage) {
      setSelectedImage(null);
      setImagePreview(null);
    }
  };

  const getAllChat = async () => {
    const response = await axios.get(`${BASE_URL}/all-chats`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response?.data;
  };


  const handleChatClick = (chatId) => {
    setActiveChatId(chatId);
    handlerChatById(chatId);
  };
  const handlerChatById = async (id) => {
    // setLoading(true);
    setChatId(id);
    const response = await axios.get(`${BASE_URL}/get-chat/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    // setLoading(false);
    setGetChatById(response?.data?.messages);
  };

    const handlerChatUnseen = async (id) => {
      const response = await axios.get(`${BASE_URL}/chat-unread/${id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      getAllChat()
      .then((data) => {
        setAllChat(data?.chat);
      })
      // setLoading(false);
      // setGetChatById(response?.data?.messages);
    };
 

  const handlerSubmitChat = (e) => {
    e.preventDefault();
    if (setShowEmojiPicker) {
      setShowEmojiPicker(false);
    }
    const formData = new FormData();
    formData.append("message", chatValue);
    formData.append("chat_id", chatId);
    if (selectedImage) {
      formData.append("image", selectedImage);
    }
    axios.post(`${BASE_URL}/message-send`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        console.log(response, "response");
        setChatValue("");
        handlerChatById(chatId);
        handleSendImage();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handlerSubmitChat(e);
    }
  };

  const [sortOrder, setSortOrder] = useState('Ascending');

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };

  const sortedChats = [...allChat];

  if (sortOrder === 'Descending') {
    console.log(allChat,);
    sortedChats.reverse();
  }

  useEffect(() => {
    getAllChat()
      .then((data) => {
        setAllChat(data?.chat);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const closeModal = () => {
    setOpenImage(false)
  }
  return (
    <>
      {loading ? <Loading /> : null}
      <div className="md:px-12 px-4">
        <div className="dashboard-title my-4">
          <h3 >AirMail <span
            style={{
              fontSize: "10px",
              verticalAlign: "super",
              position: "relative",
              top: "-7px",
              marginLeft: "-5px",
              color: "#3b4249",
              background: "none",
              fontWeight: "400",
            }}
          >
            TM
          </span></h3>
        </div>
        <div style={{ backgroundColor: "white" }}>

          <div style={{ display: "flex", justifyContent: "space-between" }} className="header row">
            <div className="listsearch-input-item " style={{ width: "180px", display: "flex", alignItems: "center", gap: "10px" }}>
              <p style={{ fontWeight: "600" }}>Sort</p>
              <select
                onChange={handleSortChange}
                className="nice-select chosen-select no-search-select"
              >
                <option value="Ascending">Ascending </option>
                <option value="Descending">Descending  </option>
              </select>
            </div>
            <div className="search-bar">
              <input type="text" placeholder="Search..." />
            </div>
            <div style={{ justifyContent: "flex-end" }} className="user-settings ">

              <img
                className="user-profile"
                src={currentUser?.image}
                alt=""
              />
              <div className="username">{currentUser?.username}</div>
            </div>
          </div>
          <div style={{ height: "60vh" }} className="wrapper">
            <div className="conversation-area">
              {sortedChats?.map((chat, index) => (
                <div
                  className={`msg ${chat?.is_seen === 0 ? "online" : ""}`}
                  style={{
                    backgroundColor:
                      chat?.id === activeChatId
                        && "#00000012" 
                        // : chat?.is_seen === 0
                        //   ? "#00000012" 
                        //   : "transparent", 
                  }}
                  key={index}
                  onClick={() => handleChatClick(chat?.id)} 
                >
                  <img
                    className="msg-profile"
                    src={
                      chat?.receiver_chat?.image
                        ? chat?.receiver_chat?.image
                        : "https://s3-us-west-2.amazonaws.com/s.cdpn.io/3364143/download+%281%29.png"
                    }
                    alt=""
                  />
                  <div className="msg-detail">
                    <div className="msg-username">{chat?.receiver_chat?.given_name}</div>
                    <div className="msg-content">
                      <span className="msg-message">{chat?.date}</span>
                      <span className="msg-date">{chat?.time}</span>
                      <span className="msg-date">{chat?.booking_id}</span>
                    </div>
                  </div>
                  <div style={{ position: "absolute", right: 17 }}>
                    <CiUnread
                      size={24}
                      onClick={(e) => {
                        e.stopPropagation();
                        handlerChatUnseen(chat?.id); 
                        // alert(chat?.id)
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="chat-area pt-5 position-relative">
              <div className="chat-area-main">
                {getChatById?.map((message, index) => (
                  <>
                    {currentUser?.id === message?.sender_id ? (
                      <>
                        {/* Right Side */}
                        <div
                          style={{ marginBottom: index === 0 ? "60px" : "0" }}
                          className={`chat-msg ${currentUser?.id === message?.sender_id ? "owner" : ""}`}
                          key={index}
                        >
                          <div className="chat-msg-profile">
                            <img
                              className="chat-msg-img"
                              src={
                                message?.sender?.image
                                  ? message?.sender?.image
                                  : "https://s3-us-west-2.amazonaws.com/s.cdpn.io/3364143/download+%281%29.png"
                              }
                              alt=""
                            />
                            <div className="chat-msg-date">
                              {message?.time}
                            </div>
                          </div>
                          <div className="chat-msg-content">
                            {message.message ? (
                              <div style={{ color: "#3b4249" }} className="chat-msg-text">
                                {message?.message}
                              </div>
                            ) : null}
                          </div>
                          {message?.image && (
                            <div style={{ display: "flex", justifyContent: "end" }} className="d-flex justify-content-end mb-4 me-4">
                              <div onClick={() => {
                                setOpenImage(!openImage)
                                setSaveImage(message?.image)
                              }}
                                className="chat-msg-text w-50">
                                <img style={{ height: "100px" }} src={message?.image} alt="Chat" />
                              </div>
                            </div>
                          )}
                        </div>

                        {openImage && createPortal(
                          <ModalImage closeModal={closeModal} saveImage={saveImage} setOpenImage={setOpenImage} />,
                          document.body
                        )}
                        {/* {openImage ?    : null} */}
                      </>

                    ) : (
                      <>
                        {/* Left Side */}
                        <div className="chat-msg">
                          <div className="chat-msg-profile">
                            <img
                              className="chat-msg-img"
                              src={
                                message?.sender?.image
                                  ? message?.sender?.image
                                  : "https://s3-us-west-2.amazonaws.com/s.cdpn.io/3364143/download+%281%29.png"
                              }
                              alt=""
                            />
                            <div className="chat-msg-date">
                              {message?.time}
                            </div>
                          </div>
                          <div className="chat-msg-content">
                            <div style={{ color: "#3b4249" }} className="chat-msg-text">
                              {message?.message}
                            </div>
                          </div>
                        </div>
                        {message?.image && (
                          <div style={{ display: "flex", justifyContent: "start", overflow: "hidden" }} className="d-flex justify-content-start mb-4 me-4">
                            <div className="chat-msg-text w-50" onClick={() => {
                              setOpenImage(!openImage)
                              setSaveImage(message?.image)
                            }}>
                              <img style={{ height: "100px" }} src={message?.image} alt="Chat" />
                            </div>
                          </div>
                        )}
                        {openImage ? <ModalImage closeModal={closeModal} saveImage={saveImage} /> : null}
                      </>
                    )}
                  </>
                ))}
                {getChatById?.length > 0 && (
                  <div style={{ position: 'relative', maxWidth: '-webkit-fill-available' }}>
                    <form
                      style={{
                        position: 'fixed',
                        bottom: 0,
                        width: '100%',
                        maxWidth: 'inherit',
                      }}
                      onSubmit={handlerSubmitChat}
                    >
                      {imagePreview && (
                        <div style={{ position: "absolute", top: "-130px" }} className="image-preview">
                          <img style={{ height: "100px" }} src={imagePreview} alt="Selected" />
                        </div>
                      )}
                      <div style={{ height: "0px", padding: "55px 0px" }} className="chat-area-footer">
                        {imagePreview ? (
                          <>
                            <MdDelete color="error" size={30} onClick={handleSendImage} />
                          </>
                        ) : (
                          <>
                            <div className="image-upload-section">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-image"
                                onClick={handleIconClick}
                              >
                                <rect
                                  x={3}
                                  y={3}
                                  width={18}
                                  height={18}
                                  rx={2}
                                  ry={2}
                                />
                                <circle cx="8.5" cy="8.5" r="1.5" />
                                <path d="M21 15l-5-5L5 21" />
                              </svg>
                              <input
                                type="file"
                                id="imageUpload"
                                accept="image/*"
                                style={{ display: "none" }}
                                onChange={handleImageChange}
                              />
                            </div>
                          </>
                        )}

                        <textarea
                          placeholder="Type something here..."
                          value={chatValue}
                          onKeyDown={handleKeyDown}
                          // required
                          onChange={(e) => setChatValue(e.target.value)}
                          style={{
                            width: "85%",
                            height: "50px",
                            padding: "10px",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            resize: "none",
                            overflow: "auto",
                            marginBottom: "20px",
                          }}
                        />

                        <FiSmile
                          onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                          style={{ cursor: "pointer", marginLeft: "8px" }}
                          size={24}
                        />
                        {showEmojiPicker && (
                          <div style={{ position: "absolute", right: "70px", zIndex: 99 }}>
                            <FiX
                              onClick={() => setShowEmojiPicker(false)}
                              style={{ cursor: "pointer", position: "absolute", top: "-120px", right: "4", fontSize: "22px", color: "#3b4249", zIndex: "999999" }}
                            />
                            <EmojiPicker onEmojiClick={handleEmojiClick} />
                          </div>
                        )}
                        <button
                          className="ms-4"
                          type="submit"
                          style={{ border: "none", background: "none" }}
                        >
                          <svg
                            viewBox="0 0 513 513"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M256.025.05C117.67-2.678 3.184 107.038.025 245.383a240.703 240.703 0 0085.333 182.613v73.387c0 5.891 4.776 10.667 10.667 10.667a10.67 10.67 0 005.653-1.621l59.456-37.141a264.142 264.142 0 0094.891 17.429c138.355 2.728 252.841-106.988 256-245.333C508.866 107.038 394.38-2.678 256.025.05z" />
                            <path
                              d="M330.518 131.099l-213.825 130.08c-7.387 4.494-5.74 15.711 2.656 17.97l72.009 19.374a9.88 9.88 0 007.703-1.094l32.882-20.003-10.113 37.136a9.88 9.88 0 001.083 7.704l38.561 63.826c4.488 7.427 15.726 5.936 18.003-2.425l65.764-241.49c2.337-8.582-7.092-15.72-14.723-11.078zM266.44 356.177l-24.415-40.411 15.544-57.074c2.336-8.581-7.093-15.719-14.723-11.078l-50.536 30.744-45.592-12.266L319.616 160.91 266.44 356.177z"
                              fill="#fff"
                            />
                          </svg>
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Message;
