/* eslint-disable*/
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BiEditAlt } from "react-icons/bi";
import { AiFillPrinter } from "react-icons/ai";
import { IoMailOutline } from "react-icons/io5";
import { FaRegCheckCircle, FaRegTrashAlt } from "react-icons/fa";
import { IoMdArrowBack } from "react-icons/io";
import { PiGreaterThanLight } from "react-icons/pi";
import BookingModal from "./BookingModal";
import BookingEditModal from "./BookingEditModal";
import { MdPushPin } from "react-icons/md";
import BtnExpenses from "./Booking/BtnExpenses";

import BtnModalExpenses from "./Booking/BtnModalExpenses";
import BtnDiscount from "./Booking/BtnDiscount";
import BtnModalDiscount from "./Booking/BtnModalDiscount";
import PinIconModal from "./PinIconModal";
import DeleteModal from "./DeleteModal";


const MyTripR = () => {

    const [expenseList, setExpenseList] = useState([
        { name: "", value: "", quantity: "" },
    ]);
    const [discountList, setDiscountList] = useState([
        { name: "", value: "", },
    ]);

    const navigate = useNavigate();
    const location = useLocation();
    const row = location.state?.row;
    console.log(row, "values");



    const arriveDateParts = row?.arrive_date.split('-');
    const departDateParts = row?.depart_date.split('-');
    const arriveDate = new Date(`20${arriveDateParts[0]}`, arriveDateParts[1] - 1, arriveDateParts[2]);
    const departDate = new Date(`20${departDateParts[0]}`, departDateParts[1] - 1, departDateParts[2]);

    const diffTime = Math.abs(departDate - arriveDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const nightlyRate = Number(row?.listing?.nightly);
    const totalCost = diffDays * nightlyRate;

    // Check if any date in the range is a weekend (Saturday or Sunday)
    let hasWeekend = false;
    let currentDate = new Date(arriveDate); // Start from the arriveDate
    const weekendDays = []; // To store weekend dates if needed

    while (currentDate <= departDate) {
        const dayOfWeek = currentDate.getDay();
        if (dayOfWeek === 0 || dayOfWeek === 6) { // 0 = Sunday, 6 = Saturday
            hasWeekend = true;
            weekendDays.push(new Date(currentDate)); // Add the weekend date to the array
        }
        currentDate.setDate(currentDate.getDate() + 1);
    }

    console.log(diffDays, "diffDays");
    console.log("Has weekend:", hasWeekend);
    if (hasWeekend) {
        console.log("Weekend dates:", weekendDays);
    }



    function PrintElem() {
        var elemContent = document.getElementById('elem').innerHTML;
        var mywindow = window.open('', 'PRINT', 'height=400,width=600');

        mywindow.document.write('<html><head>');
        mywindow.document.write('</head><body>');
        mywindow.document.write(elemContent);
        mywindow.document.write('</body></html>');

        mywindow.document.close();

        // Wait for the content to load
        mywindow.onload = function () {
            mywindow.focus();
            mywindow.print();
            mywindow.close();
        };

        return true;
    }
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [expenses, setExpenses] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [push, setPush] = useState(false);

    return (
        <>
            {
                expenses && (
                    <BtnModalExpenses expenseList={expenseList} setExpenseList={setExpenseList} expenses={expenses} setExpenses={setExpenses} />
                )
            }
            {
                openDelete && (
                    <DeleteModal openDelete={openDelete} setOpenDelete={setOpenDelete} />
                )
            }

            <main className="col-md-12" sstyle={{ marginTop: "30px" }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: '0 0 66.666667%', maxWidth: '66.666667%', paddingBottom: "20px" }} id='elem'>
                        <div style={{ padding: '16px', boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)', background: 'white' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '50px', alignItems: "center" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <h1>Reservation#{row?.id}</h1>
                                    <div style={{ marginLeft: "10px" }}>
                                        <button style={{ padding: "5px", backgroundColor: "rgb(142, 198, 57)", color: "white", borderRadius: "7px", fontWeight: "600" }}>{row?.status}</button>
                                    </div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", gap: "15px" }}>

                                    <div style={{ border: "2px solid rgb(175, 35, 34)", padding: "5px", borderRadius: "5px" }} className="icon_button_booking">

                                        <AiFillPrinter style={{ cursor: "pointer" }} onClick={PrintElem} size={20} />
                                    </div>
                                    <div style={{ border: "2px solid rgb(175, 35, 34)", padding: "5px", borderRadius: "5px" }} className="icon_button_booking">

                                        <IoMailOutline style={{ cursor: "pointer" }} size={20} />
                                    </div>
                                    {row?.status === "Booked" ? "" :

                                        <div style={{ border: "2px solid rgb(175, 35, 34)", padding: "5px", borderRadius: "5px" }} className="icon_button_booking">

                                            <MdPushPin onClick={() => setPush(true)} style={{ cursor: "pointer" }} size={20} />
                                        </div>
                                    }
                                    <div style={{ border: "2px solid rgb(175, 35, 34)", padding: "5px", borderRadius: "5px" }} className="icon_button_booking">

                                        <FaRegTrashAlt onClick={() => setOpenDelete(true)} style={{ cursor: "pointer" }} size={20} />
                                    </div>
                                    <div style={{ border: "2px solid rgb(175, 35, 34)", padding: "5px", borderRadius: "5px" }} className="icon_button_booking">

                                        <IoMdArrowBack style={{ cursor: "pointer" }} onClick={() => navigate("/trip")} size={20} />
                                    </div>
                                </div>
                            </div>
                            <div style={{ paddingBottom: "30px" }} className="row">
                                <div style={{ textAlign: "left" }} className="col-md-4">
                                    <h4>Date:</h4>
                                    <br />
                                    <p>{row?.date}</p>

                                </div>
                                <div style={{ textAlign: "left" }} className="col-md-4">
                                    <h3>From:<span> {row?.listing?.host?.first_name}</span></h3>
                                    <br />
                                    <h4>Renter Detail:</h4>
                                    <br />
                                    <h4>Listing Title: <span>{row?.listing?.title}</span></h4>
                                </div>
                                <div className="col-md-4">
                                    <div>
                                        <img style={{ height: "70px", width: "70px", borderRadius: "100px" }} src={row?.listing?.host?.image} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div style={{ position: "relative" }} className="relative">
                                <div className="absolute" style={{ position: "absolute", top: "-20px", left: "0", width: "100%", height: "1px", border: "1px solid #3B4249", opacity: "0.1" }}>
                                </div>
                            </div>
                            <div style={{ paddingBottom: "30px" }} className="row">
                                <div style={{ textAlign: "left" }} className="col-md-4">
                                    <h3>Details</h3>

                                </div>
                                <div style={{ textAlign: "left" }} className="col-md-4">
                                    <p>Check In: <span style={{ fontWeight: "700" }}>{row?.arrive_date}</span></p>
                                    <br />
                                    <p>Check Out: <span style={{ fontWeight: "700" }}>{row?.depart_date}</span></p>
                                    <br />

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <PiGreaterThanLight />
                                        <p style={{ marginLeft: "10px" }}>Smoking allowed: <span style={{ fontWeight: "700" }}>{row?.listing?.smoking_allowed === 1 ? "Yes" : "No"}</span></p>

                                    </div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <PiGreaterThanLight />
                                        <p style={{ marginLeft: "10px" }}>Pets allowed: <span style={{ fontWeight: "700" }}>{row?.listing?.pet_allowed === 1 ? "Yes" : "No"}</span></p>

                                    </div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <PiGreaterThanLight />
                                        <p style={{ marginLeft: "10px" }}>Party allowed: <span style={{ fontWeight: "700" }}>{row?.listing?.party_allowed === 1 ? "Yes" : "No"}</span></p>

                                    </div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <PiGreaterThanLight />
                                        <p style={{ marginLeft: "10px" }}>Children allowed: <span style={{ fontWeight: "700" }}>{row?.listing?.children_allowed === 1 ? "Yes" : "No"}</span></p>
                                    </div>
                                    <br />
                                    <h3>House Rules / Additional Information</h3>
                                    <p>{row?.listing?.house_rule_addi_info}</p>
                                </div>
                                <div style={{ textAlign: "left" }} className="col-md-4">
                                    <p>nights: <span style={{ fontWeight: "700" }}>{diffDays}</span></p>
                                    <br />
                                    <p>Guests: <span style={{ fontWeight: "700", fontSize: "13px" }}>(Adults: {row?.no_of_guest} Children: 0)</span></p>
                                </div>
                            </div>
                            <div style={{ position: "relative" }} className="relative">
                                <div className="absolute" style={{ position: "absolute", top: "-20px", left: "0", width: "100%", height: "1px", border: "1px solid #3B4249", opacity: "0.1" }}>
                                </div>
                            </div>
                            <div style={{ paddingBottom: "30px" }} className="row">
                                <div style={{ textAlign: "left" }} className="col-md-4">
                                    <h3>Payment</h3>
                                </div>
                                <div style={{ textAlign: "left" }} className="col-md-4">
                                    <p>{diffDays} nights {hasWeekend ? "(with weekend)" : null} </p>
                                    <br />
                                    <p>Cleaning fee</p>
                                    <br />
                                    <br />
                                    <h3>Sub Total</h3>
                                    <br />
                                    <p>City fee</p>
                                    <br />
                                    <p>Security deposite</p>
                                    <br />
                                    <p>Service fees</p>
                                    <br />
                                    <p>Taxes 20%</p>
                                    <br />
                                    <br />
                                    <h3>Total</h3>
                                </div>
                                <div style={{ textAlign: "center" }} className="col-md-4">
                                    <p style={{ textAlign: "center" }}>${totalCost}</p>
                                    <br />
                                    <p style={{ textAlign: "center" }}>${row?.listing?.cleaning_fee}</p>
                                    <br />
                                    <br />
                                    <h3>${row?.sub_total}</h3>
                                    <br />
                                    <p style={{ textAlign: "center" }}>${row?.listing?.city_fee}</p>
                                    <br />
                                    <p style={{ textAlign: "center" }}>${row?.listing?.security_deposit}</p>
                                    <br />
                                    <p style={{ textAlign: "center" }}>${row?.listing?.service_fee}</p>
                                    <br />
                                    <p style={{ textAlign: "center" }}>${row?.listing?.tax}</p>
                                    <br />
                                    <br />
                                    <h3>${row?.total}</h3>
                                </div>
                                <div style={{ position: "relative" }} className="relative">
                                    <div className="absolute" style={{ position: "absolute", top: "350px", right: "33px", width: "60%", height: "1px", border: "1px solid #3B4249", opacity: "0.1" }}>
                                    </div>
                                </div>
                                <div style={{ position: "relative" }} className="relative">
                                    <div className="absolute" style={{ position: "absolute", top: "100px", right: "33px", width: "60%", height: "1px", border: "1px solid #3B4249", opacity: "0.1" }}>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div style={{ flex: '0 0 33.333333%', maxWidth: '33.333333%', padding: '0 16px' }}>
                        <div style={{ padding: '16px', boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)', background: 'white' }}>
                            <div style={{ height: "70px", textAlign: "left" }}>

                                <h3>Payment</h3>
                            </div>
                            <div style={{ paddingBottom: "30px" }} className="row">
                                <div style={{ position: "relative" }} className="relative">
                                    <div className="absolute" style={{ position: "absolute", top: "-20px", left: "0", width: "100%", height: "1px", border: "1px solid #3B4249", opacity: "0.1" }}>
                                    </div>
                                </div>

                                <div style={{ textAlign: "left" }} className="col-md-8">
                                    <p >8 nights (with weekend)</p>
                                    <br />
                                    <p >Cleaning fee</p>
                                    <br />
                                    <br />
                                    <h3>Sub Total</h3>
                                    <br />
                                    <p >City fee</p>
                                    <br />
                                    <p >Security deposite</p>
                                    <br />
                                    <p >Service fees</p>
                                    <br />
                                    <p >Taxes 20%</p>
                                    <br />
                                    <br />
                                    <h3>Total</h3>
                                </div>
                                <div style={{ textAlign: "center" }} className="col-md-4">
                                    <p style={{ textAlign: "center" }}>${totalCost}</p>
                                    <br />
                                    <p style={{ textAlign: "center" }}>${row?.listing?.cleaning_fee}</p>
                                    <br />
                                    <br />
                                    <h3>${row?.sub_total}</h3>
                                    <br />
                                    <p style={{ textAlign: "center" }}>${row?.listing?.city_fee}</p>
                                    <br />
                                    <p style={{ textAlign: "center" }}>${row?.listing?.security_deposit}</p>
                                    <br />
                                    <p style={{ textAlign: "center" }}>${row?.listing?.service_fee}</p>
                                    <br />
                                    <p style={{ textAlign: "center" }}>${row?.listing?.tax}</p>
                                    <br />
                                    <br />
                                    <h3>${row?.total}</h3>
                                </div>
                                <div style={{ position: "relative" }} className="relative">
                                    <div className="absolute" style={{ position: "absolute", top: "350px", width: "100%", height: "1px", border: "1px solid #3B4249", opacity: "0.1" }}>
                                    </div>
                                </div>
                                <div style={{ position: "relative" }} className="relative">
                                    <div className="absolute" style={{ position: "absolute", top: "100px", width: "100%", height: "1px", border: "1px solid #3B4249", opacity: "0.1" }}>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </main>
            {isOpen &&
                <BookingModal isOpen={isOpen} setIsOpen={setIsOpen} />
            }
            {push &&
                <PinIconModal push={push} setPush={setPush} />
            }
        </>
    );
};

export default MyTripR;
