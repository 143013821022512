import React from "react";
import Layout from "../../Components/layout/Layout";
import "./term.css";
// import { Link } from "react-router-dom";
const Privacy = () => {
  return (
    <Layout>
      <div className="md:px-20 px-4" style={{ backgroundColor: "#F7F8F9" }}>
        <div style={{ maxWidth: "none" }} className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <div className="page-title">
                <div className="block-top-title">
                  <h1 className="" style={{ fontSize: "30px" }}>
                    Privacy of Privacy
                  </h1>
                </div>
                {/* block-top-title */}
              </div>
              {/* page-title */}
            </div>
          </div>
          {/* .row */}
        </div>
        {/* .container */}
        <div className="container">
          <div
            className="row"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              className=" col-sm-12 col-md-12 inner-static"
              style={{ backgroundColor: "#fff" }}
            >
              <div className="page-wrap">
                <div className="article-main">
                  <article
                    id="post-7497"
                    className="single-page-article block post-7497 page type-page status-publish hentry"
                  >
                    <div className="article-detail block-body">
                      <div
                        data-elementor-type="wp-page"
                        data-elementor-id={7497}
                        className="elementor elementor-7497"
                        data-elementor-post-type="page"
                      >
                        <div
                          className="elementor-element elementor-element-4ff942bd e-flex e-con-boxed e-con e-parent"
                          data-id="4ff942bd"
                          data-element_type="container"
                        >
                          <div className="e-con-inner">
                            <div
                              className="elementor-element elementor-element-b68cec8 elementor-widget elementor-widget-text-editor"
                              data-id="b68cec8"
                              data-element_type="widget"
                              data-widget_type="text-editor.default"
                            >
                              <div className="elementor-widget-container">
                                <style
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      "/*! elementor - v3.21.0 - 18-04-2024 */\n.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap{background-color:#69727d;color:#fff}.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap{color:#69727d;border:3px solid;background-color:transparent}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap{margin-top:8px}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter{width:1em;height:1em}.elementor-widget-text-editor .elementor-drop-cap{float:left;text-align:center;line-height:1;font-size:50px}.elementor-widget-text-editor .elementor-drop-cap-letter{display:inline-block}",
                                  }}
                                />{" "}
                                <p>{/* wp:heading */}</p>
                                <p>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    Effective as of February 14, 2024
                                  </span>
                                </p>
                                <p>
                                  This Privacy Policy (“Policy”) applies to
                                  www.fly-inn.com, and FlyInn, LLC (“FlyInn”)
                                  and governs data collection and usage. For the
                                  purposes of this Privacy Policy, unless
                                  otherwise noted, all references to
                                  FlyInn&nbsp;include www.fly-inn.com, FlyInn
                                  Disbursements, LLC and
                                  disbursements.fly-inn.com.
                                  FlyInn’s&nbsp;platform is a Hospitality site.
                                  By using FlyInn’s platform&nbsp;, you consent
                                  to the data practices described in this
                                  statement.
                                </p>
                                <h2>
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    Collection of Your Personal Information
                                  </span>
                                </h2>
                                <p>{/* /wp:heading */}</p>
                                <p>{/* wp:paragraph */}</p>
                                <p>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    In order to better provide you with products
                                    and services offered,&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    FlyInn&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      letterSpacing: 0,
                                      textAlign: "var(--text-align)",
                                    }}
                                  >
                                    may collect personally identifiable
                                    information, such as your:
                                  </span>
                                </p>
                                <p>{/* /wp:paragraph */}</p>
                                <p>{/* wp:paragraph */}</p>
                                <ul>
                                  <li aria-level={1}>First and last name</li>
                                  <li aria-level={1}>Mailing address</li>
                                  <li aria-level={1}>Email address</li>
                                  <li aria-level={1}>Phone number</li>
                                  <li aria-level={1}>Date of birth</li>
                                  <li aria-level={1}>Profile Photo</li>
                                </ul>
                                <div>
                                  <p>
                                    If you purchase FlyInn’s products and
                                    services, we collect billing and credit card
                                    information. This information is used to
                                    complete the purchase transaction.
                                  </p>
                                  <p>
                                    <span
                                      style={{
                                        textAlign: "var(--text-align)",
                                        letterSpacing: 0,
                                      }}
                                    >
                                      FlyInn
                                    </span>
                                    <span
                                      style={{
                                        textAlign: "var(--text-align)",
                                        letterSpacing: 0,
                                      }}
                                    >
                                      &nbsp;may also collect anonymous
                                      demographic information, which is not
                                      unique to you, such as your:
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <ul>
                                    <li aria-level={1}>Age</li>
                                    <li aria-level={1}>Gender</li>
                                  </ul>
                                  <div
                                    style={{
                                      textAlign: "start",
                                      marginTop: "10px",
                                    }}
                                  >
                                    Please keep in mind that if you directly
                                    disclose personally identifiable information
                                    or personally sensitive data through&nbsp;
                                    <span
                                      style={{
                                        textAlign: "var(--text-align)",
                                        letterSpacing: 0,
                                      }}
                                    >
                                      FlyInn’s&nbsp;
                                    </span>
                                    <span
                                      style={{
                                        textAlign: "var(--text-align)",
                                        letterSpacing: 0,
                                      }}
                                    >
                                      public message boards, this information
                                      may be collected and used by others.
                                    </span>
                                  </div>
                                  <div>&nbsp;</div>
                                  <div style={{ textAlign: "start" }}>
                                    <span
                                      style={{
                                        textAlign: "start",
                                        letterSpacing: 0,
                                        // textAlign: "var(--text-align)",
                                      }}
                                    >
                                      We do not collect any personal information
                                      about you unless you voluntarily provide
                                      it to us. However, you may be required to
                                      provide certain personal information to us
                                      when you elect to use certain products or
                                      services. These may include: (a)
                                      registering for an account; (b) entering a
                                      sweepstakes or contest sponsored by us or
                                      one of our partners; (c) signing up for
                                      special offers from selected third
                                      parties; (d) sending us an email message;
                                      (e) submitting your credit card or other
                                      payment information when ordering and
                                      purchasing products and services. To wit,
                                      we will use your information for, but not
                                      limited to, communicating with you in
                                      relation to services and/or products you
                                      have requested from us. We also may gather
                                      additional personal or non-personal
                                      information in the future.
                                    </span>
                                  </div>
                                  <div>&nbsp;</div>
                                </div>
                                <p>{/* /wp:paragraph */}</p>
                                <p>{/* wp:heading */}</p>
                                <h2
                                  style={{
                                    color: "#3b4249",
                                    textTransform: "none",
                                    textAlign: "start",
                                  }}
                                >
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    Use of Your Personal Information
                                  </span>
                                </h2>
                                <h2>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      fontSize: 14,
                                      fontWeight: 400,
                                      letterSpacing: 0,
                                    }}
                                  >
                                    FlyInn collects and uses your personal
                                    information in the following ways:&nbsp;
                                  </span>
                                </h2>
                                <ul>
                                  <li>
                                    to operate and deliver the services you have
                                    requested
                                  </li>
                                  <li>
                                    to provide you with information, products,
                                    or services that you request from us
                                  </li>
                                  <li>
                                    to provide you with notices about your
                                    account
                                  </li>
                                  <li>
                                    to carry out FlyInn obligations and enforce
                                    our rights arising from any contracts
                                    entered between you and us, including for
                                    billing and collection
                                  </li>
                                  <li>
                                    <span
                                      style={{
                                        textAlign: "var(--text-align)",
                                        letterSpacing: 0,
                                      }}
                                    >
                                      to notify you about changes to our
                                      www.fly-inn.com or any products or
                                      services we offer or provide through it.
                                    </span>
                                  </li>
                                  <li>
                                    in any other way we may describe when you
                                    provide the information
                                  </li>
                                  <li>
                                    for any other purpose with your consent.
                                  </li>
                                </ul>
                                <p>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    FlyInn&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    may also use your personally identifiable
                                    information to inform you of other products
                                    or services available from&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    FlyInn&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      letterSpacing: 0,
                                      textAlign: "var(--text-align)",
                                    }}
                                  >
                                    and its affiliates.
                                  </span>
                                </p>
                                <h2>
                                  <span style={{ fontWeight: "bold" }}>
                                    Sharing Your Information with Third Parties
                                  </span>
                                </h2>
                                <div>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    FlyInn&nbsp;
                                  </span>
                                  does{" "}
                                  <u>
                                    <b>
                                      <i>NOT </i>
                                    </b>
                                  </u>
                                  sell, rent, or lease its customer lists to
                                  third parties.
                                  <span style={{ fontWeight: "bold" }}>
                                    <br />
                                  </span>
                                </div>
                                <div>&nbsp;</div>
                                <div>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    FlyInn&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      letterSpacing: 0,
                                      textAlign: "var(--text-align)",
                                    }}
                                  >
                                    may, from time to time, contact you on
                                    behalf of external business partners about a
                                    particular offering that may be of interest
                                    to you. In those cases, your unique
                                    personally identifiable information (email,
                                    name, address, phone number) is not
                                    transferred to the third party.&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    FlyInn&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      letterSpacing: 0,
                                      textAlign: "var(--text-align)",
                                    }}
                                  >
                                    may share data with trusted partners to help
                                    perform statistical analysis, send you email
                                    or postal mail, provide customer support, or
                                    arrange for deliveries. All such third
                                    parties are prohibited from using your
                                    personal information except to provide these
                                    services to&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    FlyInn
                                  </span>
                                  <span
                                    style={{
                                      letterSpacing: 0,
                                      textAlign: "var(--text-align)",
                                    }}
                                  >
                                    , and they are required to maintain the
                                    confidentiality of your information.
                                  </span>
                                </div>
                                <div>
                                  <span
                                    style={{
                                      letterSpacing: 0,
                                      textAlign: "var(--text-align)",
                                    }}
                                  >
                                    &nbsp;
                                  </span>
                                </div>
                                <p>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    FlyInn&nbsp;
                                  </span>
                                  may disclose your personal information,
                                  without notice, if required to do so by law or
                                  in the good faith belief that such action is
                                  necessary to: (a) conform to the edicts of the
                                  law or comply with legal process served
                                  on&nbsp;
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    FlyInn&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    or the platform; (b) protect and defend the
                                    rights or property of&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    FlyInn&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      letterSpacing: 0,
                                      textAlign: "var(--text-align)",
                                    }}
                                  >
                                    ; and/or (c) act under exigent circumstances
                                    to protect the personal safety of users
                                    of&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    FlyInn
                                  </span>
                                  <span
                                    style={{
                                      letterSpacing: 0,
                                      textAlign: "var(--text-align)",
                                    }}
                                  >
                                    , or the public.
                                  </span>
                                </p>
                                <h2>
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    Tracking User Behavior
                                  </span>
                                </h2>
                                <p>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    FlyInn&nbsp;
                                  </span>
                                  may keep track of the&nbsp;
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    and pages our users visit within&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    FlyInn
                                  </span>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    , in order to determine what&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    FlyInn&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    services are the most popular. This data is
                                    used to deliver customized content and
                                    advertising within&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    FlyInn&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    to customers whose behavior indicates that
                                    they are interested in a particular subject
                                    area.
                                  </span>
                                </p>
                                <h2>
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    Automatically Collected Information
                                  </span>
                                </h2>
                                <p>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    FlyInn&nbsp;
                                  </span>
                                  may automatically collect information about
                                  your computer hardware and software. This
                                  information can include your IP address,
                                  browser type, domain names, access times, and
                                  referring website addresses. This information
                                  is used for the operation of the service, to
                                  maintain quality of the service, and to
                                  provide general statistics regarding the use
                                  of&nbsp;
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    FlyInn’s&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    platform.
                                  </span>
                                </p>
                                <h2>
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    Links
                                  </span>
                                </h2>
                                <p>
                                  This platform contains links to other sites.
                                  Please be aware that we are not responsible
                                  for the content or privacy practices of such
                                  other sites. We encourage our users to be
                                  aware when they leave our platform and to read
                                  the privacy statements of any other site that
                                  collects personally identifiable information.
                                </p>
                                <h2>
                                  <span style={{ fontWeight: "bold" }}>
                                    Security of your Personal Information
                                  </span>
                                </h2>
                                <p>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    FlyInn&nbsp;
                                  </span>
                                  secures your personal information from
                                  unauthorized access, use, or disclosure.&nbsp;
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    FlyInn&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    uses the following methods for this purpose:
                                  </span>
                                </p>
                                <ul>
                                  <li>
                                    <span
                                      style={{
                                        textAlign: "var(--text-align)",
                                        letterSpacing: 0,
                                      }}
                                    >
                                      SSL Protocol
                                    </span>
                                  </li>
                                </ul>
                                <p>
                                  When personal information (such as a credit
                                  card number) is transmitted to other websites,
                                  it is protected through the use of encryption,
                                  such as the Secure Sockets Layer (SSL)
                                  protocol.
                                  <span
                                    style={{
                                      letterSpacing: 0,
                                      textAlign: "var(--text-align)",
                                    }}
                                  >
                                    <br />
                                  </span>
                                </p>
                                <p>
                                  We strive to take appropriate security
                                  measures to protect against unauthorized
                                  access to or alteration of your personal
                                  information. Unfortunately, no data
                                  transmission over the Internet or any wireless
                                  network can be guaranteed to be 100% secure.
                                  As a result, while we strive to protect your
                                  personal information, you acknowledge that:
                                  (a) there are security and privacy limitations
                                  inherent to the Internet that are beyond our
                                  control; and (b) the security, integrity, and
                                  privacy of any and all information and data
                                  exchanged between you and us through this site
                                  cannot be guaranteed.
                                </p>
                                <h2>
                                  <span style={{ fontWeight: "bold" }}>
                                    Right to Deletion
                                  </span>
                                </h2>
                                <p>
                                  Subject to certain exceptions set out below,
                                  on receipt of a verifiable request from you,
                                  we will
                                  <span style={{ fontWeight: "bold" }}>
                                    <br />
                                  </span>
                                </p>
                                <ul>
                                  <li>
                                    Delete your personal information from our
                                    records; and
                                  </li>
                                  <li>
                                    Direct any service providers to delete your
                                    personal information from their records.
                                  </li>
                                </ul>
                                <p>
                                  Please note that we may not be able to comply
                                  with requests to delete your personal
                                  information if it is necessary to
                                </p>
                                <ul>
                                  <li>
                                    Complete the transaction for which the
                                    personal information was collected, fulfill
                                    the terms of a written warranty or product
                                    recall conducted in accordance with federal
                                    law, and provide a good or service requested
                                    by you, or reasonably anticipated within the
                                    context of our ongoing business relationship
                                    with you, or otherwise perform a contract
                                    between you and us
                                  </li>
                                  <li>
                                    Detect security incidents, protect against
                                    malicious, deceptive, fraudulent, or illegal
                                    activity; or prosecute those responsible for
                                    that activity
                                  </li>
                                  <li>
                                    Debug to identify and repair errors that
                                    impair existing intended functionality
                                  </li>
                                  <li>
                                    Exercise free speech, ensure the right of
                                    another consumer to exercise his or her
                                    right of free speech, or exercise another
                                    right provided for by law
                                  </li>
                                  <li>
                                    Comply with the California Electronic
                                    Communications Privacy Act
                                  </li>
                                  <li>
                                    Engage in public or peer-reviewed
                                    scientific, historical, or statistical
                                    research in the public interest that adheres
                                    to all other applicable ethics and privacy
                                    laws, when our deletion of the information
                                    is likely to render impossible or seriously
                                    impair the achievement of such research,
                                    provided we have obtained your informed
                                    consent
                                  </li>
                                  <li>
                                    Enable solely internal uses that are
                                    reasonably aligned with your expectations
                                    based on your relationship with us
                                  </li>
                                  <li>
                                    Comply with an existing legal obligation; or
                                  </li>
                                  <li>
                                    Otherwise use your personal information,
                                    internally, in a lawful manner that is
                                    compatible with the context in which you
                                    provided the information.
                                  </li>
                                </ul>
                                <h2>
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    Children Under Thirteen
                                  </span>
                                </h2>
                                <p>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    FlyInn&nbsp;
                                  </span>
                                  does not knowingly collect personally
                                  identifiable information from children under
                                  the age of 13. If you are under the age of 13,
                                  you must ask your parent or guardian for
                                  permission to use this platform.
                                </p>
                                <h2>
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    Email Communications
                                  </span>
                                </h2>
                                <p>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    From time to time,&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    FlyInn&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      letterSpacing: 0,
                                      textAlign: "var(--text-align)",
                                    }}
                                  >
                                    may contact you via email for the purpose of
                                    providing training, newsletters,
                                    announcements, promotional offers, alerts,
                                    confirmations, surveys, and/or other general
                                    communication.
                                  </span>
                                </p>
                                <h2>
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    External Data Storage Sites
                                  </span>
                                </h2>
                                <p>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    We may store your data on servers provided
                                    by third-party hosting vendors with whom we
                                    have contracted.
                                  </span>
                                </p>
                                <h2>
                                  <span style={{ fontWeight: "bold" }}>
                                    Changes to This Statement
                                  </span>
                                </h2>
                                <p>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    FlyInn&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    reserves the right to change this Policy
                                    from time to time. For example, when there
                                    are changes in our services, changes in our
                                    data protection practices, or changes in the
                                    law. When changes to this Policy are
                                    significant, we will inform you. You may
                                    receive a notice by sending an email to the
                                    primary email address specified in your
                                    account, by placing a prominent notice on
                                    our FlyInn, LLC, and/or by updating any
                                    privacy information. Your continued use of
                                    the plaform and/or services available after
                                    such modifications will constitute your: (a)
                                    acknowledgment of the modified Policy; and
                                    (b) agreement to abide and be bound by that
                                    Policy.
                                  </span>
                                </p>
                                <h2>
                                  <span style={{ fontWeight: "bold" }}>
                                    Contact Information
                                  </span>
                                </h2>
                                <p>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    FlyInn&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    welcomes your questions or comments
                                    regarding this Policy. If you believe
                                    that&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    FlyInn&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      letterSpacing: 0,
                                      textAlign: "var(--text-align)",
                                    }}
                                  >
                                    has not adhered to this Policy, please
                                    contact&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      textAlign: "var(--text-align)",
                                      letterSpacing: 0,
                                    }}
                                  >
                                    FlyInn&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      letterSpacing: 0,
                                      textAlign: "var(--text-align)",
                                    }}
                                  >
                                    at:
                                  </span>
                                </p>
                                <p>
                                  FlyInn, LLC
                                  <br />
                                  P.O. Box 270439
                                  <br />
                                  Fruitland, Utah 84027
                                </p>
                                <p>
                                  Email Address:
                                  <br />
                                  PIC@fly-inn.com
                                </p>
                                <p>
                                  Phone Number:
                                  <br />
                                  321-435-9466
                                </p>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* .entry-content */}
                  </article>
                  {/* #post-## */}{" "}
                </div>
              </div>
              {/* grid-listing-page */}
            </div>
          </div>
          {/* .row */}
        </div>{" "}
        {/* .container */}
      </div>
    </Layout>
  );
};

export default Privacy;
