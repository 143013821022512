/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import DatePicker from "react-datepicker";
import axios from "axios";
import BASE_URL from "./auth/Baseurl";
import { useNavigate } from "react-router-dom";
const InvoiceR = () => {
  const navigate = useNavigate();
  const [handle, setHandle] = useState("");
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/invoice`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setData(response.data.data);
        console.log(response, "values");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "invoice_number",
        header: "Order #",
        size: 150,
      },
      {
        accessorKey: "date",
        header: "Date",
        size: 150,
      },
      {
        accessorKey: "billing_for",
        header: "Billing for",
        size: 150,
      },
      {
        accessorKey: "billing_type",
        header: "Billing Type",
        size: 150,
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 150,
      },
      {
        accessorKey: "payment_method",
        header: "Payment Method",
        size: 150,
      },
      {
        accessorKey: "total",
        header: "Total",
        size: 150,
      },
      {
        accessorKey: "action",
        header: "Action",
        size: 150,
        Cell: ({ row }) => (
          <button
            className="logout_btn color2-bg"
            onClick={() =>
              navigate("/invoice_details", { state: { row: row.original } })
            }
          >
            Details
          </button>
        ),
      },
    ],
    [navigate]
  );

  return (
    <div className="px-4">
      <div className="dashboard-title my-4">
        <h3>Invoices</h3>
      </div>
      <div className="hero-search fl-wrap mb-5">
          <div className="main-search-input-item grid md:grid-cols-5 gap-4 grid-cols-2">
            <div className="main-search-input-item p-0 bg-white">
              <DatePicker
                className="border-none"
                placeholderText="Start Date"
              />
            </div>
            <div className="main-search-input-item p-0 bg-white">
              <DatePicker placeholderText="End Date" className="border-none" />
            </div>

            <div className="main-search-input-item p-0">
              <select
                style={{ fontWeight: 800, fontSize: "13px" }}
                placeholder="Apartments"
                className="nice-select chosen-select no-search-select border-none"
                defaultValue=""
              >
                <option disabled Value={""}>
                  Billing For
                </option>
                <option value={"Any"}>Any</option>
                <option value={"Rent"}>Rent</option>
                <option value={"Updated to Featured"}>
                  Updated to Featured
                </option>
              </select>
            </div>
            <div className="main-search-input-item p-0">
              <select
                style={{ fontWeight: 800, fontSize: "13px" }}
                data-placeholder="Apartments"
                className="nice-select chosen-select no-search-select border-none"
                defaultValue=""
              >
                <option disabled Value={""}>
                  Status
                </option>
                <option value={"Any"}>Any</option>
                <option value={"Paid"}>Paid</option>
                <option value={"Not Paid"}>Not Paid</option>
              </select>
            </div>
            <div className="main-search-input-item p-0">
              <select
                style={{ fontWeight: 800, fontSize: "13px" }}
                data-placeholder="Apartments"
                className="nice-select chosen-select no-search-select border-none"
                defaultValue=""
              >
                <option value="" disabled>
                  Billing Type
                </option>
                <option value="One Time">One Time</option>
                <option value="Recurring">Recurring</option>
              </select>
            </div>
          </div>
      </div>

      <div className="w-full flex relative md:top-0 top-32">
        <MaterialReactTable
          columns={columns}
          data={data}
          muiPaginationProps={{
            rowsPerPageOptions: [
              { value: 10, label: "10" },
              { value: 25, label: "25" },
              { value: 50, label: "50" },
              { value: 100, label: "100" },
              { value: data?.length, label: "All" },
            ],
            showFirstButton: false,
            showLastButton: false,
          }}
        />
      </div>
    </div>
  );
};

export default InvoiceR;
