import TailwindHeader from "../layout/TailwindHeader";
import Sidebar from "./Sidebar";

export default function SidebarLayout({ children }) {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <TailwindHeader logo={true} profile={true} />
        </div>
        <div className="col-md-3">
          <Sidebar />
        </div>
        <div className="col-md-9 h-screen">{children}</div>
      </div>
    </>
  );
}
