import React, { useState, useRef } from "react";
import { useFormContext } from "../../../../../providers";

export const DimensionInput = ({ index }) => {
    const { getValues, setValue, errors } = useFormContext();

    const [feetX, setFeetX] = useState("");
    const [feetY, setFeetY] = useState("");
    const [metersX, setMetersX] = useState("");
    const [metersY, setMetersY] = useState("");

    const feetYRef = useRef(null);
    const metersXRef = useRef(null);
    const metersYRef = useRef(null);

    const handleFeetXChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,5}$/.test(value)) {
            setFeetX(value);
            if (value.length === 5) {
                feetYRef.current.focus();
            }
        }
    };

    const handleFeetYChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,3}$/.test(value)) {
            setFeetY(value);
            if (value.length === 3) {
                metersXRef.current.focus();
            }
        }
    };

    const handleMetersXChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,4}$/.test(value)) {
            setMetersX(value);
            if (value.length === 4) {
                metersYRef.current.focus();
            }
        }
    };


    const handleMetersYChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,2}$/.test(value)) {
            setMetersY(value);
            updateFinalValue(feetX, feetY, metersX, value);
        } else {
            updateFinalValue(feetX, feetY, metersX, metersY);
        }
    };

    const updateFinalValue = (feetX, feetY, metersX, metersY) => {
        const finalFeetValue = `${feetX}X${feetY}ft`;
        const finalMeterValue = `${metersX}X${metersY}m.`;

        const fields = [...getValues('airports')];
        fields[index]['dimensions_feet'] = finalFeetValue;
        fields[index]['dimensions_meter'] = finalMeterValue;

        setValue('airports', fields);
    };


    return (
        <>
            <label>Dimensions</label>
            <div className="flex border border-input rounded-md gap-4 items-center px-2">
                <input
                    type="text"
                    value={feetX}
                    onChange={handleFeetXChange}
                    className="h-10 border-none shadow-none z-0 w-10"
                />
                <span>X</span>
                <input
                    type="text"
                    ref={feetYRef}
                    value={feetY}
                    onChange={handleFeetYChange}
                    className="h-10 border-none shadow-none z-0 w-10"
                />
                <span>ft./</span>
                <input
                    type="text"
                    ref={metersXRef}
                    value={metersX}
                    onChange={handleMetersXChange}
                    className="h-10 border-none shadow-none z-0 w-10"
                />
                <span>X</span>
                <input
                    type="text"
                    ref={metersYRef}
                    value={metersY}
                    onChange={handleMetersYChange}
                    className="h-10 border-none shadow-none z-0 w-10"
                />
                <span>m</span>
            </div>
            {(errors?.airports?.[index]?.dimensions_feet || errors?.airports?.[index]?.dimensions_meter) && (
                <p style={{ color: "red" }}>
                    {errors?.airports?.[index]?.dimensions_feet?.message || errors?.airports?.[index]?.dimensions_meter?.message}
                </p>
            )}
        </>
    );
};
