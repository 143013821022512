/* eslint-disable */
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import BASE_URL from "../Components/auth/Baseurl";
import axios from "axios";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "swiper/css";
import "./detail.css";
import "swiper/css/pagination";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import "react-datepicker/dist/react-datepicker.css";
import Layout from "../Components/layout/Layout";
import StripeCheckout from "react-stripe-checkout";
import Swal from "sweetalert2";
import BtnLoader from "../Components/BtnLoader/BtnLoader";
import Loading from "../Components/loader/Loading";
import { FaPeopleGroup } from "react-icons/fa6";
import CustomCalendar from "./CustomCalender";
import { BiSolidMessage } from "react-icons/bi";
import { GoVerified } from "react-icons/go";
import DetailDatePicker from "./DetailDatePicker";
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { EmailShareButton } from "react-share";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { ThemeContext } from "../context/ContextFile";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import './styles.css';

// import required modules
import { FreeMode, Pagination } from "swiper/modules";
import HeaderTwo from "./HeaderTwo";

const Details = () => {
  const [disable, setDisable] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [get, setGet] = useState([]);
  const [getone, setGetone] = useState([]);
  const [data, setData] = useState();
  const [similar, setSimilar] = useState();
  const [isDropdownOpen, setIsDropdownOpen] = useState();
  const [extraOpen, setExtraOpen] = useState();
  // Increament COde Start
  const [adults, setAdults] = useState(0);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);
  const [pets, setPets] = useState(0);
  const totalGuests = adults + children + infants + pets;
  const maxGuests = data?.no_of_guest || 0;

  const handleIncrement = (type) => {
    if (totalGuests < maxGuests) {
      switch (type) {
        case "adults":
          setAdults(adults + 1);
          break;
        case "children":
          setChildren(children + 1);
          break;
        case "infants":
          setInfants(infants + 1);
          break;
        case "pets":
          setPets(pets + 1);
          break;
        default:
          break;
      }
    }
  };

  const handleDecrement = (type) => {
    switch (type) {
      case "adults":
        if (adults > 0) setAdults(adults - 1);
        break;
      case "children":
        if (children > 0) setChildren(children - 1);
        break;
      case "infants":
        if (infants > 0) setInfants(infants - 1);
        break;
      case "pets":
        if (pets > 0) setPets(pets - 1);
        break;
      default:
        break;
    }
  };
  // Increament COde End
  const { id } = useParams();
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/listing-user-get-by-id/${id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(response.data, ">>>>>>>>>>>>>");
      setLoading(false);
      // setQuantity(response.data?.listing);
      setSimilar(response.data?.listing.listing_type);
      setData(response.data?.listing);
      console.log(
        response.data?.listing?.listing_type,
        "response.data?.listing"
      );
      setGet(eval(JSON.parse(response.data?.listing?.facilities)));
      setGetone(eval(JSON.parse(response.data?.listing?.amenities)));
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [innerData, setInnerData] = useState([]);
  const GetData = () => {
    // setLoad(true)
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("byListingType", similar);
      axios
        .post(`${BASE_URL}/listing-search`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          // setSimilar(res.data);
          setInnerData(res?.data);
          console.log(res.data, "res.data");
          setLoading(false);
          // setLoad(false)
        });
    } catch (err) {
      console.log(err);
      setInnerData(err);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (similar) {
      GetData(setInnerData(similar));
    }
  }, [similar]);

  let { setIsModalOpen } = useContext(ThemeContext);

  const allBookingDate = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/booking-date?listing_id=${id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setDisable(response.data?.dates);
      console.log(response, "responseresponse");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    allBookingDate();
  }, []);

  const onToken = async (token, note) => {
    if (LocalToken) {
      try {
        const formData = new FormData();
        if (data?.instant_booking === 1) {
          formData.append(
            "payment_method",
            data?.instant_booking === 1 ? "stripe" : "manually"
          );
          formData.append("no_of_guest", addiGuest);
          formData.append("note", text);
          formData.append("listing_id", data.id);
          formData.append("token", token.id);
          formData.append("first_name", userData.first_name);
          formData.append("last_name", userData1.last_name);
          formData.append("phone", userData2.contact_phone);
          formData.append("total", totalAmount);
          formData.append("app_fee", profitAmount);
        } else {
          formData.append(
            "payment_method",
            data?.instant_booking === 1 ? "stripe" : "manually"
          );
          formData.append("no_of_guest", addiGuest);
          formData.append("note", text);
          formData.append("listing_id", data.id);
          formData.append("app_fee", profitAmount);
          formData.append("total", totalAmount);
        }
        if (startDate) {
          formData.append(
            "arrive_date",
            moment(startDate).format("YYYY-MM-DD")
          );
        }
        if (endDate) {
          formData.append("depart_date", moment(endDate).format("YYYY-MM-DD"));
        }
        const response = await axios.post(`${BASE_URL}/booking`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setLoader(false);
        if (data?.instant_booking === 1) {
          Swal.fire({
            position: "center",
            html: `
            <div style="display: flex; flex-direction: column; align-items: center;">
<svg
                      viewBox="0 0 1024 1024"
                      height="4em"
                      width="4em"
                      style="margin-bottom: 10px;"
                  >
                      <!-- Outer circle with color #8ec639 -->
                      <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                      
                      <!-- Check mark with color #A2c66b -->
                      <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
                  </svg>
                <p style="margin: 0; font-size: 1.2em;">Profile update successful.</p>
            </div>
        `,
            title: "Booking successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          navigate("/");
        } else {
          Swal.fire({
            position: "center",
            html: `
            <div style="display: flex; flex-direction: column; align-items: center;">
<svg
                      viewBox="0 0 1024 1024"
                      height="4em"
                      width="4em"
                      style="margin-bottom: 10px;"
                  >
                      <!-- Outer circle with color #8ec639 -->
                      <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                      
                      <!-- Check mark with color #A2c66b -->
                      <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
                  </svg>
                <p style="margin: 0; font-size: 1.2em;">Profile update successful.</p>
            </div>
        `,
            title: "Requesting for booking",
            showConfirmButton: false,
            timer: 1500,
          });
          navigate("/");
        }
      } catch (error) {
        console.log("Error submitting booking:", error);
        errorPopup('', error?.response?.data?.message && "Something went wrong.");

      }
    } else {
      setIsModalOpen(true);
    }
  };

  const [extraServices, setExtraServices] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    const initialServices = {};
    data?.listing_extra_service_prices?.forEach((service) => {
      initialServices[service.id] = false;
    });
    setExtraServices(initialServices);
  }, [data]);

  const handleServiceChange = (id, price) => {
    setExtraServices((prev) => {
      const updatedServices = { ...prev, [id]: !prev[id] };
      const newTotalPrice = Object.keys(updatedServices).reduce((acc, key) => {
        return updatedServices[key]
          ? acc +
          data.listing_extra_service_prices.find(
            (s) => s.id === parseInt(key)
          ).price
          : acc;
      }, 0);
      setTotalPrice(newTotalPrice);

      return updatedServices;
    });
  };
  // console.log(totalPrice, "totalPrice");

  const rates = {
    nightly: data?.nightly,
    weekend: data?.weekend,
    weekly_seven_plus: data?.weekly_seven_plus,
    weekly_thirty_plus: data?.weekly_thirty_plus,
    city_fee: data?.city_fee,
    cleaning_fee: data?.cleaning_fee,
    security_deposit: data?.security_deposit,
    tax: data?.tax,
    additional_guest_price: data?.additional_guest_price,
  };

  const calculateTotalAmount = (startDate, endDate, rates) => {
    const arrivalDate = new Date(startDate);
    const departureDate = new Date(endDate);

    const totalDays =
      Math.ceil((departureDate - arrivalDate) / (1000 * 60 * 60 * 24)) + 1;
    const totalDayAmount = totalDays * rates.nightly;
    const extraAmountAdd = totalDayAmount + totalPrice;
    const percentageOfProfit = extraAmountAdd * 0.11;

    console.log(percentageOfProfit, "percentageOfProfit");

    let weekdayCount = 0;
    let weekendCount = 0;

    for (let i = 0; i < totalDays; i++) {
      const currentDate = new Date(arrivalDate);
      currentDate.setDate(arrivalDate.getDate() + i);
      const currentDay = currentDate.getDay();

      if (currentDay >= 1 && currentDay <= 5) {
        weekdayCount++;
      } else if (currentDay === 0 || currentDay === 6) {
        weekendCount++;
      }
    }

    let totalAmount = 0;

    if (totalDays > 30) {
      totalAmount =
        weekdayCount * rates.weekly_thirty_plus + weekendCount * rates.weekend;
    } else if (totalDays > 7) {
      totalAmount =
        weekdayCount * rates.weekly_seven_plus + weekendCount * rates.weekend;
    } else {
      totalAmount = weekdayCount * rates.nightly + weekendCount * rates.weekend;
    }
    // Additional fees
    // const additionalFees =
    //   rates.city_fee + rates.cleaning_fee + rates.security_deposit + rates.tax;
    // Calculate the total amount including additional guest price
    // totalAmount += totalGuests * rates.additional_guest_price;
    // Add extra services cost if addiGuest is greater than 0
    // if (totalGuests > 0) {
    const extraServiceCost = data?.listing_extra_service_prices?.reduce(
      (sum, service) => {
        return extraServices[service.id] ? sum + service.price : sum;
      },
      0
    );
    totalAmount += extraServiceCost;
    // }
    // totalAmount += additionalFees;

    // Store the subTotal before adding profit
    const subTotal = totalAmount;

    // Calculate the 11% profit amount
    const profitAmount = subTotal * 0.11;

    // Add 11% profit margin
    totalAmount += profitAmount;

    // Return the total amount rounded to 2 decimal places and the profit amount
    return {
      totalAmount: totalAmount.toFixed(2),
      profitAmount: profitAmount.toFixed(2),
      subTotal: subTotal.toFixed(2),
      totalDays,
      totalDayAmount,
      percentageOfProfit,
    };
  };

  const {
    totalAmount,
    profitAmount,
    subTotal,
    totalDays,
    totalDayAmount,
    percentageOfProfit,
  } = calculateTotalAmount(startDate, endDate, rates);
  console.log(profitAmount, "profitAmount");

  const [userData, setUserData] = useState({});
  const [userData1, setUserData1] = useState({});
  const [userData2, setUserData2] = useState({});

  useEffect(() => {
    const retrievedData = JSON.parse(localStorage.getItem("data"));
    // console.log(retrievedData, "name");
    if (retrievedData) {
      setUserData(retrievedData);
    }
    if (retrievedData) {
      setUserData1(retrievedData);
    }
    if (retrievedData) {
      setUserData2(retrievedData);
    }
  }, []);
  const [text, setText] = useState("");
  const handleValue = (e) => {
    const value = e.target.value;
    setText(value);
  };

  const defaultCenter = { lat: 0, lng: 0 };
  const center = {
    lat: Number(data?.lat) || defaultCenter.lat,
    lng: Number(data?.lng) || defaultCenter.lng,
  };

  const validationSchema = Yup.object().shape({
    arrive_date: Yup.date().required("Required"),
    depart_date: Yup.date()
      .min(Yup.ref("arrive_date"), "Departure date must be after arrival date")
      .required("Required"),
    note: Yup.string(),
  });

  function PrintElem() {
    var mywindow = window.open("", "PRINT", "height=1000,width=1000");

    mywindow.document.write("<html><head><style>");
    // Add your print-specific styles here
    mywindow.document.write(`
      @media print {
        /* Hide all elements you don't want to print */
        .other-images-container,
        .map-container, 
        .calendar-container,
        .sticky-container,
        .container_similar {
          display: none !important;
        }
      }
      /* Style adjustments for better print appearance */
      body {
        font-family: Arial, sans-serif;
      }
      img {
        max-width: 100%;
        height: auto;
      }
    `);
    mywindow.document.write("</style></head><body>");
    mywindow.document.write(document.getElementById("elem").innerHTML);
    mywindow.document.write("</body></html>");

    mywindow.document.close();

    mywindow.onload = function () {
      mywindow.focus();
      mywindow.print();
      mywindow.close();
    };

    return true;
  }

  const LocalToken = localStorage.getItem("token");
  const [datas, setDatas] = useState([]);
  // console.log(data,"getvalues");
  const handleFav = async () => {
    if (LocalToken) {
      const formData = new FormData();

      formData.append("listing_id", data?.id);
      try {
        const response = await axios.post(
          `${BASE_URL}/add-to-favourite`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        fetchData();

        Swal.fire({
          position: "center",
          html: `
          <div style="display: flex; flex-direction: column; align-items: center;">
              <svg
                  viewBox="0 0 1024 1024"
                  height="4em"
                  width="4em"
                  style="margin-bottom: 10px;"
              >
                  <!-- Outer circle with color #8ec639 -->
                  <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                  
                  <!-- Check mark with color #A2c66b -->
                  <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
              </svg>
              <p style="margin: 0; font-size: 1.2em;">Profile update successful.</p>
          </div>
      `,
          title: response?.data?.message,
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      setIsModalOpen(true);
    }
  };
  useEffect(() => {
    setDatas(data);
  }, [data]);

  const [modal, setModal] = useState(false);
  const copyTextRef = useRef(null);

  const handleCopy = () => {
    const input = copyTextRef.current;
    input.select();
    document.execCommand("copy");
    input.parentNode.classList.add("active");
    window.getSelection().removeAllRanges();
    setTimeout(() => {
      input.parentNode.classList.remove("active");
      setModal(false);
    }, 2500);
  };

  const userdata = localStorage.getItem("data");
  const user = JSON.parse(userdata);

  console.log(user, "userdata");

  // Swiper slider start //

  // Swiper slider start //
  const printCondition = () => {
    if (LocalToken) {
      PrintElem();
    } else {
      setIsModalOpen(true);
    }
  };

  const emailUrl = `https://flyinnew.dev-bt.xyz${window.location.pathname}`;

  return (
    <>
      <HeaderTwo />
      <div id="wrapper" className="bg-color">
        <div style={{ paddingTop: "0px" }} className="content bg-color">
          <section
            id="elem"
            style={{ padding: "20px 79px" }}
            className="bg-color"
          >
            <div className="container">
              <div className="page-container">
                {modal ? (
                  <>
                    <div className="col-md-12">
                      <div
                        style={{ display: modal ? "block" : "none" }}
                        className="wrappers"
                      ></div>
                      <div
                        className="modals"
                        style={{
                          visibility: modal ? "visible" : "hidden",
                          opacity: modal ? 1 : 0,
                        }}
                      >
                        <div className="modal__content">
                          <div className="container-detail">
                            <div className="label-detail">Copy Link</div>
                            <div className="copy-text">
                              <button onClick={handleCopy}>
                                <i className="fa fa-clone" />
                              </button>
                              <input
                                type="text"
                                className="text"
                                defaultValue={`https://flyinnew.dev-bt.xyz${window.location.pathname}`}
                                ref={copyTextRef}
                              />
                            </div>

                            <Link to={user.email}>
                              <div className="label-detail">Text</div>
                              <div className="email_btn_modal">
                                <button
                                  style={{ backgroundColor: "#54c4d9" }}
                                  className="copy-text button"
                                >
                                  <BiSolidMessage
                                    style={{ color: "white" }}
                                    size={25}
                                  />
                                </button>
                                <div className="label-detail">Text</div>
                              </div>
                            </Link>

                            <div className="label-detail">Email</div>

                            <EmailShareButton
                              url={emailUrl}
                              subject="Check out this page!"
                              body={"Click the link below to view the page:"}
                            >
                              <div
                                style={{ width: "275px" }}
                                className="email_btn_modal"
                              >
                                <button
                                  style={{ backgroundColor: "#54c4d9" }}
                                  className="copy-text button"
                                >
                                  <MdEmail
                                    style={{ color: "white" }}
                                    size={25}
                                  />
                                </button>
                                <div className="label-detail">Email</div>
                              </div>
                            </EmailShareButton>
                          </div>
                          <div
                            style={{ cursor: "pointer" }}
                            className="modal__close"
                            onClick={() => setModal(false)}
                          >
                            ×
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="row">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                      padding: "0px 20px",
                    }}
                  >
                    <div>
                      <h3
                        style={{
                          display: "flex",
                          fontSize: "28px",
                          fontWeight: "700",
                          fontFamily: "Quicksand",
                        }}
                      >
                        {data?.title}
                      </h3>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <FaLocationDot />
                        <p>
                          {data?.state}, {data?.city}, {data?.country}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <div
                        onClick={() => handleFav(id)}
                        className={
                          data?.is_favourite === 1
                            ? `geodir-js-favorite_btn1`
                            : `geodir-js-favorite_btn`
                        }
                        style={{
                          marginRight: "0px",
                          position: "relative",
                          left: "36px",
                          top: 0,
                        }}
                      >
                        <i
                          style={{ fontSize: "20px" }}
                          className="fal fa-heart"
                        />
                        <span>
                          {data?.is_favourite === 1 ? "saved" : "unsaved"}
                        </span>
                      </div>
                      <div className="_c2acbp">
                        <button
                          type="button"
                          onClick={() => {
                            LocalToken
                              ? setModal(!modal)
                              : setIsModalOpen(true);
                          }}
                        >
                          <div className="_5kaapu d-flex">
                            <span className="_14tkmhr">
                              <svg
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                                role="presentation"
                                focusable="false"
                                style={{
                                  display: "block",
                                  fill: "none",
                                  height: 16,
                                  width: 16,
                                  stroke: "currentcolor",
                                  strokeWidth: 2,
                                  overflow: "visible",
                                }}
                              >
                                <path
                                  d="m27 18v9c0 1.1046-.8954 2-2 2h-18c-1.10457 0-2-.8954-2-2v-9m11-15v21m-10-11 9.2929-9.29289c.3905-.39053 1.0237-.39053 1.4142 0l9.2929 9.29289"
                                  fill="none"
                                />
                              </svg>
                            </span>
                            Share
                          </div>
                        </button>
                      </div>

                      <button
                        style={{
                          display: "block",
                          width: "68px",
                          padding: "10px",
                          height: "33px",
                          backgroundColor: "#8EC639",
                          color: "#fff",
                          border: "none",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                        onClick={printCondition}
                      >
                        <div className="_5kaapu d-flex">
                          <span className="_14tkmhr">
                            <svg
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                              aria-hidden="true"
                              role="presentation"
                              focusable="false"
                              style={{
                                display: "block",
                                fill: "none",
                                height: 16,
                                width: 16,
                                stroke: "currentcolor",
                                strokeWidth: 2,
                                overflow: "visible",
                              }}
                            >
                              <path
                                d="M19 8H5c-1.11 0-1.99.89-1.99 2L3 18h4v4h10v-4h4v-8c0-1.11-.89-2-2-2zM16 18H8v-4h8v4zM18 1H6v4h12V1z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          Print
                        </div>
                      </button>
                    </div>
                  </div>
                  <div
                    className="col-md-6 first-image-container"
                    style={{ height: "52vh" }}
                  >
                    {data?.listing_images?.[0] && (
                      <img
                        src={data?.listing_images[0]?.image}
                        alt=""
                        style={{
                          borderRadius: "8px",
                          height: "100%",
                          width: "100%",
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-6 other-images-container">
                    <div className="row">
                      <div className="col-md-6" style={{ padding: "0px" }}>
                        <img
                          src={
                            data?.listing_images?.[1]
                              ? data?.listing_images?.[1]?.image
                              : data?.listing_images[0]?.image
                          }
                          alt=""
                          style={{
                            borderRadius: "8px",
                            height: "25vh",
                            width: "100%",
                          }}
                        />
                      </div>
                      <div className="col-md-6">
                        <img
                          src={
                            data?.listing_images?.[2]
                              ? data?.listing_images?.[2]?.image
                              : data?.listing_images[0]?.image
                          }
                          alt=""
                          style={{
                            borderRadius: "8px",
                            height: "25vh",
                            width: "100%",
                          }}
                        />
                      </div>
                      <div
                        className="col-md-6"
                        style={{ padding: "0px", marginTop: "10px" }}
                      >
                        <img
                          src={
                            data?.listing_images?.[3]
                              ? data?.listing_images?.[3]?.image
                              : data?.listing_images[0]?.image
                          }
                          alt=""
                          style={{
                            borderRadius: "8px",
                            height: "25vh",
                            width: "100%",
                          }}
                        />
                      </div>
                      <div className="col-md-6" style={{ marginTop: "10px" }}>
                        <img
                          src={
                            data?.listing_images?.[4]
                              ? data?.listing_images?.[4]?.image
                              : data?.listing_images[0]?.image
                          }
                          alt=""
                          style={{
                            borderRadius: "8px",
                            height: "25vh",
                            width: "100%",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content-wrapper">
                  {/* 8-column Section */}
                  <div className="col-md-8 main-content">
                    <div className="about-section">
                      <div className="block">
                        <div className="block-body border-bottom-gray">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h2 className="title">
                                {data?.type_of_space} - {data?.listing_type} -
                                Hosted by {data?.host?.username}
                              </h2>

                              <div
                                style={{ paddingTop: "10px" }}
                                className="property-details text-start"
                              >
                                {data?.no_of_guest + " "} Guests{" "}
                                <span>•</span> {data?.no_of_bedrooms + " "}
                                Bedrooms <span>•</span>
                                {" " + data?.no_of_beds + " "}
                                Beds <span>•</span> {data?.no_of_bathroom + " "}{" "}
                                Baths
                              </div>
                            </div>

                            <div
                              style={{ width: "150px" }}
                              className="host-avatar no-print"
                            >
                              <img src={data?.host?.image} alt="Host Avatar" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="block">
                        <div className="block-body">
                          <h2 style={{ fontSize: "24px" }}>About this stay</h2>
                          <p>
                            Welcome to your picturesque retreat nestled between
                            the mountains and Palisades Reservoir in the private
                            Alpine Airport community. Step in the front door and
                            be captivated by its open-concept design, where
                            spaciousness meets cozy elegance. The home boasts a
                            charming fireplace, inviting you to unwind in its
                            warm glow after a day of adventures.
                          </p>
                          <div className="airport-info">
                            <p className="text-decoration-underline">
                              <strong style={{ marginLeft: "3px" }}>
                                AIRPORT INFORMATION
                              </strong>
                            </p>
                            <p>
                              <strong style={{ marginLeft: "3px" }}>
                                Airport Use:
                              </strong>{" "}
                              {data?.airport_use}
                            </p>
                            <p>
                              <strong style={{ marginLeft: "3px" }}>
                                Orientation:
                              </strong>{" "}
                              {data?.orientation}
                            </p>
                            <p>
                              <strong style={{ marginLeft: "3px" }}>
                                Surface:
                              </strong>{" "}
                              {data?.surface}
                            </p>
                            <p>
                              <strong style={{ marginLeft: "3px" }}>
                                Dimensions:
                              </strong>{" "}
                              {data?.dimensions}
                            </p>
                            <p>
                              <strong style={{ marginLeft: "3px" }}>
                                Elevation:
                              </strong>{" "}
                              {data?.elevation}
                            </p>
                            <p>
                              <strong style={{ marginLeft: "3px" }}>
                                Lighting:
                              </strong>{" "}
                              {data?.lighting}
                            </p>
                            <p>
                              <strong style={{ marginLeft: "3px" }}>
                                Fuel:
                              </strong>{" "}
                              {data?.fuel}
                            </p>
                            <p>
                              <strong style={{ marginLeft: "3px" }}>
                                Parking:
                              </strong>{" "}
                              {data?.parking}
                            </p>
                            <p>
                              <strong style={{ marginLeft: "3px" }}>
                                CTAF/UNICOM:
                              </strong>{" "}
                              {data?.ctaf_unicom}
                            </p>
                            <p>
                              <strong style={{ marginLeft: "3px" }}>
                                Pattern:
                              </strong>{" "}
                              {data?.pattern}
                            </p>
                            <p>
                              <strong style={{ marginLeft: "3px" }}>
                                Operation Hours:
                              </strong>{" "}
                              {data?.operation_hours}
                            </p>
                          </div>
                          <div className="ground-transportation">
                            <p className="text-decoration-underline">
                              <strong style={{ marginLeft: "3px" }}>
                                {" "}
                                GROUND TRANSPORTATION
                              </strong>
                            </p>
                            <p>We have a car here waiting for you!</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detail-section">
                      <div className="block-body pt-0">
                        <div className="block-left">
                          <h3 className="title text-start pb-30">Details</h3>
                        </div>
                        <div className="block-right">
                          <ul className="detail-list detail-list-2-cols">
                            <li>
                              <i className="fas fa-id-badge" />
                              ID#:{" "}
                              <strong style={{ marginLeft: "3px" }}>
                                {data?.id}
                              </strong>
                            </li>
                            <li>
                              <i className="fas fa-users" />
                              Guests:{" "}
                              <strong style={{ marginLeft: "3px" }}>
                                {data?.no_of_guest}
                              </strong>
                            </li>
                            <li>
                              <i className="fas fa-bed" />
                              Bedrooms:{" "}
                              <strong style={{ marginLeft: "3px" }}>
                                {data?.no_of_bedroom}
                              </strong>
                            </li>
                            <li>
                              <i className="fas fa-bed" />
                              Beds:{" "}
                              <strong style={{ marginLeft: "3px" }}>
                                {data?.no_of_beds}
                              </strong>
                            </li>
                            <li>
                              <i className="fas fa-bath" />
                              Bathrooms:{" "}
                              <strong style={{ marginLeft: "3px" }}>
                                {data?.no_of_bathroom}
                              </strong>
                            </li>
                            <li>
                              <i className="fas fa-home" />
                              Rooms:{" "}
                              <strong style={{ marginLeft: "3px" }}>
                                {data?.no_of_room}
                              </strong>
                            </li>
                            <li>
                              <i className="fas fa-calendar-day" />
                              Check-in After:{" "}
                              <strong style={{ marginLeft: "3px" }}>
                                {data?.check_in_after}
                              </strong>
                            </li>
                            <li>
                              <i className="fas fa-calendar-day" />
                              Check-out Before:{" "}
                              <strong style={{ marginLeft: "3px" }}>
                                {data?.check_in_before}
                              </strong>
                            </li>
                            <li>
                              <i className="fas fa-key" />
                              Dwelling Type:{" "}
                              <strong style={{ marginLeft: "3px" }}>
                                Entire Cabin
                              </strong>
                            </li>
                            <li>
                              <i className="fas fa-ruler" />
                              Size:{" "}
                              <strong style={{ marginLeft: "3px" }}>
                                {data?.size}
                              </strong>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div id="price-section" className="price-section">
                      <div className="block">
                        <div className="block-section">
                          <div className="block-body">
                            <div className="block-left">
                              <h3 className="title text-start pb-30">Prices</h3>
                            </div>
                            {/* block-left */}
                            <div className="block-right">
                              <ul className="detail-list detail-list-2-cols">
                                <li>
                                  <i className="fa fa-check-circle" />
                                  Night :{" "}
                                  <strong style={{ marginLeft: "3px" }}>
                                    ${data?.nightly}
                                  </strong>
                                </li>
                                <li>
                                  <i className="fa fa-check-circle" />
                                  Cleaning Fee :{" "}
                                  <strong style={{ marginLeft: "3px" }}>
                                    ${data?.cleaning_fee}
                                  </strong>
                                </li>
                                <li>
                                  <i className="fa fa-check-circle" />
                                  Weekly (7d+) :
                                  <strong style={{ marginLeft: "3px" }}>
                                    ${data?.weekly_seven_plus}
                                  </strong>
                                </li>
                                <li>
                                  <i className="fa fa-users" />
                                  Allow additional guests :
                                  <strong style={{ marginLeft: "3px" }}>
                                    {data?.additional_guest == 1 ? "Yes" : "No"}
                                  </strong>
                                </li>
                                <li>
                                  <i className="fa fa-check-circle" />
                                  Minimum days of a booking :
                                  <strong style={{ marginLeft: "3px" }}>
                                    {data?.min_day_booking}
                                  </strong>
                                </li>
                                <li>
                                  <i className="fa fa-check-circle" />
                                  Maximum days of a booking:{" "}
                                  <strong style={{ marginLeft: "3px" }}>
                                    {data?.max_day_booking}
                                  </strong>
                                </li>
                              </ul>
                            </div>
                            {/* block-right */}
                          </div>
                          {/* block-body */}
                        </div>
                        {/* block-section */}
                      </div>
                      {/* block */}
                    </div>
                    <div
                      id="accomodation-section"
                      data-path="single-listing-to-accomodation-file"
                      className="accomodation-section"
                    >
                      <div className="block-section">
                        <div className="block-body">
                          <div className="block-left">
                            <h3 className="title text-start pb-30">
                              Accommodations
                            </h3>
                          </div>
                          {/* block-left */}
                          <div className="block-right block-right-accomo">
                            {data?.listing_beds?.map((e) => (
                              <div
                                className="block-col block-col-33 block-accomodation"
                                data-which="Master Bedroom"
                              >
                                <div className="block-icon">
                                  {/* Icon can be added here */}
                                </div>
                                <dl>
                                  <dt>{e?.name}</dt>
                                  <dd>
                                    {e?.no_of_beds} {e?.bed_type} Beds
                                  </dd>
                                  <dd>{e?.no_of_beds} Guests</dd>
                                </dl>
                              </div>
                            ))}
                          </div>
                          {/* block-right */}
                        </div>
                        {/* block-body */}
                      </div>
                      {/* block-section */}

                      {/* block */}
                    </div>
                    <div className="features-section">
                      <div className="block">
                        <div className="block-section">
                          <div className="block-body">
                            <div className="block-left">
                              <h3 className="title text-start pb-30">
                                Features
                              </h3>
                            </div>
                            {/* block-left */}
                            <div className="block-right">
                              <p>
                                <strong style={{ marginLeft: "3px" }}>
                                  Amenities
                                </strong>
                              </p>
                              <ul className="detail-list detail-list-2-cols">
                                {Array.isArray(getone) &&
                                  getone?.map((amenity, index) => (
                                    <li key={index}>
                                      <i class="fa-thin fa-greater-than"></i>{" "}
                                      {amenity}
                                    </li>
                                  ))}
                              </ul>
                              <p>
                                <strong style={{ marginLeft: "3px" }}>
                                  Facilities
                                </strong>
                              </p>
                              <ul className="detail-list detail-list-2-cols">
                                {Array.isArray(get) &&
                                  get?.map((amenity, index) => (
                                    <li key={index}>
                                      <i class="fa-thin fa-greater-than"></i>{" "}
                                      {amenity}
                                    </li>
                                  ))}
                              </ul>
                            </div>
                            {/* block-right */}
                          </div>
                          {/* block-body */}
                        </div>
                        {/* block-section */}
                      </div>
                      {/* block */}
                    </div>
                    <div className="features-section no-print">
                      <div>
                        <h2 className="title pb-30">Map</h2>
                      </div>
                      <div className="box-widget">
                        <div
                          style={{ marginBottom: "20px" }}
                          className="map-container"
                        >
                          <div>
                            <div style={{ height: "100%", width: "100%" }}>
                              {data?.lat && data?.lng && (
                                <GoogleMap
                                  mapContainerStyle={{
                                    height: "300px",
                                    width: "100%",
                                    marginBottom: "30px",
                                  }}
                                  center={center}
                                  zoom={10}
                                // apiKey="AIzaSyDXJS_VZMhnp0szh92aZGg8RHszz6RMQN8"
                                >
                                  <MarkerF position={center} />
                                </GoogleMap>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="features-section">
                      <div className="block-section">
                        <div className="block-body">
                          {/* block-left */}
                          <div className="block-right">
                            <ul className="detail-list">
                              <h3
                                className="title text-start "
                                style={{ marginBottom: "10px" }}
                              >
                                Terms & rules
                              </h3>
                              <div className="d-flex justify-content-between">
                                <li>
                                  <i class="fa-thin fa-greater-than"></i>{" "}
                                  Smoking allowed:
                                </li>
                                <li>
                                  {data?.smoking_allowed === 1 ? "Yes" : "No"}
                                </li>
                              </div>
                              <div className="d-flex justify-content-between">
                                <li>
                                  <i class="fa-thin fa-greater-than"></i> Pets
                                  allowed:
                                </li>
                                <li>
                                  {data?.pet_allowed === 1 ? "Yes" : "No"}
                                </li>
                              </div>

                              <div className="d-flex justify-content-between">
                                <li>
                                  <i class="fa-thin fa-greater-than"></i> Party
                                  allowed:
                                </li>
                                <li>
                                  {data?.party_allowed === 1 ? "Yes" : "No"}
                                </li>
                              </div>
                              <div className="d-flex justify-content-between">
                                <li>
                                  <i class="fa-thin fa-greater-than"></i>{" "}
                                  Children allowed:
                                </li>
                                <li>
                                  {data?.children_allowed === 1 ? "Yes" : "No"}
                                </li>
                              </div>
                            </ul>
                          </div>
                          {/* block-right */}
                        </div>
                        {/* block-body */}
                      </div>
                    </div>
                    <>
                      <div id="host-section" className="host-section">
                        <div className="block">
                          <>
                            <div className="d-flex justify-content-between align-items-center mb-30">
                              <div className="text-left">
                                <h3 className="title">Availability</h3>
                              </div>
                              <div>
                                <i class="fa-solid fa-calendar-days"></i>
                                The minimum number of Nights is{" "}
                                <strong style={{ color: "gray" }}>
                                  {data?.min_day_booking}
                                </strong>
                              </div>
                              <div>
                                <i class="fa-solid fa-calendar-days"></i>
                                The maximum number of Nights is{" "}
                                <strong style={{ color: "gray" }}>
                                  {data?.max_day_booking}
                                </strong>
                              </div>
                            </div>
                            <div
                              className="calender-box mb-30 no-print calendar-container
"
                            >
                              <div className="row">
                                <div className="col-md-12">
                                  <CustomCalendar disable={disable} />
                                </div>
                              </div>
                            </div>
                          </>

                          <div
                            style={{ marginTop: "40px" }}
                            className="block-head"
                          >
                            <div className="media">
                              <div className="media-left">
                                <img
                                  width={70}
                                  height={70}
                                  alt=""
                                  src={data?.host?.image}
                                  className="img-circle media-object lazyloaded"
                                />
                              </div>
                              <div className="media-body">
                                <h2 className="title">
                                  Hosted by {data?.host?.username}
                                </h2>
                                <ul className="list-inline profile-host-info">
                                  <li>
                                    <address>
                                      <i
                                        className="homey-icon homey-icon-style-two-pin-marker"
                                        aria-hidden="true"
                                      />{" "}
                                      United States
                                    </address>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          {/* /.block-head */}
                          <div className="block-body">
                            <div className="row">
                              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 text-left">
                                <dl>
                                  <dt>Languages</dt>
                                  <dd>English</dd>
                                </dl>
                              </div>
                              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <dl>
                                  <dt>Profile Status</dt>
                                  <dd className="text-danger">
                                    <i className="homey-icon homey-icon-uncheck-circle-1" />{" "}
                                    {data?.host?.status === 1
                                      ? "Verified"
                                      : "Not Verified"}
                                  </dd>
                                </dl>
                              </div>
                            </div>
                            {/* /.row */}
                            <div className="host-section-buttons text-left">
                              <Link
                                to={"/pic"}
                                state={{
                                  data: data,
                                  email: data?.host?.email,
                                }}
                                className="btn btn-grey-outlined btn-half-width"
                              >
                                View Profile
                              </Link>
                            </div>

                            <h3
                              className="title text-start "
                              style={{
                                marginTop: "40px",
                                marginBottom: "20px",
                              }}
                            >
                              Reviews: 0
                            </h3>
                            <div
                              style={{ marginBottom: "50px" }}
                              className="box-widget-item fl-wrap block_box"
                            >
                              <div className="box-widget  fl-wrap">
                                <div className="box-widget-content">
                                  {/*widget-posts*/}
                                  <div className="widget-posts  fl-wrap">
                                    <p
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                      }}
                                    >
                                      {" "}
                                      <GoVerified size={20} />
                                      Verified Reviews - All reviews are from
                                      verified guests.
                                    </p>
                                  </div>
                                  {/* widget-posts end*/}
                                </div>
                              </div>
                            </div>

                            {/* /.host-section-buttons */}
                          </div>
                          {/* /.block-body */}
                        </div>
                        {/* /.block */}
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
    // </Layout>
  );
};

export default Details;
