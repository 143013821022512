import React from 'react'
import Layout from '../Components/layout/Layout'
import { Link } from 'react-router-dom'

const Courtesy = () => {
  return (
   <Layout>
    <section className="main-content-area">
  <div className="container">
    <div className="row">
      <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9">
        <div className="page-title">
          <div className="block-top-title">
            <ol className="breadcrumb">
              <li>
                <Link to="/">
                  <span>Home</span>
                </Link>
              </li>
              <li className="active">
                Courtesy, Rental, Peer-to-Peer, Car-Sharing Policy
              </li>
            </ol>{" "}
            <h1 className="">
              Courtesy, Rental, Peer-to-Peer, Car-Sharing Policy
            </h1>
          </div>
          {/* block-top-title */}
        </div>
        {/* page-title */}
      </div>
    </div>
    {/* .row */}
  </div>
  {/* .container */}
  <div className="container">
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 inner-static">
        <div className="page-wrap">
          <div className="article-main">
            <article
              id="post-7483"
              className="single-page-article block post-7483 page type-page status-publish hentry"
            >
              <div className="article-detail block-body">
                <div
                  data-elementor-type="wp-page"
                  data-elementor-id={7483}
                  className="elementor elementor-7483"
                  data-elementor-post-type="page"
                >
                  <div
                    className="elementor-element elementor-element-3a71b800 e-flex e-con-boxed e-con e-parent"
                    data-id="3a71b800"
                    data-element_type="container"
                  >
                    <div className="e-con-inner">
                      <div
                        className="elementor-element elementor-element-54e43d8c elementor-widget elementor-widget-text-editor"
                        data-id="54e43d8c"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div className="elementor-widget-container">
                          <style
                            dangerouslySetInnerHTML={{
                              __html:
                                "/*! elementor - v3.21.0 - 18-04-2024 */\n.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap{background-color:#69727d;color:#fff}.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap{color:#69727d;border:3px solid;background-color:transparent}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap{margin-top:8px}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter{width:1em;height:1em}.elementor-widget-text-editor .elementor-drop-cap{float:left;text-align:center;line-height:1;font-size:50px}.elementor-widget-text-editor .elementor-drop-cap-letter{display:inline-block}"
                            }}
                          />{" "}
                          <p>{/* wp:paragraph */}</p>
                          <p>
                            One of the greatest things about our community is
                            that hosts offer guests convenient access to a
                            courtesy car when guests land. How else are guests
                            to have fun? They have to be able to get around!
                          </p>
                          <p>{/* /wp:paragraph */}</p>
                          <p>{/* wp:paragraph */}</p>
                          <p>
                            Convenient access does not mean that hosts are
                            required to lend or rent their car to the guest. As
                            the phrase indicates, it simply means that hosts
                            will do what they can to make it convenient for
                            their guests to have access to transportation. Below
                            are some ideas on how hosts can carry out that task.
                          </p>
                          <p>{/* /wp:paragraph */}</p>
                          <p>{/* wp:paragraph */}</p>
                          <p>
                            <strong>
                              Lending or renting their own vehicle:{" "}
                            </strong>
                            Hosts need the assurance that they will be protected
                            should anything happen to the vehicle they are
                            allowing their guests to use. There are several easy
                            ways hosts can handle this and be protected:
                          </p>
                          <ol>
                            <li>
                              Puting the car for rent on a “peer-to-peer”
                              car-sharing service (such as{" "}
                              <a href="https://turo.com/">Turo</a>,{" "}
                              <a href="https://availcarsharing.com/">Avail</a>,
                              or <a href="https://getaround.com/">GetAround</a>)
                              and rent it to the Guest for profit or for a
                              nominal fee. These car-sharing companies offer
                              insurance coverage to protect your vehicle! Visit
                              their websites to learn about them
                            </li>
                            <li>
                              Carrying commercial auto insurance—can be costly
                            </li>
                            <li>Being self-insured.</li>
                          </ol>
                          <p>{/* /wp:paragraph */}</p>
                          <p>{/* wp:paragraph */}</p>
                          <p>
                            <strong>
                              Facilitating access to a rental or courtesy car:{" "}
                            </strong>
                            Hosts can simply help their guest obtain a car from
                            a third party. Here are the most popular ways this
                            is accomplished:
                          </p>
                          <ol>
                            <li>
                              Arrange for the nearest rental car agency to bring
                              the car to the guest (many local agencies are
                              happy to provide this service)
                            </li>
                            <li>
                              Arrange for the FBO to have a courtesy car
                              available for the guest
                            </li>
                            <li>
                              Arrange for the guest to have access to a
                              car-sharing service (such as{" "}
                              <a href="https://www.enterprisecarshare.com/us/en/home.html">
                                Enterprise Car Sharing
                              </a>{" "}
                              or <a href="https://www.zipcar.com/">ZipCar</a>).
                            </li>
                          </ol>
                          <p>{/* /wp:paragraph */}</p>
                          <p>{/* wp:paragraph */}</p>
                          <p>
                            <b>
                              Please consult with your lawyer to see which
                              options suit your situation best. FlyInn assumes
                              no responsibility for property damage, bodily
                              injury, or any other loss of any sort. FlyInn does
                              not endorse any of the companies listed above, nor
                              is it affiliated with them in any way. A simply
                              search will turn these up as the only companies
                              that offer the services contained herein.
                            </b>
                          </p>
                          <p>
                            Let us know if you can think of other ways your
                            Guest can obtain transportation. You may use our
                            contact form on our Contact Page or send us an email
                            to&nbsp;
                            <Link
                              to="mailto:pic@fly-inn.com"
                              target="_blank"
                              rel="noopener"
                            >
                              PIC@fly-inn.com
                            </Link>
                            &nbsp;and write “Suggestions” in the subject line.
                          </p>
                          <p>{/* /wp:paragraph */}</p>
                          <p>
                            <strong>More on Turo: </strong>
                            This option is great if you will be letting your
                            guest use your car. Whether you rent it to them for
                            a real rental rate or for a nominal fee, the
                            insurance that Turo offers isn’t offered by anyone
                            else (that we know of as of the date of this
                            writing).
                          </p>
                          <p>
                            Because both Hosts and Guests are legally required
                            to carry insurance, Turo offers insurance (through
                            Travelers Insurance) for both Hosts and Guests. They
                            offer several different optional plans for both
                            Hosts and Guests. For those who are already insured,
                            Turo’s liability insurance will act as an additional
                            layer to supplement your current coverage. It will
                            kick in as a secondary source.
                          </p>
                          <strong>Guests:</strong> When renting, Guests can opt
                          out of the insurance plan Turo offers if the car
                          insurance policy they have for their vehicle(s)
                          specifically includes “peer-to-peer rental cars”,
                          which is not the same as a traditional car rental.
                          Many insurance companies see car rentals from agencies
                          and peer-to-peer car rentals very differently.
                          Remember that car insurance usually follows the
                          vehicle, not the driver, and both Hosts and Guests
                          should verify this by reading the policy and calling
                          the insurance company. In many cases, insurance
                          providers may offer liability but not physical
                          coverage for a rental car.
                          <p>
                            <strong>Hosts:</strong> When renting their vehicles,
                            hosts can opt-out of Turo’s protection coverage if
                            they carry commercial auto insurance in addition to
                            their personal auto policy. While you must have a
                            personal car insurance policy to host through Turo,
                            it will never extend to cover the rental use of your
                            vehicle(s). You will risk your insurance provider
                            dropping you as a client if you rent out your
                            personal car for commercial purposes.
                          </p>
                          <p> Your options are to either:</p>
                          <ol>
                            <li>Opt-in for a Turo protection plan</li>
                            <li>
                              Get a commercial rental liability policy to become
                              a Commercial Host (usually reserved for those who
                              own a business renting out multiple vehicles).
                            </li>
                          </ol>
                          Regardless of the option you choose, DO NOT cancel
                          your personal insurance. Turo’s coverage is limited to
                          rental periods. Canceling your personal insurance
                          could result in uncovered damages, a breach of
                          financing or leasing agreements, license suspension,
                          or automobile impoundment.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* .entry-content */}
            </article>
            {/* #post-## */}{" "}
          </div>
        </div>
        {/* grid-listing-page */}
      </div>
    </div>
    {/* .row */}
  </div>{" "}
  {/* .container */}
</section>

   </Layout>
  )
}

export default Courtesy