/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { useFormContext } from "../../../../providers";

import BASE_URL from "../../../auth/Baseurl";


export const FeatureForm = () => {
    const [features, setFeatures] = useState([]);
    const [subFeatures, setSubFeatures] = useState([]);
    const [selectedFeatureID, setSelectedFeatureID] = useState('');


    const { errors, getValues, setValue, watch } = useFormContext();

    const featuresBlock = watch('features');

    // Fetch features from API
    const GetData = () => {
        axios
            .get(`${BASE_URL}/feature`, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                setFeatures(res.data?.data || []);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        GetData();
    }, []);

    // Add a new block
    const addBlock = () => {
        const newBlock = {
            id: uuidv4(),
            sub_features: [],
        };
        setValue("features", [...featuresBlock, newBlock]);
    };

    const handleBlockChange = useCallback(async (e, index) => {
        const value = e.target.value;
        const updatedBlocks = [...featuresBlock];
        setSelectedFeatureID(value);
        updatedBlocks[index].id = value;
        try {
            const response = await axios.get(`${BASE_URL}/feature/${value}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            setSubFeatures(response.data.feature.feature_detail || []);
        } catch (error) {
            console.error("Error fetching feature details:", error);
        }
        setValue('features', updatedBlocks)
    }, []);


    const handleSubblockChange = useCallback((e, index) => {
        const checked = e.target.checked;
        const subFeatureID = e.target.id;
        const updatedBlocks = [...featuresBlock];
        const checkboxes = updatedBlocks[index].sub_features;

        if (checked) {
            checkboxes.push(subFeatureID.toString());
        } else {
            updatedBlocks[index].sub_features = checkboxes.filter(
                (id) => id !== subFeatureID.toString()
            );
        }

        setValue('features', updatedBlocks)

    }, []);

    // Remove a block
    const removeBlock = (blockId) => {
        const updatedBlocks = featuresBlock.filter((block) => block.id !== blockId);
        setValue("features", updatedBlocks);
    };


    return (
        <div className="grid md:grid-cols-12 bg-white p-4 rounded-lg gap-4">
            <h3 className="text-xl font-bold text-center md:col-span-12">Features</h3>

            {/* features List */}
            {featuresBlock?.map((block, index) => (
                <div key={block.id} className="md:col-span-12">
                    {/* Feature Selector */}
                    <div className="text-left">
                        <label>Feature</label>
                        <select
                            value={selectedFeatureID}
                            onChange={(e) => {
                                handleBlockChange(e, index)
                            }}
                            className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full"
                        >
                            <option disabled value="">
                                All categories
                            </option>
                            {features?.map((feature) => (
                                <option
                                    key={feature.id}
                                    value={feature.id}
                                    disabled={features.some((b) => selectedFeatureID === feature.id)}
                                >
                                    {feature.heading}
                                </option>
                            ))}
                        </select>
                        {errors?.features?.[index]?.id && (
                            <p className="text-red-500">{errors?.features?.[index]?.id.message}</p>
                        )}
                    </div>

                    {/* Checkbox List for Features */}
                    <div className="md:col-span-12 d-flex flex-wrap gap-3">
                        <ul className="fl-wrap filter-tags no-list-style ds-tg" style={{ paddingTop: "10px" }}>
                            {subFeatures?.map((item) => (
                                <li key={item.id} className="border border-gray-100 p-3">
                                    <input
                                        type="checkbox"
                                        id={item.id}
                                        checked={featuresBlock?.[index]?.sub_features.includes(item.id.toString())}
                                        onChange={(e) => {
                                            handleSubblockChange(e, index)
                                        }}
                                    />
                                    <label htmlFor={item.id} style={{ padding: "0px 20px 0px 6px", top: 0 }}>
                                        {item.sub_heading}
                                    </label>
                                </li>
                            ))}
                        </ul>
                        {errors?.features?.[index]?.sub_features && (
                            <p className="text-red-500">{errors?.features?.[index]?.sub_features?.message}</p>
                        )}
                    </div>


                    {/* Add and Remove Block Buttons */}
                    <div className="md:col-span-12 text-left flex justify-end items-end gap-4">
                        {featuresBlock.length > 1 && (
                            <button
                                type="button"
                                onClick={() => removeBlock(block.id)}
                                className="border border-[#3b4249] font-medium bg-[#8ec639] rounded-md text-white px-8 py-3"
                            >
                                Remove
                            </button>
                        )}

                        <button
                            type="button"
                            onClick={addBlock}
                            className="border border-[#3b4249] font-medium bg-[#8ec639] rounded-md text-white px-8 py-3"
                        >
                            Add New Category
                        </button>

                    </div>
                </div>
            ))}

            {/* Save and Submit Buttons */}

        </div>
    );
};
