import React, { useRef } from 'react'
import {  FaTrash } from "react-icons/fa";


const ImagesCardPost = ({
    image,
    index,
    onDelete,
 
}) => {
    const ref = useRef(null);

 


    return (
        <div
            ref={ref}
            className="image-item"
            style={{ margin: "12px", cursor: "move", position: "relative" }}
          
        >
            <div style={{ height: "150px", backgroundColor: "rgb(246, 248, 250)" }}>
                <img src={image.data_url} alt="" style={{ width: "120px", height: "120px" }}  />

                {/* Delete Button */}
                <div
                    onClick={() => onDelete(index)}
                    style={{
                        position: "absolute",
                        top: "127px",
                        right: "5px",
                        background: "transparent",
                        border: "none",
                        cursor: "pointer",
                        fontSize: "larger",
                        color: "#3b4249",
                    }}
                >
                    <FaTrash />
                </div>     
            </div>
        </div >
    );
};
export default ImagesCardPost