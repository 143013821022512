import React from 'react'
import { FormProvider } from '../../providers'
import { addListingValidationSchema } from '../../utils'
import { ADD_LISTING_DEFAULT_VALUES } from '../../constants'
import ListingTypeForm from '../../Components/Add/Listings'
import { useLocation } from 'react-router-dom'

export const AddListingFragment = () => {

    const location = useLocation();
    const { listing_type } = location.state || {};

    return (

        <FormProvider validationSchema={addListingValidationSchema} defaultValues={{ ...ADD_LISTING_DEFAULT_VALUES, listing_type }}>
            <ListingTypeForm />
        </FormProvider>
    )
}
