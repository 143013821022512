import React, { useContext, useEffect } from "react";
// import { Link } from 'react-router-dom'
import New from "./New";
import { fetchData, ThemeContext } from "../context/ContextFile";
import Layout from "./layout/Layout";

function Bannerthree() {
  // console.log(data,"value");
  let { data, setData } = useContext(ThemeContext);
  useEffect(()=>{
    fetchData().then((res)=>{
      console.log(res?.data.data,"datafetch")
      setData(res?.data.data)
    })
  },[setData]);
  


  return (
    <>

      <Layout>


        <div className="content">
          <section>

            <div style={{paddingTop:"100px"}} className="">
              <div className="grid-item-holder gallery-items fl-wrap">
                <div style={{ display: "flex", flexWrap: "wrap" }} className="row">
                  <New data={data} setData={setData} />
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
}

export default Bannerthree;
