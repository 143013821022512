import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import axios from "axios";
import BASE_URL from "./auth/Baseurl";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { errorPopup } from "../helpers";

const PayoutR = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/payout`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setData(response.data.data);
        console.log(response, "values");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "date",
        header: "Date Requested",
        size: 150,
      },
      {
        accessorKey: "id",
        header: "ID",
        size: 150,
      },
      {
        accessorKey: "amount",
        header: "Amount",
        size: 200,
      },
      {
        accessorKey: "type",
        header: "Payout Method",
        size: 150,
      },
      // {
      //   accessorKey: "date", // Changed the accessorKey to differentiate from the first date column
      //   header: "Date Processed",
      //   size: 150,
      // },
      {
        accessorKey: "status",
        header: "Status",
        size: 150,
      },
      {
        accessorKey: "action",
        header: "Action",
        size: 200,
        Cell: ({ row }) => (
          <button
            className="logout_btn color2-bg"
            onClick={() =>
              navigate("/payoutdetails", { state: { row: row.original } })
            }
          >
            Details
          </button>
        ),
      },
    ],
    [navigate]
  );

  const table = useMaterialReactTable({
    columns,
    data,
  });

  const handleSubmit = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/connected-account`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      console.log(response, "payouts");
      window.open(response.data?.link?.url);
      Swal.fire({
        position: "center",
        html: `
            <div style="display: flex; flex-direction: column; align-items: center;">
<svg
                      viewBox="0 0 1024 1024"
                      height="4em"
                      width="4em"
                      style="margin-bottom: 10px;"
                  >
                      <!-- Outer circle with color #8ec639 -->
                      <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                      
                      <!-- Check mark with color #A2c66b -->
                      <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
                  </svg>
                <p style="margin: 0; font-size: 1.2em;">Profile update successful.</p>
            </div>
        `,
        title: response.data?.message,
        showConfirmButton: true,
        timer: 1500,
      });
    } catch (error) {

      errorPopup('', "Something went wrong.");
    }
  };

  return (
    <div style={{ marginTop: "30px" }} className="col-md-12">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "10px",
        }}
        className="d-flex"
      >
        <h1 style={{ paddingTop: "17px" }}>History</h1>
        <button onClick={handleSubmit} className="logout_btn color2-bg">
          Setup Payout Method
        </button>
      </div>

      <MaterialReactTable table={table} />
    </div>
  );
};

export default PayoutR;
