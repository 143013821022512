import React from "react";
import { FaShower } from "react-icons/fa";
import { MdGroup, MdOutlineBedroomParent } from "react-icons/md";
import { Link } from "react-router-dom";
import "../../styles/ListingOutOverlay.css"

const ListingOutOverlay = (props) => {
  const { e, handleFav, handleRemove, handleCompare } = props;
  return (
    <>
      <div className="bg-white geodir-category-card">


        <div className="geodir-category-img">
          <Link
            to={`/details/${e.id}`}
            className="geodir-category-img-wrap fl-wrap"
          >
            <img
              className="w-full object-cover"
              style={{ height: '250px' }}
              src={e?.listing_images[0]?.image}
              alt=""
            />
          </Link>
          <div
            onClick={() => handleFav(e.id)}
            className={
              e?.is_favourite === 1
                ? "geodir-js-favorite_btn1"
                : "geodir-js-favorite_btn"
            }
          >
            <i style={{ fontSize: "20px" }} className="fal fa-heart" />
            <span>{e?.is_favourite === 1 ? "saved" : "unsaved"}</span>
          </div>
          <div className="absolute bottom-5 z-10 left-4">
            <h3 className="title-sin_map  font-bold text-xl">
              <Link to={`/details/${e.id}`} className="text-white">
                $ {e?.nightly} <span className="text-sm text-white">/ Night </span>
              </Link>
            </h3>
          </div>


          <div className="absolute bottom-5 item-user-image">
            <img src={e?.host?.image} alt="No Host Image!" />
          </div>

        </div>


        <div style={{ padding: "20px 15px", overflow: "hidden" }}>
          <div>
            <div className="listing-rating-count-wrap">
              <div
                className="listing-rating card-popup-rainingvis"
                data-starrating2={5}
              />
              <h3 className="title-sin_map item-title text-black text-left text-lg font-bold">
                <Link to={`/details/${e.id}`} style={{ color: "black" }}>
                  {e?.title}
                </Link>
              </h3>
            </div>

            <Link to={`/details/${e.id}`} className="item-sub-title">
              {e?.city}, {e?.state}, {e?.country}
            </Link>
          </div>
          <div
            className="geodir-category-location d-flex flex-wrap items-center gap-1"
          >
            <Link
              to={`/details/${e.id}`}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MdOutlineBedroomParent size={16} color="black" />{" "}
              <p className="truncate item-text m-0">{e?.no_of_bedroom} Bedrooms</p>
            </Link>
            <Link
              to={`/details/${e.id}`}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <FaShower size={16} color="black" />
              <p className="truncate item-text m-0">{e?.no_of_bathroom} Baths</p>
            </Link>
            <Link
              to={`/details/${e.id}`}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MdGroup size={16} color="black" />
              <p className="truncate item-text m-0">{e?.no_of_guest} Guests</p>
            </Link>

          </div>
          <div className="d-flex justify-between items-center mt-2">
            <p className="truncate item-text m-0">{e?.type_of_space}</p>

            {e.is_compare === 0 ? (
              <div
                onClick={() => handleRemove(e.id, e.is_compare)}
                className="btn btn-secondary"
              >
                Remove
              </div>
            ) : (
              <div
                onClick={() => handleCompare(e.id, e.is_compare)}
                className="btn btn-secondary "
              >
                Compare
              </div>
            )}
          </div>
        </div>
        {/* <h3
          className="title-sin_map text-black text-left"
          style={{ fontSize: "18px" }}
        >
          <Link to={`/details/${e.id}`} style={{ color: "black" }}>
            {e?.dwelling}
          </Link>
        </h3> */}
      </div>
    </>
  );
};

export default ListingOutOverlay;
