import React from 'react'
import { Button, Modal } from 'react-bootstrap'

const DeleteModal = (props) => {
    const {openDelete, setOpenDelete}=props
    const handleClose = () => setOpenDelete(false);
  return (
    <div style={{
        display: 'flex',
        justifyContent: 'center'
    }}>
        <Modal
            show={openDelete}
            onHide={handleClose}
            // backdrop="static"
            keyboard={false}

        >
            <Modal.Header >
                <Modal.Title>Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
        
            <h3> Are you sure you want to do this</h3>


            </Modal.Body>
            <Modal.Footer style={{display:"flex",justifyContent:"space-between"}}>

                <Button  onClick={handleClose}>Cancel</Button>
                <button className="logout_btn_expense" onClick={handleClose}>Delete</button>
            </Modal.Footer>
        </Modal>
    </div>
  )
}

export default DeleteModal