import React, { useEffect, useState } from "react";
import Layout from "../Components/layout/Layout";
import BASE_URL from "../Components/auth/Baseurl";
import axios from "axios";
import { GeneralAgreement, HostsAgreement } from "../Components/agreements";
import '../styles/Agreements.css'

const Permission = () => {
  const [blog, setBlog] = useState([]);
  const [activeTab, setActiveTab] = useState("guests");

  const blogData = async () => {
    const response = await axios.get(`${BASE_URL}/blog`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    setBlog(response.data.data);
  };

  useEffect(() => {
    blogData();
  }, []);

  console.log(blog, "dahs");

  return (
    <Layout>
      <div style={{ marginTop: "60px" }} className="content">
        <section id="sec1" data-scrollax-parent="true">
          <div className="container">
            <div className="section-title">
              <h2>Agreements</h2>

              <span className="section-separator" />
              <p style={{ fontSize: "15px", textAlign: "center" }}>A convenient place to find all of the agreements in place when you do business with us and/or use our site.</p>
            </div>
            {/*about-wrap */}
            <div className="container mx-auto p-4">
              {/* Tab Navigation */}
              <div className="flex border-b-2 mb-4">
                <button
                  className={`w-full px-6 py-3 text-lg font-medium ${activeTab === "guests" ? "btn-primary-fly-inn" : "bg-gray-100"} `}
                  onClick={() => setActiveTab("guests")}
                >
                  Guests Agreements
                </button>
                <button
                  className={`w-full px-6 py-3 text-lg font-medium ${activeTab === "hosts" ? "btn-primary-fly-inn" : "bg-gray-100"} `}
                  onClick={() => setActiveTab("hosts")}
                >
                  Hosts Agreements
                </button>
              </div>

              {/* Tab Content */}
              {activeTab === "guests" ? (
                <div className="about-wrap">
                  <GeneralAgreement blog={blog} />
                </div>
              ) : (
                <div className="about-wrap">
                  <GeneralAgreement blog={blog} />
                  <HostsAgreement />
                </div>
              )}
            </div>
            {/* about-wrap end  */}
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Permission;
