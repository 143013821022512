import * as Yup from 'yup';

export const ListingTypeSchema = Yup.object().shape({
    listingType: Yup.string()
        .oneOf(['Short-Term Rental', 'Long-Term Rental', 'Adventures'], 'Invalid listing type')
        .required('Listing type is required'),
});

// Yup validation schema
export const LocationSchema = Yup.object({
    address: Yup.string().required("Address is required"),
    apt_suite: Yup.string(),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    zipcode: Yup.string().required("ZIP / Postal Code is required"),
    area: Yup.string(),
    country: Yup.string().required("Country is required"),
    lat: Yup.number().required("Latitude is required").typeError("Invalid latitude"),
    lng: Yup.number().required("Longitude is required").typeError("Invalid longitude"),
});

export const MediaSchema = Yup.object({
    images: Yup.array()
        .min(1, 'At least one image is required') // Ensure there is at least one image
        .required('Images are required'),
});


export const InformationSchema = Yup.object({
    type_of_space: Yup.string().required("Please select a type of space"),
    dwelling: Yup.string().required("Please select a Lodging"),
    title: Yup.string().required("Title is required"),
    no_of_guest: Yup.number().required("Number of Guests is required").positive().integer(),
    no_of_bedrooms: Yup.number().required("Number of Bedrooms is required").positive().integer(),
    no_of_beds: Yup.number().required("Number of Beds is required").positive().integer(),
    no_of_bathroom: Yup.number().required("Number of Bathrooms is required").positive(),
    no_of_room: Yup.number().required("Number of Rooms is required").positive().integer(),
    size: Yup.number().required("Size is required").positive(),
    unit_of_measure: Yup.string().required("Unit of Measure is required")
});

export const BedroomSchema = Yup.object().shape({
    bedrooms: Yup.array()
        .of(
            Yup.object().shape({
                name: Yup.string().required("Required"),
                guest: Yup.number().required("Required"),
                bed: Yup.number().required("Required"),
                type: Yup.string().required("Required"),
            })
        )
        .min(1, "At least one bedroom is required"),
});


export const AirportFormSchema = Yup.array().of(
    Yup.object({
        airport_identifier: Yup.string().required("Identifier is required."),
        airport_name: Yup.string().required("Airport name is required."),
        airport_use: Yup.string().required("Airport use is required."),
        operation_hours: Yup.string().required("Operation hours are required."),
        lighting: Yup.string().required("Lighting is required."),
        ctaf_unicom: Yup.string().required("CTAF/UNICOM is required."),
        fuel: Yup.array().min(1, "Fuel type is required."),
        parking: Yup.string().required("Parking is required."),
        orientation: Yup.string().matches(
            /^\d{2}\/\d{2}$/,
            "Orientation must be in the format XX/XX"
        ),
        surface: Yup.array().min(1, "Surface type is required."),
        condition: Yup.string().required("Condition is required."),
        pattern: Yup.string().required("Pattern is required."),
        distance_from_runway: Yup.string().required("Distance from runway is required."),
    })
);


export const PricingFormSchema = Yup.object({
    instant_booking: Yup.boolean(),
    nightly: Yup.number().required('Nightly Price is required').positive('Must be a positive number'),
    apply_weekend_price: Yup.string().required('Weekend price selection is required'),
    weekend: Yup.number().required('Weekend price is required').positive('Must be a positive number'),
    weekly_seven_plus: Yup.number().required('Weekly price is required').positive('Must be a positive number'),
    weekly_thirty_plus: Yup.number().required('30+ nights price is required').positive('Must be a positive number'),
    additional_guest: Yup.string().required('Additional guests allowed is required'),
    no_of_guest: Yup.number().required('No. of additional guests is required').positive('Must be a positive number'),
    additional_guest_price: Yup.number().required('Price per additional guest is required').positive('Must be a positive number'),
    pet_allowed: Yup.string().required('Pet allowed selection is required'),
    no_of_pets: Yup.number().required('No. of pets is required').positive('Must be a positive number'),
    price_per_pet: Yup.number().required('Price per pet is required').positive('Must be a positive number'),
    cleaning_fee: Yup.number().required('Cleaning fee is required').positive('Must be a positive number'),
    cleaning_fee_daily: Yup.string().required('Cleaning fee daily selection is required'),
    city_fee: Yup.number().required('City fee is required').positive('Must be a positive number'),
    city_fee_freq: Yup.string().required('City fee daily selection is required'),
    tax: Yup.number().required("Tax is required"),
});

export const FeaturesFormSchema = Yup.object({
    blocks: Yup.array()
        .of(
            Yup.object({
                selectedFeature: Yup.string().required("Feature is required"),
                selectedCheckboxes: Yup.array().min(1, "At least one checkbox is required")
            })
        )
        .required("Blocks are required")
});


export const addListingValidationSchema = Yup.object().shape({
    is_draft: Yup.boolean(),
    host_id: Yup.string(),
    listing_type: Yup.string().required('Listing type is required.'),
    address: Yup.string().required('Address is required.'),
    unit_no: Yup.string(),
    city: Yup.string().required('City is required.'),
    state: Yup.string().required('State is required.'),
    zipcode: Yup.string().required('Zipcode is required.'),
    area: Yup.string(),
    country: Yup.string().required('Country is required.'),
    latitude: Yup.string(),
    longitude: Yup.string(),
    images: Yup.array()
        .of(Yup.mixed().required('Image is required.'))
        .min(1, 'At least one image is required.'),

    title: Yup.string().required('Title is required.'),
    type_of_space: Yup.string().required('Type of space is required.'),
    dwelling: Yup.string().required('Dwelling type is required.'),
    no_of_bedrooms: Yup.string().required('Number of bedrooms is required.'),
    no_of_guest: Yup.string().required('Number of guests is required.'),
    no_of_beds: Yup.string().required('Number of beds is required.'),
    no_of_bathroom: Yup.string().required('Number of bathrooms is required.'),
    no_of_room: Yup.string().required('Number of rooms is required.'),
    size: Yup.string().required('Size is required.'),
    unit_of_measure: Yup.string().required('Unit of measure is required.'),
    description: Yup.string().required('Description is required.'),

    bedrooms: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required('Bedroom name is required.'),
            guest: Yup.string().required('Number of guests in bedroom is required.'),
            no_of_bed: Yup.string().required('Number of beds in bedroom is required.'),
            bed_type: Yup.string().required('Bed type is required.')
        })
    ),

    airports: Yup.array().of(
        Yup.object().shape({
            airport_identifier: Yup.string().required('Identifier is required.'),
            airport_use: Yup.string().required('Airport Use is required.'),
            airport_name: Yup.string().required('Airport name is required.'),
            air_nav: Yup.string().required('Airport Navigation is required.'),
            ctaf_unicom: Yup.string().required('Airport CTAF/UNICOM is required.'),
            dimensions_feet: Yup.string().required('Airport Dimensions is required.'),
            dimensions_meter: Yup.string().required('Airport Dimensions is required.'),
            elevation_feet: Yup.number()
                .required('Airport Elevation is required.'),

            elevation_meter: Yup.number()
                .required('Airport Elevation is required.'),
            fuel: Yup.array().of(Yup.string()).required('Airport Fuel is required.').min(1, 'At least Airport Fuel is required.'),
            lighting: Yup.number().required('Airport Lightning is required.'),
            operation_hours: Yup.string().required('Airport Operation Hours are required.'),
            orientation: Yup.string().required('Airport Orientation is required.'),
            parking: Yup.string().required('Airport Parking is required.'),
            pattern: Yup.string().required('Airport Pattern is required.'),
            runway_condition: Yup.string().required('Airport Condition is required.'),
            surface: Yup.array().of(Yup.string().required('Airport Surface is required.')).min(1, 'At least surface is required.'),
            ground_transportation: Yup.string().required('Airport Ground Transportation is required.'),
            additional_info: Yup.string().required('Airport Ground Additional Information is required.'),
            distance_from_runway: Yup.string().required('Airport Ground Transportation is required.'),
        })
    ),

    instant_booking: Yup.boolean(),

    nightly: Yup.string().required('Nightly price is required.'),

    apply_weekend_price: Yup.string(),
    weekend_nightly_price: Yup.string().when('apply_weekend_price', {
        is: 1,
        then: Yup.string().required('Weekend price is required when "Apply Weekend Price" is selected.')
    }),

    additional_guest: Yup.string(),

    no_of_additonal_guest: Yup.string().when('additional_guest', (additional_guest, schema) => {
        return additional_guest === 1
            ? schema.required('Number of additional guests is required when "Additional Guest" is selected.')
            : schema;
    }),

    additional_additional_guest_price: Yup.string().when('additional_guest', (additional_guest, schema) => {
        return additional_guest === 1
            ? schema.required('Additional guest price is required when "Additional Guest" is selected.')
            : schema;
    }),

    pet_allowed: Yup.string(),

    no_of_pets: Yup.string().when('pet_allowed', (pet_allowed, schema) => {
        return pet_allowed === 1
            ? schema.required('Number of pets is required when pets are allowed.')
            : schema;
    }),

    price_per_pet: Yup.string().when('pet_allowed', (pet_allowed, schema) => {
        return pet_allowed === 1
            ? schema.required('Price per pet is required when pets are allowed.')
            : schema;
    }),

    cleaning_fee: Yup.string().required('Clean Fee is required.'),
    cleaning_freq: Yup.string().required('Clean Fee Period is required.'),
    city_fee: Yup.string().required('City Fee is required.'),
    city_fee_freq: Yup.string().required('City Fee Period is required.'),
    tax_percentage: Yup.string().required('Tax is required.'),

    custom_period_pricing: Yup.number(),
    custom_periods: Yup.array().of(
        Yup.object().shape({
            start_date: Yup.date()
                .nullable()
                .test('start_date-required', 'Start Date is required if any field is filled', function (value) {
                    const { end_date, nightly_price, weekend_price, price_add_guest, custom_period_pricing } = this.parent;
                    return !(end_date || nightly_price || weekend_price || price_add_guest || custom_period_pricing === 1) || !!value;
                }),

            end_date: Yup.date()
                .nullable()
                .test('end_date-required', 'End Date is required if any field is filled', function (value) {
                    const { start_date, nightly_price, weekend_price, price_add_guest, custom_period_pricing } = this.parent;
                    return !(start_date || nightly_price || weekend_price || price_add_guest || custom_period_pricing === 1) || !!value;
                }),

            nightly_price: Yup.string().test(
                'nightly_price-required',
                'Custom Price is required if any field is filled',
                function (value) {
                    const { start_date, end_date, weekend_price, price_add_guest, custom_period_pricing } = this.parent;
                    return !(start_date || end_date || weekend_price || price_add_guest || custom_period_pricing === 1) || !!value;
                }
            ),

            weekend_price: Yup.string().test(
                'weekend_price-required',
                'Custom Weekly Price is required if any field is filled',
                function (value) {
                    const { start_date, end_date, nightly_price, price_add_guest, custom_period_pricing } = this.parent;
                    return !(start_date || end_date || nightly_price || price_add_guest || custom_period_pricing === 1) || !!value;
                }
            ),

            price_add_guest: Yup.string().test(
                'price_add_guest-required',
                'Custom Price Additional is required if any field is filled',
                function (value) {
                    const { start_date, end_date, nightly_price, weekend_price, custom_period_pricing } = this.parent;
                    return !(start_date || end_date || nightly_price || weekend_price || custom_period_pricing === 1) || !!value;
                }
            ),
        })
    ),

    extra_services: Yup.number(),
    extra_service: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().test(
                'name-required',
                'Service name is required if any field is filled',
                function (value) {
                    const { price, type, quantity, extra_services } = this.parent;
                    return !(price || type || quantity, extra_services === 1) || !!value;
                }
            ),

            price: Yup.string().test(
                'price-required',
                'Service price is required if any field is filled',
                function (value) {
                    const { name, type, quantity, extra_services } = this.parent;
                    return !(name || type || quantity, extra_services === 1) || !!value;
                }
            ),

            type: Yup.string().test(
                'type-required',
                'Service type is required if any field is filled',
                function (value) {
                    const { name, price, quantity, extra_services } = this.parent;
                    return !(name || price || quantity, extra_services === 1) || !!value;
                }
            ),

            quantity: Yup.string().test(
                'quantity-required',
                'Quantity is required if any field is filled',
                function (value) {
                    const { name, price, type, extra_services } = this.parent;
                    return !(name || price || type || extra_services === 1) || !!value;
                }
            ),
        })
    ),



    // Check this for features
    features: Yup.array().of(
        Yup.object().shape({
            id: Yup.string(),
            sub_features: Yup.array()
                .of(Yup.string())
                .test(
                    'selectedCheckboxes-required',
                    'At least one checkbox must be selected if there are selected IDs',
                    function (value) {
                        const { selectedId } = this.parent;
                        return !(selectedId && selectedId.length > 0) || (value && value.length > 0);
                    }
                ),
        })
    ),



    cancellation_policy_short: Yup.string().required('Cancellation policy is required.'),

    cancellation_policy_long: Yup.string().test(
        'cancellation_policy_long-required',
        'Cancellation policy (long) is required for bookings longer than 30 days.',
        function (value) {
            const { max_day_booking } = this.parent;
            return !(max_day_booking > 30) || !!value;
        }
    ),

    min_day_booking: Yup.number().required('Minimum booking days are required.'),
    max_day_booking: Yup.number().required('Maximum booking days are required.'),
    check_in_after: Yup.string().required('Check-in time is required.'),
    check_in_before: Yup.string().required('Check-out time is required.'),
    smoking_allowed: Yup.number().required('Smoking Allowed is required.'),
    smoking_rules: Yup.string(),
    rules_pet_allowed: Yup.number().required('Pets Allowed is required.'),
    pet_rules: Yup.string(),
    party_allowed: Yup.number().required('Parties Allowed is required.'),
    party_rules: Yup.string(),
    children_allowed: Yup.number().required('Children Allowed is required.'),
    children_rules: Yup.string(),

    infant_ages: Yup.object().shape({
        min_age: Yup.number()
            .min(0, 'Minimum age cannot be negative')
            .required('Minimum age is required'),
        max_age: Yup.number()
            .min(Yup.ref('min_age'), 'Maximum age cannot be less than minimum age')
            .required('Maximum age is required'),
    }).test(
        'infant-ages-required',
        'Infant ages are required when children are allowed.',
        function (value) {
            const { children_allowed } = this.parent;
            if (children_allowed === 1) {
                return value && value.min_age !== undefined && value.max_age !== undefined;
            }
            return true;
        }
    ),

    children_ages: Yup.object().shape({
        min_age: Yup.number()
            .min(0, 'Minimum age cannot be negative')
            .required('Minimum age is required'),
        max_age: Yup.number()
            .min(Yup.ref('min_age'), 'Maximum age cannot be less than minimum age')
            .required('Maximum age is required'),
    }).test(
        'children-ages-required',
        'Children ages are required when children are allowed.',
        function (value) {
            const { children_allowed } = this.parent;
            if (children_allowed === 1) {
                return value && value.min_age !== undefined && value.max_age !== undefined;
            }
            return true;
        }
    ),

    rules: Yup.string().required('Rules is required.'),
    welcome_message: Yup.string().required('Welcome Message is required.'),

    blocked_dates: Yup.array().of(
        Yup.object().shape({
            start_date: Yup.string(),
            end_date: Yup.string(),
        })
    ),

});


