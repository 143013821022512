import React from "react";
import { MdQuestionMark } from "react-icons/md";
import Swal from "sweetalert2";
import { useFormContext } from '../../../../../providers';

export const ExtraService = () => {
    const { errors, setValue, watch } = useFormContext();

    const extra_service_prices = watch('extra_service_pricing');

    const handleInputChangeOne = (index, field, value) => {
        const updatedExtraServicePrices = [...extra_service_prices];
        updatedExtraServicePrices[index][field] = value;
        setValue('extra_service_pricing', updatedExtraServicePrices);
    };

    const handlePlusClickOne = (e) => {


        e.preventDefault();

        console.log(e)
        setValue('extra_service_pricing', [
            ...extra_service_prices,
            { name: "", price: "", type: "", quantity: "" },
        ]);
    };
    const handleMinusClickOne = (index) => {
        if (extra_service_prices.length > 1) {
            const newExtraServicePrices = [...extra_service_prices];
            newExtraServicePrices.splice(index, 1);
            setValue('extra_service_pricing', newExtraServicePrices);
        }
    };
    return (
        <>
            {extra_service_prices.map((service, index) => (
                <>
                    <div className="md:col-span-6 text-left">
                        <label>Name</label>
                        <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center">
                            <i className="fal fa-tag" />
                            <input
                                type="text"
                                placeholder="Name"
                                value={service.name}
                                name="name"
                                onChange={(e) =>
                                    handleInputChangeOne(index, "name", e.target.value)
                                }
                            />
                        </div>
                        {errors?.extra_service_pricing?.[index]?.name && (
                            <p style={{ color: "red" }}>Required</p>
                        )}
                    </div>

                    <div className="md:col-span-6 text-left">
                        <label>Price (N)</label>
                        <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center">
                            <i className="fal fa-dollar-sign" />
                            <input
                                type="number"
                                placeholder="Price"
                                value={service.price}
                                name="price"
                                onChange={(e) =>
                                    handleInputChangeOne(index, "price", e.target.value)
                                }
                            />
                        </div>
                        {errors?.extra_service_pricing?.[index]?.price && (
                            <p style={{ color: "red" }}>Required</p>
                        )}
                    </div>

                    <div className="md:col-span-6 text-left">
                        <label>Type</label>
                        <select
                            data-placeholder="Type"
                            className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full"
                            value={service.type}
                            name="type"
                            onChange={(e) =>
                                handleInputChangeOne(index, "type", e.target.value)
                            }
                        >
                            <option value="">
                                Type
                            </option>
                            <option value="Single Fee">Single Fee</option>
                            <option value="Per Night">Per Night</option>
                            <option value="Per Guest">Per Guest</option>
                            <option value="Per Night Per Guest">Per Night Per Guest</option>
                        </select>
                        {errors?.extra_service_pricing?.[index]?.type && (
                            <p style={{ color: "red" }}>Required</p>
                        )}
                    </div>

                    <div className="md:col-span-6 text-left">
                        <label className="inline-flex items-center">
                            Quantity Available
                            <MdQuestionMark
                                onClick={() =>
                                    Swal.fire({
                                        text: "How many units are available of the Extra Service you are currently entering? For example, if your extra service is a fully stocked refrigerator and you are only willing to stock it one time, enter quantity 1.",
                                        icon: "question",
                                    })
                                }
                                style={{
                                    color: "#8ec639",
                                    cursor: "pointer",
                                }}
                            />
                        </label>
                        <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center">
                            <i className="fal fa-hashtag"></i>
                            <input
                                type="number"
                                placeholder="Quantity"
                                name="quantity"
                                value={service.quantity}
                                onChange={(e) =>
                                    handleInputChangeOne(index, "quantity", e.target.value)
                                }
                            />
                        </div>
                        {errors?.extra_service_pricing?.[index]?.quantity && (
                            <p style={{ color: "red" }}>Required</p>
                        )}
                    </div>

                    <div className="md:col-span-12 flex justify-start items-start">
                        {extra_service_prices?.length > 1 && (
                            <button
                                className="border border-[#3b4249] font-medium bg-[#8ec639] rounded-md text-white px-8 py-3"
                                onClick={() => handleMinusClickOne(index)}
                            >
                                Remove Service
                            </button>
                        )}
                    </div>
                </>
            ))}

            <div className="md:col-span-12 text-left flex justify-end items-end">
                <button
                    onClick={handlePlusClickOne}
                    className="border border-[#3b4249] font-medium bg-[#8ec639] rounded-md text-white px-8 py-3"
                >
                    Add Service
                </button>
            </div>


        </>
    );
};
