/* eslint-disable */
import React, { useEffect, useState, useMemo, useContext } from "react";
import BASE_URL from "../Components/auth/Baseurl";
import axios from "axios";
import { Link } from "react-router-dom";
import Loading from "./loader/Loading"; // Import Loader component
import { toast } from "react-toastify";
import { MaterialReactTable } from "material-react-table";
import { FaPlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { ThemeContext } from "../context/ContextFile";
import Swal from "sweetalert2";
import { warningPopup } from "../helpers";

const List = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [pageCome, setPageCome] = useState(true);
  const path = useNavigate();
  const [activeButton, setActiveButton] = useState("All");
  // const [currentPage, setCurrentPage] = useState(1); // Current page state
  // const [cardsPerPage] = useState(3); // Number of cards per page

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/listing`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setData(response.data?.data);
      console.log(response.data?.data, "responselisting");

      setLoading(false); // Set loading state to false when data is fetched
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    if (pageCome) {
      Swal.fire({
        icon: "info",
        text: "Please scroll right and left to view more columns.",
        showConfirmButton: true,
      }).then(() => {
        setPageCome(false);
      });
    }
  }, []);

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${BASE_URL}/listing/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      // setLoading(true)
      toast.success("successfully deleted");
      console.log(response, ">>>>>>>>");
      // If deletion is successful, fetch the updated data
      fetchData();
    } catch (error) {
      // toast.error("Error deleting item");
      setLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 100,
      },
      {
        accessorKey: "listing_images",
        header: "Image",
        size: 100,
        Cell: ({ row }) => (
          <img
            src={row.original.host.image}
            alt="Host"
            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
          />
        ),
      },

      {
        accessorKey: "title",
        header: "Title",
        size: 100,
      },
      {
        accessorKey: "listing_type",
        header: "Listing Type",
        size: 150,
      },
      {
        accessorKey: "dwelling",
        header: "Lodging Type",
        size: 150,
      },
      {
        accessorKey: "type_of_space",
        header: "Type of Space",
        size: 150,
      },
      {
        accessorKey: "nightly",
        header: "Price",
        size: 100,
      },
      {
        accessorKey: "no_of_bedroom",
        header: "Bedrooms",
        size: 100,
      },
      {
        accessorKey: "no_of_bathroom",
        header: "Bathrooms",
        size: 100,
      },
      {
        accessorKey: "no_of_guest",
        header: "Guests",
        size: 100,
      },

      {
        accessorKey: "status",
        header: "Status",
        size: 150,
        Cell: ({ row }) => {
          const status = row.getValue("status");

          // Dynamically setting button background color based on status
          const getButtonStyle = () => {
            switch (status) {
              case "Approved":
                return { background: "#8ec639", color: "white" };
              case "Draft":
                return { background: "#54c4d9", color: "white" };
              case "Waiting for Approved":
                return { background: "yellow", color: "white" };
              case "Disabled":
                return { background: "red", color: "white" };
              default:
                return { background: "#54c4d9", color: "white" };
            }
          };

          return (
            <div style={{}}>
              <button
                style={{
                  ...getButtonStyle(),
                  padding: "7px 10px",
                  borderRadius: "5px",
                }}
              >
                {status}
              </button>
            </div>
          );
        },
      },

      {
        accessorKey: "action",
        header: "Actions",
        size: 200,
        Cell: ({ row }) => (
          <label class="dropdown_listing">
            <div class="dd-button">Actions</div>

            <input type="checkbox" class="dd-input" id="test" />

            <ul class="dd-menu">
              <li
                onClick={() => {
                  window.open(
                    `/detail-my-listing/${row.original.id}`,
                    "_blank"
                  );
                }}
              >
                View
              </li>
              <li>Edit</li>
              <li>Duplicate</li>
              <li>Update to featured</li>
              <li>Disable</li>
              <li>Delete</li>
            </ul>
          </label>
        ),
      },
    ],
    [path]
  );

  const [show, setShow] = useState(false);
  const { listingValue, setListingValue } = useContext(ThemeContext);
  const handleOpenModal = () => {
    const user = JSON.parse(localStorage.getItem("data"));
    console.log(user, "user");
    if (user?.complete === "100%") {
      setShow(true);
    } else {
      setTimeout(() => {
        warningPopup(`
          <div>
            <p style="text-align: center">Please complete your profile to 100%. You will not be able to host or book until it is complete.</p>
            <p style="text-align: center">
             Once we have verified your identity, you will receive a message letting you know you are all set.
            </p>
            <p style="text-align: center">
             To complete your profile properly, watch the Registration video on our <br/> <a  target="_blank" style="color: #af2322; text-decoration: underline;" href="https://youtube.com/@fly-inn">YouTube Channel</a>.
            </p>
          </div>
          `);
      }, 1000);
    }
  };

  const handleClose = () => {
    if (listingValue) {
      localStorage.setItem("listingValue", listingValue);
      setShow(false);
      path('/addlisting', { state: { listing_type: listingValue } });
      // path("/addlisting");
    }
  };

  console.log(data?.length, "length");
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Modal
          show={show}
          onHide={handleClose}
          // backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Listing Type</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-md-12">
              <div className="listsearch-input-item">
                <select
                  value={listingValue}
                  onChange={(e) => setListingValue(e.target.value)}
                  className="nice-select chosen-select no-search-select"
                >
                  <option disabled value={""}>
                    All categories
                  </option>
                  <option value={"Short-Term Rental"}>Short-Term Rental</option>
                  <option value="Long-Term Rental">Long-Term Rental</option>
                  <option value={"Adventures"}>Adventure </option>
                </select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="px-4">
          <div style={{ textAlign: "center" }} className="dashboard-title">
            <h3 style={{ color: "#3b4249" }}>My Listings</h3>
          </div>
          <div className="flex my-5">
            <button
              onClick={handleOpenModal}
              className="flex justify-center items-center bg-[#54c4d9] text-md font-medium cursor-pointer text-white py-3 px-8 rounded-md"
            >
              <FaPlus />
              New Listing
            </button>
          </div>
          <div className="flex flex-col space-y-4">
            <div className="flex gap-4 flex-wrap">
              <button
                className={`listing_btn ${activeButton === "All" ? "active" : ""
                  }`}
                onClick={() => setActiveButton("All")}
              >
                All (52)
              </button>
              <button
                className={`listing_btn ${activeButton === "Published" ? "active" : ""
                  }`}
                onClick={() => setActiveButton("Published")}
              >
                Published (52)
              </button>
              <button
                className={`listing_btn ${activeButton === "Drafts" ? "active" : ""
                  }`}
                onClick={() => setActiveButton("Drafts")}
              >
                Drafts (52)
              </button>
              <button
                className={`listing_btn ${activeButton === "Pending" ? "active" : ""
                  }`}
                onClick={() => setActiveButton("Pending")}
              >
                Pending (52)
              </button>
              <button
                className={`listing_btn ${activeButton === "Disabled" ? "active" : ""
                  }`}
                onClick={() => setActiveButton("Disabled")}
              >
                Disabled
              </button>
            </div>
            <div className="flex gap-4 flex-wrap">
              <button className="listing_btn">Short-Term Rentals (3)</button>
              <button className="listing_btn">Adventures (52)</button>
              <button className="listing_btn">Events (52)</button>
              <button className="listing_btn">Hotels (52)</button>
              <button className="listing_btn">Restaurants (23)</button>
              <button className="listing_btn">Shops (23)</button>
            </div>
          </div>
          <div className="overflow-x-auto max-w-full">
            <MaterialReactTable
              columns={columns}
              data={data}
              muiPaginationProps={{
                rowsPerPageOptions: [
                  { value: 10, label: "10" },
                  { value: 25, label: "25" },
                  { value: 50, label: "50" },
                  { value: 100, label: "100" },
                  { value: data?.length, label: "All" },
                ],
                showFirstButton: false,
                showLastButton: false,
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default List;
