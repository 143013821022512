import React from 'react'
import { CancellationPolicy } from './TermAndRulesComponents';


export const TermsAndRules = () => {
    return (
        <div className="grid md:grid-cols-12 bg-white p-4 rounded-lg gap-4">
            <h3 className="text-xl font-bold text-center md:col-span-12">
                Terms & Rules
            </h3>
            <CancellationPolicy />

        </div>
    )
}

