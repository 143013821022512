import React from "react";
import SidebarLayout from "../Components/sidebar/SidebarLayout";
import ReviewR from "../Components/ReviewR";

function Review() {
  return (
    <>
      <SidebarLayout>
        <ReviewR />
      </SidebarLayout>
    </>
  );
}

export default Review;
