import React from 'react'
import { Link } from 'react-router-dom'

const Dashboard = () => {
    return (
        <div style={{marginTop:"30px"}} className="col-md-12">
            <div className="list-single-facts fl-wrap">
                <div className="row">
                    <Link to={"/my_listing"}>

                        <div className="col-md-4">
                            {/* inline-facts */}
                            <div className="inline-facts-wrap gradient-bg " style={{ height: 143 }}>
                                <div className="inline-facts">
                                    <i className="fal fa-chart-bar" />
                                    <div className="milestone-counter">
                                        <div className="stats animaper">
                                            <div className="num" data-content={0} data-num={1054}>
                                                1054
                                            </div>
                                        </div>
                                    </div>
                                    <h6 style={{fontSize:"16px"}}>Listings</h6>
                                </div>
                                <div className="stat-wave">
                                    <svg viewBox="0 0 100 25">
                                        <path fill="#fff" d="M0 30 V12 Q30 17 55 2 T100 11 V30z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link to={"/booking"}>

                        <div className="col-md-4">
                            <div className="inline-facts-wrap gradient-bg " style={{ height: 143 }}>
                                <div className="inline-facts">
                                    <i className="fal fa-comments-alt" />
                                    <div className="milestone-counter">
                                        <div className="stats animaper">
                                            <div className="num" data-content={0} data-num={2557}>
                                                2557
                                            </div>
                                        </div>
                                    </div>
                                    <h6 style={{fontSize:"16px"}}>Reservations</h6>
                                </div>
                                <div className="stat-wave">
                                    <svg viewBox="0 0 100 25">
                                        <path fill="#fff" d="M0 30 V12 Q30 17 55 12 T100 11 V30z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link to={"/my_wallets"}>

                        <div className="col-md-4">
                            {/* inline-facts  */}
                            <div className="inline-facts-wrap gradient-bg " style={{ height: 143 }}>
                                <div className="inline-facts">
                                    <i className="fal fa-envelope-open-dollar" />
                                    <div className="milestone-counter">
                                        <div className="stats animaper">
                                            <div className="num" data-content={0} data-num={125}>
                                                125
                                            </div>
                                        </div>
                                    </div>
                                    <h6 style={{fontSize:"16px"}}>Earnings </h6>
                                </div>
                                <div className="stat-wave">
                                    <svg viewBox="0 0 100 25">
                                        <path fill="#fff" d="M0 30 V12 Q30 12 55 5 T100 11 V30z" />
                                    </svg>
                                </div>
                            </div>
                            {/* inline-facts end */}
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Dashboard