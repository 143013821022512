import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const DetailPayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const row = location.state?.row;
  console.log(row);

  return (
    <>
      <div style={{marginTop:"30px"}} className="col-md-12">
        {/*box-widget-item */}
        <div className="box-widget-item fl-wrap block_box">
          <div className="box-widget-item-header">
            <h3>{row?.user?.role} </h3>
          </div>
          <div className="box-widget">
            <div className="box-widget-author fl-wrap">
              <div className="box-widget-author-title">
                <div className="box-widget-author-title-img">
                  <img src={row?.user?.image} alt="" />
                </div>
                <div className="box-widget-author-title_content">
                  <Link to="#">{row?.user?.first_name}</Link>
                  <span>{row?.user?.last_name}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*box-widget-item end */}
        {/*box-widget-item */}
        <div className="box-widget-item fl-wrap block_box">
          <div className="box-widget-item-header">
            <h3>User Contacts</h3>
          </div>
          <div className="box-widget">
            <div className="box-widget-content bwc-nopad">
              <div className="list-author-widget-contacts list-item-widget-contacts bwc-padside">
                <ul className="no-list-style">
                  <li>
                    <span>
                      <i className="fal fa-browser" /> Amount :
                    </span>{" "}
                    <Link to="#">{row?.amount}</Link>
                  </li>
                  <li>
                    <span>
                      <i className="fal fa-envelope" /> Email :
                    </span>{" "}
                    <Link to="#">{row?.user?.email}</Link>
                  </li>
                  <li>
                    <span><i class="fal fa-user-plus" /> Id :</span> <Link to="#">{row?.id}</Link>
                  </li>
                  <li>
                    <span><i class="fal fa-cocktail" />Status :</span> <Link to="#">{row?.status}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/*box-widget-item end */}
        {/*box-widget-item */}

        {/*box-widget-item end */}
        <div style={{ display: "flex", justifyContent: "end" }} className="">
          <button
            className="logout_btn color2-bg"
            style={{
              display: "flex",
              //   justifyContent: "end",
              justifyContent: "flex-end",
              marginBottom: "20px",
              marginTop: "0px",
            }}
            onClick={() => navigate("/payout")}
          >
            Back
          </button>
        </div>
      </div>
    </>
  );
};

export default DetailPayout;
