import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DropdownProvider } from './context/SidebarContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
// import { MapProvider } from './providers';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId="994842147147-3t7grtobk5dh08h5k7osa5jfqvncbkf5.apps.googleusercontent.com">
    <React.StrictMode>
      <DropdownProvider>
        <DndProvider backend={HTML5Backend}>
          {/* <MapProvider> */}
          <App />
          {/* </MapProvider> */}
          <ToastContainer />
        </DndProvider>
      </DropdownProvider>
    </React.StrictMode>
  </GoogleOAuthProvider>
);

reportWebVitals();
