/* eslint-disable */
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { GoogleMap, InfoWindow, MarkerF, useLoadScript } from "@react-google-maps/api";
import SearchLocation from "../search/SearchLocation";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../context/ContextFile";

const libraries = ["places"]; // Add more libraries if needed, e.g., 'geometry', 'drawing'


const LocationMap = () => {
  const [filterItem, setFilterItem] = useState("");
  const [selectedMarker, setSelectedMarker] = useState(null);
  let { selectedLocation2, data } = useContext(ThemeContext);
  const [center, setCenter] = useState({
    lat: 39.5,
    lng: -100.0,
  });

  const [zoom, setZoom] = useState(3.2);
  const [zoom1, setZoom1] = useState();
  const [filteredData, setFilteredData] = useState(data);
  console.log(filteredData, "filteredData");

  const [visibleRegions, setVisibleRegions] = useState({
    alaska: false,
    canada: false,
    us: false,
    mexico: false,
  });
  console.log(filterItem, "filterItem");

  useEffect(() => {
    if (selectedLocation2.lat1 && selectedLocation2.lng1) {
      setCenter({ lat: parseFloat(selectedLocation2.lat1), lng: parseFloat(selectedLocation2.lng1) });
      setZoom(9); // Adjust zoom level as needed
    } else if (center) {
      setFilteredData(
        data
          ?.filter((item) => {
            console.log(item, "ineritem");
            if (visibleRegions.alaska && isWithinBounds(item, "alaska")) {
              return true;
            }
            if (visibleRegions.canada && isWithinBounds(item, "canada")) {
              return true;
            }
            if (visibleRegions.us && isWithinBounds(item, "us")) {
              return true;
            }
            if (visibleRegions.mexico && isWithinBounds(item, "mexico")) {
              return true;
            }
            return true; // Show other listings by default
          })
          .filter((item) => {
            console.log(item, "item")
            if (!filterItem) return true;
            const distanceOrder = ["0 miles", "0-1 miles", "1.1-3 miles", "3.1-7 miles", "7 miles"];
            const filterIndex = distanceOrder.indexOf(filterItem);
            const itemIndex = distanceOrder.indexOf(item.distance_from_runway);
            return itemIndex <= filterIndex;
          })
      );
    }
  }, [center, data, visibleRegions, filterItem, selectedLocation2]);


  const isWithinBounds = (item, region) => {

    const bounds = {
      us: {
        latMin: 24.396308,
        latMax: 49.384358,
        lngMin: -125.0,
        lngMax: -66.93457,
      },
      canada: { latMin: 42.0, latMax: 83.0, lngMin: -141.0, lngMax: -52.0 },
      mexico: {
        latMin: 14.532866,
        latMax: 32.718655,
        lngMin: -118.455717,
        lngMax: -86.710571,
      },
      alaska: {
        latMin: 54.0,
        latMax: 71.5388001,
        lngMin: -179.148909,
        lngMax: -129.97957,
      },
    };

    return (
      item.lat >= bounds[region].latMin &&
      item.lat <= bounds[region].latMax &&
      item.lng >= bounds[region].lngMin &&
      item.lng <= bounds[region].lngMax
    );
  };

  const getLabelColor = (distance) => {
    if (distance === "0 miles") {
      return "8ec639";
    } else if (distance === "0-1 miles") {
      return "ffff00";
    } else if (distance === "1.1-3 miles") {
      return "f17489";
    } else if (distance === "3.1-7 miles") {
      return "ff9900";
    } else if (distance === "7 miles") {
      return "52c4d7";
    }
  };

  const createSVGIcon = (color, price) => {
    const svgTemplate = `
 <svg width="20" height="20" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
  <circle cx="25" cy="25" r="10" fill="${color}" stroke="white" stroke-width="2" />
  <text x="25" y="28" alignment-baseline="middle" text-anchor="middle" font-size="8" fill="black">

  </text>
</svg>


    `;
    return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
      svgTemplate
    )}`;
  };

  const mapRef = useRef(null);
  const onLoad = useCallback((map) => {
    mapRef.current = map;

    const mapTypeControl = mapRef.current.getDiv().querySelector('.gm-style-mtc');
    if (mapTypeControl) {
      mapTypeControl.style.maxWidth = '100px'; // Adjust this value as needed
    }
    // Create custom zoom controls
    const zoomControlDiv = document.createElement('div');
    zoomControlDiv.style.display = 'flex';
    zoomControlDiv.style.marginTop = '70px';
    zoomControlDiv.style.marginLeft = '-179px';

    const buttonStyle = {
      fontSize: '18px',
      width: '40px',
      height: '40px',
      display: 'flex',
      borderRadius: "4px 4px 4px 4px",
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#24393b',
      color: 'white',
      border: 'none',
      marginRight: '5px',
      cursor: 'pointer'
    };

    const zoomInButton = document.createElement('button');
    zoomInButton.textContent = '+';

    Object.assign(zoomInButton.style, buttonStyle);

    const zoomOutButton = document.createElement('button');
    zoomOutButton.textContent = '-';
    Object.assign(zoomOutButton.style, buttonStyle);

    zoomControlDiv.appendChild(zoomInButton);
    zoomControlDiv.appendChild(zoomOutButton);

    // Append custom controls to the map
    map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(zoomControlDiv);

    // Add event listeners for zooming
    zoomInButton.addEventListener('click', () => {
      map.setZoom(map.getZoom() + 1);
    });

    zoomOutButton.addEventListener('click', () => {
      map.setZoom(map.getZoom() - 1);
    });
  }, []);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDXJS_VZMhnp0szh92aZGg8RHszz6RMQN8",
    libraries, // Include libraries here
  });

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="h=[93vh] relative w-full bg-none">
      <GoogleMap
        mapContainerStyle={{ height: "80vh", width: "100%" }}
        center={center}
        zoom={zoom}
        onLoad={onLoad}
        apiKey="AIzaSyDXJS_VZMhnp0szh92aZGg8RHszz6RMQN8"
        options={{
          zoomControl: false,
          mapTypeControl: true,
          mapTypeControlOptions: {
            style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: window.google.maps.ControlPosition.TOP_LEFT,
          },
        }}
        ref={mapRef}
        className="custom-map-type-control"
      >
        {filteredData?.map((marker, index) => (
          <MarkerF
            key={index}
            position={{ lat: Number(marker.lat), lng: Number(marker.lng) }}
            onClick={() => setSelectedMarker(marker)}
            icon={{
              url: createSVGIcon(
                `#${getLabelColor(
                  marker.distance_from_runway === ""
                    ? 0
                    : marker.distance_from_runway
                )}`,
                marker.additional_guest_price
              ),
              scaledSize: new window.google.maps.Size(80, 50),
            }}
          />
        ))}
        {selectedMarker && (
          <InfoWindow
            position={{
              lat: Number(selectedMarker.lat),
              lng: Number(selectedMarker.lng),
            }}
            onCloseClick={() => setSelectedMarker(null)}
          >
            <div className="map-box">
              <div className="map-popup-wrap">
                <div className="map-popup">
                  <Link
                    to={`/details/${selectedMarker?.id}`}
                    className="listing-img-content fl-wrap"
                  >
                    <div className="infobox-status open">
                      {selectedMarker?.description}
                    </div>
                    {selectedMarker?.listing_images?.[0] && (
                      <img
                        src={selectedMarker?.listing_images[0].image}
                        alt=""
                      />
                    )}
                  </Link>
                  <div className="listing-content">
                    <div className="listing-content-item fl-wrap">
                      <div className="map-popup-location-category gym-cat" />
                      <div className="listing-title fl-wrap">
                        <h4>
                          <Link to={`/details/${selectedMarker?.id}`}>
                            {selectedMarker?.title}
                          </Link>
                        </h4>
                        <div className="map-popup-location-info">
                          <i className="fas fa-map-marker-alt" />
                          {selectedMarker?.address}
                        </div>
                      </div>
                      <div className="map-popup-footer">
                        <Link
                          to={`/details/${selectedMarker?.id}`}
                          className="main-link"
                        >
                          Details <i className="fal fa-long-arrow-right" />
                        </Link>
                        <Link to="#" className="infowindow_wishlist-btn">
                          <i className="fal fa-heart" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
      <SearchLocation filterItem={filterItem} setFilterItem={setFilterItem} />
    </div>
  );
};

export default LocationMap;
