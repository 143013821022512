
const prohibitedPatterns = [
    // Phone Numbers
    { pattern: /\b\d{10}\b/, description: "Phone Number (10 digits)" },
    { pattern: /\b\d{3}[-.\s]?\d{3}[-.\s]?\d{4}\b/, description: "Phone Number (123-456-7890 format)" },
    { pattern: /\b\+?[0-9]{1,3}[-.\s]?[0-9]{1,4}[-.\s]?[0-9]{1,4}[-.\s]?[0-9]{1,9}\b/, description: "International Phone Number" },

    // Email Addresses
    { pattern: /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/, description: "Email Address" },
    { pattern: /\b[A-Za-z0-9._%+-]+\s?(at|AT|@)\s?[A-Za-z0-9.-]+\s?(dot|DOT|\.)\s?[A-Z|a-z]{2,}\b/, description: "Obfuscated Email Address" },

    // Generic Address Formats
    { pattern: /\b\d+\s[A-Za-z]+\s(?:Street|St|Avenue|Ave|Boulevard|Blvd|Lane|Ln|Drive|Dr|Court|Ct|Road|Rd|Highway|Hwy|Way|Place|Pl|Square|Sq|Trail|Trl|Parkway|Pkwy|Circle|Cir)\b/i, description: "Street Address" },
    { pattern: /\b(?:P\.?O\.?|Post\sOffice)\s(?:Box|Bin)\s\d+\b/i, description: "P.O. Box Address" },

    // ZIP/Postal Codes
    { pattern: /\b\d{5}(-\d{4})?\b/, description: "US ZIP Code" },
    { pattern: /\b[A-Za-z]\d[A-Za-z]\s?\d[A-Za-z]\d\b/, description: "Canadian Postal Code" },

    // Landmark References
    { pattern: /\b(?:next\s?to|near|beside|close\s?to)\s[A-Za-z\s]+(?:restaurant|landmark|building|mall|hotel|park|station|school)\b/i, description: "Landmark Reference" },
    { pattern: /\b(?:opposite|across\sfrom|behind|in\sfront\sof|adjacent\sto)\s[A-Za-z\s]+(?:restaurant|landmark|building|mall|hotel|park|station|school)\b/i, description: "Landmark Direction" },

    // Apartment or Suite Numbers
    { pattern: /\b(?:Apartment|Apt|Suite|Ste|Unit|#)\s?\d+\b/i, description: "Apartment/Suite Number" },

    // Coordinates
    { pattern: /\b-?\d{1,3}\.\d+,\s?-?\d{1,3}\.\d+\b/, description: "Latitude and Longitude" },

    // General Obfuscation Attempts
    { pattern: /\d+\s(?:cats|dogs|sheep|houses)\s(?:on|near)\s[A-Za-z\s]+/, description: "Obfuscated Address (e.g., '801 cats on Main Street')" },
    { pattern: /\b(?:dot|dash|underscore|slash|backslash|comma|at)\b/i, description: "Obfuscation Indicators" },
    { pattern: /\b\d{1,4}\s?(?:[A-Za-z]+\s?)+\b/i, description: "Address-like Phrase" },


    // NSFW Words
    {
        pattern: /\b(hell|damn|ass|arse|crap|shit|bitch|f\*?ck|fu?ck(?:er|ing)?|d\*?ck|dick|p\*?ssy|pussy|slut|whore|bastard|c\*?nt|cunt|prick|twat|wanker|bollocks|bugger|bloody|ho|hoe|n\*?gga|nigga|nigger|faggot|dyke|tranny|retard|idiot|jackass|moron|jerk|loser|creep|pervert|slapper|pimp|scumbag|porn|xxx|nude|hooker|stripper|semen|cum|boobs|tits|penis|vagina|clit|anal|oral|blowjob|handjob|sex|masturbate|fetish|bdsm|bondage|lust|horny|rape|molest|abuse|necrophilia|bestiality|sadism|masochism|devil|satanic|witchcraft|occult|curse|ritual|voodoo)\b/gi,
        description: 'NSFW Word'
    },

    // Providers
    {
        pattern: /\b(?:gmail|hotmail|yahoo|outlook|live|aol|icloud|yandex|msn|comcast|mail|proton|zoho)\b/i,
        description: 'Provider Word'
    },

    //Domain
    {
        pattern: /\b(?:com|net|org|edu|gov|co|io|info|biz|tv|me|us)\b/i,
        description: 'Domain Word'
    }
];

export const validateInput = (text) => {
    for (let { pattern, description } of prohibitedPatterns) {
        const match = text.match(pattern);
        if (match) {
            return { found: true, content: match[0], description };
        }
    }
    return { found: false };
};