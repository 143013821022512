import React from "react";

import { getIconClass, warningPopup } from "../../../../helpers";
import { useFormContext } from "../../../../providers";

export const BedroomForm = () => {
    const { errors, getValues, setValue, watch } = useFormContext();

    const bedrooms = watch('bedrooms')

    const handlePlusClick = () => {
        const equalBedrooms = bedrooms.reduce(
            (sum, bedroom) => sum + Number(bedroom.bed),
            0
        );
        const maxBed = Number(getValues('no_of_bedrooms')) || 0;
        if (equalBedrooms >= maxBed) {
            warningPopup("Hold short - the number of bedrooms you stated above is different from the number of bedrooms you are entering here. If this is OK with you, please continue.");
        }
        setValue("bedrooms", [
            ...bedrooms,
            { name: "", guest: "", no_of_bed: "", bed_type: "" },
        ]);
    };

    const handleMinusClick = (index) => {
        if (bedrooms.length > 1) {
            const newBedrooms = [...bedrooms];
            newBedrooms.splice(index, 1);
            setValue("bedrooms", newBedrooms);
        }
    };

    const handleInputChange = (value, index, field) => {
        const newBedrooms = [...bedrooms];
        newBedrooms[index][field] = value;
        setValue("bedrooms", newBedrooms);
    };


    return (
        <>
            <div className="grid md:grid-cols-12 bg-white p-4 rounded-lg gap-4">
                <h3 className="text-xl font-bold text-center md:col-span-12">Bedrooms</h3>
                {bedrooms.map((bedroom, index) => (
                    <div key={index} className="md:col-span-12 text-left grid md:grid-cols-2 gap-6">
                        {/* Bedroom Name */}
                        <div>
                            <label>Bedroom Name</label>
                            <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full">
                                <i className={getIconClass("Bedroom Name")} />
                                <input
                                    type="text"
                                    placeholder="Ex. Master Bedroom, Bedroom 3"
                                    className="w-full"
                                    defaultValue={bedroom.name}
                                    onChange={(e) => handleInputChange(e.target.value, index, "name")}
                                />
                            </div>
                            {errors?.bedrooms?.[index]?.name && (
                                <p style={{ color: "red" }}>{errors.bedrooms[index].name.message}</p>
                            )}
                        </div>

                        {/* Number of Guests */}
                        <div>
                            <label>Number of Guests</label>
                            <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full">
                                <i className={getIconClass("Number of Guests")} />
                                <input
                                    type="number"
                                    placeholder="Enter the no. of guests in this room."
                                    className="w-full"
                                    defaultValue={bedroom.guest}
                                    onChange={(e) => handleInputChange(e.target.value, index, "guest")}
                                />
                            </div>
                            {errors?.bedrooms?.[index]?.guest && <p style={{ color: "red" }}>{errors?.bedrooms?.[index]?.guest.message}</p>}
                        </div>

                        {/* Number of Beds */}
                        <div>
                            <label>Number of Beds</label>
                            <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full">
                                <i className={getIconClass("Number of Beds")} />
                                <input
                                    type="number"
                                    placeholder="Enter the no. of beds in this room"
                                    className="w-full"
                                    defaultValue={bedroom.no_of_bed}
                                    onChange={(e) => handleInputChange(e.target.value, index, "no_of_bed")}
                                />
                            </div>
                            {errors?.bedrooms?.[index]?.no_of_bed && <p style={{ color: "red" }}>{errors?.bedrooms?.[index]?.no_of_bed.message}</p>}
                        </div>

                        {/* Bed Type */}
                        <div>
                            <label>Bed Type</label>
                            <div className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full">
                                <i className={getIconClass("Bed Type")} />
                                <input
                                    type="text"
                                    placeholder="Ex. King, Queen, Trundle, Bunk Beds"
                                    className="w-full"
                                    defaultValue={bedroom.bed_type}
                                    onChange={(e) => handleInputChange(e.target.value, index, "bed_type")}
                                />
                            </div>
                            {errors?.bedrooms?.[index]?.bed_type && <p style={{ color: "red" }}>{errors?.bedrooms?.[index]?.bed_type.message}</p>}
                        </div>

                        {/* Remove Bedroom Button */}
                        <div className="md:col-span-2 flex justify-start items-start">
                            {bedrooms.length > 1 && (
                                <button
                                    type="button"
                                    className="border border-[#3b4249] font-medium bg-[#8ec639] rounded-md text-white px-8 py-3"
                                    onClick={() => handleMinusClick(index)}
                                >
                                    Remove Bedroom
                                </button>
                            )}
                        </div>
                    </div>

                ))}

                <div className="md:col-span-12 text-left flex justify-end items-end">
                    <button
                        type="button"
                        onClick={handlePlusClick}
                        className="border border-[#3b4249] font-medium bg-[#8ec639] rounded-md text-white px-8 py-3"
                    >
                        Add bedrooms
                    </button>
                </div>


            </div>
        </>
    );
};
