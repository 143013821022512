import React from "react";
import MyWallet from "../Components/MyWallet";
import SidebarLayout from "../Components/sidebar/SidebarLayout";

const Wallet = () => {
  return (
    <>
      <SidebarLayout>
        <MyWallet />
      </SidebarLayout>
    </>
  );
};

export default Wallet;
