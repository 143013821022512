/* eslint-disable */
import axios from "axios";
import { Field, Form, Formik } from "formik";
import React from "react";
import { GrDesktop } from "react-icons/gr";
import { LuPencil } from "react-icons/lu";

import * as Yup from "yup";
import BASE_URL from "../auth/Baseurl";
import '../../styles/ContactDetails.css';
import '../../styles/ContactUs.css';


const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email address").required("Required"),
  department: Yup.string().optional(),
  subject: Yup.string().required("Required"),
  msg: Yup.string().required("Required"),
});


const ContactDetails = () => {
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const token = localStorage.getItem("token");

    const formData = new FormData();
    for (const key in values) {
      formData.append(key, values[key]);
    }

    try {
      const response = await axios.post(`${BASE_URL}/contact`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("Success:", response.data);
      setSubmitting(false);
      resetForm();
    } catch (error) {
      console.error("Error:", error);
      setSubmitting(false);
    }
  };

  return (
    <div className="container mx-auto md:px-20 px-4 py-10">
      <div className="grid md:grid-cols-12 grid-cols-1 gap-20">
        <div className="md:col-span-8 col-span-1">
          <div className="ab_text-title fl-wrap">
            <h3
              style={{
                fontFamily: "Quicksand",
                fontSize: "30px",
                fontWeight: "700",
              }}
            >
              Contact us
            </h3>
            <span className="section-separator fl-sec-sep" />
          </div>
          <p className="m-0">
            <strong>We can’t wait to hear from you!</strong> You SQUAWK, we
            WILCO 😃
          </p>
          <div id="contact-form">
            <div id="message" style={{ display: "none" }} />
            <Formik
              initialValues={{
                name: "",
                email: "",
                department: "",
                subject: "",
                msg: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, errors, touched }) => (
                <Form className="custom-form">
                  <fieldset>
                    <p className="m-0">
                      <i
                        style={{
                          color: "#3B4249",
                          marginRight: "10px",

                          paddingBottom: "5px",
                        }}
                        className="fal fa-user"
                      />
                      {touched.subject && errors.subject
                        ? "Your Name *"
                        : "Your Name *"}
                    </p>
                    <Field
                      type="text"
                      name="name"
                      className="email_place"
                      placeholder={
                        touched.subject && errors.subject ? "Required." : null
                      }
                    />

                    <div className="clearfix" />

                    <p className="m-0">
                      <i
                        style={{
                          color: "#3B4249",
                          marginRight: "10px",

                          paddingBottom: "5px",
                        }}
                        className="fal fa-envelope"
                      />
                      {touched.subject && errors.subject
                        ? "Email Address *"
                        : "Email Address *"}
                    </p>
                    <Field
                      type="text"
                      name="email"
                      className="email_place"
                      placeholder={
                        touched.subject && errors.subject ? "Required." : null
                      }
                    // className={touched.email && errors.email ? "error" : ""}
                    />

                    <p
                      style={{
                        textAlign: "left",
                        padding: "5px",
                        display: "flex",
                        justifyContent: " flex-start",
                        gap: "10px",
                        margin: '0px',
                        paddingBottom: "5px",
                      }}
                    >
                      <GrDesktop style={{ marginTop: "5px" }} />

                      {touched.department && errors.department
                        ? "Department"
                        : "Department"}

                      {/* <i
                                style={{ color: "#3B4249" }}
                                className="fal fa-times-circle"
                              /> */}
                    </p>
                    <div
                      className="listsearch-input-item"
                      style={{ backgroundColor: "white" }}
                    >
                      <Field
                        as="select"
                        name="department"
                        placeholder={
                          'choose one, if applicable'
                        }
                        className={
                          touched.department && errors.department
                            ? "nice-select chosen-select no-search-select error color-inner"
                            : "nice-select chosen-select no-search-select color-inner"
                        }
                      >
                        <option value=""> choose one, if applicable </option>
                        <option value="Sales">Sales</option>
                        <option value="Careers">Careers</option>
                        <option value="Disputes">Disputes</option>
                        <option value="Help">Help</option>
                      </Field>
                    </div>
                    <label
                      style={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        padding: "5px",
                        paddingBottom: "5px",
                        display: "flex",
                        justifyContent: "left",
                        textAlign: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <LuPencil />

                      {touched.subject && errors.subject
                        ? "Subject"
                        : "Subject"}
                    </label>
                    <Field
                      type="text"
                      name="subject"
                      className="email_place"
                      placeholder={
                        touched.subject && errors.subject ? "Required." : null
                      }
                    />
                    <label
                      style={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        padding: "5px",

                        paddingBottom: "5px",
                      }}
                    >
                      {touched.msg && errors.msg
                        ? "Your Message *"
                        : "Your Message *"}
                    </label>
                    <Field
                      as="textarea"
                      name="msg"
                      cols={40}
                      rows={3}
                      placeholder={
                        touched.msg && errors.msg ? "Required." : null
                      }
                      className="color-inner email_place"
                    />
                  </fieldset>

                  <button
                    style={{ padding: "15px 44px 15px 16px" }}
                    className="btn float-btn color2-bg w-full"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Send Message
                    <i
                      style={{ color: "#3B4249" }}
                      className="fal fa-paper-plane"
                    />
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="md:col-span-4 col-span-1 mt-9">
          <div className="ab_text-title fl-wrap">
            <h3 style={{ fontFamily: "Quicksand" }} className="heading-title">Mailing Address</h3>

            <p className="subheading-title">P.O. Box 270439, Fruitland, UT 84027</p>
            <span className="section-separator fl-sec-sep" />
            <br />
            <br />
          </div>
          {/*box-widget-item */}
          <div className="ab_text-title fl-wrap">
            <h3 style={{ fontFamily: "Quicksand" }} className="heading-title">Phones and Email</h3>
          </div>
          <div className="box-widget-item fl-wrap">
            <div className="box-widget">
              <div className="list-author-widget-contacts">
                <ul className="no-list-style subheading-title" style={{ fontFamily: "Quicksand" }}>
                  <li className="flex items-center">
                    {/* <i className="fal fa-phone text-gray-700 mr-2" /> */}
                    <span>Toll-Free :</span> 833-I-Fly-Inn (833-435-9466)
                  </li>
                  <li className="flex items-center">
                    {/* <i className="fal fa-phone text-gray-700 mr-2" /> */}
                    <span>Phone :</span> 321-I-Fly-Inn (321-435-9466)
                  </li>
                  <li className="flex items-center">
                    {/* <i className="fal fa-envelope text-gray-700 mr-2" /> */}
                    <span>Email :</span> PIC@Fly-Inn.com
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/*box-widget-item end */}
          {/*box-widget-item */}

          {/*box-widget-item end */}
        </div>
      </div>
      {/* about-wrap end  */}
    </div>
  );
};

export default ContactDetails;
