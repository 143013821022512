import React from "react";
import { ColorRing } from "react-loader-spinner";

const BtnLoader = () => {
  return (
    <>
      <ColorRing
        visible={true}
        height="20"
        width="20"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
      />
    </>
  );
};

export default BtnLoader;

export const BtnLoader1 = () => {
  return (
    <>
      <ColorRing
        visible={true}
        display="flex"
        justifyContent="center"
        alignItems="center"
      
        height="200px"
        width="100px"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        colors={["#3D528B", "#3D528B", "#3D528B", "#3D528B", "#3D528B"]}
      />
    </>
  );
};