/* eslint-disable no-script-url */
import React, { useEffect, useState } from "react";
import Layout from "../Components/layout/Layout";
import BASE_URL from "../Components/auth/Baseurl";
import axios from "axios";
import { Link } from "react-router-dom";

const Squawks = () => {
  const [blog, setBlog] = useState([]);
  const [filterBlog, setFilterBlog] = useState([]);

  const blogData = async () => {
    const response = await axios.get(`${BASE_URL}/blog`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    setBlog(response.data.data);
    setFilterBlog(response.data.data);
  };

  useEffect(() => {
    blogData();
  }, []);

  const [activeTab, setActiveTab] = useState("All");

  const handleTabClick = (tab) => {
    // product company stays policy community
    setActiveTab(tab);
    const filter = blog?.filter(
      (fil) => fil.news_by_topic === tab.toLowerCase()
    );
    if (tab === "All") {
      setFilterBlog(blog);
    } else {
      setFilterBlog(filter);
    }
  };

  // const [swiper, setSwiper] = useState(null);

  return (
    <Layout>
      <dvi className="bg-white">
        <div className="container mx-auto md:px-12 px-4">
          <article
            id="post-531"
            className="post-531 page type-page status-publish hentry"
          >
            <div className="grid md:grid-cols-2 gap-10 grid-cols-1 justify-center items-center my-5">
              <div className="elementor-widget-container">
                <p>July 18, 2024</p>
                <div className="ab_text-title fl-wrap">
                  <h1 className="text-3xl font-bold">
                    Paris 2024 countdown: Where to book last-minute
                  </h1>
                  <button
                    style={{ padding: "15px 44px 15px 16px" }}
                    className="btn float-btn color2-bg"
                    type="submit"
                  >
                    Read more
                    <i style={{ color: "#3B4249" }} className="fal fa-users" />
                  </button>
                </div>
              </div>
              <div className="elementor-widget-container">
                <img
                  style={{ borderRadius: "20px" }}
                  src="css/squawks.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="entry-content clearfix">
              <div
                data-elementor-type="wp-post"
                data-elementor-id={531}
                className="elementor elementor-531"
              >
                <hr />
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ed96de7"
                    data-id="ed96de7"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="elementor-element elementor-element-35ead37 elementor-widget elementor-widget-section_title"
                        data-id="35ead37"
                        data-element_type="widget"
                        data-widget_type="section_title.default"
                      >
                        <div
                          style={{ paddingTop: "50px" }}
                          className="elementor-widget-container"
                        >
                          <div className=" section-title-dk-blue fl-wrap">
                            <h2
                              style={{
                                textAlign: "start",
                                marginBottom: "30px",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "30px",
                                  fontWeight: "700",
                                }}
                              >
                                Latest news
                              </span>
                            </h2>{" "}
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-19d20d3 elementor-widget elementor-widget-members_grid"
                        data-id="19d20d3"
                        data-element_type="widget"
                        data-widget_type="members_grid.default"
                      >
                        <div className="elementor-widget-container">
                          <div
                            className="row"
                            style={{ display: "flex", flexWrap: "wrap" }}
                          >
                            {/* team-item */}
                            {blog?.map((blo, index) => {
                              const parser = new DOMParser();
                              const doc = parser.parseFromString(
                                blo?.description,
                                "text/html"
                              );
                              const imgSrc = doc.querySelector("img")?.src;
                              return (
                                <div key={index} className="col-md-3">
                                  <Link
                                    to={`/squawks/${blo?.id}`}
                                    state={{
                                      imgSrc: imgSrc,
                                      description: blo?.description,
                                      title: blo?.heading,
                                      subTitle: blo?.sub_heading,
                                      date: blo?.date,
                                    }}
                                  >
                                    <div className="team-photo">
                                      <img
                                        decoding="async"
                                        style={{
                                          width: "100%",
                                          height: "30vh",
                                          objectFit: "cover",
                                        }}
                                        className="respimg wp-post-image lazy loaded"
                                        alt=""
                                        src={
                                          imgSrc ||
                                          "https://townhub.cththemes.org/wp-content/uploads/2017/07/2.jpg"
                                        }
                                      />{" "}
                                    </div>
                                    <div className="team-info fl-wrap">
                                      <h3 className="section-title">
                                        {blo?.heading}
                                      </h3>{" "}
                                      <p>{blo?.date}</p>
                                    </div>
                                  </Link>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h2 className="text-center">
                  <span className="text-2xl font-bold">
                    Follow Fly-inn for news and travel inspiration
                  </span>
                </h2>{" "}
                <div>
                  <ul className="custom-nav-tabs md:py-10 flex-wrap gap-4">
                    {[
                      "All",
                      "Company",
                      "Stays",
                      "Product",
                      "Policy",
                      "Community",
                    ].map((tab) => (
                      <li className="custom-nav-item" key={tab}>
                        <Link
                          className={`custom-nav-link ${
                            activeTab === tab ? "active" : ""
                          }`}
                          to="javascript:0"
                          onClick={() => handleTabClick(tab)}
                        >
                          {tab}
                        </Link>
                      </li>
                    ))}
                  </ul>
                  <div className="custom-tab-content">
                    <div className="custom-tab-pane active">
                      {filterBlog?.map((blo, index) => {
                        const parser = new DOMParser();
                        const doc = parser.parseFromString(
                          blo?.description,
                          "text/html"
                        );
                        const imgSrc = doc.querySelector("img")?.src;
                        return (
                          <>
                            <div
                              id="member-988"
                              className="cthiso-item team-box post-988 member type-member status-publish has-post-thumbnail hentry"
                            >
                              <Link
                                to={`/squawks/${blo?.id}`}
                                state={{
                                  imgSrc: imgSrc,
                                  description: blo?.description,
                                  title: blo?.heading,
                                  subTitle: blo?.sub_heading,
                                  date: blo?.date,
                                }}
                              >
                                <div className="team-photo">
                                  <img
                                    decoding="async"
                                    style={{
                                      width: "100%",
                                      height: "40vh",
                                      objectFit: "cover",
                                    }}
                                    className="respimg wp-post-image lazy loaded"
                                    alt=""
                                    src={
                                      imgSrc
                                        ? imgSrc
                                        : "https://townhub.cththemes.org/wp-content/uploads/2017/07/2.jpg"
                                    }
                                  />{" "}
                                </div>
                                <div className="team-info fl-wrap">
                                  <h3 className="section-title">
                                    {blo?.heading}
                                  </h3>{" "}
                                  <p>{blo?.date}</p>
                                </div>
                              </Link>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="grid md:grid-cols-2 gap-10 grid-cols-1 justify-center items-center my-5">
                  <div className="elementor-widget-container">
                    <img
                      style={{ borderRadius: "20px" }}
                      src="/css/squawks2.webp"
                      alt=""
                    />
                  </div>
                  <div className="ab_text-title fl-wrap">
                    <h1
                      style={{
                        fontSize: "30px",
                      }}
                    >
                      What makes Fly-inn, Fly-inn
                    </h1>
                    <p
                      style={{
                        fontSize: "22px",
                        marginBottom: "12px",
                      }}
                    >
                      A letter from our founders
                    </p>
                    <button
                      style={{ padding: "15px 44px 15px 16px" }}
                      className="btn float-btn color2-bg"
                      type="submit"
                    >
                      Read more
                      <i
                        style={{ color: "#3B4249" }}
                        className="fal fa-users"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </dvi>
    </Layout>
  );
};

export default Squawks;
// https://jskim1991.medium.com/react-swiper-with-pagination-sample-4177f411c70d
