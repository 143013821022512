import Swal from "sweetalert2";

export function successPopup(title, message) {
    Swal.fire({
        position: "center",
        html: `
        <div style="display: flex; flex-direction: column; align-items: center;">
            <svg
                  viewBox="0 0 1024 1024"
                  height="4em"
                  width="4em"
                  style="margin-bottom: 10px;"
              >
                  <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                  <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
              </svg>
            <p style="margin: 0; font-size: 1.2em;">${message}</p>
        </div>
        `,
        title,
        showConfirmButton: true,  // Show confirm button
    }).then(() => {
        console.log('User pressed OK');
    });
}

export function successPopupWithConfirm(title, message) {
    Swal.fire({
        position: "center",
        html: `
        <div style="display: flex; flex-direction: column; align-items: center;">
            <svg
                  viewBox="0 0 1024 1024"
                  height="4em"
                  width="4em"
                  style="margin-bottom: 10px;"
              >
                  <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                  <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
              </svg>
            <p style="margin: 0; font-size: 1.2em;">${message}</p>
        </div>
        `,
        // title,
        showConfirmButton: true,  // Show confirm button
    }).then(() => {
        console.log('User pressed OK');
    });
}

export function warningPopup(message) {
    Swal.fire({
        position: "center",
        icon: "warning",
        html: `${message}`,
        showConfirmButton: true,  // Show confirm button
    }).then(() => {
        console.log('User pressed OK');
    });
}

export function infoPopup(title) {
    Swal.fire({
        position: "center",
        icon: "info",
        html: `
        <div style="display: flex; flex-direction: column; align-items: center;">
        <svg
                  viewBox="0 0 1024 1024"
                  height="4em"
                  width="4em"
                  style="margin-bottom: 10px;"
              >
                  <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                  <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
              </svg>
        </div>
    `,
        title: title,
        showConfirmButton: true,  // Show confirm button
    }).then(() => {
        console.log('User pressed OK');
    });
}

export function infoPopupMessage(message) {
    Swal.fire({
        position: "center",
        icon: "info",
        html: message,
        showConfirmButton: true,  // Show confirm button
    }).then(() => {
        console.log('User pressed OK');
    });
}

export function errorPopup(title, text) {
    Swal.fire({
        icon: "error",
        title,
        text,
        showConfirmButton: true,  // Show confirm button
    }).then(() => {
        console.log('User pressed OK');
    });
}

export function errorPopupWithModal(title, message, setIsModalOpen) {
    Swal.fire({
        position: "center",
        icon: "error",
        title: `${title}`,
        text: `${message}`,
        confirmButtonText: "OK",
    }).then(() => {
        setIsModalOpen(true); // Set the modal open state after the user presses OK
        console.log('User pressed OK');
    });
}
