import React from "react";
import { FiFacebook, FiTwitter, FiYoutube } from "react-icons/fi";
import { FaInstagram } from "react-icons/fa6";
import { Link } from "react-router-dom";
import ProfileMenu from "./ProfileMenu";

const TopHeader = () => {
  return (
    <>
      <div className="md:grid-cols-3 py-5 bg-white md:grid hidden">
        <div className="footer-social">
          <ul
            style={{
              width: "240px",
              display: "flex",
              justifyContent: "space-between",
              marginLeft: "20px",
            }}
            className="no-list-style"
          >
            <li>
              <Link to="https://www.facebook.com/FlyInnLLC/" target="_blank">
                <FiFacebook style={{ fontSize: "20px", color: "#4f5962" }} />
              </Link>
            </li>
            <li>
              <Link to="https://twitter.com/FlyInnLLC" target="_blank">
                <FiTwitter style={{ fontSize: "20px", color: "#4f5962" }} />
              </Link>
            </li>
            <li>
              <Link to="https://www.instagram.com/flyinnllc/" target="_blank">
                <FaInstagram style={{ fontSize: "20px", color: "#4f5962" }} />
              </Link>
            </li>
            <li>
              <Link to="https://www.youtube.com/@FLY-INN" target="_blank">
                <FiYoutube style={{ fontSize: "20px", color: "#4f5962" }} />
              </Link>
            </li>
          </ul>
        </div>
        <div className="mx-auto">
          <Link className="logo-holder " to={"/"}>
            <img
              style={{
                width: "100%",
                height: "82px",
              }}
              src="/image/fly-inn-logo.svg"
              alt=""
            />
          </Link>
        </div>
        <ProfileMenu />
      </div>
    </>
  );
};

export default TopHeader;
