import React from 'react'
import { Link } from 'react-router-dom'

export const HostsAgreement = () => {
    return (
        <div className="flex flex-wrap">
            <div className="w-full md:w-1/3 p-4">
                <div className="ab_text">
                    <div className="ab_text-title fl-wrap">
                        <h3>Hosting Stays</h3>
                        <span className="section-separator fl-sec-sep" />
                    </div>
                    <Link to={"/terms-of-service"}>
                        <p className="text-sm text-black underline">
                            Terms of Service
                        </p>
                    </Link>
                </div>
            </div>
        </div>
    )
}
