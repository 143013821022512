/* eslint-disable */
import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import BASE_URL from "../Components/auth/Baseurl";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { TbBrandGoogle } from "react-icons/tb";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
// import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import BtnLoader from "../Components/BtnLoader/BtnLoader";
import { useGoogleLogin } from "@react-oauth/google";
import { errorPopupWithModal, popup, successPopup, warningPopup } from "../helpers";
// import BtnLoader from "../Components/BtnLoader/BtnLoader";
const ModalLogin = ({ isModalOpen, setIsModalOpen, setIsRegister, isRegister }) => {
  const [loader, setLoader] = useState(false);
  const [image, setImage] = useState(null);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("login");
  const [showForgetPasswordForm, setShowForgetPasswordForm] = useState(false);
  // console.log(status, message,"location");

  // forget password//
  const toggleForgetPasswordForm = () => {
    setShowForgetPasswordForm(!showForgetPasswordForm);
  };

  const data = [
    {
      path: "images/avatar/swiper1.jpg",
      title: "Runway!",
      des: "Every listing on Fly-Inn either has a landing strip or helipad conveniently located on the property or is situated within 10 minutes of an airport you are likely to pilot into yourself. Most homes are either hangar homes, airpark homes or have their own private runway. Some homes even offer a water landing!",
    },
    {
      path: "images/avatar/swiper2.jpg",
      title: "Aircraft Storage!",
      des: "Whether it be access to a hangar, tie-downs, etc. on the property itself, or whether it be storage services the nearby airport offers, you are sure to have a place where you can securely store your aircraft.",
    },
    {
      path: "images/avatar/swiper3.jpg",
      title: "Cars!",
      des: "What good is it to land if you can’t get around once you arrive? All our hosts know this and provide either a free loaner or a nice car to rent or at least transportation to a nearby car rental.If the airport is a few minutes from the property, transportation will be made available to you at the airport upon your arrival.",
    },
  ];

  const validationSchema3 = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  const handleSubmit3 = async (values, { setSubmitting }) => {
    setLoader(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/forgot-password`,
        { email: values.email },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setShowAdditionalInputs(true);
      console.log("Password reset email sent:", response);
      setLoader(false);
    } catch (error) {
      console.error("Password reset email sending failed:", error);
      setLoader(false);
    } finally {
      setSubmitting(false);
    }
  };
  const [showAdditionalInputs, setShowAdditionalInputs] = useState(false);

  const validationSchema4 = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string().required("Required"),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Required"),
    otp: Yup.string().required("Required"),
  });
  const handleSubmit4 = async (values, { setSubmitting }) => {
    setLoader(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/reset-password`,
        {
          email: values.email,
          password: values.password,
          password_confirmation: values.password_confirmation,
          otp: values.otp,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setShowForgetPasswordForm(false);

      // Password Reset Email Successful
      successPopup("Password Reset", "We have sent you an Email, Check your email to reset your password!");

      console.log("Password reset email sent:", response);
      setLoader(false);
      // Show success message or redirect to another page
    } catch (error) {
      console.error("Password reset email sending failed:", error);
      // Handle error, show error message, etc.
      setLoader(false);
    } finally {
      setSubmitting(false);
    }
  };

  // End forget password//
  // Define initial values for both registration and become a user forms
  const initialValues = {
    first_name: "",
    last_name: "",
    user: "",
    email: "",
    password: "",
  };

  // Define validation schema for both forms
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    user: Yup.string().required("User name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const validationSchema2 = Yup.object().shape({
    email: Yup.string(),
    password: Yup.string().required("Password is required"),
  });

  // Handle image change for both forms
  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  // Handle form submission for registration
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setLoader(true);
    try {
      const formData = new FormData();
      formData.append("first_name", values.first_name);
      formData.append("role", "user");
      formData.append("username", values.user);
      formData.append("last_name", values.last_name);
      formData.append("email", values.email);
      formData.append("password", values.password);
      formData.append("image", image);

      const response = await axios.post(`${BASE_URL}/register`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setLoader(false);

      // Register Success Message
      successPopup("Register successful:", "You have registered your account successfully.");

      resetForm();
      setActiveTab("login");
    } catch (res) {
      console.log(res?.response?.data?.message, "value");

      setLoader(false);
      //  Error Message
      errorPopup("", res?.response?.data?.message)
    }
  };

  const location = useLocation();


  const handleSubmit2 = async (values, { setSubmitting, resetForm }) => {
    setLoader(true);
    try {
      const formData2 = new FormData();
      formData2.append("email", values.email);
      formData2.append("password", values.password);
      formData2.append("role", "user");
      const params = new URLSearchParams(location.search);
      const status = params.get("status");

      if (status) {
        if (status === "true") {
          // console.log(status, "hi");

          const response = await axios.post(`${BASE_URL}/login`, formData2, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          localStorage.setItem("token", response?.data?.data?.accessToken);
          localStorage.setItem(
            "data",
            JSON.stringify(response?.data?.data?.user)
          );

          // Warning Prompt if profile incomplete
          if (response?.data?.data?.user?.complete !== '100%') {
            warningPopup(`
              <div>
                <p style="text-align: center"> 
                  Your profile is not complete. You will not be able to host or book until it is complete. Please complete your profile to 100%.
                </p>
                <p style="text-align: center">
                 Once we have verified your identity, you will receive a message letting you know you are all set.
                </p>
                <p style="text-align: center">
                  To complete your profile properly, watch the Registration video on our <a  target="_blank" style="color: #af2322; text-decoration: underline;" href="https://youtube.com/@fly-inn">YouTube Channel</a>.
                </p>
              </div>
              `
            );
          }

          navigate(response?.data?.data?.user?.complete === "100%" ? "/" : "/profile");
          setIsModalOpen(false);
          setLoader(false);
          resetForm();
          // Success Prompt
          successPopup("Login successful.")

          setTimeout(() => {
            if (response?.data?.data?.user?.complete === "100%") {
              window.location.reload()
            }
          }, 1000);
          setSubmitting(false);
        } else {
          setIsModalOpen(false);
          setLoader(false);
          // Error Prompt
          errorPopupWithModal('', 'Login failed. Please check your credentials.', setIsModalOpen)

          setShowForgetPasswordForm(!showForgetPasswordForm);
        }
        // toast.success(message)
      } else {
        const response = await axios.post(`${BASE_URL}/login`, formData2, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        localStorage.setItem("token", response?.data?.data?.accessToken);
        localStorage.setItem(
          "data",
          JSON.stringify(response?.data?.data?.user)
        );
        navigate(response?.data?.data?.user?.complete === "100%" ? "/" : "/profile");
        setIsModalOpen(false);
        setLoader(false);
        resetForm();
        // Success Prompt
        successPopup("Login successful.")
        setTimeout(() => {
          if (response?.data?.data?.user?.complete === "100%") {
            window.location.reload()
          } else {
            // Warning Prompt if profile incomplete



            warningPopup(`
            <div>
              <p style="text-align: center"> 
                Your profile is not complete. You will not be able to host or book until it is complete. Please complete your profile to 100%.
              </p>
              <p style="text-align: center">
               Once we have verified your identity, you will receive a message letting you know you are all set.
              </p>
              <p style="text-align: center">
                To complete your profile properly, watch the Registration video on our <a  target="_blank" style="color: #af2322; text-decoration: underline;"  href="https://youtube.com/@fly-inn">YouTube Channel</a>.
              </p>
            </div>
            `
            );
          }
        }, 1000);
        setSubmitting(false);
      }
    } catch (error) {
      setLoader(false);
      setIsModalOpen(false);
      // Error prompt
      errorPopupWithModal("", `Login failed. ${error.response?.data?.message || "Something went wrong."}`, setIsModalOpen);
      console.log("Login failed:", error);
      if (error.response?.data?.status === false) {
        console.log(error.response?.data?.message);
      } else {
        console.log("Login failed. Please check your credentials.");
      }
    } finally {
      setSubmitting(false);
    }
  };
  // Handle tab change
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const userdata = localStorage.getItem("data");
  console.log(userdata, "datauser");


  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };



  // sign in google start //

  const [user, setUser] = useState([]);
  const GetGoogle = (codeResponse) => {
    axios
      .get(
        ` https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        UserData
          (res?.data);
      })
      .catch((err) => console.log(err));
  };
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      if (codeResponse) {
        setUser(codeResponse);
        GetGoogle(codeResponse);
      }
    },
    onError: (error) => console.log("Login Failed:", error),
  });
  const UserData = async (newdata) => {
    const data = new FormData();
    data.append("email", newdata?.email);
    data.append("family_name", newdata?.family_name);
    data.append("given_name", newdata?.given_name);
    data.append("id", newdata?.id);
    data.append("name", newdata?.name);
    data.append("username", newdata?.name);
    data.append("picture", newdata?.picture);
    data.append("verified_email", newdata?.verified_email);
    data.append("role", "user");

    try {
      const res = await axios.post(`${BASE_URL}/google/login`, data);
      console.log(res, "login res");

      // Debugging response structure
      console.log(res.data, "res.data");
      console.log(res.data.data, "res.data.data");
      console.log(res.data.data.status, "res.data.data.status");

      if (res?.data?.data?.status) {
        localStorage.setItem("token", res?.data?.data?.accessToken);
        console.log("token", res?.data?.data?.accessToken);

        localStorage.setItem(
          "data",
          JSON.stringify(res?.data?.data?.user)
        );
        console.log(JSON.stringify(res?.data?.data?.user), "token");


        setIsModalOpen(false);
        setLoader(false);
        // resetForm();
        // Login Success Prompt
        successPopup("Login", "Login Successful..!");

        navigate("/");
        window.location.reload();
      } else {
        setIsModalOpen(false);
        Swal.fire({
          position: "center",
          icon: "error",

          text: res.data.message,
          confirmButtonText: "OK",
          preConfirm: () => {
            setIsModalOpen(true);
          }
        });
      }
    } catch (err) {
      console.error("API Error:", err);

      // Debugging error response
      console.log(err.response?.data?.message, "Error Message");

      setIsModalOpen(false);

      // Error Prompt
      errorPopupWithModal("Error", `${err?.res?.data?.message || "Something went wrong"}`, setIsModalOpen)
      // window.location.reload();
    }
  };

  // sign in google start //


  return (
    <>
      {/* <Layout> */}

      <div
        className="main-register-wrap "
        style={{
          display: "block",
          height: "108vh",
          borderRadius: "0px",
          paddingTop: "0px",
        }}
      >
        <div className="reg-overlay" style={{ display: "block" }} />

        <div className="container" style={{
          display: "flex",
          justifyContent: "center"
        }}>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              width: "750px",
              marginTop: "50px",
              background: "white",
              alignItems: " center",
              zIndex: "99",
            }}
          >
            <div style={{ paddingTop: "0px", paddingLeft: "0px", paddingRight: "0px" }} className=" col-md-6">
              <img style={{ height: "500px", width: "530px" }} src="/css/loginimage.png" alt="" />
            </div>
            <div
              class="login-column col-md-6"
              style={{
                backgroundColor: "white",
                zIndex: 999,
              }}
            >
              <div
                className="main-register-holder tabs-act"
                style={{ margin: "0px" }}
              >
                <div className="main-register fl-wrap">
                  <ul
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",


                    }}
                    className="tabs-menu fl-wrap no-list-style  "
                  >
                    <li className={activeTab === "login" ? "current" : ""}>
                      <div style={{ display: "flex" }} onClick={() => handleTabChange("login")}>

                        <h4 style={{ fontSize: "16px" }}>

                          Log in
                        </h4>
                      </div>

                    </li>
                    <RxCross2 style={{ cursor: 'pointer' }} className="x-icon" onClick={() => setIsModalOpen(false)} />

                  </ul>
                  {/*tabs */}
                  <div className="tabs-container">

                    <div style={{ background: "lightgray", display: "flex", justifyContent: "center", alignContent: "center" }}>

                      <span style={{
                        paddingTop: "18px"
                      }}>

                        <TbBrandGoogle
                        />
                      </span>

                      <button style={{
                        padding: "17px",
                        background: "lightgray",
                        fontWeight: "600",
                        cursor: "pointer"
                      }} onClick={() => login()}>

                        Log in with google
                      </button></div>
                    <div style={{ display: "flex", justifyContent: "center", paddingTop: "10px" }}>
                      <h3 style={{ color: "gray", fontSize: "13px", color: "#3b4249" }}>OR</h3>
                    </div>
                    <div style={{ position: "relative" }}>
                      <div style={{
                        position: "absolute",
                        height: "1px",
                        width: "100px",
                        top: "-8px",
                        right: "25px",
                        backgroundColor: "lightgray"
                      }}>
                      </div>
                    </div>
                    <div style={{ position: "relative" }}>
                      <div style={{
                        position: "absolute",
                        height: "1px",
                        width: "100px",
                        top: "-8px",
                        left: "25px",
                        backgroundColor: "lightgray"
                      }}>
                      </div>
                    </div>

                    <div
                      id="tab-1"
                      className={`tab-content ${activeTab === "login" ? "first-tab" : "hide"
                        }`}
                    >
                      <div className="custom-form">
                        {!showForgetPasswordForm && (
                          <Formik
                            initialValues={{ email: "", password: "" }}
                            validationSchema={validationSchema2}
                            onSubmit={handleSubmit2}
                          >
                            {({ isSubmitting }) => (
                              <Form name="loginform">
                                <label style={{
                                  paddingBottom: "8px",
                                  paddingTop: "15px",
                                  color: "#3b4249",
                                  fontSize: "15px",
                                  fontWeight: "400",
                                }}>
                                  Username or Email Address <span>*</span>
                                </label>
                                <Field type="text" name="email" />
                                <ErrorMessage className="error-msg-color" name="email" component="div" />

                                <div>
                                  <label style={{
                                    paddingBottom: "8px", paddingTop: "15px", color: "#3b4249", fontSize: "15px",
                                    fontWeight: "400"
                                  }}>
                                    Password <span>*</span>
                                  </label>
                                  <div style={{ position: 'relative' }}>
                                    <Field
                                      type={showPassword ? 'text' : 'password'}
                                      name="password"
                                      style={{ paddingRight: '30px' }}
                                    />
                                    <span
                                      onClick={togglePasswordVisibility}
                                      style={{
                                        position: 'absolute',
                                        right: '10px',
                                        top: '150px',
                                        transform: 'translateY(-50%)',
                                        cursor: 'pointer'
                                      }}
                                    >
                                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                                    </span>
                                  </div>
                                  <ErrorMessage className="error-msg-color" name="password" component="div" />
                                </div>

                                <button
                                  // onClick={()=>alert("hi")}
                                  type="submit"
                                  style={{
                                    backgroundColor: "#8EC639",
                                    color: "white",
                                    marginTop: "20px",
                                    paddingRight: "29px",
                                  }}
                                  className="btn float-btn"
                                  disabled={isSubmitting}
                                >
                                  {loader ? (
                                    <BtnLoader />
                                  ) : (
                                    <>
                                      Log In{" "}
                                      <i className="fas fa-caret-right" style={{ right: "5px" }} />
                                    </>
                                  )}
                                </button>

                                <div className="clearfix" />
                                <div className="filter-tags">
                                  <input
                                    id="check-a3"
                                    type="checkbox"
                                    name="check"
                                  />
                                  <label
                                    style={{ color: "#3b4249", fontSize: "12px", fontWeight: "600" }}
                                    htmlFor="check-a3"
                                  >
                                    Remember me
                                  </label>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        )}
                        {!showForgetPasswordForm && (
                          <div className="lost_password">
                            <Link
                              to="#"
                              style={{ color: "#A42322" }}
                              className="show-lpt"
                              onClick={toggleForgetPasswordForm}
                            >
                              Forgot Password?
                            </Link>
                          </div>
                        )}
                        {showForgetPasswordForm && !showAdditionalInputs && (
                          <Formik
                            initialValues={{ email: "" }}
                            validationSchema={validationSchema3}
                            onSubmit={handleSubmit3}
                          >
                            {({ isSubmitting }) => (
                              <Form>
                                <label>
                                  Enter your Email Address <span>*</span>{" "}
                                </label>
                                <Field type="text" name="email" />
                                <ErrorMessage className="error-msg-color" name="email" component="div" />
                                <button
                                  type="submit"
                                  style={{ backgroundColor: "#4E65A3" }}
                                  className="btn float-btn"
                                  disabled={isSubmitting}
                                >
                                  {loader ? (
                                    <BtnLoader />
                                  ) : (
                                    <>
                                      Reset Email{" "}
                                      <i className="fas fa-caret-right" />
                                    </>
                                  )}
                                </button>
                                <div className="lost_password">
                                  <Link
                                    to="#"
                                    className="show-lpt"
                                    onClick={toggleForgetPasswordForm}
                                  >
                                    Cancel
                                  </Link>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        )}
                        {showForgetPasswordForm && showAdditionalInputs && (
                          <Formik
                            initialValues={{
                              email: "",
                              password: "",
                              password_confirmation: "",
                              otp: "",
                            }}
                            validationSchema={validationSchema4}
                            onSubmit={handleSubmit4}
                          >
                            {({ isSubmitting }) => (
                              <Form>
                                <label>
                                  Email Address <span>*</span>
                                </label>
                                <Field type="text" name="email" />
                                <ErrorMessage className="error-msg-color" name="email" component="div" />
                                <label>
                                  OTP Code <span>*</span>
                                </label>
                                <Field type="number" name="otp" />
                                <ErrorMessage className="error-msg-color" name="otp" component="div" />

                                <label>
                                  Password <span>*</span>
                                </label>
                                <Field type="password" name="password" />
                                <ErrorMessage className="error-msg-color" name="password" component="div" />

                                <label>
                                  Confirm Password <span>*</span>
                                </label>
                                <Field
                                  type="password"
                                  name="password_confirmation"
                                />
                                <ErrorMessage className="error-msg-color"
                                  name="password_confirmation"
                                  component="div"
                                />

                                <button
                                  type="submit"
                                  style={{ backgroundColor: "#4E65A3" }}
                                  className="btn float-btn"
                                  disabled={isSubmitting}
                                >
                                  Submit <i className="fas fa-caret-right" />
                                </button>
                                <div className="lost_password">
                                  <Link
                                    to="#"
                                    className="show-lpt"
                                    onClick={toggleForgetPasswordForm}
                                  >
                                    Cancel
                                  </Link>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        )}
                      </div>
                    </div>
                    {/* Tab 2: Register */}
                    <div
                      id="tab-2"
                      className={`tab-content ${activeTab === "register" ? "first-tab" : "hide"
                        }`}
                    >
                      <div className="custom-form">
                        <Formik
                          initialValues={initialValues}
                          validationSchema={validationSchema}
                          onSubmit={handleSubmit}
                        >
                          {({ isSubmitting }) => (
                            <Form name="">
                              <label>
                                First Name <span>*</span>{" "}
                              </label>
                              <Field type="text" name="first_name" />
                              <ErrorMessage className="error-msg-color" name="first_name" component="div" />

                              <label>
                                Last Name <span>*</span>{" "}
                              </label>
                              <Field type="text" name="last_name" />
                              <ErrorMessage className="error-msg-color" name="last_name" component="div" />
                              <label>
                                User Name <span>*</span>{" "}
                              </label>
                              <Field type="text" name="user" />
                              <ErrorMessage className="error-msg-color" name="user" component="div" />

                              <label>
                                Email Address <span>*</span>{" "}
                              </label>
                              <Field type="text" name="email" />
                              <ErrorMessage className="error-msg-color" name="email" component="div" />

                              <label>
                                Password <span>*</span>{" "}
                              </label>
                              <Field type="password" name="password" />
                              <ErrorMessage className="error-msg-color" name="password" component="div" />

                              <label>
                                Image <span>*</span>{" "}
                              </label>
                              <input
                                style={{ display: "block" }}
                                type="file"
                                name="image"
                                onChange={handleImageChange}
                              />
                              <ErrorMessage className="error-msg-color" name="image" component="div" />

                              <button
                                style={{
                                  backgroundColor: "#8EC639",
                                  color: "white",
                                }}
                                type="submit"
                                className="btn float-btn"
                                disabled={isSubmitting}
                              >
                                {loader ? (
                                  <BtnLoader />
                                ) : (
                                  <>
                                    Register{" "}
                                    <i className="fas fa-caret-right" />
                                  </>
                                )}
                              </button>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                    {/* Tab 3: Become a user */}

                    {/*tabs end */}
                    <p>Don't have an account yet? <span style={{ cursor: "pointer", color: "#af2322" }} onClick={() => { setIsModalOpen(false); setIsRegister(true) }}>Register.</span></p>
                  </div>
                  {/* Tab content end */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* </Layout> */}
    </>
  );
};

export default ModalLogin;
