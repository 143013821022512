/* eslint-disable */
import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import BASE_URL from "../Components/auth/Baseurl";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { TbBrandGoogle } from "react-icons/tb";
// import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import BtnLoader from "../Components/BtnLoader/BtnLoader";
import Layout from "../Components/layout/Layout";
import { DiJava } from "react-icons/di";
import { useGoogleLogin } from "@react-oauth/google";
import { errorPopup, errorPopupWithModal } from "../helpers";
// import BtnLoader from "../Components/BtnLoader/BtnLoader";
const RegisterModal = ({ isRegister, setIsRegister, isModalOpen, setIsModalOpen }) => {
    const [loader, setLoader] = useState(false);
    const [image, setImage] = useState(null);
    const [activeTab, setActiveTab] = useState("login");
    const [isChecked, setIsChecked] = useState(false);
    const initialValues = {

        user: "",
        email: "",
        password: "",
        repeat_password: "",
    };
    const validationSchema = Yup.object().shape({
        user: Yup.string().required("User name is required"),
        email: Yup.string().email("Invalid email").required("Email is required"),
        password: Yup.string().required("Password is required"),
        repeat_password: Yup.string()
            .oneOf([Yup.ref('password'), null], "Passwords must match")
            .required("Repeat password is required"),
    });



    // Handle form submission for registration
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        if (!isChecked) {

            Swal.fire({
                position: "center",
                // text: ("You must agree with the Terms of Service."),
                icon: "warning",
                // customClass: {
                //     // popup: 'custom-swal-popup',
                //     // confirmButton: 'custom-swal-button'
                // }
                html: `
                <p style="text-align: center">You must agree with the <a href="https://fly-inn.com/terms-of-service">Terms of Service</a>.</p>
                `
            })
        } else {

            setLoader(true);
            try {
                const formData = new FormData();

                // formData.append("first_name", values.first_name);
                formData.append("role", "user");
                formData.append("username", values.user);
                formData.append("email", values.email);
                formData.append("password", values.password);
                formData.append("password", values.repeat_password);
                // formData.append("image", image);

                const response = await axios.post(`${BASE_URL}/register`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                resetForm();
                setLoader(false);
                setIsRegister(false);
                Swal.fire({
                    position: "center",
                    html: `
            <div style="display: flex; flex-direction: column; align-items: center;">
               <svg
                      viewBox="0 0 1024 1024"
                      height="4em"
                      width="4em"
                      style="margin-bottom: 10px;"
                  >
                      <!-- Outer circle with color #8ec639 -->
                      <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                      
                      <!-- Check mark with color #A2c66b -->
                      <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
                  </svg>
                <p style="margin: 0; font-size: 1.2em;">We have sent you an email. Please verify your email to activate your account. This may take a few minutes. Be sure to check your inbox, spam, and promotions folders.</p>
            </div>
        `,
                    // title: "Register successful:",
                    showConfirmButton: true,
                });

                setActiveTab("login");
            } catch (res) {
                console.log(res?.response?.data?.message, "value");

                setLoader(false);
                errorPopup('', res?.response?.data?.message);

            }
        }
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const userdata = localStorage.getItem("data");
    console.log(userdata, "datauser");




    const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const togglePasswordVisibility1 = () => {
        setShowPassword1(!showPassword1);
    };

    // Register with google start //
    const [user, setUser] = useState([]);
    const GetGoogle = (codeResponse) => {
        axios
            .get(
                ` https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
                {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        Accept: "application/json",
                    },
                }
            )
            .then((res) => {
                UserData
                    (res?.data);
            })
            .catch((err) => console.log(err));
    };
    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            if (codeResponse) {
                setUser(codeResponse);
                GetGoogle(codeResponse);
            }
        },
        onError: (error) => console.log("Login Failed:", error),
    });
    const UserData = async (newdata) => {
        const data = new FormData();
        data.append("email", newdata?.email);
        data.append("family_name", newdata?.family_name);
        data.append("given_name", newdata?.given_name);
        data.append("id", newdata?.id);
        data.append("name", newdata?.name);
        data.append("picture", newdata?.picture);
        data.append("verified_email", newdata?.verified_email);
        data.append("role", "user");

        try {
            const res = await axios.post(`${BASE_URL}/google/login`, data);
            console.log(res, "login res");

            // Debugging response structure
            console.log(res.data, "res.data");
            console.log(res.data.data, "res.data.data");
            console.log(res.data.data.status, "res.data.data.status");

            if (res?.data?.data?.status) {
                localStorage.setItem("token", res?.data?.data?.accessToken);
                console.log("token", res?.data?.data?.accessToken);

                localStorage.setItem(
                    "data",
                    JSON.stringify(res?.data?.data?.user)
                );
                console.log(JSON.stringify(res?.data?.data?.user), "token");

                navigate("/");
                setIsModalOpen(false);
                setLoader(false);
                resetForm();
                Swal.fire({
                    position: "center",
                    html: `
            <div style="display: flex; flex-direction: column; align-items: center;">
<svg
                      viewBox="0 0 1024 1024"
                      height="4em"
                      width="4em"
                      style="margin-bottom: 10px;"
                  >
                      <!-- Outer circle with color #8ec639 -->
                      <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                      
                      <!-- Check mark with color #A2c66b -->
                      <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
                  </svg>
                <p style="margin: 0; font-size: 1.2em;">Profile update successful.</p>
            </div>
        `,
                    title: "Login successful:",
                    showConfirmButton: true,
                    timer: 1500,
                });
                window.location.reload();
            } else {
                setIsModalOpen(false);
                errorPopupWithModal('', res.data.message, setIsModalOpen)

            }
        } catch (err) {
            console.error("API Error:", err);

            // Debugging error response
            console.log(err.response?.data?.message, "Error Message");

            setIsModalOpen(false);
            Swal.fire({
                position: "center",
                html: `
            <div style="display: flex; flex-direction: column; align-items: center;">
<svg
                      viewBox="0 0 1024 1024"
                      height="4em"
                      width="4em"
                      style="margin-bottom: 10px;"
                  >
                      <!-- Outer circle with color #8ec639 -->
                      <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                      
                      <!-- Check mark with color #A2c66b -->
                      <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
                  </svg>
                <p style="margin: 0; font-size: 1.2em;">Profile update successful.</p>
            </div>
        `,
                title: "Login successful:",
                showConfirmButton: true,
                timer: 1500,
            });
            window.location.reload();
        }
    };

    // Register with google End //



    return (
        <>
            {/* <Layout> */}

            <div
                className="main-register-wrap "
                style={{
                    display: "block",
                    height: "108vh",
                    borderRadius: "0px",
                    paddingTop: "0px",
                }}
            >
                <div className="reg-overlay" style={{ display: "block" }} />

                <div className="container" style={{
                    display: "flex",
                    justifyContent: "center"
                }}>
                    <div
                        className="row"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "750px",
                            marginTop: "50px",
                            background: "white",
                            alignItems: " center",
                            zIndex: "99",
                        }}
                    >
                        <div style={{ paddingTop: "0px", paddingLeft: "0px", paddingRight: "0px" }} className=" col-md-6">
                            <img style={{ height: "500px", width: "550px" }} src="/css/loginimage.png" alt="" />
                        </div>
                        <div
                            class="login-column col-md-6"
                            style={{
                                backgroundColor: "white",
                                zIndex: 999,

                            }}
                        >
                            <div
                                className="main-register-holder tabs-act"
                                style={{ margin: "0px" }}
                            >
                                <div className="main-register fl-wrap">
                                    <ul
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",


                                        }}
                                        className="tabs-menu fl-wrap no-list-style  "
                                    >
                                        <li className={activeTab === "login" ? "current" : ""}>
                                            <div style={{ display: "flex" }} onClick={() => handleTabChange("login")}>

                                                <h4 style={{ fontSize: "16px" }}>

                                                    Register
                                                </h4>
                                            </div>

                                        </li>
                                        <RxCross2 style={{ cursor: 'pointer' }} className="x-icon" onClick={() => setIsRegister(false)} />

                                    </ul>
                                    {/*tabs */}
                                    <div className="tabs-container">
                                        {/* Tab 1: Login */}
                                        <div style={{ background: "lightgray", display: "flex", justifyContent: "center", alignContent: "center" }}>

                                            <span style={{
                                                paddingTop: "19px"
                                            }}>

                                                <TbBrandGoogle
                                                />
                                            </span>

                                            <button style={{
                                                padding: "17px",
                                                background: "lightgray",
                                                fontWeight: "600",
                                                cursor: "pointer",

                                            }} onClick={() => login()}>

                                                Register with google
                                            </button></div>
                                        <div style={{ display: "flex", justifyContent: "center", paddingTop: "10px" }}>
                                            <h3 style={{ color: "gray", fontSize: "13px" }}>OR</h3>
                                        </div>
                                        <div style={{ position: "relative" }}>
                                            <div style={{
                                                position: "absolute",
                                                height: "1px",
                                                width: "100px",
                                                top: "-8px",
                                                right: "25px",
                                                backgroundColor: "lightgray"
                                            }}>
                                            </div>
                                        </div>
                                        <div style={{ position: "relative" }}>
                                            <div style={{
                                                position: "absolute",
                                                height: "1px",
                                                width: "100px",
                                                top: "-8px",
                                                left: "25px",
                                                backgroundColor: "lightgray"
                                            }}>
                                            </div>
                                        </div>

                                        <div className="custom-form">

                                            <Formik
                                                initialValues={{
                                                    user: '',
                                                    email: '',
                                                    password: '',
                                                    repeat_password: ''
                                                }}
                                                validationSchema={validationSchema}
                                                onSubmit={handleSubmit}
                                            >
                                                {({ isSubmitting }) => (
                                                    <Form name="">
                                                        <div className="">
                                                            <Field name="user">
                                                                {({ field, meta }) => (
                                                                    <div style={{ position: "relative" }}>
                                                                        <input
                                                                            {...field}
                                                                            type="text"
                                                                            className={`custom-input_change ${meta.touched && meta.error ? 'error' : ''}`}
                                                                            placeholder={meta.touched && meta.error ? meta.error : 'Username'}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </Field>

                                                            <Field name="email">
                                                                {({ field, meta }) => (
                                                                    <div style={{ position: "relative" }}>
                                                                        <input
                                                                            {...field}
                                                                            type="text"
                                                                            className={`custom-input_change ${meta.touched && meta.error ? 'error' : ''}`}
                                                                            placeholder={meta.touched && meta.error ? meta.error : 'Email'}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </Field>

                                                            <Field name="password">
                                                                {({ field, meta }) => (
                                                                    <div style={{ position: "relative" }}>
                                                                        <input
                                                                            {...field}
                                                                            type={showPassword ? 'text' : 'password'}
                                                                            className={`custom-input_change ${meta.touched && meta.error ? 'error' : ''}`}
                                                                            placeholder={meta.touched && meta.error ? meta.error : 'Password'}
                                                                        />
                                                                        <span
                                                                            onClick={togglePasswordVisibility}
                                                                            style={{
                                                                                position: 'absolute',
                                                                                right: '10px',
                                                                                top: '98px',
                                                                                zIndex: 1,
                                                                            }}
                                                                        >
                                                                            {showPassword ? <FaEye /> : <FaEyeSlash />}
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </Field>

                                                            <Field name="repeat_password">
                                                                {({ field, meta }) => (
                                                                    <div style={{ position: "relative" }}>
                                                                        <input
                                                                            {...field}
                                                                            type={showPassword1 ? 'text' : 'password'}
                                                                            className={`custom-input_change ${meta.touched && meta.error ? 'error' : ''}`}
                                                                            placeholder={meta.touched && meta.error ? meta.error : 'Repeat Password'}
                                                                        />
                                                                        <span
                                                                            onClick={togglePasswordVisibility1}
                                                                            style={{
                                                                                position: 'absolute',
                                                                                right: '10px',
                                                                                top: '139px',
                                                                                zIndex: 1,
                                                                            }}
                                                                        >
                                                                            {showPassword1 ? <FaEye /> : <FaEyeSlash />}
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </Field>

                                                            <p style={{ margin: "20px 0px", top: '13px' }}>
                                                                <input onChange={(e) => setIsChecked(e.target.checked)} type="checkbox" />
                                                                <span style={{ marginLeft: '10px' }}>I agree to the
                                                                    <span style={{ color: "#af2322" }}>
                                                                        <Link style={{ color: "#af2322", marginLeft: "5px" }} to={"/terms-of-service"}>Terms of Service.</Link>
                                                                    </span>
                                                                </span>
                                                            </p>

                                                            <button
                                                                style={{
                                                                    backgroundColor: "#8EC639",
                                                                    color: "white",
                                                                    fontSize: "15px",
                                                                    width: "100%",
                                                                    padding: "12px 0px"
                                                                }}
                                                                type="submit"
                                                                className="btn"
                                                                disabled={isSubmitting}
                                                            >
                                                                Register
                                                            </button>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>


                                        </div>


                                        {/*tabs end */}

                                        <p>Already have an account? <span style={{ cursor: "pointer", color: "#af2322" }} onClick={() => { setIsModalOpen(true); setIsRegister(false) }}>Log In.</span></p>
                                    </div>
                                    {/* Tab content end */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            {/* </Layout> */}
        </>
    );
};

export default RegisterModal;
