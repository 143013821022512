/* eslint-disable*/
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const BookDetailR = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const row = location.state?.row;
  console.log(row, "values");

  // const row = location?.state;
  // const currentUser = JSON.parse(localStorage.getItem('currentuser'));
  const arriveDateParts = row?.booking?.arrive_date.split('-');
  const departDateParts = row?.booking?.depart_date.split('-');
  const arriveDate = new Date(`20${arriveDateParts[0]}`, arriveDateParts[1] - 1, arriveDateParts[2]);
  const departDate = new Date(`20${departDateParts[0]}`, departDateParts[1] - 1, departDateParts[2]);
  const diffTime = Math.abs(departDate - arriveDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const nightlyRate = Number(row?.listing?.nightly);
  const totalCost = diffDays * nightlyRate;

  function PrintElem() {
    var elemContent = document.getElementById('elem').innerHTML;
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');

    mywindow.document.write('<html><head>');
    mywindow.document.write('</head><body>');
    mywindow.document.write(elemContent);
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10

    // Wait for the content to load
    mywindow.onload = function () {
      mywindow.focus(); // necessary for IE >= 10
      mywindow.print();
      mywindow.close();
    };

    return true;
  }


  return (
    <>
      <main className="col-md-12" style={{marginTop:"30px"}} >
        <div style={{ display: 'flex' }}>
          <div style={{ flex: '0 0 66.666667%', maxWidth: '66.666667%' }} id='elem'>
            <div style={{ padding: '16px', boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)', background: 'white' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
                <div style={{ flex: '0 0 auto', padding: '0px 16px' }}>
                  <div id="elem" style={{ marginBottom: '8px' }}>
                    <img src="/css/loginimage.png" style={{ width: '200px' }} alt="logo" />
                  </div>
                  <ul style={{ padding: '0', listStyle: 'none' }}>
                    <li>
                      <strong>FlyInn LLC</strong>
                    </li>
                    <li>P.O. Box 270439, Fruitland, UT 84027</li>
                  </ul>
                </div>
                <div style={{ flex: '0 0 auto', padding: '40px 40px' }}>
                  <ul style={{ padding: '0', listStyle: 'none' }}>
                    <li style={{ display: 'flex', alignItems: "flex-start", paddingBottom: "7px", fontSize: "15px" }}>
                      <strong>Invoice: </strong>
                      <span style={{ marginLeft: "5px" }}> {row?.invoice_number}</span>
                    </li>
                    <li style={{ display: 'flex', alignItems: "flex-start", paddingBottom: "7px", fontSize: "15px" }}>
                      <strong>Date:</strong>
                      <span style={{ marginLeft: "5px" }}> {row?.date}</span>
                    </li>
                    <li style={{ display: 'flex', alignItems: "flex-start", paddingBottom: "7px", fontSize: "15px" }}>
                      <strong>Reservation ID: </strong>
                      <span style={{ marginLeft: "5px" }}> {row?.reservation_id}</span>
                    </li>
                    <li style={{ display: 'flex', alignItems: "flex-start", paddingBottom: "7px", fontSize: "15px" }}>
                      <strong>Check In: </strong>
                      <span style={{ marginLeft: "5px" }}> {row?.booking?.arrive_date}</span>
                    </li>
                    <li style={{ display: 'flex', alignItems: "flex-start", paddingBottom: "7px", fontSize: "15px" }}>
                      <strong>Check Out: </strong>
                      <span style={{ marginLeft: "5px" }}> {row?.booking?.depart_date}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div style={{ marginBottom: '16px', padding: '0px 16px' }}>
                <ul style={{ padding: '0', listStyle: 'none', textAlign: "left" }}>
                  <li style={{paddingBottom:"7px"}}>
                    <strong>To:</strong>
                  </li>
                  <li style={{paddingBottom:"7px"}}>Disply Name: - {row?.user?.given_name}</li>
              

                  <li style={{paddingBottom:"7px"}}>Email: - {row?.user?.email}</li>
              
                  <li style={{paddingBottom:"7px"}}>Phone: - {row?.user?.phone}</li>
                </ul>
                <div style={{ paddingTop: "20px", textAlign: "left" }}>
                  <li style={{ listStyle: 'none', paddingBottom: "10px" }}>
                    <strong>Details</strong>
                  </li>
                  <hr />
                  <ul style={{ padding: '0', listStyle: 'none', lineHeight: "25px" }}>
                    <li className="sub-total" style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>
                        $ {row?.listing?.nightly} x {diffDays}
                      </span>
                      <span>$ {totalCost}</span>
                    </li>
                    <hr />
                    <li className="sub-total" style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>Sub Total</span>
                      <span>$ {row?.booking?.sub_total}</span>
                    </li>
                    <hr />
                    <li className="payment-due" style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>Total</span>
                      <span>$ {row?.booking?.total}</span>
                    </li>
                  </ul>
                  <input type="hidden" name="is_valid_upfront_payment" id="is_valid_upfront_payment" value="4.56" />
                </div>
              </div>
            </div>
          </div>
          <div style={{ flex: '0 0 33.333333%', maxWidth: '33.333333%', padding: '0 16px' }}>


            <button
              className="invoice_change_btn"
              style={{
                display: 'block',
                width: '100%',
                padding: '12px 0',
                marginBottom: '8px',
                fontSize: "15px",
                fontWeight: "600",
                backgroundColor: '#8ec639',
                color: '#fff',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
              onClick={PrintElem}
            >
              Print
            </button>
            <button
              className="invoice_change_btn1"
              style={{
                display: 'block',
                width: '100%',
                padding: '12px 0',
                marginBottom: '8px',
                fontSize: "15px",
                fontWeight: "600",
                backgroundColor: '#54c4d9',
                color: '#fff',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
              onClick={() => navigate('/invoice')}
            >
              Go Back
            </button>
          </div>
        </div>
      </main>
    </>
  );
};

export default BookDetailR;
