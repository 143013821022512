import React, { useState } from "react";
import { DateRange } from "react-date-range";
import moment from "moment";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const DetailDatePicker = ({ setStartDate, setEndDate, disable = [] }) => {
  const [range, setRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const isDateDisabled = (date) => {
    return disable.some((disabledDate) => moment(disabledDate).isSame(moment(date), "day"));
  };

  const isPastDate = (date) => {
    return moment(date).isBefore(moment(), "day");
  };

  const isToday = (date) => {
    return moment(date).isSame(moment(), "day");
  };

  const handleDateChange = (item) => {
    const newStartDate = item.selection.startDate;
    const newEndDate = item.selection.endDate;

    // Prevent selecting a past date as the start date
    if (isPastDate(newStartDate)) {
      return; // Do nothing if the selected start date is in the past
    }

    if (!range[0].startDate || (range[0].startDate && range[0].endDate)) {
      if (!isDateDisabled(newStartDate)) {
        setRange([{ startDate: newStartDate, endDate: null, key: "selection" }]);
        setStartDate(newStartDate);
      }
    } else if (range[0].startDate && !range[0].endDate) {
      // Prevent selecting a past date as the end date
      if (isPastDate(newEndDate)) {
        return; // Do nothing if the selected end date is in the past
      }

      if (!isDateDisabled(newEndDate)) {
        setRange([{ startDate: range[0].startDate, endDate: newEndDate, key: "selection" }]);
        setEndDate(newEndDate);
        setIsCalendarOpen(false); // Close the calendar after end date is selected
      }
    }
  };

  const renderDayContents = (day) => {
    const date = moment(day); // Convert to Moment.js object
    const isDisabled = isDateDisabled(day);
    const isStartDate = range[0].startDate && date.isSame(range[0].startDate, "day");
    const isEndDate = range[0].endDate && date.isSame(range[0].endDate, "day");

    const statusStyles = {
      available: { backgroundColor: "transparent", color: "inherit" },
      booked: { backgroundColor: "#fde2e1", color: "#fff" },
      past: { backgroundColor: "#eef0f2", color: "inherit" },
      today: { backgroundColor: "#52c4d7", color: "#ffffff" }, // Blue background with white text
    };

    let style = statusStyles.available;

    if (isDisabled) {
      style = statusStyles.booked;
    } else if (isPastDate(day)) {
      style = statusStyles.past;
    } else if (isToday(day)) {
      style = statusStyles.today;
    }

    if (isStartDate || isEndDate) {
      style = { backgroundColor: "#54C4D9", color: "white" };
    }

    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ...style,
          pointerEvents: isDisabled || isPastDate(day) ? "none" : "auto",
          borderRadius: "20%", // Makes the selected day a circle
        }}
      >
        {date.date()}
      </div>
    );
  };

  return (
    <div className="calendar-containers">
      <div className="date-display">
        <input
         className="date_picker_place"
          type="text"
          value={range[0].startDate ? moment(range[0].startDate).format("YYYY-MM-DD") : ""}
          placeholder="CHECK-IN"
          readOnly
          onClick={() => setIsCalendarOpen(true)} // Open calendar on click
        />
        <input
         className="date_picker_place"
          type="text"
          value={range[0].endDate ? moment(range[0].endDate).format("YYYY-MM-DD") : ""}
          placeholder="CHECK-OUT"
          readOnly
          onClick={() => setIsCalendarOpen(true)} // Open calendar on click
        />
      </div>
      {isCalendarOpen && (
        <div className="picker">
          <DateRange
            ranges={range}
            onChange={handleDateChange}
            showDateDisplay={false}
            months={2}
            direction="horizontal"
            dayContentRenderer={renderDayContents}
            moveRangeOnFirstSelection={false}
          />
        </div>
      )}
    </div>
  );
};

export default DetailDatePicker;
