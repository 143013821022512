import React from 'react'
import Layout from '../Components/layout/Layout'
import { Link } from 'react-router-dom'

const Neighborhood = () => {
    return (
        <Layout>
            <section className="main-content-area">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9">
                            <div className="page-title">
                                <div className="block-top-title">
                                    <ol className="breadcrumb">
                                        <li>
                                            <Link to="/">
                                                <span>Home</span>
                                            </Link>
                                        </li>
                                        <li className="active">
                                            Neighborhood Nuisance and Disruptive Behavior Policy
                                        </li>
                                    </ol>{" "}
                                    <h1 className="">
                                        Neighborhood Nuisance and Disruptive Behavior Policy
                                    </h1>
                                </div>
                                {/* block-top-title */}
                            </div>
                            {/* page-title */}
                        </div>
                    </div>
                    {/* .row */}
                </div>
                {/* .container */}
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 inner-static">
                            <div className="page-wrap">
                                <div className="article-main">
                                    <article
                                        id="post-7746"
                                        className="single-page-article block post-7746 page type-page status-publish hentry"
                                    >
                                        <div className="article-detail block-body">
                                            <div
                                                data-elementor-type="wp-page"
                                                data-elementor-id={7746}
                                                className="elementor elementor-7746"
                                                data-elementor-post-type="page"
                                            >
                                                <div
                                                    className="elementor-element elementor-element-51d9322a e-flex e-con-boxed e-con e-parent"
                                                    data-id="51d9322a"
                                                    data-element_type="container"
                                                >
                                                    <div className="e-con-inner">
                                                        <div
                                                            className="elementor-element elementor-element-39f30950 elementor-widget elementor-widget-text-editor"
                                                            data-id="39f30950"
                                                            data-element_type="widget"
                                                            data-widget_type="text-editor.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <style
                                                                    dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            "/*! elementor - v3.21.0 - 18-04-2024 */\n.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap{background-color:#69727d;color:#fff}.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap{color:#69727d;border:3px solid;background-color:transparent}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap{margin-top:8px}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter{width:1em;height:1em}.elementor-widget-text-editor .elementor-drop-cap{float:left;text-align:center;line-height:1;font-size:50px}.elementor-widget-text-editor .elementor-drop-cap-letter{display:inline-block}"
                                                                    }}
                                                                />{" "}
                                                                {/* wp:paragraph */}
                                                                <p>
                                                                    This Policy applies to Hosts as well as Guests and
                                                                    it encompasses our prohibition on Disruptive
                                                                    Behavior during a Guest’s stay anywhere in or on the
                                                                    Rental Property, or in the neighborhood or area
                                                                    where the Rental Property is located.
                                                                    <br />
                                                                    It is difficult even to imagine Members of our
                                                                    Community causing annoyances in the neighborhoods
                                                                    where we are guests, or anywhere else. Needless to
                                                                    say, we all need to behave with decorum and treat
                                                                    everyone with respect and kindness, and this
                                                                    especially applies when we are guests.
                                                                </p>
                                                                <br />
                                                                {/* /wp:paragraph */}
                                                                {/* wp:heading */}
                                                                <h2 className="wp-block-heading">DEFINITION</h2>
                                                                {/* /wp:heading */}
                                                                {/* wp:paragraph */}
                                                                <p>
                                                                    <strong className="privacy-policy-tutorial">
                                                                        “Disruptive Behavior”{" "}
                                                                    </strong>
                                                                    is herein defined as any party, gathering,
                                                                    get-together, or event, collectively{" "}
                                                                    <strong className="privacy-policy-tutorial">
                                                                        “Event,”
                                                                    </strong>{" "}
                                                                    that causes any kind of annoyance including, but not
                                                                    limited to
                                                                </p>
                                                                <ol>
                                                                    <li>
                                                                        Unreasonable amounts of pollution including air,
                                                                        noise, lights, trash, waste
                                                                    </li>
                                                                    <li>Unreasonable numbers of people</li>
                                                                    <li>
                                                                        Smoking, vaping, alcohol consumption, drug use
                                                                    </li>
                                                                    <li>Creating parking issues</li>
                                                                    <li>
                                                                        Disregard for and disrespect of the Rental
                                                                        Property itself, the surrounding properties, or
                                                                        the neighborhood in which they are situated,
                                                                        including causing any kind of damage
                                                                    </li>
                                                                    <li>
                                                                        Breaking the law with regards to the Rental
                                                                        Property itself, the surrounding properties, or
                                                                        the neighborhood in which they are situated.
                                                                    </li>
                                                                </ol>
                                                                <p />
                                                                <br />
                                                                {/* /wp:paragraph */}
                                                                {/* wp:heading */}
                                                                <h2 className="wp-block-heading">
                                                                    GUEST AND HOST COMMITMENT
                                                                </h2>
                                                                {/* /wp:heading */}
                                                                {/* wp:paragraph */}
                                                                <p>
                                                                    <strong className="privacy-policy-tutorial">
                                                                        Guests{" "}
                                                                    </strong>
                                                                    are not allowed to participate in such Disruptive
                                                                    Behaviors or actions. FlyInn reserves the right to
                                                                    intervene and cancel a reservation that we, in our
                                                                    sole discretion, determine to be a risk for
                                                                    Disruptive Behavior. We also reserve the right to
                                                                    terminate a Guest’s membership if these rules are
                                                                    not followed.
                                                                </p>
                                                                {/* /wp:paragraph */}
                                                                {/* wp:paragraph */}
                                                                <p>
                                                                    <strong className="privacy-policy-tutorial">
                                                                        Hosts{" "}
                                                                    </strong>
                                                                    are not allowed to list their Rental Property in any
                                                                    way that would lead a Guest to believe that Events
                                                                    are permitted. We especially take note of Listings
                                                                    that invite Guests, inadvertently or otherwise, to
                                                                    use the property as a venue which could lead to
                                                                    Disruptive Behavior. This includes, but is not
                                                                    limited to, advertising the Rental Property as being
                                                                    a venue that is conducive to such events; posting
                                                                    photography of the Rental Property decorated for an
                                                                    Event; posting photography in which an Event is
                                                                    taking place; using words such as “party,” “event,”
                                                                    “gathering,” “hangout,” “graduation,” “bachelor,”
                                                                    “bachelorette;” setting the number of guests allowed
                                                                    in the listing inordinately high for the amount of
                                                                    space.
                                                                </p>
                                                                <p>
                                                                    FlyInn reserves the right to intervene and cancel a
                                                                    reservation that we, in our sole discretion,
                                                                    determine to be a risk for Disruptive Behavior. We
                                                                    also reserve the right to terminate a Host’s
                                                                    membership and permanently delete a Listing if these
                                                                    rules are not followed. Not canceling a reservation,
                                                                    terminating a membership, deleting a Listing, or
                                                                    taking any other corrective action does not mean we
                                                                    give up that right.
                                                                </p>
                                                                <br />
                                                                {/* /wp:paragraph */}
                                                                {/* wp:heading */}
                                                                <h2 className="wp-block-heading">INTERVENTION</h2>
                                                                {/* /wp:heading */}
                                                                {/* wp:paragraph */}
                                                                <p>
                                                                    As Members of this wonderful tight-knit Community of
                                                                    aviation enthusiasts, we ask you to help us keep our
                                                                    Community thriving by ensuring the rules above are
                                                                    followed. The best way to do this is to call us to
                                                                    report any of the above Disruptive Behaviors
                                                                    immediately, especially if it is still happening
                                                                    when you call. This is the time we can intervene
                                                                    most effectively. If you are not a Member of our
                                                                    Community, we still urge you to call us should you
                                                                    see any Disruptive Behavior and we thank you in
                                                                    advance for doing so. We may be reached at
                                                                    833-I-Fly-Inn or 321-I-Fly-Inn.
                                                                </p>
                                                                {/* /wp:paragraph */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* .entry-content */}
                                    </article>
                                    {/* #post-## */}{" "}
                                </div>
                            </div>
                            {/* grid-listing-page */}
                        </div>
                    </div>
                    {/* .row */}
                </div>{" "}
                {/* .container */}
            </section>

        </Layout>
    )
}

export default Neighborhood