import React from "react";
import { Link } from "react-router-dom";
import '../styles/DwellingImg.css';

const DwellingImg = () => {
  const listings = [
    {
      title: "Apartment, Condo, Loft",
      image: "images/all/homedwelling11.webp",
      link: "/map_listing/Apt_Condo_Loft/byDwelling",
    },

    {
      title: "Beachfront",
      image: "images/all/homedwelling1.webp",
      link: "/map_listing/beach_front/byDwelling",
    },
    {
      title: "Bed & Breakfast",
      image: "images/all/homedwelling2.webp",
      link: "/map_listing/bed_breakfast/byDwelling",
    },
    {
      title: "Cabin",
      image: "images/all/homedwelling3.webp",
      link: "/map_listing/cabin/byDwelling",
    },
    {
      title: "Campsite",
      image: "images/all/homedwelling4.webp",
      link: "/map_listing/campsite/byDwelling",
    },

    {
      title: "Farm",
      image: "images/all/homedwelling6.webp",
      link: "/map_listing/farm/byDwelling",
    },
    {
      title: "Hangar",
      image: "images/all/homedwelling7.webp",
      link: "/map_listing/hangar/byDwelling",
    },
    {
      title: "Hangar Home",
      image: "images/all/homedwelling8.webp",
      link: "/map_listing/hangar_home/byDwelling",
    },
    {
      title: "House",
      image: "images/all/homedwelling9.webp",
      link: "/map_listing/house/byDwelling",
    },
    {
      title: "Lakefront",
      image: "images/all/homedwelling10.webp",
      link: "/map_listing/lake_front/byDwelling",
    },

    {
      title: "Mansion",
      image: "images/all/homedwelling12.webp",
      link: "/map_listing/mansion/byDwelling",
    },
    {
      title: "RV",
      image: "images/all/rv.png",
      link: "/map_listing/rv/byDwelling",
    },
    {
      title: "RV Pad",
      image: "images/all/rvpad.png",
      link: "/map_listing/rv_pad/byDwelling",
    },
    {
      title: "Tiny Home",
      image: "images/all/homedwelling14.webp",
      link: "/map_listing/tiny_home/byDwelling",
    },
  ];


  return (
    <div className="bg-[#f6f8fa] py-20">
      <h2 className="text-center text-2xl font-bold my-20">
        Browse by Lodging Type
      </h2>
      <div className="container mx-auto">
        <div className="grid md:grid-cols-4 gap-4 px-4">
          {listings?.map((e) => (
            <Link to={e?.link}>
              <div className="relative card overflow-hidden rounded-md">
                <img
                  src={`${e?.image}`}
                  alt=""
                  className="rounded-md transition-transform duration-300 ease-in-out transform hover:scale-105"
                />
                <div className="absolute left-0 bottom-0 right-0 z-20 text-center bg-gradient-to-t from-black to-transparent" style={{ height: '30%' }}>
                  <div className="p-4">
                    <h3 className="text-white taxonomy-title">{e?.title}</h3>
                  </div>
                </div>
              </div>
            </Link>

          ))}
        </div>
      </div>

    </div>
  );
};

export default DwellingImg;
