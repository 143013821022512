import React, { useRef } from "react";
import JoditEditor from "jodit-react";
import { useFormContext } from "../../../../providers";

export const TextEditor = ({ field }) => {
    const editor = useRef(null);

    const { setValue, getValues, errors } = useFormContext();

    const config = {
        readonly: false,
        placeholder: "Start typing...",
        beautifyHTML: true, // Automatically beautifies HTML for better indentation
        enter: "P", // Configures the Enter key to create a <p> tag
        toolbarAdaptive: false,
        useSearch: true, // Enable search in code mode
        allowResizeX: true,
        allowResizeY: true,
        showXPathInStatusbar: false, // Hides unnecessary info in the status bar
        askBeforePasteHTML: false,
        askBeforePasteFromWord: true,
        defaultActionOnPaste: "insert_as_html",
        removeButtons: [
            "print",
            "font",
            "fontsize",
            "brush",
            "image",
            "file",
            "video",
            "about",
            "className",
            "paint",
            "lineHeight",
            "classSpan",
            "spellcheck",
            "copyformat",
            "ai-assistant",
            "ai-commands",
            'source'
        ],
        defaultActionOnEnter: "enter", // Use default behavior for new paragraphs
        events: {
            afterEnter: () => {
                console.log("Paragraph added after pressing Enter.");
            },
        },
        beforeCommand: (command) => {
            if (command === "source") {
                const content = editor.current.getEditorValue();
                setValue(field, content); // Sync changes when toggling modes
            }
        },
    };

    const handleEditorBlur = (newContent) => {
        // if (!newContent.trim() || newContent === "<p><br></p>") {
        //   setEditorError("This field is required.");
        // } else {
        //   setEditorError(""); // Clear error if content is valid
        // }

        setValue(field, newContent); // Pass content to parent component
    };

    return (
        <div className="editor_our">
            <JoditEditor
                ref={editor}
                value={getValues(field)}
                config={config}
                tabIndex={1}
                onBlur={handleEditorBlur} // Handle blur
            />
            {/* {errors && <span style={{ color: "red" }}>{errors[`${field}`].message}</span>} */}
        </div>
    );
};
