/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
// import Header from "../Header";
// import Footer from "../Footer";
import { BsTrash } from "react-icons/bs";
import { fetchData, ThemeContext } from "../../context/ContextFile";
import axios from "axios";
import BASE_URL from "../auth/Baseurl";
import { useNavigate } from "react-router-dom";
import { BtnLoader1 } from "../BtnLoader/BtnLoader";
import TailwindHeader from "./TailwindHeader";
import TopHeader from "./TopHeader";
import TailwindFooter from "./TailwindFooter";
const Layout = ({ children }) => {
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const {
    filterListing,
    setFilterListing,
    setCompareData,
    setShuffledDatas,
    shuffledDatas,
  } = useContext(ThemeContext);
  const filter = filterListing?.filter((fil) => fil?.is_compare === 0);

  console.log(filterListing, "filterListingPage");
  console.log(filter, "filter");

  const handleClick = async (id, currentCompare) => {
    setLoad(true);
    try {
      const response = await axios.get(`${BASE_URL}/listing-compare/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setLoad(false);
      if (response.status === 200) {
        setFilterListing((prevList) =>
          prevList.map((item) =>
            item.id === id
              ? { ...item, is_compare: currentCompare === 0 ? 1 : 0 }
              : item
          )
        );
      }
      fetchData().then((res) => {
        const shuffledIds = shuffledDatas?.map((item) => item.id);
        const shuffledArray = shuffledIds?.map((id) =>
          res?.data?.data?.find((item) => item.id === id)
        );
        setShuffledDatas(shuffledArray);
      });
    } catch (error) {
      console.error("Error making API call:", error);
    }
  };
  const ComparePost = async () => {
    setLoad(true);
    const formdata = new FormData();
    filter.forEach((item) => {
      if (item?.id) {
        formdata.append("listing_id[]", item.id);
      }
    });
    try {
      const response = await axios.post(
        `${BASE_URL}/listing-compare`,
        formdata,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setLoad(false);
      setCompareData(response?.data?.data);
      document.getElementById("UniqueMenuToggle").click();
      navigate("/compare");
    } catch (error) {
      console.error("Error making API call:", error);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [window.location.pathname]);

  return (
    <>
      <div>
        <TopHeader />
        <TailwindHeader />
        {/* <Header /> */}
        <>
          <input type="checkbox" id="UniqueMenuToggle" />
          <aside className="unique-sidebar">
            <nav>
              <h2>Stays</h2>
              <div className="listing-item-grid_container fl-wrap">
                <div
                  style={{ marginLeft: "0px", marginRight: "0px" }}
                  className="row"
                >
                  {load ? (
                    <BtnLoader1 />
                  ) : (
                    <>
                      {filter?.map((e) => (
                        <div className="col-sm-6">
                          <div
                            style={{ padding: "60px 0px" }}
                            className="listing-item-grid"
                          >
                            <div
                              className="bg"
                              style={{
                                backgroundImage: `url(${
                                  e?.listing_images[0]?.image || ""
                                })`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                              }}
                            />
                            <div className="d-gr-sec" />
                            <div
                              style={{
                                left: "70px",
                                right: "0px",
                                bottom: "10px",
                                backgroundColor: "black",
                              }}
                              className="listing-item-grid_title"
                            >
                              <BsTrash
                                style={{ color: "white" }}
                                onClick={() => handleClick(e.id, e.is_compare)}
                                size={25}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>

              <button
                onClick={ComparePost}
                className="logout_btn color2-bg"
                type="submit"
                style={{
                  width: 200,
                  color: "rgb(255, 255, 255)",
                  backgroundColor: "#8ec639 ",
                  fontSize: 14,
                  fontWeight: "bold",
                  fontFamily: "Quicksand",
                }}
              >
                Compare
              </button>
              <br />
              <h2> Compare experiences</h2>
              <button
                onClick={() => navigate("/listing")}
                className="logout_btn color2-bg"
                type="submit"
                style={{
                  width: 200,
                  color: "",
                  backgroundColor: "#8ec639",
                  fontSize: 14,
                  fontWeight: "bold",
                  fontFamily: "Quicksand",
                }}
              >
                Compare
              </button>

              <button
                onClick={() =>
                  document.getElementById("UniqueMenuToggle").click()
                }
                className="logout_btn color2-bg"
                type="submit"
                style={{
                  width: 200,
                  color: "gray",
                  backgroundColor: "white",
                  fontSize: 14,
                  fontWeight: "bold",
                  border: "1px solid gray",
                  fontFamily: "Quicksand",
                }}
              >
                Close
              </button>
            </nav>
          </aside>
          <main className="unique-right">
            <label htmlFor="UniqueMenuToggle" className="unique-toggle-icon">
              <span className="unique-line unique-line1" />
              <span className="unique-line unique-line3" />
              <span className="unique-line unique-line2" />
            </label>
            <div className="unique-content">{children}</div>
          </main>
        </>
        <TailwindFooter />
        {/* <Footer /> */}
      </div>
    </>
  );
};

export default Layout;
