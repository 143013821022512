import React from "react";
import Layout from "../../Components/layout/Layout";
import { useLocation } from "react-router-dom";

const Mostpopular = () => {
  const { state } = useLocation();
  console.log(state?.data, "state");

  
  return (
    <Layout>
      <div className="content">
        <section id="sec1" data-scrollax-parent="true">
          <div className="container">
            <div className="section-title">
              <h2> {state?.data?.sub_heading}</h2>
              <div className="section-subtitle">who we are</div>
              <span className="section-separator" />

            </div>
            {/*about-wrap */}
            <div className="about-wrap">
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="list-single-main-media fl-wrap"
                    style={{ boxShadow: "0 9px 26px rgba(58, 87, 135, 0.2)" }}
                  >
                    <p style={{ fontSize: "15px", textAlign: "center" }}>
                      <div
                        dangerouslySetInnerHTML={{ __html: state?.data?.description }}
                      />
                    </p>
                  </div>
                </div>

              </div>
            </div>



            {/* about-wrap end  */}
            <span className="fw-separator" />
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Mostpopular;
