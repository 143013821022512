import React from "react";
import { Link } from "react-router-dom";
import { MdOutlineBedroomParent, MdGroup } from "react-icons/md";
import { FaShower } from "react-icons/fa";
import "../../styles/ListOverlayChild.css";

const ListingOverlayChild = ({ e, handleFav, handleCompare, handleRemove }) => {
  return (
    <div className="geodir-category-img my-4 card-container rounded-md overflow-hidden">
      <Link
        to={`/details/${e.id}`}
        className="geodir-category-img-wrap fl-wrap rounded-md"
      >
        <img
          style={{
            // width: "300px",
            height: "250px"
          }}
          className="img"
          src={e?.listing_images[0]?.image}
          alt=""
        />
      </Link>

      <div
        onClick={() => handleFav(e.id)}
        className={
          e?.is_favourite === 1
            ? "geodir-js-favorite_btn1"
            : "geodir-js-favorite_btn"
        }
      >
        <i style={{ fontSize: "20px" }} className="fal fa-heart" />
        <span>{e?.is_favourite === 1 ? "saved" : "unsaved"}</span>
      </div>

      <div
        style={{
          position: "absolute",
          right: "12px",
          top: "12px"
        }}
      >
        <img
          src={e?.host?.image}
          alt=""
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      </div>

      <div
        style={{
          position: "absolute",
          bottom: 0,
          zIndex: 10,
          padding: "10px 20px",
          textAlign: "left",
          width: "100%",
        }}
      >
        <h3 className="title-sin_map item-price">
          <Link
            to={`/details/${e.id}`}
            style={{ color: "white" }}
            className="flex"
          >
            <div className="flex items-center item-price">
              <sup className="text-white">$ </sup>
              <span className="font-bold text-white">{e?.additional_guest_price} </span>

              <span className="text-sm mt-2 text-white ">/Night</span>
            </div>
          </Link>
        </h3>
        <Link to={`/details/${e.id}`} className="text-white text-wrap listing-title">
          {e?.title}
        </Link>
        <div
          className="geodir-category-location d-flex justify-between items-start flex-wrap mb-3"
        >
          <div className="item-amenities">
            <Link
              to={`/details/${e.id}`}
              className="d-flex items-center mx-1"
            >
              <MdOutlineBedroomParent size={18} color="white" />
              <p className="text-white">{e?.no_of_bedroom}</p>
            </Link>
            <Link
              to={`/details/${e.id}`}
              className="d-flex items-center mx-1"
            >
              <FaShower size={18} color="white" />
              <p className="text-white">{e?.no_of_bathroom}</p>
            </Link>
            <Link
              to={`/details/${e.id}`}
              className="d-flex items-center mx-1"
            >
              <MdGroup size={18} color="white" />
              <p className="text-white">{e?.no_of_guest}</p>
            </Link>
          </div>
          {e.is_compare === 0 ? (
            <div
              onClick={() => handleRemove(e.id, e.is_compare)}
              className="btn btn-secondary"
              style={{ padding: "6px 7px" }}
            >
              Remove
            </div>
          ) : (
            <div
              onClick={() => handleCompare(e.id, e.is_compare)}
              className="btn btn-secondary"
              style={{ padding: "6px 7px" }}
            >
              Compare
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ListingOverlayChild;