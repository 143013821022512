import React from 'react'
import Layout from '../Components/layout/Layout'
import { Link } from 'react-router-dom'

const CirtPolicy = () => {
  return (
    <>
    <Layout>
    <section style={{marginTop:"100px",backgroundColor:"#f6f8fa"}} className="main-content-area">
  <div className="container">
    <div className="row">
      <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9">
        <div className="page-title">
          <div className="block-top-title">
            <ol className="breadcrumb">
              <li>
                <Link to="/">
                  <span>Home</span>
                </Link>
              </li>
              <li className="active">Fair Housing Policy</li>
            </ol>{" "}
            <h1 className="">Fair Housing Policy</h1>
          </div>
          {/* block-top-title */}
        </div>
        {/* page-title */}
      </div>
    </div>
    {/* .row */}
  </div>
  {/* .container */}
  <div className="container">
    <div className="row">
      <div style={{backgroundColor:"white",padding:"10px"}} className=" col-sm-12 col-md-12 col-lg-12 inner-static">
        <div className="page-wrap">
          <div className="article-main">
            <article
              id="post-8715"
              className="single-page-article block post-8715 page type-page status-publish hentry"
            >
              <div className="article-detail block-body">
                <div
                  data-elementor-type="wp-page"
                  data-elementor-id={8715}
                  className="elementor elementor-8715"
                  data-elementor-post-type="page"
                >
                  <div
                    className="elementor-element elementor-element-3a71b800 e-flex e-con-boxed e-con e-parent"
                    data-id="3a71b800"
                    data-element_type="container"
                  >
                    <div className="e-con-inner">
                      <div
                        className="elementor-element elementor-element-54e43d8c elementor-widget elementor-widget-text-editor"
                        data-id="54e43d8c"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div className="elementor-widget-container">
                          <style
                            dangerouslySetInnerHTML={{
                              __html:
                                "/*! elementor - v3.21.0 - 18-04-2024 */\n.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap{background-color:#69727d;color:#fff}.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap{color:#69727d;border:3px solid;background-color:transparent}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap{margin-top:8px}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter{width:1em;height:1em}.elementor-widget-text-editor .elementor-drop-cap{float:left;text-align:center;line-height:1;font-size:50px}.elementor-widget-text-editor .elementor-drop-cap-letter{display:inline-block}"
                            }}
                          />{" "}
                          <p>{/* wp:paragraph */}</p>
                          <p>
                            Our Fair Housing Policy goes hand in hand with our
                            CIRT Policy.
                            <br />
                            Our&nbsp;
                            <Link
                              to="/cirt-policy"
                              target="_blank"
                            >
                              CIRT Policy
                            </Link>
                            &nbsp;states that
                            <br />
                            <u>
                              <b> C</b>ommon Sense
                            </u>{" "}
                            in creating rules leads to
                            <br />
                            <u>
                              <b> I</b>ntegrity
                            </u>{" "}
                            in following those rules which leads to
                            <br />
                            <u>
                              <b> R</b>espect
                            </u>{" "}
                            toward Members which leads to
                            <br />
                            <u>
                              <b> T</b>rust
                            </u>{" "}
                            in the Community.
                          </p>
                          <p>{/* /wp:paragraph */}</p>
                          <p>{/* wp:paragraph */}</p>
                          <p>
                            Some of the most Common Sense rules in our community
                            are those of inclusion for our fellow Members. One
                            of the things we value most as Members of this
                            Community is the opportunity to meet and make
                            friends with fellow aviation enthusiasts. Our
                            fellows come from all different backgrounds and we
                            wouldn’t have a real Community if we didn’t all
                            welcome each other. We are here to respect each
                            other, treat each other fairly, and be good Members
                            of our Community.
                          </p>
                          <p>{/* /wp:paragraph */}</p>
                          <p>{/* wp:paragraph */}</p>
                          <p>
                            We take the opportunity here to introduce the Fair
                            Housing Act of 1968. The purpose of this Act is to
                            prohibit discrimination when it comes to the
                            financing, sale, and rental of housing based on six
                            protected classes which are race, religion, national
                            origin, sex, handicap, and familial status. Though
                            most of the rentals that happen on our site are
                            transitory, we still adopt this policy of inclusion
                            for our Community and you must conduct your business
                            on the Platform following it. By using the Platform
                            you are demonstrating that you agree to this Policy.
                            You hereby agree to familiarize yourself fully with
                            the&nbsp;
                            <Link
                              to="https://www.justice.gov/crt/fair-housing-act-1"
                              target="_blank"
                              rel="noopener"
                            >
                              Fair Housing Act
                            </Link>
                            &nbsp;which can be found on the&nbsp;
                            <Link
                              to="https://www.justice.gov/crt/fair-housing-act-1"
                              target="_blank"
                              rel="noopener"
                            >
                              Department of Justice’s website
                            </Link>
                            , and with any other state or local Fair Housing
                            laws that may apply to your Listing. You agree to
                            proactively search which laws apply to you or email
                            us for assistance finding any&nbsp;information, at
                            help@fly-inn.com with the subject line reading,
                            “Fair Housing.”
                          </p>
                          <p>
                            Ask your lawyer for assistance interpreting the Fair
                            Housing Act of 1968 and any other state or local
                            Fair Housing laws that may apply to your Listing. We
                            cannot offer legal advice.
                          </p>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* .entry-content */}
            </article>
            {/* #post-## */}{" "}
          </div>
        </div>
        {/* grid-listing-page */}
      </div>
    </div>
    {/* .row */}
  </div>{" "}
  {/* .container */}
</section>


    </Layout>
    </>
  )
}

export default CirtPolicy
