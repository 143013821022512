import React from "react";
import Message from "../Components/MessagesAll";
import SidebarLayout from "../Components/sidebar/SidebarLayout";

function Messages() {
  return (
    <>
      <SidebarLayout>
        <Message />
      </SidebarLayout>
    </>
  );
}

export default Messages;
