/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Layout from "../Components/layout/Layout";
import { Link, useLocation } from "react-router-dom";

const Squawks = () => {
  const { state } = useLocation();
  const parser = new DOMParser();
  const doc = parser.parseFromString(state?.description, "text/html");
  const paragraphs = doc.querySelectorAll("p");
  const paragraphTexts = Array?.from(paragraphs)?.map((p) => p?.innerText)?.join(" ");
  return (
    <>
      <Layout>
        <div id="wrapper">
          {/* Content*/}
          <div className="content">
            <div className="azp_element azp-element-azp-zvmeb6m1tr azp_row_section azp_row_section-default azp_row_section-15-gap pad-bot-50">
              <div className="azp_container">
                <div className="azp_row azp_row-wrap">
                  <div className="azp_element azp-element-azp-c1r6whtz4ki azp_col azp-col-66 lcontent-content">
                    <div
                      className="azp_element ldescription azp-element-azp-se6qk9apvyj authplan-hide-false row"
                      id="details_sec"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div className="for-hide-on-author" />
                      {/* lsingle-block-box */}
                      <div className="lsingle-block-box col-md-7">
                        <div className="ab_text-title fl-wrap">
                          <h1
                            style={{
                              fontSize: "40px",
                              marginBottom: "12px",
                            }}
                          >
                            {state?.title}
                          </h1>
                          <div className=" section-title-dk-blue fl-wrap">
                            <h2
                              style={{
                                textAlign: "center",
                                marginBottom: "12px",
                              }}
                            >
                              <span>
                                By FlyInn ·{state?.date} · Destinations, Stays
                              </span>
                            </h2>{" "}
                            <div
                              className="team-social"
                              style={{
                                marginBottom: "20px",
                                marginTop: "24px",
                              }}
                            >
                              <ul className="no-list-style">
                                <li>
                                  <Link to="https://www.facebook.com/FlyInnLLC/" target="_blank">
                                    <i className="fab fa-facebook-f" />
                                  </Link>
                                </li>
                                <li>
                                  <Link to="https://twitter.com/FlyInnLLC" target="_blank">
                                    <i className="fab fa-twitter" />
                                  </Link>
                                </li>
                                <li>
                                  <Link to="https://www.instagram.com/flyinnllc/" target="_blank">
                                    <i className="fab fa-instagram" />
                                  </Link>
                                </li>
                                <li>
                                  <Link to="https://www.youtube.com/@FLY-INN" target="_blank">
                                    <i className="fab fa-vk" />
                                  </Link>
                                </li>{" "}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="lsingle-block-content">
                          <h2
                            style={{
                              textAlign: "left",
                              marginBottom: "12px",
                            }}
                          >
                            <span>{state?.subTitle}</span>
                          </h2>{" "}
                          <p>{paragraphTexts}</p>
                        </div>
                      </div>
                      {/* lsingle-block-box end */}
                      <div
                        className="col-md-8"
                        style={{
                          marginTop: "20px",
                        }}
                      >
                        <img
                          style={{ borderRadius: "14px" }}
                          src={
                            state?.imgSrc
                              ? state?.imgSrc
                              : "https://news.airbnb.com/wp-content/uploads/sites/4/2024/05/415595-GettyImages-104187094-ForYou-1-edited.jpg?w=1440"
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="limit-box fl-wrap" />
          </div>
          {/* Content end */}
        </div>
      </Layout>
    </>
  );
};

export default Squawks;
