import React from "react";
import List from "../Components/List";
import SidebarLayout from "../Components/sidebar/SidebarLayout";

const MyListing = () => {
  return (
    <>
      <SidebarLayout>
        <div className="relative top-16 sm:top-16 md:top-14 ls:top-[unset]">
          <List />
        </div>
      </SidebarLayout>
    </>
  );
};

export default MyListing;
