import React, { useState } from "react";
import { DateRange } from "react-date-range";
import moment from "moment";
import "react-date-range/dist/styles.css"; // Main style file
import "react-date-range/dist/theme/default.css"; // Theme CSS file
import { useFormContext } from "../../../../providers";

export const CustomDateSelectionCalender = () => {
    const { setValue, watch } = useFormContext();

    const blocked_dates = watch('blocked_dates') || [];

    const [range, setRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        },
    ]);

    // Function to check if a date is disabled
    const isDateDisabled = (date) => {
        return blocked_dates.some(({ start_date, end_date }) =>
            moment(date).isBetween(moment(start_date), moment(end_date), "day", "[]")
        );
    };

    // Handle date range selection

    const handleDateChange = (item) => {
        const newRange = item.selection;
        setRange([newRange]);

        if (newRange.startDate && newRange.endDate && newRange.startDate !== newRange.endDate) {
            const startDate = moment(newRange.startDate).format("YYYY-MM-DD");
            const endDate = moment(newRange.endDate).format("YYYY-MM-DD");
            setValue('blocked_dates', [
                ...blocked_dates,
                {
                    start_date: startDate,
                    end_date: endDate,
                },
            ]);
        }
    };

    // Render day with custom styling
    const renderDayContents = (day) => {
        const date = moment(day);
        const isDisabled = isDateDisabled(day);
        const statusStyles = {
            available: "bg-white text-gray-800",
            disabled: "bg-red-200 text-gray-500 pointer-events-none",
        };

        let style = isDisabled ? statusStyles.disabled : statusStyles.available;

        return (
            <div className={`w-full h-full flex items-center justify-center rounded-lg ${style}`}>
                {date.date()}
            </div>
        );
    };

    return (
        <>
            <div className="w-full border border-gray-300 rounded-lg p-4">
                <DateRange
                    ranges={range}
                    onChange={handleDateChange}
                    showDateDisplay={false}
                    months={2}
                    direction="horizontal"
                    dayContentRenderer={renderDayContents}
                    moveRangeOnFirstSelection={false}
                    rangeColors={["#54C4D9"]} // Highlight for selected range
                />
            </div>
        </>
    );
};