import React, { createContext, useState } from 'react';

// Create the context
export const DropdownContext = createContext();

// Create the provider component
export const DropdownProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  return (
    <DropdownContext.Provider value={{ open, setOpen, open1, setOpen1, open2, setOpen2 }}>
      {children}
    </DropdownContext.Provider>
  );
};
