// FormProvider.jsx
import React, { createContext, useCallback, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
import axios from 'axios';
import BASE_URL from '../../Components/auth/Baseurl';

// Create the context
const FormContext = createContext();

// Custom hook to use the form context
const useFormContext = () => useContext(FormContext);

const FormProvider = ({ children, validationSchema, defaultValues }) => {
    // Initialize react-hook-form with Yup validation schema
    const [formData, setFormData] = useState(defaultValues);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        reset,
        watch,
        getValues
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: formData,
    });

    const setMultipleValues = (values) => {
        Object.entries(values).forEach(([key, value]) => {
            setValue(key, value);  // Sets each field using setValue
        });
    };


    // Generalized handleChange function
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type.toString() === 'checkbox') {
            setFormData((prevData) => ({
                ...prevData,
                [name]: checked,
            }));
            setValue(name, checked);
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }));

            setValue(name, value);
        }
    };

    // Handle form submission
    const onSubmit = useCallback(async (data, event) => {
        try {
            event.preventDefault();
            // Create a new FormData object
            const finalData = new FormData();

            const updatedData = { ...data, host_id: JSON.parse(localStorage.getItem('data'))?.id }

            // Populate the FormData object with the key-value pairs from data
            for (const key in updatedData) {
                if (updatedData.hasOwnProperty(key)) {
                    finalData.append(key, updatedData[key]);
                }
            }

            // Example of logging FormData entries
            for (const [key, value] of finalData.entries()) {
                console.log(`${key}: ${value}`);
            }


            // -----------------------------------------------------------------------------------------------------------


            try {
                await axios.post(`${BASE_URL}/listing`, finalData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });

                Swal.fire({
                    position: "center",
                    html: `
                      <div style="display: flex; flex-direction: column; align-items: center;">
                            <svg
                                viewBox="0 0 1024 1024"
                                height="4em"
                                width="4em"
                                style="margin-bottom: 10px;"
                            >
                                <!-- Outer circle with color #8ec639 -->
                                <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                                
                                <!-- Check mark with color #A2c66b -->
                                <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
                            </svg>
                          <p style="margin: 0; font-size: 1.2em;">Profile update successful.</p>
                      </div>
                  `,
                    title:
                        "Your changes have been submitted to Admin. Please stand by for approval.",
                    showConfirmButton: true,
                    timer: 1500,
                });
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Please complete all required fields.",
                    text: error?.responsformData?.data?.message,
                });
            }

            // ------------------------------------------------------------------------------------------------------------

            setFormData(data);
            console.log('Submitted Data:', data);
        } catch (err) {
            console.log(err)
        }
    }, []);

    // const submitForm = useCallback((e) => {
    //     try {
    //         e.preventDefault();
    //         const allValues = getValues();

    //         console.log('All Form Values:', allValues);
    //         console.log('errors', errors)
    //     } catch (err) {
    //         console.log(err)
    //     }
    // }, [errors, getValues])

    // Handle draft saving (e.g., saving the form data to localStorage)
    const handleDraft = () => {
        localStorage.setItem('formDraft', JSON.stringify(formData));
        console.log('Draft saved:', formData);
    };

    // Context value to provide to children
    const contextValue = {
        register,
        handleSubmit: handleSubmit(onSubmit),
        handleChange,
        handleDraft,
        setValue,
        errors,
        formData,
        reset,
        watch,
        getValues,
        setMultipleValues
    };

    return <FormContext.Provider value={contextValue}>{children}</FormContext.Provider>;
};

export { FormProvider, useFormContext };
