import React from "react";
import { Button, Modal } from "react-bootstrap";

const BtnModalDiscount = ({ openDiscount, setOpenDiscount ,discountList,setDiscountList}) => {
    // State to handle the list of cloned fields
  

    // Handle closing the modal
    const handleClose = () => setOpenDiscount(false);

    // Handle adding a new set of fields
    const handleAddMore = () => {
        setDiscountList([...discountList, { name: "", value: ""}]);
    };

    // Handle removing a set of fields
    const handleRemove = (index) => {
        const updatedList = [...discountList];
        updatedList.splice(index, 1);
        setDiscountList(updatedList);
    };

    // Handle saving the fields to the state and closing the modal
    const handleSave = () => {
        console.log("Saved Data: ", discountList);
        // You can now process the discountList data, e.g., send it to an API
        handleClose(); // Close the modal after saving
    };

    // Handle input changes
    const handleInputChange = (index, field, value) => {
        const updatedList = [...discountList];
        updatedList[index][field] = value;
        setDiscountList(updatedList);
    };

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Modal style={{ overflowY: "hidden", overflowX: "hidden", paddingTop: "0px" }} show={openDiscount} onHide={handleClose} keyboard={false}>
                <div
                    className="modal-content specific-ul"
                    style={{
                        height: "480px",
                        overflowY: "scroll",
                        overflowX: "hidden"
                    }}
                >
                    <Modal.Header>
                        <Modal.Title>Extra Discount</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="profile-edit-container fl-wrap block_box">
                            <div className="custom-form">
                                {discountList.map((discount, index) => (
                                    <div key={index} className="row" style={{ marginBottom: "10px" }}>
                                        <div className="col-sm-6">
                                            <label>
                                                Discount Name <i className="far fa-envelope" />
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Discount Name"
                                                value={discount.name}
                                                onChange={(e) =>
                                                    handleInputChange(index, "name", e.target.value)
                                                }
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <label>
                                                Discount Value <i className="fal fa-user" />
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Discount Value"
                                                value={discount.value}
                                                onChange={(e) =>
                                                    handleInputChange(index, "value", e.target.value)
                                                }
                                            />
                                        </div>
                                     
                                        {discountList.length > 1 && (
                                            <div className="col-sm-12 " style={{ marginTop: "10px", display: "flex", justifyContent: "start", }}>
                                                <button
                                                    className="logout_btn12"

                                                    onClick={() => handleRemove(index)}
                                                >
                                                    Remove
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                ))}
                                <div className="col-sm-12 " style={{ marginTop: "10px", display: "flex", justifyContent: "end" }}>


                                    <Button variant="secondary" onClick={handleAddMore}>
                                        Add More
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ display: "flex", justifyContent: "space-between" }}>

                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <button    className="logout_btn_expense" onClick={handleSave}>
                            Save
                        </button>
                </Modal.Footer>
        </div>
            </Modal >
        </div >
    );
};

export default BtnModalDiscount;
