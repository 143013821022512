import React from "react";
import Layout from "../Components/layout/Layout";
import HeroSection from "../Components/HeroSection";

const Home = () => {
  return (
    <Layout>
      <HeroSection />
     
    </Layout>
  );
};

export default Home;
