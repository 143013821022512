/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Layout from "../Components/layout/Layout";
import { Link, useLocation } from "react-router-dom";
import "./detail.css";
import BASE_URL from "../Components/auth/Baseurl";
import axios from "axios";
import { MdGroup, MdOutlineBedroomParent } from "react-icons/md";
import { FaShower } from "react-icons/fa6";

const PicProfile = () => {
  const { state } = useLocation();


  const data = state?.data;
  const email = state?.email;
  console.log(data, "formdata");


  const [filterListing, setFilterListing] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/listing-user`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const filteration = response?.data?.data?.filter(
        (fil) => fil?.host?.email === email
      );
      console.log(response, "responsePIC");

      setFilterListing(filteration);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Layout>
      <section
        className="main-content-area"
        style={{ backgroundColor: "rgb(247, 248, 249)", paddingTop: "60px" }}
      >
        <div className="container">
          <div
            className="row"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              className="col-sm-12 col-md-12"
              style={{ backgroundColor: "rgb(255, 255, 255)", padding: 40 }}
            >
              <div className="page-wrap">
                <div className="article-main">
                  <article
                    id="post-7550"
                    className="single-page-article block post-7550 page type-page status-publish hentry"
                  >
                    <div className="block">
                      <div className="block-head">
                        <div className="media">
                          <div className="media-left">
                            <img
                              width={70}
                              height={70}
                              alt=""
                              src={data?.host?.image || data?.image}
                              className="img-circle media-object lazyloaded"
                            />
                          </div>
                          <div className="media-body">
                            <h2 className="title">
                              {data?.host?.username || data?.given_name}
                            </h2>
                            <ul className="list-inline profile-host-info">
                              <li>
                                <address>
                                  <i
                                    className="homey-icon homey-icon-style-two-pin-marker"
                                    aria-hidden="true"
                                  />{" "}
                                  {data?.street_address || data?.host?.street_address} {data?.country || data?.host?.country}
                                </address>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <h3 style={{ display: "flex", textAlign: "left", marginTop: "30px" }}>Bio</h3>
                      <p style={{ padding: "0px" }}>
                        {data?.bio || data?.host?.bio}
                      </p>
                      <div className="block-body" style={{ marginTop: "0px" }}>
                        <div className="row">

                          <div className="col-md-3 text-left">
                            <dl>
                              <dt>Native / Fluent Languages</dt>
                              <dd>{data?.native_language || data?.host?.native_language}</dd>
                            </dl>
                          </div>
                          <div className="col-md-3 text-left">
                            <dl>
                              <dt>Other Languages</dt>
                              <dd>{data?.other_language || data?.host?.other_language}</dd>
                            </dl>
                          </div>
                          <div className="col-md-3 text-left">
                            <dl>
                              <dt>Profile Status</dt>
                              <dd className="text-danger">
                                <i className="homey-icon homey-icon-uncheck-circle-1" />{" "}
                                {data?.host?.status || data?.status === 1
                                  ? "Verified"
                                  : "Not Verified"}
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <h2 className="mt-30 mb-30">Listings</h2>
            {filterListing?.map((e) => {
              return (
                <div className="gallery-item hotels col-md-3">
                  <div className="listing-item">
                    <article className="geodir-category-listing fl-wrap container">
                      <div className="geodir-category-img">
                        <Link
                          to={`/details/${e.id}`}
                          className="geodir-category-img-wrap fl-wrap"
                        >
                          <img
                            style={{
                              width: "300px",
                              height: "250px",
                            }}
                            src={e?.listing_images[0]?.image}
                            alt=""
                          />
                        </Link>
                        <div className="listing-price">
                          <h3
                            className="title-sin_map"
                            style={{ fontSize: "18px" }}
                          >
                            <Link
                              to={`/details/${e.id}`}
                              style={{ color: "white" }}
                            >
                              $ {e?.additional_guest_price}
                            </Link>
                          </h3>
                        </div>
                        <div className="geodir-category-opt">
                          <div className="listing-rating-count-wrap">
                            <div
                              className="listing-rating card-popup-rainingvis"
                              data-starrating2={5}
                            />
                            <h3
                              className="title-sin_map"
                              style={{ fontSize: "18px" }}
                            >
                              <Link
                                to={`/details/${e.id}`}
                                style={{ color: "white" }}
                              >
                                {e?.host?.first_name + " " + e?.address}
                              </Link>
                            </h3>
                          </div>
                        </div>
                        <div
                          className="geodir-category-location"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            position: "absolute",
                            bottom: "30px",
                            zIndex: 3,
                            width: "100%",
                            padding: "0px 10px",
                          }}
                        >
                          <Link
                            to={`/details/${e.id}`}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <MdOutlineBedroomParent size={20} color="white" />{" "}
                            <p className="text-white">{e?.no_of_bedrooms}</p>
                          </Link>
                          <Link
                            to={`/details/${e.id}`}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <FaShower size={20} color="white" />
                            <p className="text-white">{e?.no_of_bathroom}</p>
                          </Link>
                          <Link
                            to={`/details/${e.id}`}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <MdGroup size={20} color="white" />
                            <p className="text-white">{e?.no_of_guest}</p>
                          </Link>
                          <div
                            className="btn btn-primary cursor-pointer"
                            style={{ padding: "6px 10px" }}
                          >
                            Compare
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              );
            })}
          </div>
        </div>{" "}
      </section>
    </Layout>
  );
};

export default PicProfile;
