const labelIcons = {
    Title: "fal fa-id-badge",
    "Number of Guests (N)": "fal fa-user-friends",
    "Number of Bedrooms": "fal fa-bed",
    "Number of Beds (N)": "fal fa-bed",
    "Type of food": "fal fa-utensils",
    "Number of Bathrooms": "fal fa-toilet",
    "Number of Rooms": "fal fa-door-open",
    "Size (N)": "fal fa-ruler",
    "Nightly Price (N)": "fal fa-moon-stars", // Icon for nightly price (moon)
    "Apply Weekend Price": "fal fa-calendar-week", // Icon for apply weekend price (calendar week)
    "Weekend Nightly Price": "fal fa-sun", // Icon for weekend nightly price (sun)
    "Nightly Price (if 7+ nights)": "fal fa-calendar-alt", // Icon for 7+ nights (calendar-alt)
    "Nightly Price (if 30+ nights)": "fal fa-calendar-check", // Icon for 30+ nights (calendar-check)
    "Bedroom Name": "fal fa-bed", // Icon for bedroom name
    "Number of Guests": "fal fa-user-friends", // Icon for guests
    "Number of Beds": "fal fa-bed", // Icon for number of beds
    "Bed Type": "fal fa-couch", // Icon for bed type (couch is a good generic choice)
};

export const getIconClass = (label) => labelIcons[label] || "fal fa-question-circle";