import React from 'react'
import Layout from '../Components/layout/Layout'
import { Link } from 'react-router-dom'

const TradeMark = () => {
  return (
<Layout>
<section className="main-content-area">
  <div className="container">
    <div className="row">
      <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9">
        <div className="page-title">
          <div className="block-top-title">
            <ol className="breadcrumb">
              <li>
                <Link to="/">
                  <span>Home</span>
                </Link>
              </li>
              <li className="active">Trademark Policy</li>
            </ol>{" "}
            <h1 className="">Trademark Policy</h1>
          </div>
          {/* block-top-title */}
        </div>
        {/* page-title */}
      </div>
    </div>
    {/* .row */}
  </div>
  {/* .container */}
  <div className="container">
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 inner-static">
        <div className="page-wrap">
          <div className="article-main">
            <article
              id="post-7733"
              className="single-page-article block post-7733 page type-page status-publish hentry"
            >
              <div className="article-detail block-body">
                <div
                  data-elementor-type="wp-page"
                  data-elementor-id={7733}
                  className="elementor elementor-7733"
                  data-elementor-post-type="page"
                >
                  <div
                    className="elementor-element elementor-element-38c74140 e-flex e-con-boxed e-con e-parent"
                    data-id="38c74140"
                    data-element_type="container"
                  >
                    <div className="e-con-inner">
                      <div
                        className="elementor-element elementor-element-4acce0d0 elementor-widget elementor-widget-text-editor"
                        data-id="4acce0d0"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div className="elementor-widget-container">
                          <style
                            dangerouslySetInnerHTML={{
                              __html:
                                "/*! elementor - v3.21.0 - 18-04-2024 */\n.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap{background-color:#69727d;color:#fff}.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap{color:#69727d;border:3px solid;background-color:transparent}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap{margin-top:8px}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter{width:1em;height:1em}.elementor-widget-text-editor .elementor-drop-cap{float:left;text-align:center;line-height:1;font-size:50px}.elementor-widget-text-editor .elementor-drop-cap-letter{display:inline-block}"
                            }}
                          />{" "}
                          {/* wp:paragraph */}
                          <p>
                            This policy is part of our Terms of Service. By
                            using or accessing the Platform, you agree to our
                            Terms of Service as well as the rest of our
                            Policies. We reserve the right to amend this
                            Trademark Policy at any time including any
                            permissions contained herein.
                          </p>
                          {/* /wp:paragraph */}
                          {/* wp:heading */}
                          <h2 className="wp-block-heading">PURPOSE</h2>
                          {/* /wp:heading */}
                          {/* wp:paragraph */}
                          <p>
                            We support our tight-knit Community of aviation
                            enthusiasts. To keep our Community thriving, we need
                            to safeguard the proper use of our Trademarks. Our
                            Trademarks represent us and help maintain FlyInn’s
                            strong association with quality and dependability.
                            <br />
                            Our Trademarks symbolize our identity and our
                            reputation for quality and dependability and carry
                            with them the substantial goodwill generated by our
                            Community. Therefore, this policy sets out general
                            guidelines for using our trademarks to facilitate
                            and guarantee their correct use by others to prevent
                            confusion in the public.
                          </p>
                          {/* /wp:paragraph */}
                          {/* wp:heading */}
                          <h2 className="wp-block-heading">DEFINITIONS</h2>
                          {/* /wp:heading */}
                          {/* wp:paragraph */}
                          <p>
                            According to US Patent and Trademark law, a
                            trademark is “any word, phrase, symbol, design, or a
                            combination of these things that identifies your
                            goods or services. It is how customers recognize you
                            in the marketplace and distinguish you from your
                            competitors.” Accordingly, and for the purpose of
                            simplicity, FlyInn, LLC defines our Trademarks as
                            our names (FlyInn, LLC; FlyInn, Fly-Inn); our logos
                            (the red biplane with FLY-INN on the attitude
                            indicator and any part thereof); Squawks (blog);
                            AlphaHost and the AlphaHost Ribbon; as well as any
                            other identifiers and branding including, but not
                            limited to, our slogans, taglines, songs, sounds,
                            icons, packaging, color palette, mascots,
                            typography, design elements, voice, tone, designs,
                            graphics, videos, and specially designed photography
                            and presets, collectively, “Trademarks.”
                          </p>
                          {/* /wp:paragraph */}
                          {/* wp:heading */}
                          <h2 className="wp-block-heading">
                            What is not allowed:
                          </h2>
                          {/* /wp:heading */}
                          {/* wp:paragraph */}
                          <p />
                          <ol>
                            <li>
                              Using any identifiers that are confusingly similar
                              to FlyInn’s Trademarks
                            </li>
                            <li>
                              Using any of our Trademarks or any part of our
                              Trademarks (including style and design elements)
                              in combination with
                              <ol>
                                <li>
                                  Any of your trademarks, service marks or
                                  logos, or any part thereof.
                                </li>
                                <li>The name of your business</li>
                                <li>
                                  The name of your website or domain
                                  registration, second or third level domain
                                  names, such as “flyinnrentals.com”
                                </li>
                                <li>
                                  The names or handles of any of your social
                                  media accounts, profiles or aliases, such as
                                  “FlyInn Bob”
                                </li>
                                <li>
                                  Your business purpose, such as “Fly-Inn
                                  Cleaning Service”
                                </li>
                                <li>
                                  Any other generic, common or descriptive term
                                </li>
                              </ol>
                            </li>
                            <li>
                              Using our Trademarks as keywords, metatags,
                              triggers, hashtags, or any web link that doesn’t
                              resolve to https://fly-inn.com
                            </li>
                            <li>
                              Changing, modifying or altering any part of our
                              Trademarks or branding including, but not limited
                              to, hyphenating, abbreviating, shortening,
                              truncating or creating acronyms; adding or
                              deleting words; lengthening; altering designs;
                              altering colors, backgrounds, angles, or any other
                              quality; reducing to a size where the Trademark is
                              no longer easy to identify
                            </li>
                            <li>
                              Using our Trademarks as part of a larger mark
                            </li>
                            <li>
                              Using our Trademarks in plural or possessive forms
                            </li>
                            <li>Using variations of our Trademarks</li>
                            <li>
                              Using translations of our Trademarks into foreign
                              languages
                            </li>
                            <li>Using old versions of our Trademarks</li>
                            <li>
                              Using “FlyInn” or ”Fly-Inn” in lieu of “vacation
                              rental,” “short-term accommodations,” et al
                            </li>
                            <li>
                              Using “FlyInn” or ”Fly-Inn” in a way that suggests
                              to others that we are in a partnership or
                              sponsorship relationship, or that we are
                              affiliated with or endorse you, your business,
                              products or services, unless we have specifically
                              authorized it
                            </li>
                            <li>
                              Using our Trademarks in advertising that is false
                              or misleading, or carries a similar intention
                            </li>
                            <li>
                              Using our Trademarks to, in our sole discretion,
                              maliciously, illegally or unethically defame or
                              disparage FlyInn or our Community
                            </li>
                            <li>
                              Using our Trademarks in a way that they are
                              displayed more prominently than your own
                              trademarks including, but not limited to, your
                              names, product or service names
                            </li>
                            <li>
                              Using our Trademarks, especially word marks, more
                              frequently than necessary
                            </li>
                            <li>
                              Displaying our Trademarks in any manner that
                              violates any law, regulation, rule or ordinance
                            </li>
                            <li>
                              Copying or appropriating our trade dress
                              including, but not limited to, our colors and
                              color combinations; typography and fonts and
                              combinations thereof; icons and graphics; design
                              and layout; specially designed photography and
                              presets; and any other of our brand elements and
                              brand identity
                            </li>
                          </ol>
                          <p />
                          {/* /wp:paragraph */}
                          {/* wp:paragraph */}
                          <p>
                            If you would like permission to use our Trademarks,
                            please email us at legal@fly-inn.com. With the
                            exceptions outlined below, you may only use our
                            Trademarks with our express written consent. Any
                            consent granted will be predicated upon your
                            agreement to follow any the guidelines set forth in
                            this document as well as any further guidelines we
                            require regarding the use of our Trademarks and your
                            agreement to follow within a reasonable amount of
                            time future guidelines we may issue.
                            <br />
                            You may use FlyInn’s Trademarks to refer to our
                            company, product or service only if you follow the
                            guidelines established herein and if you use them in
                            a manner that is in line with maintaining the mark’s
                            value and goodwill and that is accurate, not
                            disparaging, not misleading and fair to FlyInn and
                            our Community.
                          </p>
                          {/* /wp:paragraph */}
                          {/* wp:heading */}
                          <h2 className="wp-block-heading">
                            What is allowed and/or expected:
                          </h2>
                          {/* /wp:heading */}
                          {/* wp:paragraph */}
                          <p />
                          <ol>
                            <li>
                              Using our name correctly. FlyInn, Fly-Inn, and
                              FlyInn, LLC are all appropriate
                            </li>
                            <li>
                              Using our Trademarks only as adjectives modifying
                              a generic name or noun. They may not be used as a
                              noun on their own or as a verb
                            </li>
                            <li>
                              Using our name to describe your relationship with
                              FlyInn, such as “FlyInn Host”
                            </li>
                            <li>
                              Using our name to talk about products or services
                              offered on the Platform, such as “FlyInn Listing,”
                              or “FlyInn Squawks”
                            </li>
                            <li>
                              Using the most current versions of our Trademarks
                              to identify and hyperlink to our home page,
                              https://fly-inn.com. No separate written
                              authorization is required
                            </li>
                            <li>
                              Using and displaying our attribution statement
                              prominently. It reads, “FlyInn, LLC’s [Mark] and
                              the [Logo name or description] are trademarks
                              and/or service marks of FlyInn, LLC.”
                            </li>
                          </ol>
                          <p />
                          {/* /wp:paragraph */}
                          {/* wp:paragraph */}
                          <p>
                            For users that register on our website (if any), we
                            also store the personal information they provide in
                            their user profile. All users can see, edit, or
                            delete their personal information at any time
                            (except they cannot change their username). Website
                            administrators can also see and edit that
                            information.
                          </p>
                          {/* /wp:paragraph */}
                          {/* wp:heading */}
                          <h2 className="wp-block-heading">COMMUNITY</h2>
                          {/* /wp:heading */}
                          {/* wp:paragraph */}
                          <p>
                            Maintaining our Trademark’s value and goodwill
                            benefits our whole Community. Please support your
                            Community by emailing us at legal@fly-inn.com if you
                            notice any violation of the guidelines set forth in
                            this Trademark Policy.{" "}
                          </p>
                          {/* /wp:paragraph */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* .entry-content */}
            </article>
            {/* #post-## */}{" "}
          </div>
        </div>
        {/* grid-listing-page */}
      </div>
    </div>
    {/* .row */}
  </div>{" "}
  {/* .container */}
</section>

</Layout>
  )
}

export default TradeMark