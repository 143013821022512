import React, { useEffect, useContext } from "react";
import Bannerfour from "./Bannerfour";
import LocationMap from "./map/LocationMap";
import DwellingImg from "./DwellingImg";
import { fetchData, ThemeContext } from "../context/ContextFile";
import Feature from "../Pages/Feature";
import FindUsImage from "./ui/FindUsImage";
function HeroSection() {
  let { setData, setFilterListing } = useContext(ThemeContext);
  const token = localStorage.getItem("token");
  useEffect(() => {
    fetchData().then((res) => {
      setData(res.data?.data);
      const filter = res.data?.data?.filter((fil) => fil?.is_compare === 0);
      setFilterListing(filter)
      console.log(res.data?.data, "res.data?.data");
    });
  }, [setData, setFilterListing, token]);
  return (

    <>


      <div>
        <LocationMap />
        <Feature />
        <DwellingImg />
        <FindUsImage />
        <Bannerfour />
      </div>


    </>
  );
}

export default HeroSection;
