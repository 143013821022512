import Layout from "../../Components/layout/Layout"

/* eslint-disable react/jsx-no-target-blank */
const  ShortTermRental=()=>{
  
    return(
        <>
        <Layout>
        <section className="main-content-area">
  <div className="container">
    <div className="row">
      <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9">
        <div className="page-title">
          <div className="block-top-title">
            <ol className="breadcrumb">
              <li>
                <a href="https://fly-inn.com/">
                  <span>Home</span>
                </a>
              </li>
              <li className="active">Short-Term Rental Insurance</li>
            </ol>{" "}
            <h1 className="">Short-Term Rental Insurance</h1>
          </div>
          {/* block-top-title */}
        </div>
        {/* page-title */}
      </div>
    </div>
    {/* .row */}
  </div>
  {/* .container */}
  <div className="container">
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 inner-static">
        <div className="page-wrap">
          <div className="article-main">
            <article
              id="post-8775"
              className="single-page-article block post-8775 page type-page status-publish hentry"
            >
              <div className="article-detail block-body">
                <div
                  data-elementor-type="wp-page"
                  data-elementor-id={8775}
                  className="elementor elementor-8775"
                  data-elementor-post-type="page"
                >
                  <div
                    className="elementor-element elementor-element-3a71b800 e-flex e-con-boxed e-con e-parent"
                    data-id="3a71b800"
                    data-element_type="container"
                  >
                    <div className="e-con-inner">
                      <div
                        className="elementor-element elementor-element-54e43d8c elementor-widget elementor-widget-text-editor"
                        data-id="54e43d8c"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div className="elementor-widget-container">
                          <style
                            dangerouslySetInnerHTML={{
                              __html:
                                "/*! elementor - v3.21.0 - 18-04-2024 */\n.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap{background-color:#69727d;color:#fff}.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap{color:#69727d;border:3px solid;background-color:transparent}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap{margin-top:8px}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter{width:1em;height:1em}.elementor-widget-text-editor .elementor-drop-cap{float:left;text-align:center;line-height:1;font-size:50px}.elementor-widget-text-editor .elementor-drop-cap-letter{display:inline-block}"
                            }}
                          />{" "}
                          <p>{/* wp:paragraph */}</p>
                          <p>
                            As part of our Terms of Service, Hosts must carry
                            short-term rental insurance (also known as
                            home-sharing insurance).
                          </p>
                          <p>
                            The service is offered by many companies. Below is a
                            comprehensive list of all companies in the United
                            States (known to us as of the date of our Terms of
                            Service) that offer this type of insurance.
                          </p>
                          <p>
                            This is a comprehensive list, in alphabetical order.
                            We do not endorse nor are we affiliated with any of
                            the companies listed below in any way.
                          </p>
                          <ul>
                            <li>
                              <a
                                href="https://www.allstate.com/home-insurance/host-advantage"
                                target="_blank"
                                rel="noopener"
                              >
                                Allstate
                              </a>
                            </li>
                            <li>
                              <span
                                style={{
                                  letterSpacing: 0,
                                  textAlign: "var(--text-align)"
                                }}
                              >
                                <a
                                  href="https://www.amfam.com/insurance/home/coverages/short-term-rental"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  American Family&nbsp;&nbsp;
                                </a>
                              </span>
                            </li>
                            <li>
                              <span
                                style={{
                                  letterSpacing: 0,
                                  textAlign: "var(--text-align)"
                                }}
                              >
                                <a
                                  href="https://amig.com/insurance/rental-property/"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  American Modern&nbsp;
                                </a>
                              </span>
                            </li>
                            <li>
                              <span
                                style={{
                                  letterSpacing: 0,
                                  textAlign: "var(--text-align)"
                                }}
                              >
                                <a
                                  href="https://www.anderson.insure/short-term-rentals/"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  Anderson&nbsp;
                                </a>
                              </span>
                            </li>
                            <li>
                              <span
                                style={{
                                  letterSpacing: 0,
                                  textAlign: "var(--text-align)"
                                }}
                              >
                                <a
                                  href="https://www2.cbiz.com/quote-landing-page2"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  Cbiz&nbsp;
                                </a>
                              </span>
                            </li>
                            <li>
                              <span
                                style={{
                                  letterSpacing: 0,
                                  textAlign: "var(--text-align)"
                                }}
                              >
                                <a
                                  href="https://www.erieinsurance.com/blog/short-term-rental-insurance"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  Erie&nbsp;
                                </a>
                              </span>
                            </li>
                            <li>
                              <span
                                style={{
                                  letterSpacing: 0,
                                  textAlign: "var(--text-align)"
                                }}
                              >
                                <a
                                  href="https://www.farmers.com/home/seasonal-vacation-homes/"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  Farmers&nbsp;
                                </a>
                              </span>
                            </li>
                            <li>
                              <span
                                style={{
                                  letterSpacing: 0,
                                  textAlign: "var(--text-align)"
                                }}
                              >
                                <a
                                  href="https://fudgeinsurance.com/short-term-rental-insurance/"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  Fudge&nbsp;
                                </a>
                              </span>
                            </li>
                            <li>
                              <span
                                style={{
                                  letterSpacing: 0,
                                  textAlign: "var(--text-align)"
                                }}
                              >
                                <a
                                  href="https://www.insuraguest.com/"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  Insuraguest&nbsp;
                                </a>
                              </span>
                            </li>
                            <li>
                              <span
                                style={{
                                  letterSpacing: 0,
                                  textAlign: "var(--text-align)"
                                }}
                              >
                                <a
                                  href="https://nreig.com/tag/short-term-rental/"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  NREIG&nbsp;
                                </a>
                              </span>
                            </li>
                            <li>
                              <span
                                style={{
                                  letterSpacing: 0,
                                  textAlign: "var(--text-align)"
                                }}
                              >
                                <a
                                  href="https://www.proper.insure/"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  Proper&nbsp;
                                </a>
                              </span>
                            </li>
                            <li>
                              <span
                                style={{
                                  letterSpacing: 0,
                                  textAlign: "var(--text-align)"
                                }}
                              >
                                <a
                                  href="https://www.usaa.com/inet/wc/insurance-home-rental-home?akredirect=true"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  USAA&nbsp;
                                </a>
                              </span>
                            </li>
                            <li>
                              <span
                                style={{
                                  textAlign: "var(--text-align)",
                                  letterSpacing: 0
                                }}
                              >
                                <a
                                  href="https://www.steadily.com/get-a-quote/short-term-rental-insurance?utm_source=bing&utm_medium=paid_cpc&utm_content=kwd-85007244921309:loc-190&utm_term=short%20term%20rental%20insurance&adgroupid=1360097542830891&campaignid=424381626&utm_campaign=short_term_rental&msclkid=7f02cbc7579c1068c2c7ae10c2191b09"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  Steadily&nbsp;
                                </a>
                              </span>
                            </li>
                          </ul>
                          <p>
                            Let us know if you become aware of any other
                            companies offering this service. You may use our
                            contact form on our Contact Page or send us an email
                            to PIC@fly-inn.com and write “Suggestions” in the
                            subject line.
                          </p>
                          <p>
                            Please consult with your lawyer and accountant to
                            see which options suit your situation best. FlyInn
                            assumes no responsibility for property damage,
                            bodily injury, or any other loss of any sort.
                          </p>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* .entry-content */}
            </article>
            {/* #post-## */}{" "}
          </div>
        </div>
        {/* grid-listing-page */}
      </div>
    </div>
    {/* .row */}
  </div>{" "}
  {/* .container */}
</section>
</Layout>

        </>
    )
}

export default ShortTermRental