import React from 'react'
import { Link } from 'react-router-dom'

function BannerDashboard() {
  const data = JSON.parse(localStorage.getItem("data")) 
  console.log(data,"data");
  return (
    <section
      className="parallax-section dashboard-header-sec"
      data-scrollax-parent="true"
    >
      <div className="container">
        <div className="dashboard-breadcrumbs breadcrumbs text-black">
          <Link className='text-black' to="/">Home</Link> 
          
        </div>
        {/*Tariff Plan menu*/}
   
        {/*Tariff Plan menu end*/}
        <div className="dashboard-header_conatiner fl-wrap dashboard-header_title">
          <h1 className='text-black'>
            Welcome : <span>{data?.first_name}</span>
          </h1>
        </div>
      </div>
      <div className="clearfix" />
      <div className="dashboard-header fl-wrap">
        <div className="container">
          <div className="dashboard-header_conatiner fl-wrap">
            <div className="dashboard-header-avatar">
              <img src={data?.image} alt="" />
              <Link 
                to="/profile"
                className="color-bg edit-prof_btn"
              >
                <i className="fal fa-edit" />
              </Link> 
            </div>
            <div className="dashboard-header-stats-wrap">
              <div className="dashboard-header-stats">
                <div className="swiper-container">
                  <div className="swiper-wrapper">
                    {/*  dashboard-header-stats-item */}
                  
                  
                    {/*  dashboard-header-stats-item end */}
                   
                    {/*  dashboard-header-stats-item end */}
                  </div>
                </div>
              </div>
              {/*  dashboard-header-stats  end */}
         
            </div>
            {/*  dashboard-header-stats-wrap end */}
            <Link to={"/addlisting"}  className="add_new-dashboard">
              Add Listing <i className="fal fa-layer-plus" />
            </Link> 
          </div>
        </div>
      </div>
   
   
    
    </section>
  )
}

export default BannerDashboard