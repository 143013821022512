/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { fetchData, ThemeContext } from "../context/ContextFile";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import Swal from "sweetalert2";
import axios from "axios";
import DatePicker from "react-datepicker";
import Skeleton from "react-loading-skeleton";
import { FaDotCircle, FaShower } from "react-icons/fa";
import { MdGroup, MdOutlineBedroomParent } from "react-icons/md";
import "react-loading-skeleton/dist/skeleton.css";
import Layout from "../Components/layout/Layout";
import BASE_URL from "../Components/auth/Baseurl";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";
import ListingOverlayChild from "../Components/ui/ListingOverlayChild";
import { errorPopup } from "../helpers";

const markersData = [
  { id: 1, lat: 37.7749, lng: -122.4194 }, // Example marker data
  { id: 2, lat: 34.0522, lng: -118.2437 },
  { id: 3, lat: 40.7128, lng: -74.006 },
];

function NewMapChange() {
  const { name, key } = useParams();
  const [loading, setLoading] = useState(true);
  const { theme, setFilterListing, shuffledDatas, setShuffledDatas, SetTheme } =
    useContext(ThemeContext);
  const [filterItem, setFilterItem] = useState("");
  const [handle, setHandle] = useState(name);
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [quantity, setQuantity] = useState("");
  const [query, setQuery] = useState("");
  const [location, setLocation] = useState(null);
  const autoCompleteRef = useRef(null);
  const mapRef = useRef(null);
  const [markers, setMarkers] = useState(markersData);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDXJS_VZMhnp0szh92aZGg8RHszz6RMQN8",
  });
  let autoComplete;
  console.log(theme, name, "themetheme");

  useEffect(() => {
    setHandle(name);
    setTimeout(() => {
      setLoading(false);
    }, 1000);

    const GetListing = (name, key) => {
      const formData = new FormData();
      formData.append(key, name?.replace(/_/g, " "));
      // if (key === "byAddress") {
      // } else {
      //     formData.append(key, name);
      // }

      axios
        .post(`${BASE_URL}/listing-search`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          SetTheme(res?.data);
        })
        .catch((err) => {
          SetTheme(err);
        });
    };
    GetListing(name, key);

    return () => {
      SetTheme([]);
    };
  }, [name, key]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleFav = async (id) => {
    const formData = new FormData();
    formData.append("listing_id", id);
    try {
      const response = await axios.post(
        `${BASE_URL}/add-to-favourite`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      Swal.fire({
        position: "center",
        html: `
                <div style="display: flex; flex-direction: column; align-items: center;">
                   <svg
                viewBox="0 0 1024 1024"
                height="4em"
                width="4em"
                style="margin-bottom: 10px;"
            >
                <!-- Outer circle with color #8ec639 -->
                <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                
                <!-- Plus icon with color #A2c66b -->
                <path fill="#A2c66b" d="M512 274c-17.7 0-32 14.3-32 32v176H304c-17.7 0-32 14.3-32 32s14.3 32 32 32h176v176c0 17.7 14.3 32 32 32s32-14.3 32-32V546h176c17.7 0 32-14.3 32-32s-14.3-32-32-32H544V306c0-17.7-14.3-32-32-32z" />
            </svg>
                    <p style="margin: 0; font-size: 1.2em;">Profile update successful.</p>
                </div>
            `,
        title: response?.data?.message,
        showConfirmButton: true,
        timer: 1500,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value > 0) {
      setQuantity(value);
    } else {
      setQuantity(1);
    }
  };

  const formData = new FormData();
  formData.append("byAddress", query);
  formData.append("byNoOfGuest", quantity);
  formData.append("byListingType", handle);
  if (startDate) {
    const formattedArrivalDate = startDate.toISOString().split("T")[0];
    formData.append("byCheckin", formattedArrivalDate);
  }
  if (endDate) {
    const formattedDepartureDate = endDate.toISOString().split("T")[0];
    formData.append("byCheckout", formattedDepartureDate);
  }

  const isFormValid = () => {
    return quantity || query || startDate || endDate || handle;
  };

  const GetData = () => {
    if (!isFormValid()) {
      console.log("Please fill out all required fields.");
      return;
    }
    axios
      .post(`${BASE_URL}/listing-search`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        SetTheme(res?.data);

        // alert(res?.data)
        navigate(`/map/${query}`);
        Swal.fire({
          position: "center",
          html: `  
                <div style="display: flex; flex-direction: column; align-items: center;">
                   <svg
                viewBox="0 0 1024 1024"
                height="4em"
                width="4em"
                style="margin-bottom: 10px;"
            >
                <!-- Outer circle with color #8ec639 -->
                <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                
                <!-- Plus icon with color #A2c66b -->
                <path fill="#A2c66b" d="M512 274c-17.7 0-32 14.3-32 32v176H304c-17.7 0-32 14.3-32 32s14.3 32 32 32h176v176c0 17.7 14.3 32 32 32s32-14.3 32-32V546h176c17.7 0 32-14.3 32-32s-14.3-32-32-32H544V306c0-17.7-14.3-32-32-32z" />
            </svg>
                    <p style="margin: 0; font-size: 1.2em;">Profile update successful.</p>
                </div>
            `,
          title: res?.data?.message,
          showConfirmButton: true,
          timer: 1500,
        });
      })
      .catch((err) => {
        SetTheme(err);
        // console.log(err);
        errorPopup('', err?.response?.data?.message);

      });
  };

  const handleScriptLoad = (updateQuery, autoCompleteRef) => {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      {}
    );

    autoComplete.addListener("place_changed", () => {
      handlePlaceSelect(updateQuery);
    });
  };

  const onLoad = (map) => {
    mapRef.current = map;
    fitBounds();
  };

  const fitBounds = () => {
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach((marker) => {
      bounds.extend({ lat: marker?.lat, lng: marker?.lng });
    });
    if (markers.length === 1) {
      // Center on the single marker if only one exists
      mapRef.current.setCenter({ lat: markers[0]?.lat, lng: markers[0]?.lng });
      mapRef.current.setZoom(14); // Adjust the zoom level for a single marker
    } else {
      mapRef.current.fitBounds(bounds);
    }
  };

  useEffect(() => {
    if (mapRef.current) {
      fitBounds();
    }
  }, [markers]);

  const handlePlaceSelect = async (updateQuery) => {
    const addressObject = await autoComplete.getPlace();
    const selectedAddress = addressObject?.formatted_address;
    updateQuery(selectedAddress);

    if (addressObject?.geometry) {
      const latLng = {
        address: selectedAddress,
        lat: addressObject.geometry.location.lat(),
        lng: addressObject.geometry.location.lng(),
      };
      setLocation(latLng);
    }
  };

  useEffect(() => {
    handleScriptLoad(setQuery, autoCompleteRef);
  }, []);

  if (!isLoaded) return <div>Loading...</div>;

  const handleRemove = async (id, currentCompare) => {
    try {
      const response = await axios.get(`${BASE_URL}/listing-compare/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const menuToggleCheckbox = document.getElementById("UniqueMenuToggle");
      if (!menuToggleCheckbox.checked) {
        menuToggleCheckbox.click();
      }

      if (response.status === 200) {
        setFilterListing(response.data?.data);
      }
      fetchData().then((res) => {
        const shuffledIds = shuffledDatas?.map((item) => item.id);
        const shuffledArray = shuffledIds?.map((id) =>
          res?.data?.data?.find((item) => item.id === id)
        );
        setShuffledDatas(shuffledArray);
        setFilterListing(res.data?.data);
      });
    } catch (error) {
      console.error("Error making API call:", error);
    }
  };

  const handleClick = async (id, currentCompare) => {
    try {
      const response = await axios.get(`${BASE_URL}/listing-compare/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const menuToggleCheckbox = document.getElementById("UniqueMenuToggle");

      // Only toggle the menu open if it's not already checked
      if (!menuToggleCheckbox.checked) {
        menuToggleCheckbox.click();
      }

      if (response.status === 200) {
        setFilterListing(response.data?.data);
        console.log(response.data, "responsefilter");
      }
      fetchData().then((res) => {
        const shuffledIds = shuffledDatas?.map((item) => item.id);
        const shuffledArray = shuffledIds?.map((id) =>
          res?.data?.data?.find((item) => item.id === id)
        );
        setShuffledDatas(shuffledArray);
        setFilterListing(res.data?.data);
      });
    } catch (error) {
      console.error("Error making API call:", error);
    }
  };

  const handleCompare = (id, currentCompare) => {
    const filterCompare = shuffledDatas?.filter((e) => e?.is_compare === 0);
    console.log(filterCompare, "filterCompare");
    if (filterCompare?.length <= 4) {
      handleClick(id, currentCompare);
    } else {
      Swal.fire({
        position: "center",
        html: `<div style="display: flex; flex-direction: column; align-items:center;">
                     <svg
                            viewBox="0 0 1024 1024"
                            height="4em"
                            width="4em"
                            style="margin-bottom: 10px;"
                        >
                            <!-- Outer circle with color #8ec639 -->
                            <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                            
                            <!-- Check mark with color #A2c66b -->
                            <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
                        </svg>
                      <p style="margin: 0; font-size: 1.2em;">Profile update successful.</p>
                  </div>
              `,
        title: "You can only compare 5 listings",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <>
      <Layout>
        <div
          className="container mx-auto md:px-20 my-4 px-4"
          style={{ overflow: "hidden" }}
        >
          <div className="bg-white py-4">
            <div className="md:flex mb-2 hidden justify-between items-center px-6 md:w-12/12 mx-auto">
              <div>
                <h3 className="text-md text-nowrap font-bold">
                  Distance From Runway
                </h3>
              </div>
              <button
                className="custom-button"
                onClick={() => setFilterItem("0 miles")}
              >
                <FaDotCircle className="icon" color="#8ec639" size={"20px"} />
                <span className="button-text">0 miles away</span>
              </button>
              <button
                className="custom-button"
                onClick={() => setFilterItem("0-1 miles")}
              >
                <FaDotCircle className="icon" color="#ffff00" size={"20px"} />
                <span className="button-text">Within 1 Mile</span>
              </button>
              <button
                className="custom-button"
                onClick={() => setFilterItem("1.1-3 miles")}
              >
                <FaDotCircle className="icon" color="#f17489" size={"20px"} />
                <span className="button-text">Within 3 Miles</span>
              </button>
              <button
                className="custom-button"
                onClick={() => setFilterItem("3.1-7 miles")}
              >
                <FaDotCircle className="icon" color="#ff9900" size={"20px"} />
                <span className="button-text">Within 7 Miles</span>
              </button>
              <button
                className="custom-button"
                onClick={() => setFilterItem("7 miles")}
                style={{ borderRadius: "0px 0px 7px 0px" }}
              >
                <FaDotCircle className="icon" color="#52c4d7" size={"20px"} />
                <span className="button-text">Over 7 Miles away</span>
              </button>
            </div>
            <div className="grid md:grid-cols-7 grid-cols-3 rounded-lg md:gap-0 gap-2 px-4">
              <div className="main-search-input-item location autocomplete-container">
                <input
                  type="text"
                  ref={autoCompleteRef}
                  placeholder="Destination"
                  className="autocomplete-input pac-target-input"
                  onChange={(e) => setQuery(e.target.value)}
                  value={query}
                />
                <Link href="#">
                  <i className="fa fa-dot-circle-o" />
                </Link>
              </div>
              <div className="main-search-input-item">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    setEndDate(null);
                  }}
                  placeholderText="Arrival Date"
                />
              </div>
              <div className="main-search-input-item">
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  placeholderText="Departure Date"
                  minDate={startDate}
                />
              </div>
              <div className="main-search-input-item input-containers">
                <input
                  style={{ width: "100%" }}
                  type="number"
                  name="quantity"
                  placeholder="Guests"
                  className="input-field"
                  value={quantity}
                  onChange={handleChange}
                />
              </div>
              <div className="main-search-input-item">
                <select
                  style={{ fontWeight: 800, fontSize: "13px" }}
                  data-placeholder="Dwelling Type"
                  className="nice-select chosen-select no-search-select"
                  onChange={(e) => setHandle(e.target.value)}
                >
                  <option style={{ textAlign: "left" }}>Lodging Type</option>
                  <option value={"Apt. / Condo / Loft"}>
                    Apt. / Condo / Loft
                  </option>
                  <option value={"Bed & Breakfast"}>Bed & Breakfast</option>
                  <option value={"Cabin"}>Cabin</option>
                  <option value={"Campsite"}>Campsite</option>
                  <option value={"Farm"}>Farm</option>
                  <option value={"Hangar"}>Hangar</option>
                  <option value={"Hangar Home"}>Hangar Home</option>
                  <option value={"House"}>House</option>
                  <option value={"Mansion"}>Mansion</option>
                  <option value={"Novelty"}>Novelty</option>
                  <option value={"RV"}>RV</option>
                  <option value={"RV Pad"}>RV Pad</option>
                  <option value={"Tiny Home"}>Tiny Home</option>
                </select>
              </div>
              <div className="main-search-input-item">
                <button className="color2-bg mt-0 text-white bg-[#8EC639] rounded-md font-medium w-full h-full">
                  Filter
                </button>
              </div>
              <div className="flex justify-center items-center">
                <button
                  className="color2-bg mt-0 text-white bg-[#8EC639] rounded-md font-medium w-full h-full flex justify-center items-center md:py-0 py-3"
                  onClick={GetData}
                  disabled={!isFormValid()}
                >
                  Search
                  <IoSearchOutline />
                </button>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-wrap gap-4">
              {theme?.listings?.length > 0 ? (
                theme.listings.map((e) => (
                  <div key={e.id} className="w-full md:w-[calc(50%-1rem)]">
                    <ListingOverlayChild
                      e={e}
                      handleFav={handleFav}
                      handleCompare={handleCompare}
                      handleRemove={handleRemove}
                    />
                  </div>
                ))
              ) : (
                <h2 className="text-center w-full">No Listings Found</h2>
              )}
            </div>

            <div>
              <div
                style={{
                  height: "70vh",
                  width: "100%",
                  position: "relative",
                  top: "10px",
                  background: "none",
                }}
              >
                <GoogleMap
                  mapContainerStyle={{ height: "80vh", width: "100%" }}
                  center={
                    markers?.length === 1
                      ? { lat: markers[0]?.lat, lng: markers[0]?.lng }
                      : undefined
                  }
                  zoom={10}
                  onLoad={onLoad}
                  apiKey="AIzaSyDXJS_VZMhnp0szh92aZGg8RHszz6RMQN8"
                  className="custom-map-type-control"
                >
                  {markers.map((marker) => (
                    <MarkerF
                      key={marker.id}
                      position={{ lat: marker.lat, lng: marker.lng }}
                    />
                  ))}
                </GoogleMap>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
export default NewMapChange;
