/* eslint-disable react-hooks/exhaustive-deps */
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Listen from "./Pages/Listen";
import Booking from "./Pages/Booking";
import Profile from "./Pages/Profile";
import Changepassword from "./Pages/Changepassword";
import Details from "./Pages/Details";
import MyListing from "./Pages/MyListing";
import EditListing from "./Pages/EditListing";
import ThemProvider from "./context/ContextFile";
import Messages from "./Pages/Message";
import AddListing from "./Pages/AddListing";
import About from "./Pages/About";
import Favorite from "./Pages/Favorite";
import MyDetails from "./Pages/MyDetails";
import Invoice from "./Pages/Invoice";
import BookingD from "./Pages/BookingD";
import Wallet from "./Pages/Wallet";
import Payout from "./Pages/Payout";
import PayoutDetails from "./Pages/PayoutDetails";
import Request from "./Pages/Request";
import Login from "./Pages/Login";
import Contact from "./Pages/Contact";
import State from "./Pages/State";
import StateDetail from "./Pages/StateDetail";
import ApartmentDetail from "./Pages/ApartmentDetail";
import BySpace from "./Pages/BySpace";
import Listing from "./Pages/Listing";
import Squawks from "./Pages/Squawks";
import MostFun from "./Pages/StaticPages/MostFun";
import TermService from "./Pages/term/TermService";
import Privacy from "./Pages/term/Privacy";
import Gallery from "./Pages/Gallery";
import Testimonials from "./Pages/Testimonials";
import Mostpopular from "./Pages/StaticPages/Mostpopular";
import AirparkCommunities from "./Pages/StaticPages/AirparkCommunities";
import InovatedIdea from "./Pages/StaticPages/InovatedIdea";
import PackingListLadies from "./Pages/StaticPages/PackingListladies";
import PicProfile from "./Pages/PicProfile";
import Bannerthree from "./Components/Bannerthree";
import Permission from "./Pages/Permission";
import BigMap from "./Pages/BigMap";
import PhilanthropyPage from "./Pages/PhilanthropyPage";
import SquawksDetail from "./Pages/SquawksDetail";
import FAQ from "./Pages/FAQ";
import FairPolicy from "./Pages/FairPolicy";
import CirtPolicy from "./Pages/CirtPolicy";
import ShortTermRental from "./Pages/StaticPages/ShortTermRental";
import Platform from "./Pages/Platform";
import CopyPolicy from "./Pages/StaticPages/CopyPolicy";
import TradeMark from "./Pages/TradeMark";
import Neighborhood from "./Pages/Neighborhood";
import ServiceFee from "./Pages/ServiceFee";
import Courtesy from "./Pages/Courtesy";
import Compare from "./Pages/Compare";
import Trips from "./Pages/Trips";
import BookingView from "./Pages/BookingView";
import MyTrip from "./Pages/MyTrip";
import Review from "./Pages/Review";
import NewMapChange from "./Pages/NewMapChange";

function App({ children }) {

  return (
    <>
      <ThemProvider>
        {children}
        <BrowserRouter >
          <Routes >
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/map/:id" element={<Bannerthree />} />
            <Route path="/map_listing/:name/:key" element={<NewMapChange />} />
            <Route path="/map" element={<Bannerthree />} />
            <Route path="/bigmap" element={<BigMap />} />
            <Route path="/terms-of-service" element={<TermService />} />
            <Route path="/copy_right" element={<CopyPolicy />} />
            <Route path="/compare" element={<Compare />} />
            <Route path="/Service-Fees-Policy" element={<ServiceFee />} />
            <Route path="/privacy_policy" element={<Privacy />} />
            <Route path="/short_term_rental" element={<ShortTermRental />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/testimonials" element={<Testimonials />} />
            <Route
              path="/the-most-fun-fly-ins-in-america"
              element={<MostFun />}
            />
            <Route
              path="/most-popular-air-shows-in-the-united-states"
              element={<Mostpopular />}
            />
            <Route
              path="/airpark-communities-in-the-usa"
              element={<AirparkCommunities />}
            />
            <Route
              path="/innovative-ideas-for-a-hangar-home"
              element={<InovatedIdea />}
            />
            <Route
              path="/ultimate-travel-packing-list-for-ladies"
              element={<PackingListLadies />}
            />
            <Route path="/about" element={<About />} />
            <Route path="/listing" element={<Listing />} />
            <Route path="/squawks" element={<Squawks />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/squawks/:id" element={<SquawksDetail />} />
            <Route path="/philanthropy" element={<PhilanthropyPage />} />
            <Route path="/fair-housing-policy" element={<FairPolicy />} />
            <Route path="/trademark-policy" element={<TradeMark />} />
            <Route path="/courtesy-rental-peer-to-peer-car-sharing-policy" element={<Courtesy />} />
            <Route path="/cirt-policy" element={<CirtPolicy />} />
            <Route path="/Off-the-Platform-Fees-Policy" element={<Platform />} />
            <Route path="/Neighborhood-Nuisance-and-Disruptive-Behavior-Policy" element={<Neighborhood />} />
            <Route path="/dashboard" element={<Listen />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/booking_view" element={<BookingView />} />
            <Route path="/trip_view" element={<MyTrip />} />

            <Route path="/trip" element={<Trips />} />
            <Route path="/my_listing" element={<MyListing />} />
            <Route path="/invoice_details" element={<BookingD />} />
            <Route path="/my_wallets" element={<Wallet />} />
            <Route path="/payout" element={<Payout />} />
            <Route path="/request" element={<Request />} />
            <Route path="/payoutdetails" element={<PayoutDetails />} />
            <Route path="/favorite" element={<Favorite />} />
            <Route path="/invoice" element={<Invoice />} />
            <Route path="/review" element={<Review />} />
            <Route path="/addlisting" element={<AddListing />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/agreements" element={<Permission />} />
            <Route path="/pic" element={<PicProfile />} />
            <Route path="/air_mail" element={<Messages />} />
            <Route path="/details/:id" element={<Details />} />
            <Route path="/detail-my-listing/:id" element={<MyDetails />} />
            <Route path="/EditListing/:id" element={<EditListing />} />
            <Route path="/changepassword" element={<Changepassword />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/by-state-airport" element={<State />} />
            <Route
              path="/by-state-airport/detail/:location"
              element={<StateDetail />}
            />
            <Route
              path="/by-dwelling/details/:location"
              element={<ApartmentDetail />}
            />
            <Route path="/by-space/details/:location" element={<BySpace />} />
          </Routes>
        </BrowserRouter>
      </ThemProvider>
    </>
  );
}

export default App;
