/* eslint-disable */
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import BASE_URL from './auth/Baseurl';
import Swal from 'sweetalert2';
import ReactStars from "react-rating-stars-component";

const ReplyReview = ({ replyModal, setReplyModal, setSendReply, handleSubmitReply = () => { }, handleSubmit = () => { }, selectedId, selectedRowStatus }) => {
    const [popupOpen, setPopupOpen] = useState(true)
    const [reply, setReply] = useState(true);
    const modalBodyRef = useRef(null);

    useEffect(() => {
        Swal.fire({
            icon: "info",
            text: selectedRowStatus === 'No Reply' ? 'You have already chosen to not reply, and this was a final action.' : "You can only reply to a review one time.",
            showConfirmButton: true,
        }).then(() => {
            setPopupOpen(false)
        })
        if (replyModal && modalBodyRef.current) {
            modalBodyRef.current.scrollTop = modalBodyRef.current.scrollHeight;
        }
    }, [])

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            overflow: "hidden",
            top: "-40px"
        }}>
            <Modal
                show={replyModal}
                onHide={() => setReplyModal(false)}
                style={{ height: "600px", overflowY: "hidden", top: "-20px" }}
                keyboard={false}
            >
                <Modal.Header >
                    <Modal.Title>Reply to your review.</Modal.Title>
                </Modal.Header>
                <Modal.Body ref={modalBodyRef} style={{ height: "400px", overflowY: "scroll" }}>

                    <div className="row d-flex flex-wrap">
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <label>Overall Satisfaction</label>
                            <ReactStars
                                count={5}
                                value={selectedId?.original?.overall_satisfaction || 0}
                                size={30}
                                activeColor="#ffd700"
                                edit={false}
                            />
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <label>Cleanliness</label>
                            <ReactStars
                                count={5}
                                value={selectedId?.original?.cleanliness || 0}
                                size={30}
                                activeColor="#ffd700"
                                edit={false}
                            />
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <label>Accuracy</label>
                            <ReactStars
                                count={5}
                                value={selectedId?.original?.accuracy || 0}
                                size={30}
                                activeColor="#ffd700"
                                edit={false}
                            />
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <label>Communication</label>
                            <ReactStars
                                count={5}
                                value={selectedId?.original?.communication || 0}
                                size={30}
                                activeColor="#ffd700"
                                edit={false}
                            />
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <label>Location</label>
                            <ReactStars
                                count={5}
                                value={selectedId?.original?.location || 0}
                                size={30}
                                activeColor="#ffd700"
                                edit={false}
                            />
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <label>Check-in</label>
                            <ReactStars
                                count={5}
                                value={selectedId?.original?.checkin || 0}
                                size={30}
                                activeColor="#ffd700"
                                edit={false}
                            />
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <label>Value</label>
                            <ReactStars
                                count={5}
                                value={selectedId?.original?.value || 0}
                                size={30}
                                activeColor="#ffd700"
                                edit={false}
                            />
                        </div>
                    </div>

                    <form id="add-comment" className="add-comment  custom-form" name="rangeCalc">
                        <fieldset>

                            <div style={{ padding: "10px 30px 0px" }} className="list-single-main-item_content fl-wrap">

                                <textarea
                                    cols={40}
                                    rows={3}
                                    value={selectedId?.original?.comment || ""}
                                    placeholder="Write Your Reason:"
                                    defaultValue={""}
                                />

                            </div>
                        </fieldset>
                        <p style={{ fontSize: "11px", fontStyle: "italic" }}>Caution: Clicking No Reply is a final action; you will not be able to leave a review once it has been clicked.</p>
                        <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>

                            {selectedRowStatus !== 'No Reply' && (
                                <>
                                    <Button variant="primary" onClick={() => setReply(!reply)}>Reply</Button>
                                    <Button variant="primary" tooltip onClick={handleSubmit}>No Reply</Button>
                                </>
                            )
                            }

                        </div>
                    </form>

                    {reply && selectedRowStatus !== 'No Reply' ?

                        <form id="add-comment" className="add-comment  custom-form" name="rangeCalc">
                            <fieldset>

                                <div style={{ padding: "10px 30px 0px" }} className="list-single-main-item_content fl-wrap">

                                    <textarea
                                        cols={40}
                                        rows={3}
                                        onChange={(e) => setSendReply(e.target.value)}
                                        placeholder="Write Your Reply:"
                                        defaultValue={""}
                                    />

                                </div>
                            </fieldset>
                            <p style={{ fontSize: "11px", fontStyle: "italic" }}>You can only respond to a review one time. Once you click Submit, your review cannot be retracted. You have a limit of 500 characters for your reply.</p>

                            <div style={{ display: "flex", justifyContent: "flex-end" }}>

                                <Button variant="primary" onClick={handleSubmitReply}>Submit</Button>
                            </div>
                        </form>
                        :
                        <>
                            <p style={{ fontSize: "11px", fontStyle: "italic" }}>You have already reviewed this.</p>


                        </>
                    }


                </Modal.Body>

            </Modal>
        </div>
    )
}

export default ReplyReview