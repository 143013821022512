import axios from "axios";
import React, { useEffect, useState } from "react";
import BASE_URL from "./auth/Baseurl";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { errorPopup } from "../helpers";

const RequestR = () => {
  const [value, setValue] = useState("");
  const [status, setStatus] = useState(false);
  const [get, setGet] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/connected-account`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      window.open(response.data?.link?.url);
      Swal.fire({
        position: "center",
        html: `
            <div style="display: flex; flex-direction: column; align-items: center;">
<svg
                      viewBox="0 0 1024 1024"
                      height="4em"
                      width="4em"
                      style="margin-bottom: 10px;"
                  >
                      <!-- Outer circle with color #8ec639 -->
                      <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                      
                      <!-- Check mark with color #A2c66b -->
                      <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
                  </svg>
                <p style="margin: 0; font-size: 1.2em;">Profile update successful.</p>
            </div>
        `,
        title: response.data?.message,
        showConfirmButton: true,
        timer: 1500,
      });
    } catch (error) {
      errorPopup('', "Something went wrong.");
    }
  };
  useEffect(() => {
    const storedData = localStorage.getItem("walletData");
    if (storedData) {
      setGet(JSON.parse(storedData));
    }
  }, []);
  const checkStatus = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/connected-account-status-check`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(response, "status check");
      setStatus(response.data?.status);
    } catch (err) {
      console.error("Status check error:", err);
      setStatus(false);
    }
  };
  useEffect(() => {
    checkStatus();
  }, []);
  const paymentSubmit = async () => {
    if (!status) {
      errorPopup('', "Status check failed or account not connected.");
      return;
    }

    const formdata = new FormData();
    formdata.append("amount", value);

    try {
      const response = await axios.post(
        `${BASE_URL}/payout-request`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      // console.log(response, "payout request");
      setValue(); // Clear the value after successful submission
      Swal.fire({
        html: `
            <div style="display: flex; flex-direction: column; align-items: center;">
<svg
                      viewBox="0 0 1024 1024"
                      height="4em"
                      width="4em"
                      style="margin-bottom: 10px;"
                  >
                      <!-- Outer circle with color #8ec639 -->
                      <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                      
                      <!-- Check mark with color #A2c66b -->
                      <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
                  </svg>
                <p style="margin: 0; font-size: 1.2em;">Profile update successful.</p>
            </div>
        `,
        title: "Success",
        text: response.data.message,
      });
    } catch (error) {
      console.error("Payout request error:", error);
      errorPopup('', "Something went wrong.");

    }
  };
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const status = params.get("status");
    const message = params.get("message");

    if (status && message) {
      Swal.fire({
        icon: status === "true" ? "success" : "error",
        title: status === "true" ? "Success" : "",
        text: decodeURIComponent(message),
      }).then(() => {
        navigate({
          pathname: location.pathname,
          search: "",
        });
      });
    }
  }, [location, navigate]);

  return (
    <>
      <div style={{ marginTop: "30px" }} className="col-md-12">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}
          className="d-flex"
        >
          <h1 style={{ paddingTop: "17px" }}>
            Available Balance ${get?.available}
          </h1>
          <button onClick={handleSubmit} className="logout_btn color2-bg">
            Setup Payout Method
          </button>
        </div>
      </div>
      <div style={{ marginTop: "30px" }} className="col-md-12">
        <div className="profile-edit-container fl-wrap block_box">
          <div className="custom-form">
            <span style={{ display: "flex", marginBottom: "10px" }}>
              Note: the minimum amount for a payout is $1
            </span>
            <label>
              Payout Amount <i class="fal fa-dollar-sign" />
            </label>
            <input
              type="number"
              placeholder="Enter a payout Amount.Only numbers "
              defaultValue=""
              onChange={(e) => setValue(e.target.value)}
            />
          </div>
          <button onClick={paymentSubmit} className="logout_btn color2-bg">
            Request a Payout
          </button>
        </div>
      </div>
    </>
  );
};

export default RequestR;
