/* eslint-disable */
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import BASE_URL from "./auth/Baseurl";
import axios from "axios";
import { ThemeContext } from "../context/ContextFile";
import DatePicker from "react-datepicker";
import { FaDotCircle } from "react-icons/fa";
import Swal from "sweetalert2";
import { MdBorderClear } from "react-icons/md";
import SearchModal from "./search/SearchModal";
import { IoSearchOutline } from "react-icons/io5";
import { errorPopup } from "../helpers";

const BigMapcomponent = ({ filterItem, setFilterItem }) => {
  const [filters, setFilters] = useState(false);
  const [handle, setHandle] = useState();
  console.log(handle, "handle");
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  let { SetTheme, setSelectedLocation2 } = useContext(ThemeContext);
  const [query, setQuery] = useState("");
  const [query1, setQuery1] = useState("");

  const [quantity, setQuantity] = useState();

  const handleChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value > 0) {
      setQuantity(value);
    } else {
      setQuantity(1);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp") {
      setQuantity((prevQuantity) => prevQuantity + 1);
    } else if (e.key === "ArrowDown") {
      setQuantity((prevQuantity) => Math.max(1, prevQuantity - 1));
    }
  };
  console.log(quantity, "num");
  const formData = new FormData();
  formData.append("byAddress", query);
  formData.append("byNoOfGuest", quantity);
  formData.append("byListingType", handle);
  if (startDate) {
    const formattedArrivalDate = startDate.toISOString().split("T")[0];
    formData.append("byCheckin", formattedArrivalDate);
  }

  // Departure Date
  if (endDate) {
    const formattedDepartureDate = endDate.toISOString().split("T")[0];
    formData.append("byCheckout", formattedDepartureDate);
  }

  const isFormValid = () => {
    return quantity || query || startDate || endDate || handle;
  };

  console.log(handle, "handlw");

  const GetData = () => {
    if (!isFormValid()) {
      console.log("Please fill out all required fields.");
      return;
    }



    axios
      .post(`${BASE_URL}/listing-search`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        SetTheme(res?.data);

        // alert(res?.data)
        navigate(`/map/${query}`);
        Swal.fire({
          position: "center",
          html: `
            <div style="display: flex; flex-direction: column; align-items: center;">
<svg
                      viewBox="0 0 1024 1024"
                      height="4em"
                      width="4em"
                      style="margin-bottom: 10px;"
                  >
                      <!-- Outer circle with color #8ec639 -->
                      <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                      
                      <!-- Check mark with color #A2c66b -->
                      <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
                  </svg>
                <p style="margin: 0; font-size: 1.2em;">Profile update successful.</p>
            </div>
        `,
          title: res?.data?.message,
          showConfirmButton: true,
          timer: 1500,
        });
      })
      .catch((err) => {
        SetTheme(err);
        // console.log(err);
        errorPopup('', err?.response?.data?.message);
      });
  };

  const autoCompleteRef = useRef(null);
  let autoComplete;

  const handleScriptLoad = (updateQuery, autoCompleteRef) => {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      {
        // types: ["(cities)"],
      }
    );

    autoComplete.addListener("place_changed", () => {
      handlePlaceSelect(updateQuery);
    });
  };

  const handlePlaceSelect = async (updateQuery) => {
    const addressObject = await autoComplete.getPlace();
    const query = addressObject?.formatted_address;
    updateQuery(query);
    console.log({ query });
    const latLng = {
      lat: addressObject?.geometry?.location?.lat(),
      lng: addressObject?.geometry?.location?.lng(),
    };
    console.log(latLng);
    // setSelectedLocation(latLng);
  };

  useEffect(() => {
    handleScriptLoad(setQuery, autoCompleteRef);
  }, [setQuery, autoCompleteRef]);

  const autoCompleteRef1 = useRef(null);
  let autoComplete1;

  const handleScriptLoad1 = (updateQuery1, autoCompleteRef1) => {
    autoComplete1 = new window.google.maps.places.Autocomplete(
      autoCompleteRef1.current,
      {
        // types: ["(cities)"],
      }
    );

    autoComplete1.addListener("place_changed", () => {
      handlePlaceSelect1(updateQuery1);
    });
  };

  const handlePlaceSelect1 = async (updateQuery1) => {
    const addressObject1 = await autoComplete1?.getPlace();
    if (!addressObject1 || !addressObject1.geometry) {
      console.error("Place selection returned no geometry.");
      return;
    }

    const latestQuery1 = addressObject1.formatted_address || "";
    updateQuery1(latestQuery1);

    const latLng1 = {
      lat1: addressObject1.geometry.location.lat(),
      lng1: addressObject1.geometry.location.lng(),
    };

    setSelectedLocation2(latLng1);
  };

  useEffect(() => {
    handleScriptLoad1(setQuery1, autoCompleteRef1);
  }, [autoCompleteRef1]);

  console.log(filterItem, "filterItem");


  return (
    <>
      <div
        style={{
          position: "absolute",
          top: "70px",
          textAlign: "left",
          width: "100px",
          left: "95px"
          , borderRadius: "12px 12px 12px 12px"
        }}
      >
        <input
          style={{ padding: "11px", width: "171px", borderRadius: "0px 4px 4px 0px" }}
          type="text"
          value={query1}
          ref={autoCompleteRef1}
          onChange={(e) => setQuery1(e.target.value)}
          placeholder="Google Map Search"
        />
      </div>
      <div
        style={{
          width: "97%",
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
        className="hero-map-search-wrap fl-wrap"
      >
        <div
          style={{
            width: "90%",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "9px",
            borderRadius: "0px 4px 4px 0px"
          }}
          className="hero-search  fl-wrap"
        >
          <div className="main-search-input-wrap fl-wrap">
            <div
              className="main-search-input-item"
              style={{ display: "flex", gap: "12px", background: "#ffffffcf" }}
            >
              <div className="main-search-input-item p-0 location autocomplete-container">
                <label>{/* <i className="fal fa-map-marker-check" /> */}</label>
                <input
                  type="text"
                  ref={autoCompleteRef}
                  placeholder="Destination"
                  className="autocomplete-input pac-target-input border-none"
                  onChange={(e) => setQuery(e.target.value)}
                  value={query}
                />
                <Link href="#">
                  <i className="fa fa-dot-circle-o" />
                </Link>
              </div>
              <div className="main-search-input-item p-0">
                {/* <p className="opening-hours-day"> Arrival Date</p> */}
                <DatePicker
                  // style={{paddingLeft:"0px"}}
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    setEndDate(null);
                  }}
                  className="border-none"
                  placeholderText="Arrival Date"
                />
              </div>
              <div className="main-search-input-item p-0">
                {/* <p className="opening-hours-day">Departure Date</p> */}
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  placeholderText="Departure Date"
                  minDate={startDate}
                  className="border-none"
                />
              </div>
              <div className="main-search-input-item p-0">
                <label>{/* <i className="fal fa-user-friends" /> */}</label>
                <input
                  type="number"
                  name="quantity"
                  placeholder="No of Guests"
                  className="border-none"
                  value={quantity}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                />
              </div>
              <div className="main-search-input-item p-0">
                <select
                  style={{ fontWeight: 800, fontSize: "13px" }}
                  data-placeholder="Apartments"
                  className="nice-select chosen-select no-search-select border-none"
                  // value={service.type}

                  onChange={(e) => setHandle(e.target.value)}
                >
                  <option style={{ textAlign: "left" }}>Dwelling Type</option>


                  <option value={"Apartment"}>Apartment</option>
                  <option value={"Bed &amp; Breakfast"}>
                    Bed &amp; Breakfast
                  </option>
                  <option value={"Beachfront"}>Beachfront</option>
                  <option value={"Cabin"}>Cabin</option>
                  <option value={"Campsite"}>Campsite</option>
                  <option value={"Condo"}>Condo</option>
                  <option value={"Farm"}>Farm</option>
                  <option value={"Hangar"}>Hangar</option>
                  <option value={"Hangar Home"}>Hangar Home</option>
                  <option value={"House"}>House</option>
                  <option value={"Lakefront"}>Lakefront</option>
                  <option value={"Loft"}>Loft</option>
                  <option value={"Mansion"}>Mansion</option>
                  <option value={"Novelty"}>Novelty</option>
                  <option value={"RV"}>RV</option>
                  <option value={"Tiny Home"}>Tiny Home</option>
                </select>

              </div>
              <div className="main-search-input-item p-0">
                <button
                  style={{ color: "#fff", backgroundColor: "#8EC639", borderRadius: "4px", fontWeight: 800 }}
                  className="main-search-button color2-bg"
                  onClick={() => setFilters(!filters)}
                >
                  Filter
                </button>
              </div>
              <div className="main-search-input-item p-0">
                <button
                  style={{
                    color: "#fff", backgroundColor: "#8EC639", borderRadius: "4px", fontWeight: 800, display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "2px",
                  }}
                  className="main-search-button color2-bg"
                  onClick={GetData}
                  disabled={!isFormValid()}
                >
                  Search
                  <IoSearchOutline />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          width: "80%",
          textAlign: "center",
          top: "0px",
          right: "80px",
          borderRadius: "6px 0px 0px 6px",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <h3
            style={{
              width: "312px",
              fontSize: "13px",
              borderRadius: "0px 0px 0px 6px",
              backgroundColor: "#fff",
              color: "#3b4249",
              padding: "7px",
              justifyContent: "center",
              fontWeight: "bolder",
              textAlign: "center",
            }}
          >
            Distance From Runway
          </h3>
          <button
            className="custom-button"
            onClick={() => setFilterItem("0 miles")}
          >
            <FaDotCircle className="icon" color="#8ec639" size={"20px"} />
            <span className="button-text">0 miles away</span>
          </button>
          <button
            className="custom-button"
            onClick={() => setFilterItem("0-1 miles")}
          >
            <FaDotCircle className="icon" color="#ffff00" size={"20px"} />
            <span className="button-text">Within 1 Mile</span>
          </button>
          <button
            className="custom-button"
            onClick={() => setFilterItem("1.1-3 miles")}
          >
            <FaDotCircle className="icon" color="#f17489" size={"20px"} />
            <span className="button-text">Within 3 Miles</span>
          </button>
          <button
            className="custom-button"
            onClick={() => setFilterItem("3.1-7 miles")}
          >
            <FaDotCircle className="icon" color="#ff9900" size={"20px"} />
            <span className="button-text">Within 7 Miles</span>
          </button>
          <button
            style={{ borderRadius: "0 0 6px 0" }}
            className="custom-button"
            onClick={() => setFilterItem("7 miles")}
          >
            <FaDotCircle className="icon" color="#52c4d7" size={"20px"} style={{ borderRadius: "6px 0px 0px 6px" }} />
            <span className="button-text">Over 7 Miles away</span>
          </button>

        </div>
      </div>
      <SearchModal filters={filters} setFilters={setFilters} />
    </>
  );
};

export default BigMapcomponent;
