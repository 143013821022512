import React from "react";
import { FaInstagram } from "react-icons/fa6";
import { FiFacebook, FiTwitter, FiYoutube } from "react-icons/fi";
import { TbMathGreater } from "react-icons/tb";
import { Link, useLocation } from "react-router-dom";
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, FacebookIcon, TwitterIcon, LinkedinIcon, FacebookMessengerShareButton, FacebookMessengerIcon, WhatsappShareButton, WhatsappIcon, TelegramShareButton, TelegramIcon, PinterestShareButton, PinterestIcon, RedditShareButton, RedditIcon, EmailShareButton, EmailIcon } from 'react-share';


const TailwindFooter = () => {


  // Share here

  const location = useLocation();
  const shareUrl = window.location.origin + location.pathname + location.search;

  console.log(shareUrl); // Logs the current URL (e.g., https://yourwebsite.com/details/123?query=param)




  return (
    <div>
      <footer className="bg-white">
        <div className="max-w-screen-xl px-4 pt-16 pb-6 mx-auto sm:px-6 lg:px-8 lg:pt-24">
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
            <div>
              <div className="flex flex-col justify-center text-teal-300 sm:justify-start">
                <div className="footer-logo">
                  <Link to="/">
                    <img
                      style={{ width: "200px", height: "150px" }}
                      src="/image/fly-inn-logo.svg"
                      alt=""
                    />
                  </Link>
                </div>
                <div className="footer-contacts-widget fl-wrap">
                  <p style={{ color: "#3B4249", marginLeft: "-8px" }}>
                    We empower aviation enthusiasts to book and host lodging,
                    transportation and more!
                  </p>

                  <div className="d-flex flex-wrap items-center space-x-4 bg-white rounded p-3 gap-3">
                    <div>

                      <h3 className="title"> Share us on : </h3>
                    </div>
                    <div className="flex-column d-flex flex-wrap gap-4">
                      {/* Facebook Share Button */}
                      <FacebookShareButton url={shareUrl} quote="Check this out!" // Optional: Add a custom quote
                        hashtag={`Check our our growing inventory…growing daily all over North America.`} title={'Fly-Inn'} className="flex items-center justify-center p-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition">
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>


                      <FacebookMessengerShareButton url={shareUrl} title={'Fly-Inn'} className="flex items-center justify-center p-3 bg-blue-700 text-white rounded-full hover:bg-blue-800 transition">
                        <FacebookMessengerIcon size={32} round />
                      </FacebookMessengerShareButton>


                      {/* Twitter Share Button */}
                      <TwitterShareButton url={shareUrl} title={'Fly-Inn'} className="flex items-center justify-center p-3 bg-blue-400 text-white rounded-full hover:bg-blue-500 transition">
                        <TwitterIcon size={32} round />
                      </TwitterShareButton>

                      {/* LinkedIn Share Button */}
                      <LinkedinShareButton url={shareUrl} title={'Fly-Inn'} className="flex items-center justify-center p-3 bg-blue-700 text-white rounded-full hover:bg-blue-800 transition">
                        <LinkedinIcon size={32} round />
                      </LinkedinShareButton>

                      <WhatsappShareButton url={shareUrl} title={'Fly-Inn'} className="flex items-center justify-center p-3 bg-blue-700 text-white rounded-full hover:bg-blue-800 transition">
                        <WhatsappIcon size={32} round />
                      </WhatsappShareButton>

                      <TelegramShareButton url={shareUrl} title={'Fly-Inn'} className="flex items-center justify-center p-3 bg-blue-700 text-white rounded-full hover:bg-blue-800 transition">
                        <TelegramIcon size={32} round />
                      </TelegramShareButton>

                      <PinterestShareButton url={shareUrl} title={'Fly-Inn'} className="flex items-center justify-center p-3 bg-blue-700 text-white rounded-full hover:bg-blue-800 transition">
                        <PinterestIcon size={32} round />
                      </PinterestShareButton>

                      <RedditShareButton url={shareUrl} title={'Fly-Inn'} className="flex items-center justify-center p-3 bg-blue-700 text-white rounded-full hover:bg-blue-800 transition">
                        <RedditIcon size={32} round />
                      </RedditShareButton>

                      <EmailShareButton url={shareUrl} title={'Fly-Inn'} className="flex items-center justify-center p-3 bg-blue-700 text-white rounded-full hover:bg-blue-800 transition">
                        <EmailIcon size={32} round />
                      </EmailShareButton>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:col-span-2 md:grid-cols-3">
              <div className="footer-widget fl-wrap">
                <h3>Our Latest News</h3>
                <div className="footer-widget-posts fl-wrap">
                  <ul className="no-list-style">
                    <li className="clearfix">
                      <Link to="#" className="widget-posts-img">
                        <img
                          src="/images/all/4.jpg"
                          className="respimg"
                          alt=""
                        />
                      </Link>
                      <div className="widget-posts-descr">
                        <Link className="h-btn" to="#" title="">
                          Vivamus dapibus rutrum
                        </Link>
                        <span
                          style={{ color: "#4f5962" }}
                          className="widget-posts-date"
                        >
                          <i
                            style={{ color: "#4f5962" }}
                            className="fal fa-calendar"
                          />{" "}
                          21 Mar 09.05{" "}
                        </span>
                      </div>
                    </li>
                    <li className="clearfix">
                      <Link to="#" className="widget-posts-img">
                        <img
                          src="/images/all/2.jpg"
                          className="respimg"
                          alt=""
                        />
                      </Link>
                      <div className="widget-posts-descr">
                        <Link className="h-btn" to="#" title="">
                          {" "}
                          In hac habitasse platea
                        </Link>
                        <span
                          style={{ color: "#4f5962" }}
                          className="widget-posts-date"
                        >
                          <i
                            style={{ color: "#4f5962" }}
                            className="fal fa-calendar"
                          />{" "}
                          7 Mar 18.21{" "}
                        </span>
                      </div>
                    </li>
                    <li className="clearfix">
                      <Link to="#" className="widget-posts-img">
                        <img
                          src="/images/all/7.jpg"
                          className="respimg"
                          alt=""
                        />
                      </Link>
                      <div className="widget-posts-descr">
                        <Link className="h-btn" to="#" title="">
                          Tortor tempor in porta
                        </Link>
                        <span
                          style={{ color: "#4f5962" }}
                          className="widget-posts-date"
                        >
                          <i
                            style={{ color: "#4f5962" }}
                            className="fal fa-calendar"
                          />{" "}
                          7 Mar 16.42{" "}
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer-widget fl-wrap">
                <h3>Company</h3>
                <div className="footer-widget-posts fl-wrap">
                  <ul className="footer-list fl-wrap">
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer-widget fl-wrap ">
                <h3>Contact us</h3>
                <ul className="footer-contacts fl-wrap no-list-style">
                  <li>
                    {" "}
                    <span>
                      <i
                        style={{ color: "#4f5962" }}
                        className="fal fa-map-marker"
                      />
                    </span>
                    <Link className="h-btn" to="#">
                      P.O. Box 270439, Fruitland, UT 84027
                    </Link>
                  </li>
                  <li>
                    <span>
                      <i
                        style={{ color: "#4f5962" }}
                        className="fal fa-phone"
                      />
                    </span>
                    <Link className="h-btn" to="#">
                      {" "}
                      833-I-Fly-Inn
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <span>
                      <i
                        style={{ color: "#4f5962" }}
                        className="fal fa-phone"
                      />
                    </span>
                    <Link className="h-btn" to="#">
                      321-I-Fly-Inn
                    </Link>
                  </li>
                  <li>
                    <span>
                      <i
                        style={{ color: "#4f5962" }}
                        className="fal fa-envelope"
                      />
                    </span>

                    <Link className="h-btn" to="mailto:PIC@Fly-Inn.com">
                      <span className="footer_link_changes">
                        {" "}
                        PIC@Fly-Inn.com
                      </span>
                    </Link>
                  </li>
                  <li
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={() => window.scrollTo({ top: 0 })}
                  >
                    <TbMathGreater style={{ fontSize: "16px" }} />
                    <Link className="h-btn" to="/contact">
                      <span className="footer_link_changes">Contact us</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="pt-6 mt-12 border-t border-gray-800">
            <div className="text-center sm:flex sm:justify-between sm:text-left">
              <p className="text-sm text-gray-400">
                <ul className="flex gap-10">
                  <li>
                    <Link
                      to="https://www.facebook.com/FlyInnLLC/"
                      target="_blank"
                    >
                      <FiFacebook
                        style={{ fontSize: "20px", color: "#4f5962" }}
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://twitter.com/FlyInnLLC" target="_blank">
                      <FiTwitter
                        style={{ fontSize: "20px", color: "#4f5962" }}
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.instagram.com/flyinnllc/"
                      target="_blank"
                    >
                      <FaInstagram
                        style={{ fontSize: "20px", color: "#4f5962" }}
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.youtube.com/@FLY-INN" target="_blank">
                      <FiYoutube
                        style={{ fontSize: "20px", color: "#4f5962" }}
                      />
                    </Link>
                  </li>
                </ul>
              </p>
              <p className="mt-4 text-sm text-gray-500 sm:order-first sm:mt-0">
                FlyInn LLC - All rights reserved © 2020-2024
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default TailwindFooter;
