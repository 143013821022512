/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LuLayoutDashboard } from "react-icons/lu";
import { FaRegMessage } from "react-icons/fa6";
import { GrFavorite } from "react-icons/gr";
import { PiInvoiceDuotone, PiMoneyWavyLight } from "react-icons/pi";
import { SlWallet } from "react-icons/sl";
import { RiMenuAddLine } from "react-icons/ri";
import { MdOutlineRateReview } from "react-icons/md";
import { IoAirplaneOutline } from "react-icons/io5";
import { IoIosLogOut } from "react-icons/io";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

function Sidebar() {
  const navigate = useNavigate();

  const Logout = () => {
    alert("Logout SuccesFully");
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
  };
  const Check = () => {
    const Token = localStorage.getItem("token");
    if (Token) {
      return true;
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    Check();
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => setIsOpen(!isOpen);

  const menuItems = [
    {
      to: "/dashboard",
      icon: <LuLayoutDashboard className="text-lg" />,
      label: "Dashboard",
    },
    {
      to: "/profile",
      icon: <i className="fal fa-user text-lg"></i>,
      label: "Profile",
    },
    {
      to: "/my_listing",
      icon: <RiMenuAddLine className="text-lg" />,
      label: "My Listings",
    },
    {
      to: "/booking",
      icon: <PiMoneyWavyLight className="text-lg" />,
      label: "My Bookings",
    },
    {
      to: "/trip",
      icon: <IoAirplaneOutline className="text-lg" />,
      label: "My Trips",
    },
    {
      to: "/my_wallets",
      icon: <SlWallet className="text-lg" />,
      label: "My Wallet",
    },
    {
      to: "/air_mail",
      icon: <FaRegMessage className="text-lg" />,
      label: "AirMail",
      power: "TM",
    },
    {
      to: "/invoice",
      icon: <PiInvoiceDuotone className="text-lg" />,
      label: "Invoices",
    },
    {
      to: "/review",
      icon: <MdOutlineRateReview className="text-lg" />,
      label: "Reviews",
    },
    {
      to: "/favorite",
      icon: <GrFavorite className="text-lg" />,
      label: "My Favorites",
    },
  ];

  return (
    <>
      <div className="flex">
        {/* Sidebar */}
        <div
          className={`fixed top-28 left-0 h-[80vh] bg-white text-white z-40 transition-transform duration-300 lg:translate-x-0 overflow-y-scroll ${isOpen ? "translate-x-0" : "-translate-x-full"
            } md:w-1/4 w-3/4 sm:w-1/2`}
        >
          <div className="p-5">
            <ul className="space-y-3">
              {menuItems.map((item, index) => (
                <li key={index}>
                  <Link
                    to={item.to}
                    className="flex items-center font-medium gap-3 p-3 rounded text-black"
                  >
                    {item.icon}
                    <span>
                      {item.label}
                      {item?.power && <sup>TM</sup>}
                    </span>
                  </Link>
                  <hr />
                </li>
              ))}
              <li>
                <button
                  onClick={Logout}
                  className="flex items-center gap-3 w-full text-left p-3 rounded text-black"
                >
                  <IoIosLogOut className="text-lg" />
                  <span>Logout</span>
                </button>
              </li>
            </ul>
          </div>
        </div>

        {/* Toggle button for small and medium devices */}
        <div className="lg:hidden flex justify-start fixed sm:top-16 md:top-28 py-4 bg-white z-[1000] w-full px-4">
          <button
            className="text-black font-light"
            onClick={toggleSidebar}
            aria-label="Toggle Menu"
          >
            {isOpen ? (
              <XMarkIcon aria-hidden="true" className="size-6" />
            ) : (
              <Bars3Icon aria-hidden="true" className="size-6" />
            )}
          </button>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
