import React from "react";
import FavoriteR from "../Components/FavoriteR";
import SidebarLayout from "../Components/sidebar/SidebarLayout";

function Favorite() {
  return (
    <>
      <SidebarLayout>
        <FavoriteR />
      </SidebarLayout>
    </>
  );
}

export default Favorite;
