import React from 'react'

const BtnDiscount = ({setOpenDiscount}) => {
  return (
    <button className="booking_btn" onClick={()=>setOpenDiscount(true)}
    style={{
        display: 'block',
        width: '100%',
        padding: '12px 0',
        marginBottom: '8px',
        backgroundColor: '#949ca5',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        fontSize: "14px",
        cursor: 'pointer',
        fontWeight: "600",
    }}

>
    Discount
</button>
  )
}

export default BtnDiscount
