import React from "react";
import { Link } from "react-router-dom";

const FindUsImage = () => {
  return (
    <div>
      <div className="bg-white py-10">
        <div className="container mx-auto">
          <h2 className="text-2xl font-bold text-center">Find Us...</h2>
          <p className="text-xl text-center">
            You'll see us in the following groovy publications:
          </p>
          <div className="flex flex-wrap justify-center">
            <div
              className="swiper-slide swiper-slide-duplicate"
              style={{ width: "200px", height: "150px", marginRight: 10 }}
            >
              <Link
                to="https://www.flyingmag.com/destinations/ga-flight-planning-website-takes-the-guesswork-out-of-finding-next-destination/
"
                className="client-item"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="images/bg/groovy.jpg" alt="" />
              </Link>
            </div>

            <div
              className="swiper-slide swiper-slide-duplicate"
              style={{ width: "200px", height: "150px", marginRight: 10 }}
            >
              <Link
                to="https://www.barnstormers.com/
"
                className="client-item"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="images/bg/groovy1.jpg" alt="" />
              </Link>
            </div>

            <div
              className="swiper-slide swiper-slide-duplicate"
              style={{ width: "200px", height: "150px", marginRight: 10 }}
            >
              <Link
                to="https://generalaviationnews.com/2024/04/08/a-different-kind-of-fly-in-2/
"
                className="client-item"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="images/bg/groovy2.jpg" alt="" />
              </Link>
            </div>

            <div
              className="swiper-slide swiper-slide-duplicate"
              style={{ width: "200px", height: "150px", marginRight: 10 }}
            >
              <Link
                to="https://www.piperflyer.com/component/fileman/file/0624_PiperFlyer.pdf.html?routed=1&container=fileman-attachments
"
                className="client-item"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="images/bg/groovy4.png" alt="" />
              </Link>
            </div>

            <div
              className="swiper-slide swiper-slide-duplicate"
              style={{ width: "200px", height: "150px", marginRight: 10 }}
            >
              <Link
                to="https://robbreport.com/motors/aviation/gallery/best-fly-ins-north-america-1235603388/rr-mv-requests-050724-3/
"
                className="client-item"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="images/bg/groovy5.jpg" alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindUsImage;
