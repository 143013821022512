import React from "react";
import Layout from "../Components/layout/Layout";
import Philanthropy from "../Components/philanthropy/Philanthropy";

const PhilanthropyPage = () => {
  return (
    <Layout>
      <div id="wrapper">
        <Philanthropy />
      </div>
    </Layout>
  );
};

export default PhilanthropyPage;
