
export const ADD_LISTING_DEFAULT_VALUES = {
    is_draft: true,
    host_id: '',
    listing_type: '',
    address: "",
    apt_suite: "",
    city: "",
    state: "",
    zipcode: "",
    area: "",
    country: "",
    images: [],
    title: "",
    type_of_space: "",
    dwelling: "",
    no_of_bedrooms: "",
    no_of_guest: "",
    no_of_beds: "",
    no_of_bathroom: "",
    no_of_room: "",
    size: '',
    unit_of_measure: '',
    description: '',
    bedrooms: [
        { name: "", guest: "", no_of_bed: "", bed_type: "" }
    ],
    airports: [
        {
            airport_identifier: '',
            airport_name: '',
            airport_use: "",
            ctaf_unicom: "",
            dimensions_feet: "",
            dimensions_meter: "",
            elevation_feet: 0,
            elevation_meter: 0,
            fuel: [],
            lighting: 0,
            operation_hours: "",
            orientation: "",
            runway_condition: "",
            parking: "",
            pattern: "",
            surface: [],
            air_nav: '',
            ground_transportation: "",
            distance_from_runway: "",
            additional_info: ''
        }
    ],
    instant_booking: false,
    nightly: "",
    apply_weekend_price: "",
    weekend_nightly_price: "",
    weekly_seven_plus: "",
    weekly_thirty_plus: "",
    additional_guest: 0,
    no_of_additonal_guest: "",
    additional_guest_price: "",
    pet_allowed: 0,
    no_of_pets: "",
    price_per_pet: "",
    cleaning_fee: "",
    cleaning_freq: "Daily",
    city_fee: "",
    city_fee_freq: "Daily",
    tax: "",
    custom_period_pricing: 0,
    custom_periods: [
        {
            start_date: null,
            end_date: null,
            nightly_price: "",
            weekend_price: "",
            price_add_guest: "",
        },
    ],
    extra_services: 0,
    extra_service: [
        { name: "", price: "", type: "", quantity: "" },
    ],
    features: [
        {
            id: '',
            sub_features: [],
        }
    ],
    cancellation_policy_short: '',
    cancellation_policy_long: '',
    min_day_booking: 0,
    max_day_booking: 10,
    check_in_after: '',
    check_in_before: '',
    smoking_allowed: 0,
    smoking_rules: '',
    rules_pet_allowed: 0,
    pet_rules: '',
    party_allowed: 0,
    party_rules: '',
    children_allowed: 0,
    children_rules: '',
    infant_ages: {
        min_age: 0,
        max_age: 0
    },
    children_ages: {
        min_age: 0,
        max_age: 0
    },
    rules: '',
    welcome_message: '',
    blocked_dates: [],
};