// ListingTypeForm.jsx
import React, { useRef, useState } from 'react';
import { AirportForm, BedroomForm, CustomDateSelectionCalender, FeatureForm, ImagesForm, InformationForm, ListingTypeSelect, LocationForm, PricingForm, SubmitAndDraftSection, TermsAndRules } from './components';
import { useFormContext } from '../../../providers';

const ListingTypeForm = () => {
    const { handleSubmit } = useFormContext();

    const autoCompleteRef = useRef(null);


    const [drag, setDrag] = useState({
        lat: 0,
        lng: 0
    });

    const [selectedLocation, setSelectedLocation] = useState({
        lat: 0,
        lng: 0
    });


    return (
        <>
            <form onSubmit={handleSubmit} className="p-6 space-y-4">
                <ListingTypeSelect />
                <LocationForm setSelectedLocation={setSelectedLocation} selectedLocation={selectedLocation} drag={drag} setDrag={setDrag} autoCompleteRef={autoCompleteRef} />
                <ImagesForm />
                <InformationForm />
                <BedroomForm />
                <AirportForm />
                <PricingForm />
                <FeatureForm />
                <TermsAndRules />
                <CustomDateSelectionCalender />
                <SubmitAndDraftSection />
            </form>
        </>
    );
};

export default ListingTypeForm;
