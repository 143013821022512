import React from 'react'
import Layout from '../Components/layout/Layout'
import { Link } from 'react-router-dom'

const ServiceFee = () => {
  return (
<Layout>
<section className="main-content-area">
  <div className="container">
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div className="page-wrap">
          <div className="article-main">
            <article className="single-page-article text-center">
              <div className="article-detail block-body">
                <h1>Oh oh! Listing not found.</h1>
                <p>
                  We're sorry, but the Listing you are looking for doesn't exist.
                  <br /> Click below to return to the homepage.
                </p>
                <p>
                  <Link to="/">Back to the homepage</Link>
                </p>
              </div>
            </article>
          </div>
        </div>
        {/* grid-listing-page */}
      </div>
      {/* col-xs-12 col-sm-12 col-md-8 col-lg-8 */}
    </div>
    {/* .row */}
  </div>{" "}
  {/* .container */}
</section>

</Layout>
  )
}

export default ServiceFee