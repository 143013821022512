import React from 'react'

const PostCheckOut = ({setOPenPost}) => {
  return (
    <button className="booking_btn_post" onClick={()=>setOPenPost(true)}
    style={{
        display: 'block',
        width: '100%',
        padding: '12px 0',
        marginBottom: '8px',
        backgroundColor: '#54c4d9',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        fontSize: "14px",
        cursor: 'pointer',
        fontWeight: "600",
    }}

>
    Post Checkout Fee
</button>
  )
}

export default PostCheckOut