import React from 'react'
import { useFormContext } from '../../../../providers';

export const SubmitAndDraftSection = () => {
    const { handleDraft } = useFormContext();
    return (
        <div className="flex justify-end gap-4 p-6 bg-white sticky bottom-0 w-full">
            <button
                type='button'
                className="border border-[#3b4249] font-medium bg-[#54c4d9] rounded-md text-white px-8 py-3"
                onClick={handleDraft}>
                Save as Draft
            </button>
            <button
                type='submit'
                className="border border-[#3b4249] font-medium bg-[#8ec639] rounded-md text-white px-8 py-3"
            >
                Submit <i className="fas fa-sign-out" />
            </button>
        </div>
    )
}
