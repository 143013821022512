import React from "react";
import Layout from "../../Components/layout/Layout";
// import { Link } from "react-router-dom";

const PackingListLadies = () => {
  return (
    <Layout>
      <div className="content">
        <section id="sec1" data-scrollax-parent="true">
          <div className="container">
            <div className="section-title">
              <h2>Ultimate Travel Packing List For Ladies</h2>
              {/* <div className="section-subtitle">who we are</div> */}
              <span className="section-separator" />
              {/* <p>Aviation Homes, Your account</p> */}
            </div>
            {/*about-wrap */}
            <div className="about-wrap">
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="list-single-main-media fl-wrap"
                    style={{ boxShadow: "0 9px 26px rgba(58, 87, 135, 0.2)" }}
                  >
                    <img
                      style={{marginTop:"20px"}}

                      src="/image/packlad1.webp"
                      className="respimg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="ab_text">
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      {/* <h3>In order of popularity…</h3> */}

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    <p>
                      Have you ever felt that gnawing feeling like you forgot
                      something only to land and find out that you did? Use this
                      handy-dandy travel pack list to make sure you get never
                      get that feeling again! Don’t be overwhelmed, the list is
                      long and my daughter is making fun of me as I write it,
                      and I can’t stop laughing! But seriously, if you follow
                      this list, you won’t forget a thing! Feel free to only
                      include the things on this list that you know you will
                      need. Moving on…
                    </p>
                    <h3 style={{ display: "flex" }}>Essentials:</h3>
                    <ul>
                      <li className="mt5">
                        <b>Passport & Visas:</b> If you’re traveling
                        internationally.
                      </li>
                      <li className="mt5">
                        <b>Personal Identification:</b> Such as a driver’s
                        license.
                      </li>
                      <li className="mt5">
                        <b>Tickets or Boarding Documents:</b> If required.
                      </li>
                      <li className="mt5">
                        <b>Booking Details:</b> For transportation, lodging,
                        excursions, entries and transportation rentals.
                      </li>
                      <li className="mt5">
                        <b>Wallet:</b> With cash, debit / credit cards,
                        insurance cards and other essential cards.
                      </li>
                      <li className="mt5">
                        <b>Travel Insurance Documents:</b> Especially if
                        traveling abroad.
                      </li>
                      <li className="mt5">
                        <b>Prescription Medications: </b>In their original
                        containers with a copy of the prescription.
                      </li>
                      <li className="mt5">
                        <b>Electronics & Chargers:</b> Phone, tablet, laptop,
                        and the necessary chargers, especially a CAR charger,
                        portable power bank, bluetooth speaker, portable hard
                        drive.
                      </li>
                      <li className="mt5">
                        <b>Adapters & Converters:</b> If traveling to countries
                        with different electrical outlets.
                      </li>
                      <li className="mt5">
                        <b>Keys:</b> For your home, car and airplane.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="about-wrap">
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="list-single-main-media fl-wrap"
                    style={{ boxShadow: "0 9px 26px rgba(58, 87, 135, 0.2)" }}
                  >
                    <img src="/image/packlad2.webp" 
                      style={{marginTop:"20px"}}
                      className="respimg" alt="" />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="ab_text">
                    <div className="ab_text-title fl-wrap">
                      {/* <h3>
                Our Awesome Team <span>Story</span>
              </h3> */}
                      {/* <h3>In order of popularity…</h3> */}

                      <span className="section-separator fl-sec-sep" />
                    </div>
                    {/* <h4 className=" text-start">
                      Spruce Creek Fly-In Community, Florida:
                    </h4> */}

                    <h3 style={{ display: "flex" }}>In-Flight Comfort:</h3>
                    <ul>
                      <li className="mt5">
                        <b>Snacks:</b> Pack your favorites.
                      </li>
                      <li className="mt5">
                        <b>Reusable Water Bottle:</b> Stay hydrated throughout
                        your journey.
                      </li>
                      <li className="mt5">
                        <b>Travel Pillow & Blanket:</b>For added comfort,
                        especially on longer flights.
                      </li>
                      <li className="mt5">
                        <b>Headset:</b> We love our Bose headsets!
                      </li>
                      <li className="mt5">
                        <b>Headphones or Earplugs:</b> In case you want to
                        listen to music, watch movies, or block out noise.
                      </li>
                      <li className="mt5">
                        <b>Entertainment:</b> Books, magazines, music devices,
                        or loaded tablets.
                      </li>
                      <li className="mt5">
                        <b>Gum or Mints: </b>Helpful for ear pressure during
                        takeoff and landing, and for freshening up before
                        arrival.
                      </li>
                    </ul>
                    <h3 style={{ display: "flex", marginTop: "10px" }}>
                      “Proper Preparation Prevents Poor Performance.”
                    </h3>
                    <h3 style={{ display: "flex", marginTop: "20px" }}>
                      Clothing & Accessories:
                    </h3>

                    <ul>
                      <li className="mt5">
                        <b>Comfortable Outfit:</b> Depending on the length of
                        the trip, you might want to wear or pack comfy clothes.
                      </li>
                      <li className="mt5">
                        <b>Change of Clothes:</b> Especially if it’s a overnight
                        journey or you’re going to an event
                      </li>
                      <li className="mt5">
                        <b>Socks / Underwear / Bras:</b> These are overlooked
                        quite often
                      </li>
                      <li className="mt5">
                        <b>Warm Layer:</b>For those of us who tend to be cold.
                      </li>
                      <li className="mt5">
                        <b>Weather-Appropriate Gear:</b> Umbrella, sunglasses,
                        hat, sunscreen, gloves, scarves, ear muffs.
                      </li>
                      <li className="mt5">
                        <b>Shoes and Belts:</b> A comfortable pair for
                        traveling, and possibly an extra pair appropriate for
                        your destination. Make sure your belts match your
                        shoes..
                      </li>
                      <li className="mt5">
                        <b>Activity-Appropriate Gear: </b>Workout clothes and
                        shoes, bathing suit, water shoes, swim cap, goggles,
                        towel.
                      </li>
                      <li className="mt5">
                        <b>Watch and Jewelry : </b>Whatever matches the
                        situations you will be in at your destination. Remember
                        at least a sports watch and wedding ring, if you’re
                        married.
                      </li>
                    </ul>

                    <h3 style={{ display: "flex", marginTop: "20px" }}>
                      Toiletries, Health and Beauty:
                    </h3>

                    <ul>
                      <li className="mt5">
                        <b>
                          Toothbrush / Toothpaste / Floss / Tongue Scraper /
                          Mouthwash :
                        </b>{" "}
                        Good health begins in your mouth.
                      </li>
                      <li className="mt5">
                        <b>Makeup Remover / Cotton Balls :</b> Proper skin care
                        can’t be overlooked.
                      </li>
                      <li className="mt5">
                        <b>Shampoo / Conditioner / Soap : </b> : Hosts will most
                        likely include this, of course, but if you have a
                        favorite one, you might want it along for your trip. We
                        like to save space by just using shampoo as soap.
                      </li>
                      <li className="mt5">
                        <b>Razor :</b>With extra razors. You’ll be glad you have
                        extras.
                      </li>
                      <li className="mt5">
                        <b>Shower Gloves / Scrubbie / Loofah :</b> Exfoliation
                        doesn’t stop being important just because we are
                        traveling.
                      </li>
                      <li className="mt5">
                        <b>Shower Cap:</b> In case you’re not washing your hair
                        some days.
                      </li>
                      <li className="mt5">
                        <b>Foot Scrubber: </b>Gotta keep those heels in good
                        repair!
                      </li>
                      <li className="mt5">
                        <b>Comb / Brush : </b> Whichever one you prefer.
                      </li>
                      <li className="mt5">
                        <b>Cotton Swabs : </b> Think Q-Tips.
                      </li>
                      <li className="mt5">
                        <b>Nose Hair Trimmer : </b> Really…who wants to see the
                        unsightly?! LOL.
                      </li>
                      <li className="mt5">
                        <b>Lotions : </b> For face and body. Be sure to include
                        cleansers and acne spot treatments, if necessary.
                      </li>
                      <li className="mt5">
                        <b>Deodorant : </b>We only want pleasant scents when
                        locked up with loved ones!
                      </li>
                      <li className="mt5">
                        <b>Perfume: </b> See deodorant.
                      </li>
                      <li className="mt5">
                        <b>Eyelash Serum : </b>Long lashes will always come in
                        handy. Those who have them know what we mean!
                      </li>
                      <li className="mt5">
                        <b>Tissues / Toilet Paper : </b> Especially when
                        airplane camping.
                      </li>
                      <li className="mt5">
                        <b>Matches : </b> If you don’t know why, find out on
                        your own! (Notice this is conveniently written below
                        toilet paper and above wipes.)
                      </li>
                      <li className="mt5">
                        <b>Flushable Wipes : </b> Don’t need to explain this
                      </li>
                      <li className="mt5">
                        <b>First Aid : </b> Think Band-Aids, Neosporin
                      </li>{" "}
                      <li className="mt5">
                        <b>Gastro-intestinal : </b> Think Pepto-Bismol, Tagamet,
                        Tums
                      </li>{" "}
                      <li className="mt5">
                        <b>Pain Relief : </b>Think Tylenol, Ibuprofen.
                      </li>{" "}
                      <li className="mt5">
                        <b>Cold and Allergy Meds : </b> Think Benadryl,
                        Claritin, Albuterol, Nyquil
                      </li>{" "}
                      <li className="mt5">
                        <b>Eyedrops : </b> Think Visene.
                      </li>{" "}
                      <li className="mt5">
                        <b>Nasal Spray : </b> Think Afrin
                      </li>{" "}
                      <li className="mt5">
                        <b>Supplements : </b>What you take daily.
                      </li>{" "}
                      <li className="mt5">
                        <b>Medications : </b> Be sure to take the prescription
                        along with you as well.
                      </li>
                      <li className="mt5">
                        <b>Hand Sanitizer : </b> Can be wipes or liquid.
                      </li> <li className="mt5">
                        <b>Lip Balm : </b> Think Carmex.
                      </li> <li className="mt5">
                        <b>Makeup : </b>Coverup, foundation, powders, brushes and applicators, pencils and sharpeners, eyelash conditioner, mascara, eyelash curler, lipstick or gloss, etc.
                      </li> <li className="mt5">
                        <b>Nails : </b>  Clippers, file, cuticle cutters and removers, nail polishes, individually wrapped acetone wipes.
                      </li>
                      <li className="mt5">
                        <b>Blow Dryer / Flat Iron / Curling Iron / Glove / Hair products : </b>  For allll your hair needs should you have flown with the window open and the head scarf blew off! (Laughs are compliments of my daughter 😂😂😂 )
                      </li>
                    </ul>
                    <h3 style={{display:'flex',marginTop:'20px'}}>Miscellaneous:</h3>

                    <ul>
                      <li className="mt5">
                        <b>Camera:</b> To capture memories, charger, extra batteries, memory card, card reader.
                      </li>
                      <li className="mt5">
                        <b>Selfie Stick / Tripod :</b> Some selfie-sticks come with a built-in tripod.
                      </li>
                      <li className="mt5">
                        <b>AirPods:</b> And charger.
                      </li>
                      <li className="mt5">
                        <b>Binoculars:</b>If you enjoy aerial sightseeing.
                      </li>
                      <li className="mt5">
                        <b>Travel Journal & Pen :</b>  To jot down your experiences.
                      </li>
                      <li className="mt5">
                        <b>Reusable Shopping Bag :</b>Handy for shopping at your destination or for storing items during your trip.
                      </li>
                      <li className="mt5">
                        <b>Sewing Kit : </b>For the occasional mishaps.
                      </li>
                      <li className="mt5">
                        <b>Lint Roller / Detergent / Stain Remover : </b>Yes, they sell these travel-size.
                      </li>
                      <h3 style={{display:"flex"}}>Sleeping:</h3>
                      <li className="mt5">
                        <b>Sleepwear : </b>If it’s an extended journey or there are sleeping facilities onboard.
                      </li> <li className="mt5">
                        <b>Slippers or Socks : </b>For added comfort. Think Fuzzy
                      </li> <li className="mt5">
                        <b>Eye Mask : </b>For taking naps.
                      </li> <li className="mt5">
                        <b>Ear Plugs : </b>For a sound sleep.
                      </li> 
                      <h3 style={{display:'flex',marginTop:"20px"}}>Reading / Studying / Working:</h3>
                      <li className="mt5">
                        <b>Guidebooks & Maps : </b>If you’re exploring a new destination.
                      </li>
                      <li className="mt5">
                        <b>Books / Magazines : </b>Faves.
                      </li> <li className="mt5">
                        <b>Highlighters / Pencils / Eraser / Sharpener / Pens : </b>For note-taking.
                      </li> <li className="mt5">
                        <b>Reading Light : </b>For dark flights.
                      </li> <li className="mt5">
                        <b>Reading Glasses / Contacts with Case / Lens Cleaner : </b>n case you can’t read this LOL!
                      </li>
                      <li className="mt5">
                        <b>Language Translation Book or App : </b>If you’re traveling to a country where you’re unfamiliar with the language.
                      </li>
                    </ul>
                    <h3 style={{display:'flex',marginTop:"20px"}}>Now you can travel and relax knowing you have eeeverything you need! Clear for takeoff!</h3>
                    <img
                      src="/image/packlad3.webp"
                      style={{marginTop:"20px"}}
                      className="respimg"
                      alt=""
                    />
                    <p style={{marginTop:"20px"}} >Before you fly, always check the weight of your gear combined with the weight of your aircraft, fuel, and passengers to ensure you’re within the safe weight and balance limits. Also, adjust the list based on the nature of your journey, the specifics of your aircraft, and the regulations of the regions you’re flying to. Safe travels!</p>

                    <p>Did we forget anything? Is there anything you deem essential when you travel that isn’t already on this list? Please comment so we can add it to the list and so your fellow flyers can benefit!</p>


                  </div>
                </div>
              </div>
            </div>

            {/* about-wrap end  */}
            <span className="fw-separator" />
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default PackingListLadies;
