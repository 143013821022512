import React, { useEffect, useState } from "react";
import Layout from "../Components/layout/Layout";
import { Link, useParams } from "react-router-dom";
import BASE_URL from "../Components/auth/Baseurl";
import axios from "axios";
import Loading from "../Components/loader/Loading";

const BySpace = () => {
  const { location } = useParams();
  const [loading, setLoading] = useState(false);
  const [Theme, SetTheme] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("byTypeOfSpace", location.replace(/_/g, " "));

        const response = await axios.post(
          `${BASE_URL}/listing-search`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        SetTheme(response?.data?.listings);
        setLoading(false);
        console.log(response.data.listings, "values inner");
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [location]);

  return loading ? (
    <>
      <Loading />
    </>
  ) : (
    <>
      <Layout>
        {Theme.length === 0 ? (
          <>
            <div
              style={{
                paddingTop: "100px",
                height: "90vh",
                justifyContent: "center",
                alignItems: " center",
                display: "flex",
              }}
              className="container big-container"
            >
              <div style={{ justifyContent: "center", alignItems: "center" }}>
                <h3 style={{ fontSize: "20px" }}>Oh oh! Page not found. </h3>
                <p style={{ fontSize: "15px" }}>
                  We're sorry, but the page you are looking for doesn't exist.
                  Click below to return to the homepage.
                </p>
                <p style={{ fontSize: "15px" }}>Back to the homepage</p>
              </div>
            </div>
          </>
        ) : (
          <>
            <section>
              <div className="container big-container">
                <p
                  style={{
                    textAlign: "left",
                    paddingLeft: "20px",
                    fontSize: "20px",
                  }}
                >
                  HomeUnited States of America (Page 1)
                </p>
                <h1
                  style={{
                    textAlign: "left",
                    paddingLeft: "20px",
                    fontSize: "20px",
                  }}
                  className="mt-5"
                >
                  {location.replace(/_/g, " ")}
                </h1>
                <p
                  style={{
                    textAlign: "left",
                    paddingLeft: "20px",
                    fontSize: "20px",
                  }}
                >
                  United States of America
                </p>

                <div className="grid-item-holder gallery-items fl-wrap">
                  <div
                    style={{ display: "flex", flexWrap: "wrap" }}
                    className="row"
                  >
                    {/* Mapping over Theme array to render multiple items */}
                    {Theme?.map((e) => {
                      return (
                        <div className="gallery-item hotels">
                          {/* listing-item  */}
                          <div className="listing-item">
                            <article className="geodir-category-listing fl-wrap">
                              <div className="geodir-category-img">
                            
                                <Link
                                  to={`/details/${e.id}`}
                                  className="geodir-category-img-wrap fl-wrap"
                                >
                                  <img
                                    src={e?.listing_images[0]?.image}
                                    alt=""
                                  />
                                </Link>
                                <div className="listing-avatar">
                                  <Link to={`/details/${e.id}`}>
                                    <img src={e?.host?.image} alt="" />
                                  </Link>
                             
                                </div>
                              
                                <div className="geodir-category-opt">
                                  <div className="listing-rating-count-wrap">
                                    <div
                                      className="listing-rating card-popup-rainingvis"
                                      data-starrating2={5}
                                    />
                                    <br />
                                    <div className="reviews-count"></div>
                                  </div>
                                </div>
                              </div>
                              <div className="geodir-category-content fl-wrap title-sin_item">
                                <div className="geodir-category-content-title fl-wrap">
                                  <div className="geodir-category-content-title-item">
                                    <h3 className="title-sin_map">
                                      <Link to={`/details/${e.id}`}>
                                        {e?.host?.first_name}
                                      </Link>
                                      <span className="verified-badge">
                                        <i className="fal fa-check" />
                                      </span>
                                    </h3>
                                    <div className="geodir-category-location fl-wrap">
                                      <Link to={`/details/${e.id}`}>
                                        <i className="fas fa-map-marker-alt" />{" "}
                                        {e?.address}
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                                <div className="geodir-category-text fl-wrap">
                                  <p className="small-text">
                                    {e?.house_rule_addi_info}
                                  </p>
                                  <div className="facilities-list fl-wrap">
                                    <div className="facilities-list-title">
                                      Facilities :{" "}
                                    </div>
                                    <ul className="no-list-style">
                                      <li
                                        className="tolt"
                                        data-microtip-position="top"
                                        data-tooltip="Free WiFi"
                                      >
                                        <i className="fal fa-wifi" />
                                      </li>
                                      <li
                                        className="tolt"
                                        data-microtip-position="top"
                                        data-tooltip="Parking"
                                      >
                                        <i className="fal fa-parking" />
                                      </li>
                                      <li
                                        className="tolt"
                                        data-microtip-position="top"
                                        data-tooltip="Non-smoking Rooms"
                                      >
                                        <i className="fal fa-smoking-ban" />
                                      </li>
                                      <li
                                        className="tolt"
                                        data-microtip-position="top"
                                        data-tooltip="Pets Friendly"
                                      >
                                        <i className="fal fa-dog-leashed" />
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="geodir-category-footer fl-wrap">
                                  <Link className="listing-item-category-wrap">
                                    <div className="listing-item-category  yellow-bg">
                                      <i className="fal fa-bed" />
                                    </div>
                                    <span>{e?.type_of_space}</span>
                                  </Link>
                                  <div className="geodir-opt-list">
                                    <ul className="no-list-style">
                                      <li>
                                        <Link
                                          to={`/details/${e.id}`}
                                          className="show_gcc"
                                        >
                                          <i className="fal fa-envelope" />
                                          <span className="geodir-opt-tooltip">
                                            Contact Info
                                          </span>
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          to={`/details/${e.id}`}
                                          className="single-map-item"
                                          data-newlatitude="40.72228267"
                                          data-newlongitude="-73.99246214"
                                        >
                                          <i className="fal fa-map-marker-alt" />
                                          <span className="geodir-opt-tooltip">
                                            On the map <strong>4</strong>
                                          </span>
                                        </Link>
                                      </li>
                                      <li>
                                        <div
                                          className="dynamic-gal gdop-list-link"
                                          data-dynamicpath="[{'src': 'images/all/16.jpg'},{'src': 'images/all/27.jpg'}, {'src': 'images/all/20.jpg'}]"
                                        >
                                          <i className="fal fa-search-plus" />
                                          <span className="geodir-opt-tooltip">
                                            Gallery
                                          </span>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="price-level geodir-category_price">
                                    <span
                                      className="price-level-item"
                                      data-pricerating={4}
                                    />
                                    <span className="price-name-tooltip">
                                      Ultra High
                                    </span>
                                  </div>
                                  <div className="geodir-category_contacts">
                                    <div className="close_gcc">
                                      <i className="fal fa-times-circle" />
                                    </div>
                                    <ul className="no-list-style">
                                      <li>
                                        <span>
                                          <i className="fal fa-phone" /> Call :{" "}
                                        </span>
                                        <Link to={`/details/${e.id}`}>
                                          +38099231212
                                        </Link>
                                      </li>
                                      <li>
                                        <span>
                                          <i className="fal fa-envelope" />{" "}
                                          Write :{" "}
                                        </span>
                                        <Link to={`/details/${e.id}`}>
                                          yourmail@domain.com
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </article>
                          </div>
                          {/* listing-item end */}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </Layout>
    </>
  );
};

export default BySpace;
