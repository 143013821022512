
import React from 'react'
import { Modal, Button } from 'react-bootstrap'

const BookingModal = ({ isOpen, setIsOpen }) => {
    const handleClose = () => setIsOpen(false);

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center'
        }}>
            <Modal
                show={isOpen}
                onHide={handleClose}
                // backdrop="static"
                keyboard={false}

            >
                <Modal.Header >
                    <Modal.Title>Decline Reason</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <form id="add-comment" className="add-comment  custom-form" name="rangeCalc">
                        <fieldset>
                         
                            <div className="list-single-main-item_content fl-wrap">
                             
                                <textarea
                                    cols={40}
                                    rows={3}
                                    placeholder="Write Your Reason:"
                                    defaultValue={""}
                                />
                                
                            </div>
                        </fieldset>
                    </form>

                </Modal.Body>
                <Modal.Footer>

                    <Button variant="primary" onClick={handleClose}>Save</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default BookingModal