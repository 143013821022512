import React from 'react'
import HeaderTwo from './HeaderTwo'
// import Sidebar from '../Components/Sidebar'
import BookingViewR from '../Components/BookingViewR'
import SidebarLayout from '../Components/sidebar/SidebarLayout'

const BookingView = () => {
  return (
    <>
      <SidebarLayout>
        <HeaderTwo />
        <div id="wrapper" className="col-md-12">
          <div className="relative top-16 sm:top-16 md:top-14 ls:top-[unset]">
            {/* <Sidebar /> */}
            <BookingViewR />
          </div>
        </div>
      </SidebarLayout>
    </>
  )
}

export default BookingView