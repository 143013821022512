/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import DatePicker from "react-datepicker";
import axios from "axios";
import BASE_URL from "./auth/Baseurl";
import { useNavigate } from "react-router-dom";
import ViewReview from "./ViewReview";
import ReplyReview from "./ReplyReview";
import Swal from "sweetalert2";
const ReviewR = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isOpen2, setIsOpen2] = useState(false);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/booking-review`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setData(response.data.data);
      console.log(response, "values");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const [selectedId, setSelectedId] = useState(null);

  // reply start //
  const [selectReply, setSelectReply] = useState(null);
  const [replyModal, setReplyModal] = useState(false);
  const [selectedRowStatus, setSelectedRowStatus] = useState('');
  const [sendReply, setSendReply] = useState("");
  const [activeButton, setActiveButton] = useState("All");

  const handleReply = (row) => {
    if (row.original.reply !== 'No Reply') {
      setSelectReply(row);
      setSelectedRowStatus(row.original.status)
      setReplyModal(true);
    }
  };
  // reply end //
  const handleOpenModal = (row) => {
    setSelectedId(row);
    setSelectedRowStatus(row.original.status)
    setIsOpen2(true);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "booking_id",
        header: "ID",
        size: 150,
      },
      {
        accessorKey: "host_username",
        header: "Host",
        size: 150,
      },
      {
        accessorKey: "user_username",
        header: "Guest",
        size: 150,
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 150,
      },

      {
        accessorKey: "arrive_date",
        header: "Check-In Date",
        size: 150,
      },
      {
        accessorKey: "listing_title",
        header: "Listing Title",
        size: 150,
      },
      {
        accessorKey: "action",
        header: "Actions",
        size: 200,
        Cell: ({ row }) => (
          <label class="dropdown_listing">
            <div class="dd-button">Actions</div>
            <input type="checkbox" class="dd-input" id="test" />
            <ul class="dd-menu">
              <li onClick={() => handleOpenModal(row)}>View</li>
              <li
                onClick={() => {
                  if (row.original.reply) {
                    Swal.fire({
                      icon: "info",
                      text: row.original.reply === 'No Reply' ? 'You have already chosen to not reply, and this was a final action.' : "You can only reply to a review one time.",
                    });
                  } else {
                    if (row.original.reply !== 'No Reply') {
                      handleReply(row); setSelectedId(row);
                    }
                  }
                }}
              >
                Reply
              </li>
              {/* <li onClick={() => handleNoReply(row)}>No Reply</li> */}
            </ul>
          </label>
        ),
      },
    ],
    [navigate]
  );

  const handleSubmit = async () => {
    if (selectedId?.original?.status === "New") {
      const review = new FormData();
      review.append("status", "Pending");
      review.append("booking_id", selectedId?.original?.booking_id);
      try {
        const response = await axios.post(
          `${BASE_URL}/booking-review`,
          review,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        fetchData();
        setIsOpen2(false);
      } catch (err) {
        Swal.fire({
          icon: "error",
          text: err?.response?.data?.message,
        });
        setIsOpen2(false);
      }
    } else {
      fetchData();
      setIsOpen2(false);
    }
  };
  console.log(selectedId, "selectedId");
  const handleSubmitReply = async () => {
    if (!selectedId?.original?.status === "Replied") {
      if (!sendReply) {
        Swal.fire({
          icon: "info",
          text: "Please reply to the review",
        });
        setReplyModal(false);
        return;
      }
      const review = new FormData();

      review.append("status", "Replied");
      review.append(
        "booking_id",
        selectReply?.original?.booking_id || selectedId?.original?.booking_id
      );
      review.append("reply", sendReply);
      try {
        const response = await axios.post(
          `${BASE_URL}/booking-review`,
          review,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        Swal.fire({
          position: "center",
          html: `
                <div style="display: flex; flex-direction: column; align-items: center;">
     <svg
                  viewBox="0 0 1024 1024"
                  height="4em"
                  width="4em"
                  style="margin-bottom: 10px;"
              >
                  <!-- Outer circle with color #8ec639 -->
                  <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                  
                  <!-- Check mark with color #A2c66b -->
                  <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
              </svg>
                    <p style="margin: 0; font-size: 1.2em;">Reply submitted successfully.</p>
                </div>
            `,
          showConfirmButton: true,
          // timer: 1500  // Close after 1.5 seconds
        });
        fetchData();
        setReplyModal(false);
        {
          isOpen2 && setIsOpen2(false);
        }
      } catch (err) {
        Swal.fire({
          icon: "error",
          text: err?.response?.data?.message,
        });
        setReplyModal(false);
      }
    } else {
      setReplyModal(false);
      {
        isOpen2 && setIsOpen2(false);
      }
    }
  };
  const handleNoReply = async (row) => {
    const review = new FormData();
    console.log(row.original.status, "checkingStatus");

    if (row?.original?.status === "Replied") {
      review.append("status", row?.original?.status);
    } else {
      review.append("status", "No Reply");
    }
    review.append("booking_id", row?.original?.booking_id);
    try {
      const response = await axios.post(`${BASE_URL}/booking-review`, review, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      Swal.fire({
        position: "center",
        html: `
                    <div style="display: flex; flex-direction: column; align-items: center;">
         <svg
                      viewBox="0 0 1024 1024"
                      height="4em"
                      width="4em"
                      style="margin-bottom: 10px;"
                  >
                      <!-- Outer circle with color #8ec639 -->
                      <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                      
                      <!-- Check mark with color #A2c66b -->
                      <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
                  </svg>
                        <p style="margin: 0; font-size: 1.2em;">You have chosen to not reply.</p>
                    </div>
                `,
        showConfirmButton: true,
      });
      fetchData();
    } catch (err) {
      console.log(err);
      Swal.fire({
        icon: "error",
        text: err?.response?.data?.message,
      });
    }
  };
  const [roleCheck, setRoleCheck] = useState("");

  const handleSortChange = (e) => {
    setRoleCheck(e.target.value);
  };
  return (
    <>
      {replyModal && (
        <ReplyReview
          setSendReply={setSendReply}
          selectedId={selectedId}
          replyModal={replyModal}
          setReplyModal={setReplyModal}
          selectedRowStatus={selectedRowStatus}
          selectReply={selectReply}
          handleSubmitReply={handleSubmitReply}
        />
      )}
      {isOpen2 && (
        <ViewReview
          isOpen2={isOpen2}
          setIsOpen2={setIsOpen2}
          selectedId={selectedId}
          handleSubmit={handleSubmit}
          selectedRowStatus={selectedRowStatus}
          handleSubmitReply={handleSubmitReply}
          setSendReply={setSendReply}
        />
      )}

      <div className="col-md-12" style={{ marginTop: "30px" }}>
        <div className="dashboard-title">
          <h3>Reviews</h3>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "10px",
            paddingBottom: "30px",
          }}
        >
          <button
            className={`listing_btn ${activeButton === "All" ? "active" : ""}`}
            onClick={() => setActiveButton("All")}
          >
            All (52)
          </button>
          <button
            className={`listing_btn ${activeButton === "I'm a Guest" ? "active" : ""
              }`}
            onClick={() => setActiveButton("I'm a Guest")}
          >
            I'm a Guest
          </button>
          <button
            className={`listing_btn ${activeButton === "I'm a Host" ? "active" : ""
              }`}
            onClick={() => setActiveButton("I'm a Host")}
          >
            I'm a Host
          </button>
        </div>

        {/* <div style={{ paddingBottom: "20px" }} className="row">
                    <div className="listsearch-input-item col-md-12 pb-10" style={{ width: "300px", display: "flex", alignItems: "center", gap: "10px" }}>
                        <p style={{ fontWeight: "600", width: "100px" }}>Sort are:</p>
                        <select
                            onChange={handleSortChange}
                            className="nice-select chosen-select no-search-select"
                        >
                            <option value="All">All</option>
                            <option value="I'm a Guest">I'm a Guest</option>
                            <option value="I'm a Host">I'm a Host</option>
                        </select>
                    </div>
                </div> */}
        <MaterialReactTable
          columns={columns}
          data={data}
          muiPaginationProps={{
            rowsPerPageOptions: [
              { value: 10, label: "10" },
              { value: 25, label: "25" },
              { value: 50, label: "50" },
              { value: 100, label: "100" },
              { value: data?.length, label: "All" },
            ],
            showFirstButton: false,
            showLastButton: false,
          }}
        />
      </div>
    </>
  );
};

export default ReviewR;
