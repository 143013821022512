import React from 'react'
import Layout from '../Components/layout/Layout'
import { Link } from 'react-router-dom'

const Platform = () => {
  return (
    <>
    <Layout>
    <section className="main-content-area">
  <div className="container">
    <div className="row">
      <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9">
        <div className="page-title">
          <div className="block-top-title">
            <ol className="breadcrumb">
              <li>
                <Link to ="/">
                  <span>Home</span>
                </Link>
              </li>
              <li className="active">Off-the-Platform Fees Policy</li>
            </ol>{" "}
            <h1 className="">Off-the-Platform Fees Policy</h1>
          </div>
          {/* block-top-title */}
        </div>
        {/* page-title */}
      </div>
    </div>
    {/* .row */}
  </div>
  {/* .container */}
  <div className="container">
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 inner-static">
        <div className="page-wrap">
          <div className="article-main">
            <article
              id="post-7702"
              className="single-page-article block post-7702 page type-page status-publish hentry"
            >
              <div className="article-detail block-body">
                <div
                  data-elementor-type="wp-page"
                  data-elementor-id={7702}
                  className="elementor elementor-7702"
                  data-elementor-post-type="page"
                >
                  <div
                    className="elementor-element elementor-element-413af666 e-flex e-con-boxed e-con e-parent"
                    data-id="413af666"
                    data-element_type="container"
                  >
                    <div className="e-con-inner">
                      <div
                        className="elementor-element elementor-element-41994ee6 elementor-widget elementor-widget-text-editor"
                        data-id="41994ee6"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div className="elementor-widget-container">
                          <style
                            dangerouslySetInnerHTML={{
                              __html:
                                "/*! elementor - v3.21.0 - 18-04-2024 */\n.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap{background-color:#69727d;color:#fff}.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap{color:#69727d;border:3px solid;background-color:transparent}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap{margin-top:8px}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter{width:1em;height:1em}.elementor-widget-text-editor .elementor-drop-cap{float:left;text-align:center;line-height:1;font-size:50px}.elementor-widget-text-editor .elementor-drop-cap-letter{display:inline-block}"
                            }}
                          />{" "}
                          <p>{/* wp:paragraph */}</p>
                          <p>
                            You may not collect any other fees or charges off
                            the Platform without express written permission from
                            FlyInn, except in the case of hotels, motels, inns,
                            resorts, etc. in charging and processing certain
                            payments on their payment systems. Hotels use these
                            terms interchangeably, so, for example, what one
                            hotel may call an incidental hold another may call a
                            security deposit.
                          </p>
                          <p>{/* /wp:paragraph */}</p>
                          <p>{/* wp:paragraph */}</p>
                          <p>
                            Following is a list of off-the-platform fees for
                            which hotel hosts do not need to obtain our written
                            permission:
                          </p>
                          <ol>
                            <li>
                              <b>Security deposit</b> – an amount held by the
                              hotel temporarily to to cover potential incidental
                              costs during a guest’s stay or to secure a room
                              booking
                            </li>
                            <li>
                              <b>Incidental hold</b> – an amount that represents
                              the cost of amenities and services that are not
                              included in the hotel’s room rates. It can include
                              costs such as repairs for any damage caused to the
                              room by the guest, valet parking, self-parking,
                              shuttle fees, room service, minibar items, spa
                              treatments, internet access, etc.
                            </li>
                            <li>
                              <b>Cleaning fee</b> – an amount that is usually
                              included in the room rate. If you see a cleaning
                              fee, it might be due to excessive cleaning
                              necessary when a room is left in an excessively
                              dirty or messy condition that requires the
                              cleaning crew to go well beyond the amount of time
                              it normally takes to clean that room
                            </li>
                            <li>
                              <b>Resort fees</b> – an amount charged for
                              conveniences and amenities such as gym, spa, or
                              pool access; pool towels; your in-room safe;
                              wireless internet access; local calls; newspapers;
                              and more.
                            </li>
                          </ol>
                          <p>{/* /wp:paragraph */}</p>
                          <p>{/* wp:paragraph */}</p>
                          <p>All off-the-platform fees must be</p>
                          <ol>
                            <li>Conspicuously outlined in the listing</li>
                            <li>
                              Contain a disclosure that the guest will be
                              charged those fees separately from what they are
                              paying FlyInn and on a separate platform
                            </li>
                            <li>
                              Contain an additional disclosure of when the guest
                              will be required to pay those fees.
                            </li>
                          </ol>
                          <p>{/* /wp:paragraph */}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* .entry-content */}
            </article>
            {/* #post-## */}{" "}
          </div>
        </div>
        {/* grid-listing-page */}
      </div>
    </div>
    {/* .row */}
  </div>{" "}
  {/* .container */}
</section>

    </Layout>
    </>
  )
}

export default Platform