import React from "react";
import Dashboard from "./Dashboard";
import SidebarLayout from "../Components/sidebar/SidebarLayout";

function Listen() {
  return (
    <>
      <SidebarLayout>
        <div className="relative top-16 sm:top-16 md:top-14 ls:top-[unset]">
          <Dashboard />
        </div>
      </SidebarLayout>
    </>
  );
}

export default Listen;
