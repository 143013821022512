/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import Layout from '../Components/layout/Layout'
import { ThemeContext } from '../context/ContextFile'
import BASE_URL from '../Components/auth/Baseurl'
import axios from 'axios'
import { IoIosCheckmarkCircleOutline, IoMdClose } from "react-icons/io";
import { Link } from 'react-router-dom'
const Compare = () => {
    const { compareData } = useContext(ThemeContext)
    console.log(compareData, "compareData");

    const [amenity, setAmenity] = useState([]);
    const GetData = () => {
        axios
            .get(`${BASE_URL}/amenity`, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                setAmenity(res.data?.data || []);
                setMainAmen(res.data?.data || []);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const [facility, setFacility] = useState([]);
    const GetData1 = () => {
        axios
            .get(`${BASE_URL}/facility`, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                setFacility(res.data?.data || []);
                setMainFaci(res.data?.data || [])
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        GetData();
        GetData1();
    }, []);
    console.log(facility, 'facility');
    return (
        <Layout>
            <div style={{ marginTop: "200px" }} className="container">
                <h1 style={{ marginBottom: "20px" }}>Compare</h1>
                <div className="row">
                    <div style={{ backgroundColor: "white" }} className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="compare-table">
                            <table className="table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            {/*  */}
                                        </th>
                                        {compareData?.map((e) => (
                                            <>
                                                <th>
                                                    <Link to={`/details/${e.id}`}>
                                                        <img style={{ maxWidth: "90%" }}
                                                            width={450}
                                                            height={200}
                                                            alt=""

                                                            className="img-responsive wp-post-image ls-is-cached lazyloaded"
                                                            src={e?.listing_images[0]?.image}
                                                        />
                                                    </Link>
                                                </th>
                                            </>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <strong>Title</strong>
                                        </td>
                                        {compareData?.map((e) => (
                                            <>
                                                <td>
                                                    {e?.title}
                                                </td>
                                            </>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Price</strong>
                                        </td>
                                        {compareData?.map((e) => (
                                            <>
                                                <td>
                                                    ${e?.nightly}
                                                </td>
                                            </>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Dwelling</strong>
                                        </td>
                                        {compareData?.map((e) => (
                                            <>
                                                <td>
                                                    {e?.listing_type}
                                                </td>
                                            </>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Size</strong>
                                        </td>
                                        {compareData?.map((e) => (
                                            <>
                                                <td>
                                                    {e?.size} SqFt
                                                </td>
                                            </>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Bedrooms</strong>
                                        </td>
                                        {compareData?.map((e) => (
                                            <>
                                                <td>
                                                    {e?.no_of_bedroom}
                                                </td>
                                            </>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Bathrooms</strong>
                                        </td>
                                        {compareData?.map((e) => (
                                            <>
                                                <td>
                                                    {e?.no_of_bathroom}
                                                </td>
                                            </>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Beds</strong>
                                        </td>
                                        {compareData?.map((e) => (
                                            <>
                                                <td>
                                                    {e?.no_of_beds}
                                                </td>
                                            </>
                                        ))}
                                    </tr>
                                    {facility?.map((facilityItem, index) => (
                                        <tr key={index} style={{
                                            padding: "10px",
                                            textAlign: "left",
                                            fontSize: "20px",
                                            height: "7vh"
                                        }}>
                                            <td>
                                                <strong>{facilityItem?.name}</strong>
                                            </td>
                                            {compareData?.map((compareItem, compareIndex) => (
                                                <td key={compareIndex}>
                                                    {facilityItem.name === compareItem.facility ? <IoIosCheckmarkCircleOutline />
                                                        : <IoMdClose />}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                    {amenity?.map((facilityItem, index) => (
                                        <tr key={index} style={{
                                            padding: "10px",
                                            textAlign: "left",
                                            fontSize: "20px",
                                            height: "7vh"
                                        }}>
                                            <td>
                                                <strong>{facilityItem?.name}</strong>
                                            </td>
                                            {compareData?.map((compareItem, compareIndex) => (
                                                <td key={compareIndex}>
                                                    {facilityItem.name === compareItem.amenity ? <IoIosCheckmarkCircleOutline />
                                                        : <IoMdClose />
                                                    }
                                                </td>
                                            ))}
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Layout >

    )
}

export default Compare



