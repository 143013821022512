import React from 'react'
// import Sidebar from '../Components/Sidebar'
import BookDetailR from '../Components/BookDetailR'
import HeaderTwo from './HeaderTwo'
import SidebarLayout from '../Components/sidebar/SidebarLayout'

const BookingD = () => {
  return (
    <>
      <SidebarLayout>

        <HeaderTwo />
        <div id="wrapper" className="col-md-12">
          <div className="relative top-16 sm:top-16 md:top-14 ls:top-[unset]">
            {/* <Sidebar /> */}
            <BookDetailR />
          </div>
        </div>
      </SidebarLayout>
    </>
  )
}

export default BookingD