import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import { useEffect, useState } from "react";
import Layout from "../Components/layout/Layout";

function Gallery() {
  const onInit = () => {
    console.log("lightGallery has been initialized");
  };

  const [images, setImages] = useState([]);
  useEffect(() => {
    const imageUrls = [
      "image/gallery0.webp",
      "image/gallery1.webp",
      "image/gallery2.webp",
      "image/gallery3.webp",
      "image/gallery4.webp",
      "image/gallery5.webp",
      "image/gallery6.webp",
      "image/gallery7.webp",
      "image/gallery8.webp",
      "image/gallery9.webp",
    ];
    setImages(imageUrls);
  }, []);

  return (
    <>
      <Layout>
        <div className="container mx-auto md:px-20 px-4">
          <div className="section-title">
            <h2 style={{ textAlign: "left" }}>Gallery</h2>
            <p style={{ maxWidth: "none" }}>
              We love to see you smiling 😀 The gallery is where we share your
              smile and beautiful, uplifting (aviation pun intended) moments
              with the rest of our community! Invite us to attend your next
              important aviation-related or aviation-themed event!
            </p>
          </div>
          <div>
            <div className="grid-sizer" style={{ width: "25%" }}></div>
            <LightGallery
              onInit={onInit}
              speed={500}
              plugins={[lgThumbnail, lgZoom]}
            >
              {images?.map((image, index) => (
                <div
                  className="col-sm-3 grid-item"
                  key={index}
                  data-src={image}
                >
                  <div className="listing-item-grid">
                    <div
                      className="bg"
                      style={{
                        backgroundImage: `url(${image})`,
                        backgroundPosition: "top",
                        backgroundSize: "cover",
                      }}
                    />
                  </div>
                </div>
              ))}
            </LightGallery>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Gallery;
