import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import BASE_URL from "../auth/Baseurl";
import { ThemeContext } from "../../context/ContextFile";
import "../../styles/Modal.css";
import { errorPopup, successPopupWithConfirm } from "../../helpers/swirlfire";

const SearchModal = ({ filters, setFilters }) => {
  // const [facility, setFacility] = useState([]);
  // const [amenity, setAmenity] = useState([]);
  const [features, setFeatures] = useState([]);
  const [priceOpen, setPriceOpen] = useState(false);
  const [sizeOpen, setSizeOpen] = useState(false);
  const [subFeatureOpen, setSubFeatureOpen] = useState({});
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [subFeatures, setSubFeatures] = useState([]);
  const [selectedSubFeatures, setSelectedSubFeatures] = useState([]);


  const handleFeatureSelect = async (event) => {
    const featureId = event.target.value;
    setSelectedFeature(featureId);
    setSubFeatureOpen(true); // Automatically open sub-features when a feature is selected

    if (featureId) {

      try {
        const subFeaturesData = await getSubFeatures(featureId); // Fetch sub-features
        setSubFeatures(subFeaturesData);
      } catch (error) {
        console.error("Error fetching sub-features:", error);
      }
    } else {
      setSubFeatures([]); // Clear sub-features if no feature is selected
    }
  };

  // const toggleSubFeatureDropdown = (index) =>
  //   setSubFeatureOpen((prev) => ({
  //     ...prev,
  //     [index]: !prev[index],
  //   }));



  let { SetTheme } = useContext(ThemeContext);

  // const GetData1 = () => {
  //   axios
  //     .get(`${BASE_URL}/facility`, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //     })
  //     .then((res) => {
  //       // console.log(res.data?.data, "values");
  //       setFacility(res.data?.data || []);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };


  // const GetData2 = () => {
  //   axios
  //     .get(`${BASE_URL}/amenity`, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //     })
  //     .then((res) => {
  //       setAmenity(res.data?.data || []); // Ensure amenity is always an array
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const getFeatures = () => {
    axios
      .get(`${BASE_URL}/feature`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        // console.log(res?.data)
        setFeatures(res.data?.data || []); // Ensure amenity is always an array
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSubFeatures = (id) => {
    axios
      .get(`${BASE_URL}/feature/${id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        // console.log("subfeatures", res?.data);
        setSubFeatures(res.data?.feature?.feature_detail || []); // Ensure amenity is always an array
      })
      .catch((err) => {
        console.log(err);
      });
  };




  useEffect(() => {
    // GetData1();
    // GetData2();
    getFeatures();
  }, []);

  const [formData, setFormData] = useState({
    byTypeOfSpace: "",
    byMin: "",
    byMax: "",
    byNoOfBedroom: "",
    byNoOfRoom: "",
    // amenity: [],
    // facility: [],
    byFeatures: [],
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? (checked ? 1 : 0) : value;
    setFormData((prevState) => ({
      ...prevState,
      [name]: inputValue,
    }));
  };

  // const valueGetFacilities = (e) => {
  //   const { name, checked } = e.target;
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     facility: checked
  //       ? [...prevState.facility, name] // Add facility if checked
  //       : prevState.facility.filter((item) => item !== name), // Remove facility if unchecked
  //   }));
  // };
  // const valueGetAmenities = (e) => {
  //   const { name, checked } = e.target;
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     amenity: checked
  //       ? [...prevState.amenity, name]
  //       : prevState.amenity.filter((item) => item !== name),
  //   }));
  // };


  const valueGetFeature = (e) => {
    const { id, checked, name } = e.target;

    setSelectedSubFeatures((prev) => {
      let updatedSubFeatures = [...prev];

      if (checked) {
        // Add the feature if it's checked, but ensure no duplicates
        if (!updatedSubFeatures.some(item => item.id === +id)) {
          updatedSubFeatures.push({ id, name });
        }
      } else {
        // Remove the feature if it's unchecked
        updatedSubFeatures = updatedSubFeatures.filter(item => item.id !== +id);
      }

      return updatedSubFeatures;
    });

    setFormData((prevState) => ({
      ...prevState,
      byFeatures: checked
        ? [...prevState.byFeatures, +id] // Add sub-feature ID to formData
        : prevState.byFeatures.filter((item) => item !== +id), // Remove sub-feature ID from formData
    }));
  };


  const value = new FormData();
  value.append("byTypeOfSpace", formData.byTypeOfSpace);
  value.append("byMin", formData.byMin);
  value.append("byMax", formData.byMax);
  value.append("byNoOfBedroom", formData.byNoOfBedroom);
  value.append("byNoOfRoom", formData.byNoOfRoom);
  // value.append("amenity", JSON.stringify(formData.amenity));
  // value.append("facility", JSON.stringify(formData.facility));
  value.append("byFeatures", JSON.stringify(formData.byFeatures));


  const isFormValid = () => {
    return (
      formData.byTypeOfSpace ||
      formData.byMin ||
      formData.byMax ||
      formData.byNoOfBedroom ||
      formData.byNoOfRoom ||
      formData.amenity ||
      formData.facility
    );
  };

  const GetData = () => {
    if (!isFormValid()) {
      console.log("Please fill out all required fields.");
      return;
    }

    console.log(value)

    axios
      .post(`${BASE_URL}/listing-search`, value, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        SetTheme(res?.data);
        // Success Prompt
        successPopupWithConfirm(res?.data?.message, res?.data?.message);

        setFilters(false);
      })

      .catch((err) => {
        SetTheme(err);
        // Error Prompt
        errorPopup('', err?.response?.data?.message);
      });
  };


  // console.log(formData, "formdata");

  console.log(selectedSubFeatures);


  return (
    <>
      {filters ? (
        <>
          <div
            className="modal fade show fixed inset-0 flex items-center justify-center sm:justify-start"
            id="gridSystemModal"
            tabIndex={-1}
            aria-labelledby="gridModalLabel"
            style={{ display: "block" }}
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog">
              <div className="modal-content">


                <div className="modal-body">
                  <div className="container-fluid bd-example-row">
                    {/* Size and Space Dropdown */}
                    <div className="w-full">
                      {/* Accordion Header */}
                      <button
                        className="w-full flex justify-between items-center py-2 text-gray-800 font-medium border-b"
                        onClick={() => setSizeOpen((open) => !open)}
                      >
                        <span>Size</span>
                        <svg
                          className={`w-5 h-5 transform ${sizeOpen ? "rotate-180" : ""}`}
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </button>

                      {/* Accordion Body */}
                      {sizeOpen && (
                        <div className="p-4 bg-gray-50 space-y-4">
                          {/* Bedrooms Selector */}
                          <div>
                            <label
                              htmlFor="byNoOfBedroom"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Number of Bedrooms
                            </label>
                            <select
                              id="byNoOfBedroom"
                              name="byNoOfBedroom"
                              value={formData.byNoOfBedroom}
                              onChange={handleInputChange}
                              className="w-full mt-1 p-2 border rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                              <option>no of bedrooms</option>
                              {[...Array(20)].map((_, i) => (
                                <option key={i + 1} value={i + 1}>
                                  {i + 1}
                                </option>
                              ))}
                            </select>
                          </div>

                          {/* Rooms Selector */}
                          <div>
                            <label
                              htmlFor="byNoOfRoom"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Number of Rooms
                            </label>
                            <select
                              id="byNoOfRoom"
                              name="byNoOfRoom"
                              value={formData.byNoOfRoom}
                              onChange={handleInputChange}
                              className="w-full mt-1 p-2 border rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                              <option>no of rooms</option>
                              {[...Array(20)].map((_, i) => (
                                <option key={i + 1} value={i + 1}>
                                  {i + 1}
                                </option>
                              ))}
                            </select>
                          </div>

                          {/* Type of Space Selector */}
                          <div>
                            <label
                              htmlFor="byTypeOfSpace"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Type of Space
                            </label>
                            <select
                              id="byTypeOfSpace"
                              name="byTypeOfSpace"
                              value={formData.byTypeOfSpace}
                              onChange={handleInputChange}
                              className="w-full mt-1 p-2 border rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                              <option value={"1"}>Space</option>
                              <option value={"Entire Place"}>Entire Place</option>
                              <option value={"Private Room"}>Private Room</option>
                              <option value={"Shared Room"}>Shared Room</option>
                            </select>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Price Dropdown */}
                    <div className="w-full">
                      {/* Accordion Header */}
                      <button
                        className="w-full flex justify-between items-center py-2 text-gray-800 font-medium border-b"
                        onClick={() => setPriceOpen((open) => !open)}
                      >
                        <span>Price</span>
                        <svg
                          className={`w-5 h-5 transform ${priceOpen ? "rotate-180" : ""}`}
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </button>

                      {/* Accordion Body */}
                      {priceOpen && (
                        <div className="p-4 bg-gray-50 space-y-4">
                          {/* Min Price Selector */}
                          <div>
                            <label htmlFor="byMin" className="block text-sm font-medium text-gray-700">
                              Minimum Price
                            </label>
                            <select
                              id="byMin"
                              name="byMin"
                              value={formData.byMin}
                              onChange={handleInputChange}
                              className="w-full mt-1 p-2 border rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                              <option>Min.</option>
                              <option value="10">$10</option>
                              <option value="20">$20</option>
                              <option value="30">$30</option>
                              <option value="40">$40</option>
                              <option value="60">$60</option>
                              <option value="70">$70</option>
                              <option value="80">$80</option>
                              <option value="90">$90</option>
                              <option value="100">$100</option>
                              <option value="110">$110</option>
                              <option value="120">$120</option>
                              <option value="130">$130</option>
                              <option value="140">$140</option>
                              <option value="150">$150</option>
                              <option value="160">$160</option>
                            </select>
                          </div>

                          {/* Max Price Selector */}
                          <div>
                            <label htmlFor="byMax" className="block text-sm font-medium text-gray-700">
                              Maximum Price
                            </label>
                            <select
                              id="byMax"
                              name="byMax"
                              value={formData.byMax}
                              onChange={handleInputChange}
                              className="w-full mt-1 p-2 border rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                              <option>Max.</option>
                              <option value="50">$50</option>
                              <option value="100">$100</option>
                              <option value="125">$125</option>
                              <option value="150">$150</option>
                              <option value="160">$160</option>
                              <option value="200">$200</option>
                              <option value="250">$250</option>
                              <option value="300">$300</option>
                              <option value="400">$400</option>
                              <option value="500">$500</option>
                              <option value="600">$600</option>
                              <option value="700">$700</option>
                              <option value="800">$800</option>
                              <option value="900">$900</option>
                              <option value="1000">$1000</option>
                              <option value="1200">$1200</option>
                            </select>
                          </div>
                        </div>
                      )}
                    </div>


                    {/* Features Dropdown */}
                    <div className="space-y-4">
                      <div className="border-b">
                        <button
                          className="w-full text-left flex justify-between items-center py-2 text-gray-800 font-large"
                          onClick={() => { }}
                        >
                          <span>Features</span>
                        </button>
                        <div className="w-full">
                          <div className="w-full space-y-4">
                            {/* Features Dropdown */}
                            <select
                              className="w-full p-2 border rounded-md bg-gray-100"
                              onChange={handleFeatureSelect}
                              defaultValue={selectedFeature}
                            >
                              {features.map((feature) => (
                                <option key={feature.id} value={feature.id}>
                                  {feature.heading}
                                </option>
                              ))}
                            </select>

                            {/* Sub-feature List */}
                            {subFeatureOpen && selectedFeature !== null && (
                              <div className="p-4 bg-gray-50 rounded-md shadow-md mt-2">
                                <ul className="space-y-2 d-flex flex-wrap gap-3">
                                  {subFeatures?.map((subItem, subIndex) => (
                                    <li key={subItem?.id} className="flex items-center space-x-2">
                                      <input
                                        id={subItem?.id}
                                        type="checkbox"
                                        name={subItem?.sub_heading}
                                        onChange={valueGetFeature}
                                        className="form-checkbox text-blue-600"
                                      />
                                      <label htmlFor={subItem?.id} className="text-gray-700">
                                        {subItem?.sub_heading}
                                      </label>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}

                            {/* Sub-feature List */}
                            {selectedSubFeatures?.length > 0 && (
                              <div className="p-4 bg-gray-50 rounded-md shadow-md mt-2">
                                <h3>Selected Features </h3>
                                <ul className="space-y-2 d-flex flex-wrap gap-3">
                                  {selectedSubFeatures?.map((subItem, subIndex) => (
                                    <li key={subItem?.id} className="flex items-center space-x-2">
                                      <input
                                        id={subItem?.id}
                                        type="checkbox"
                                        checked
                                        name={subItem?.name}
                                        onChange={valueGetFeature}
                                        className="form-checkbox text-blue-600"
                                      />
                                      <label htmlFor={subItem?.id} className="text-gray-700">
                                        {subItem?.name}
                                      </label>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}

                            {/* Message if no sub-features are available */}
                            {subFeatureOpen && subFeatures?.length <= 0 && (
                              <p className="text-gray-500 mt-2">No sub-features available for this feature.</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>



                <div className="modal-footer">
                  <button
                    onClick={() => setFilters(false)}
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    onClick={GetData}
                    type="button"
                    // disabled={!isFormValid()}
                    className="btn btn-primary"
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default SearchModal;
