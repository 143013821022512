import React from "react";
import BokkingR from "../Components/BokkingR";
import SidebarLayout from "../Components/sidebar/SidebarLayout";

function Booking() {
  return (
    <>
      <SidebarLayout>
        <div className="relative top-16 sm:top-16 md:top-14 ls:top-[unset]">
          <BokkingR />
        </div>
      </SidebarLayout>
    </>
  );
}

export default Booking;
