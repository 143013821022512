import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ImageUploading from "react-images-uploading";
import ImagesCardPost from "./ImagesCardPost";
import { FiSmile } from "react-icons/fi";
import EmojiPicker from "emoji-picker-react";
const BtnModalPost = ({ postValueInput, setPostValueInput, images, setImages, openPost, setOPenPost = () => { } }) => {
    const handleClose = () => setOPenPost(false);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false)
    const handleSave = () => {
        console.log("Saved Data: ", openPost);
        handleClose();
        setPostValueInput("");
        setImages([]);
    };

    const maxNumber = 70;

    const onChange = (imageList) => {
        setImages(imageList);
    };

    const handleDelete = (index) => {
        const updatedImages = images.filter((_, i) => i !== index);
        setImages(updatedImages);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPostValueInput({ ...postValueInput, [name]: value })
    }
    const handleEmojiClick = (emojiObject) => {
        setPostValueInput((prevState) => ({
          ...prevState,
          situation: prevState.situation + emojiObject.emoji,
        }));
      };
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Modal style={{ overflowY: "hidden", overflowX: "hidden", paddingTop: "0px" }} show={openPost} onHide={handleClose} keyboard={false}>
                <div
                    className="modal-content specific-ul"
                    style={{
                        height: "480px",
                        overflowY: "scroll",
                        overflowX: "hidden"
                    }}
                >
                    <Modal.Header>
                        <Modal.Title>Checkout Fee Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="profile-edit-container fl-wrap block_box">
                            <div className="custom-form">

                                <div className="row" style={{ marginBottom: "10px" }}>
                                    <div className="col-sm-6">
                                        <label>
                                            Reason <i className="far fa-envelope" />
                                        </label>
                                        <input
                                            type="text"
                                            name="reason"
                                            placeholder="Reason"
                                            onChange={handleInputChange}

                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <label>
                                            Amount Requested <i className="fal fa-user" />
                                        </label>
                                        <input
                                            type="number"
                                            name="amount_request"
                                            placeholder="Amount Requested"
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                    <div className="col-md-12">
                                        <ImageUploading
                                            multiple
                                            value={images}
                                            onChange={onChange}
                                            maxNumber={maxNumber}
                                            dataURLKey="data_url"
                                            acceptType={["jpg", "jpeg", "png", "webp"]}
                                        >
                                            {({
                                                imageList,
                                                onImageUpload,
                                                isDragging,
                                                dragProps,
                                            }) => (
                                                <div className="upload__image-wrapper">
                                                    <div
                                                        className="fu-text"
                                                        style={isDragging ? { color: "red" } : null}
                                                        onClick={onImageUpload}
                                                        {...dragProps}
                                                    >
                                                        <div className="add-list-media-wrap">
                                                            <div className="listsearch-input-item fl-wrap">
                                                                <div className="fuzone">
                                                                    <form>
                                                                        <div className="fu-text">
                                                                            <span>
                                                                                <i className="fal fa-images" /> PLEASE BE PATIENT WHILE
                                                                                YOUR PHOTOS UPLOAD :)<br />
                                                                               
                                                                            </span>
                                                                            <div className="photoUpload-files fl-wrap" />
                                                                            <div style={{ paddingTop: "27px" }}>
                                                                                <span className="custom_image_button">
                                                                                    Select and upload
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="photoUpload-files fl-wrap" />
                                                    </div>

                                                    <div
                                                        className="row"
                                                        style={{
                                                            display: "flex",
                                                            flexWrap: "wrap",
                                                            justifyContent: "flex-start",
                                                        }}
                                                    >
                                                        {imageList.map((image, index) => (
                                                            <ImagesCardPost
                                                                key={index}
                                                                image={image}
                                                                index={index}
                                                                onDelete={handleDelete}

                                                            />
                                                        ))}
                                                    </div>

                                                </div>
                                            )}
                                        </ImageUploading>
                                    </div>
                                    <div  className="col-sm-12">
                                        <label>
                                            Describe the situation to your guest <i className="fal fa-user" />
                                        </label>
                                        <input 
                                        style={{width:"90%"}}
                                            type="text"
                                            placeholder="Describe the situation to your guest"
                                            name="situation"
                                            value={postValueInput.situation}
                                            onChange={handleInputChange}
                                        />

                                        <FiSmile
                                            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                                            style={{ cursor: "pointer", marginLeft: "8px" ,marginTop:"10px"}}
                                            size={24}
                                        />

                                        {showEmojiPicker && (
                                            <div style={{ position: "absolute", right: "70px", zIndex: 99 }}>
                                                <EmojiPicker onEmojiClick={handleEmojiClick} />
                                            </div>
                                        )}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="col-md-12">

                        <button  className="logout_btn_expense_post" onClick={handleSave}>
                            Submit
                        </button>
                    </Modal.Footer>
                </div>
            </Modal >
        </div >
    );
};

export default BtnModalPost;
