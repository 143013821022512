import React from "react";
import InvoiceR from "../Components/InvoiceR";
import SidebarLayout from "../Components/sidebar/SidebarLayout";

function Invoice() {
  return (
    <>
      <SidebarLayout>
        <InvoiceR />
      </SidebarLayout>
    </>
  );
}

export default Invoice;
