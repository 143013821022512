import React from "react";
import Select from "react-select";
import { useFormContext } from "../../../../providers";
import { DimensionInput, ElevationInput } from "./AirportsComponents";


const colourOptions = [
    { value: "chocolate", label: "Asphalt" },
    { value: "strawberry", label: "Concrete" },
    { value: "Grass", label: "Grass" },
    { value: "Gravel", label: "Gravel" },
    { value: "Ice", label: "Ice" },
    { value: "Sand", label: "Sand" },
    { value: "Snow", label: "Snow" },
    { value: "Water", label: "Water" },
    { value: "Other", label: "Other" },
];
const FuelOptions = [
    { value: "100LL", label: "100LL" },
    { value: "Jet-A", label: "Jet-A" },
    { value: "Jet-A1", label: "Jet-A1" },
    { value: "Jet-A+", label: "Jet-A+" },
    { value: "Jet-B", label: "Jet-B" },
    { value: "MoGas", label: "MoGas" },
    { value: "other", label: "Other" },
];

export const AirportForm = ({ loaders }) => {
    const { setValue, errors, watch } = useFormContext();

    const airports = watch('airports');


    // const handleInputChange = (index, fieldName, value) => {
    //     const updatedFields = [...airports];
    //     updatedFields[index][fieldName] = value;
    //     setValue('airports', updatedFields);
    // };

    // const handleSelectChange = (index, fieldName, selectedOptions) => {
    //     const values = selectedOptions ? selectedOptions.map((option) => option.value) : [];
    //     handleInputChange(index, fieldName, values);
    // };

    const handleOrientationInput = (e, index) => {
        let { value } = e.target;
        value = value.replace(/[^\d]/g, "");
        if (value.length > 2) {
            value = value.slice(0, 2) + "/" + value.slice(2);
        }
        if (value.length > 5) {
            value = value.slice(0, 5);
        }
        const fields = [...airports];
        fields[index]["orientation"] = value;
        setValue('airports', fields);
    };

    const handleSelectOption =
        (selectedOption, index) => {
            const fields = [...airports];
            fields[index]["surface"] = selectedOption?.label;
            setValue('airports', fields);
            // setSelectedOption(selectedOption);
        }

    const handleCloningAirPort = (e, index) => {
        const { name, value } = e.target;
        const fields = [...airports];
        fields[index][name] = value;
        setValue('airports', fields);
        // validateField(name, value, index);
    };

    const addField = (e) => {
        setValue('airports', [
            ...airports,
            {
                airport_identifier: '',
                airport_name: '',
                airport_use: "",
                ctaf_unicom: "",
                dimensions_feet: "",
                dimensions_meter: "",
                elevation_feet: 0,
                elevation_meter: 0,
                fuel: [],
                lighting: "",
                operation_hours: "",
                orientation: "",
                runway_condition: "",
                parking: "",
                pattern: "",
                surface: [],
                air_nav: '',
                ground_transportation: "",
                distance_from_runway: "",
                additional_info: ''
            },
        ]);
    };

    const removeField = (e, index) => {
        e.preventDefault();

        setValue('airports', airports.filter((_, i) => i !== index));
        // setFormErrorsAirport(errors.filter((_, i) => i !== index));
    };


    return (
        <>
            <div className="grid md:grid-cols-12 bg-white p-4 rounded-lg gap-4">
                <h3 className="text-xl font-bold text-center md:col-span-12">
                    Airport Information
                </h3>

                {airports?.map((field, index) => (
                    <>
                        <div className="md:col-span-4 text-left">
                            <label>Identifier</label>
                            <input
                                type="text"
                                placeholder="Identifier"
                                name="airport_identifier"
                                className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full"
                                value={field.airport_identifier}
                                onChange={(e) => handleCloningAirPort(e, index)}
                            />
                            {errors?.airports?.[index]?.airport_identifier && (
                                <p style={{ color: "red" }}>
                                    {errors?.airports?.[index]?.airport_identifier.message}
                                </p>
                            )}
                        </div>
                        <div className="md:col-span-4 text-left">
                            <label>Airport Name</label>
                            <input
                                type="text"
                                placeholder="Airport Name"
                                name="airport_name"
                                className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full"
                                value={field.airport_name}
                                onChange={(e) => handleCloningAirPort(e, index)}
                            />
                            {errors?.airports?.[index]?.airport_name && (
                                <p style={{ color: "red" }}>
                                    {errors?.airports?.[index]?.airport_name.message}
                                </p>
                            )}
                        </div>
                        <div className="md:col-span-4 text-left">
                            <label>Airport Use</label>
                            <select
                                value={field.airport_use}
                                name="airport_use"
                                onChange={(e) => handleCloningAirPort(e, index)}
                                className="border border-input px-3 py-3 rounded-md gap-4 w-full"
                            >
                                <option value="">Airport Use</option>
                                <option value="Public">Public</option>
                                <option value="Private">Private</option>
                                <option value="Private - permission required to land">
                                    Private - permission required to land
                                </option>
                            </select>
                            {errors?.airports?.[index]?.airport_use && (
                                <p style={{ color: "red" }}>
                                    {errors?.airports?.[index]?.airport_use?.message}
                                </p>
                            )}
                        </div>
                        <div className="md:col-span-4 text-left">
                            <label>Operation Hours</label>
                            <input
                                type="text"
                                placeholder="Operation Hours"
                                name="operation_hours"
                                className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full"
                                value={field.operation_hours}
                                onChange={(e) => handleCloningAirPort(e, index)}
                            />
                            {errors?.airports?.[index]?.operation_hours && (
                                <p style={{ color: "red" }}>
                                    {errors?.airports?.[index]?.operation_hours?.message}
                                </p>
                            )}
                        </div>
                        <div className="md:col-span-4 text-left">
                            <label>Lighting</label>
                            <div className="listsearch-input-item">
                                <select
                                    value={field.lighting}
                                    name="lighting"
                                    onChange={(e) => handleCloningAirPort(e, index)}
                                    className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full"
                                >
                                    <option disabled value="">
                                        select
                                    </option>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </select>
                                {errors?.airports?.[index]?.lighting && (
                                    <p style={{ color: "red" }}>
                                        {errors?.airports?.[index]?.lighting?.message}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="md:col-span-4 text-left">
                            <label>CTAF/UNICOM</label>
                            <input
                                type="text"
                                placeholder="CTAF/UNICOM"
                                name="ctaf_unicom"
                                value={field.ctaf_unicom}
                                className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full"
                                onChange={(e) => handleCloningAirPort(e, index)}
                            />
                            {errors?.airports?.[index]?.ctaf_unicom && (
                                <p style={{ color: "red" }}>
                                    {errors?.airports?.[index]?.ctaf_unicom?.message}
                                </p>
                            )}
                        </div>
                        <div className="md:col-span-6 text-left">
                            <label>Fuel</label>
                            <Select
                                value={FuelOptions.filter(option => field.fuel.includes(option.value))}
                                onChange={(selectedFuel) => {
                                    const fields = [...airports];
                                    const selectedFuelValues = selectedFuel.map(
                                        (option) => option.value
                                    );
                                    fields[index]["fuel"] = selectedFuelValues;
                                    setValue('airports', fields);
                                }}
                                isMulti
                                options={FuelOptions}
                            />
                            {errors?.airports?.[index]?.fuel && (
                                <p style={{ color: "red" }}>{errors?.airports?.[index]?.fuel?.message}</p>
                            )}
                        </div>

                        <div className="md:col-span-6 text-left">
                            <label>Parking</label>
                            <div className="listsearch-input-item">
                                <select
                                    value={field.parking}
                                    name="parking"
                                    onChange={(e) => handleCloningAirPort(e, index)}
                                    className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full"
                                >
                                    <option value="">Select Parking</option>
                                    <option value="Hangar">Hangar</option>
                                    <option value="Hangar and Tie-downs">
                                        Hangar and Tie-downs
                                    </option>
                                    <option value="Tie-downs">Tie-downs</option>
                                </select>
                                {errors?.airports?.[index]?.parking && (
                                    <p style={{ color: "red" }}>
                                        {errors?.airports?.[index]?.parking?.message}
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="md:col-span-6 text-left">
                            <label>Orientation</label>
                            <input
                                type="text"
                                placeholder="Orientation"
                                name="orientation"
                                className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full"
                                value={field.orientation}
                                onChange={(e) => handleOrientationInput(e, index)}
                                maxLength={5}
                                pattern="\d{2}/\d{2}"
                            />
                            {errors?.airports?.[index]?.orientation && (
                                <p style={{ color: "red" }}>
                                    {errors?.airports?.[index]?.orientation?.message}
                                </p>
                            )}
                        </div>
                        <div className="md:col-span-6 text-left">
                            <DimensionInput
                                index={index}
                            />
                        </div>

                        <div className="md:col-span-6 text-left">
                            <label>Surface</label>
                            <Select
                                defaultValue={field.surface}
                                name="surface"
                                onChange={(e) => handleSelectOption(e, index)}
                                isMulti
                                options={colourOptions}
                                styles={{
                                    option: (baseStyles, state) => ({
                                        ...baseStyles,
                                        position: "relative",
                                        zIndex: 100,
                                    }),
                                }}
                            />
                            {errors?.airports?.[index]?.surface && (
                                <p style={{ color: "red" }}>
                                    {errors?.airports?.[index]?.surface?.message}
                                </p>
                            )}
                        </div>

                        <div className="md:col-span-6 text-left">
                            <label>Condition</label>
                            <input
                                type="text"
                                placeholder="Condition"
                                name="runway_condition"
                                className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full"
                                value={field.runway_condition}
                                onChange={(e) => handleCloningAirPort(e, index)}
                            />
                            {errors?.airports?.[index]?.runway_condition && (
                                <p style={{ color: "red" }}>
                                    {errors?.airports?.[index]?.runway_condition?.message}
                                </p>
                            )}
                        </div>

                        <div className="md:col-span-6 text-left">
                            <label>Pattern</label>
                            <div className="listsearch-input-item">
                                <select
                                    value={field.pattern}
                                    name="pattern"
                                    onChange={(e) => handleCloningAirPort(e, index)}
                                    className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full"
                                >
                                    <option value="">Select Pattern</option>
                                    <option value="left, left">left, left</option>
                                    <option value="left, right">left, right</option>
                                    <option value="right, left">right, left</option>
                                    <option value="right, right">right, right</option>
                                </select>
                                {errors?.airports?.[index]?.pattern && (
                                    <p style={{ color: "red" }}>
                                        {errors?.airports?.[index]?.pattern?.message}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="md:col-span-6 text-left">
                            <ElevationInput
                                index={index}
                            />
                        </div>

                        <div className="md:col-span-6 text-left">
                            <label>Distance from Runway to Accommodations</label>
                            <div className="listsearch-input-item">
                                <select
                                    value={field.distance_from_runway}
                                    name="distance_from_runway"
                                    onChange={(e) => handleCloningAirPort(e, index)}
                                    className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full"
                                >
                                    <option value="">Select Distance</option>
                                    <option value="0 miles">0 miles</option>
                                    <option value="0 to 1 miles">0 to 1 miles</option>
                                    <option value="1.1 to 3 miles">1.1 to 3 miles</option>
                                    <option value="3.1 to 7 miles">3.1 to 7 miles</option>
                                    <option value="7+ miles">7+ miles</option>
                                </select>
                                {errors?.airports?.[index]?.distance_from_runway && (
                                    <p style={{ color: "red" }}>
                                        {errors?.airports?.[index]?.distance_from_runway?.message}
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="md:col-span-6 text-left">
                            <label>Airnav.com Information</label>
                            <input
                                type="text"
                                placeholder="https://airnav.com/airport/"
                                name="air_nav"
                                className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full"
                                value={field.air_nav || "https://airnav.com/airport/"}
                                onChange={(e) => handleCloningAirPort(e, index)}
                            />
                            {errors?.airports?.[index]?.air_nav && (
                                <p style={{ color: "red" }}>
                                    {errors?.airports?.[index]?.air_nav?.message}
                                </p>
                            )}
                        </div>

                        <div className="dashboard-title md:col-span-12 text-left">
                            <h4>Ground Transportation</h4>
                        </div>
                        <div className="md:col-span-6 text-left">
                            <select
                                value={field.ground_transportation}
                                name="ground_transportation"
                                onChange={(e) => handleCloningAirPort(e, index)}
                                className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full"
                            >
                                <option value="">Select Transportation</option>
                                <option value="We have a courtesy car waiting for you!">
                                    We have a courtesy car waiting for you!
                                </option>
                                <option value="We have a car you can rent from us!">
                                    We have a car you can rent from us!
                                </option>
                                <option value="Rent a car right in the airport! Their phone number is:">
                                    Rent a car right in the airport! Their phone number is:
                                </option>
                                <option value="We will give you a ride to the nearest rental car agency!">
                                    We will give you a ride to the nearest rental car agency!
                                </option>
                                <option value="We have a deal with the FBO so you can keep the car your whole stay!">
                                    We have a deal with the FBO so you can keep the car your whole
                                    stay!
                                </option>
                                <option value="You don't need a car here!">
                                    You don't need a car here!
                                </option>
                            </select>
                            {errors?.airports?.[index]?.ground_transportation && (
                                <p style={{ color: "red" }}>
                                    {errors?.airports?.[index]?.ground_transportation?.message}
                                </p>
                            )}
                        </div>

                        <div className="md:col-span-6 text-left">
                            <input
                                type="text"
                                placeholder="Additional Information"
                                name="additional_info"
                                className="flex border border-input px-3 py-3 rounded-md gap-4 items-center w-full"
                                value={field.additional_info}
                                onChange={(e) => handleCloningAirPort(e, index)}
                            />
                            {errors?.airports?.[index]?.additional_info && (
                                <p style={{ color: "red" }}>
                                    {errors?.airports?.[index]?.additional_info?.message}
                                </p>
                            )}
                        </div>

                        <div className="md:col-span-12 flex justify-start items-start">
                            {airports?.length > 1 && (
                                <button
                                    className="border border-[#3b4249] font-medium bg-[#8ec639] rounded-md text-white px-8 py-3"
                                    onClick={(e) => removeField(e, index)}
                                >
                                    Remove
                                </button>
                            )}
                        </div>

                        <div className="md:col-span-12 text-left flex justify-end items-end">
                            <button
                                type="button"
                                onClick={addField}
                                className="border border-[#3b4249] font-medium bg-[#8ec639] rounded-md text-white px-8 py-3"
                            >
                                Add Airport
                            </button>
                        </div>
                    </>
                ))}


            </div>
        </>

    );
};
