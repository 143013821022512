import React from "react";
import { Button, Modal } from "react-bootstrap";

const BtnModalExpenses = ({ expenses, setExpenses, expenseList, setExpenseList }) => {


    const handleClose = () => setExpenses(false);

    const handleAddMore = () => {
        setExpenseList([...expenseList, { name: "", value: "", quantity: "" ,type:""}]);
    };

    const handleRemove = (index) => {
        const updatedList = [...expenseList];
        updatedList.splice(index, 1);
        setExpenseList(updatedList);
    };

    const handleSave = () => {
        console.log("Saved Data: ", expenseList);
        handleClose();
    };

    // Handle input changes
    const handleInputChange = (index, field, value) => {
        const updatedList = [...expenseList];
        updatedList[index][field] = value;
        setExpenseList(updatedList);
    };

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Modal style={{ overflowY: "hidden", overflowX: "hidden", paddingTop: "0px" }} show={expenses} onHide={handleClose} keyboard={false}>
                <div
                    className="modal-content specific-ul"
                    style={{
                        height: "480px",
                        overflowY: "scroll",
                        overflowX: "hidden"
                    }}
                >
                    <Modal.Header>
                        <Modal.Title>Extra Expenses</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="profile-edit-container fl-wrap block_box">
                            <div className="custom-form">
                                {expenseList.map((expense, index) => (
                                    <div key={index} className="row" style={{ marginBottom: "10px" }}>
                                        <div className="col-sm-6">
                                            <label>
                                                Expense Name <i className="far fa-envelope" />
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Expense Name"
                                                value={expense.name}
                                                onChange={(e) =>
                                                    handleInputChange(index, "name", e.target.value)
                                                }
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <label>
                                                Expense Value <i className="fal fa-user" />
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Expense Value"
                                                value={expense.value}
                                                onChange={(e) =>
                                                    handleInputChange(index, "value", e.target.value)
                                                }
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label >Type</label>
                                            <select
                                                data-placeholder="Apartments"
                                                className="nice-select chosen-select no-search-select"
                                                value={expense.type}
                                                name="type"
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        index,
                                                        "type",
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <option disabled value="">Type</option>
                                                <option value="Singel Fee">Single Fee</option>
                                                <option value="Per Night">Per Night</option>
                                                <option value="Per Guest">Per Guest</option>
                                                <option value="Per Night Per Guest">Per Night Per Guest</option>

                                            </select>
                                        </div>
                                        <div className="col-sm-6">
                                            <label>
                                                Expense Quantity <i className="fal fa-user" />
                                            </label>
                                            <input
                                                type="number"
                                                placeholder="Expense Quantity"
                                                value={expense.quantity}
                                                onChange={(e) =>
                                                    handleInputChange(index, "quantity", e.target.value)
                                                }
                                            />
                                        </div>
                                        {expenseList.length > 1 && (
                                            <div className="col-sm-12 " style={{ marginTop: "10px", display: "flex", justifyContent: "start", }}>
                                                <button
                                                    className="logout_btn12"

                                                    onClick={() => handleRemove(index)}
                                                >
                                                    Remove
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                ))}
                                <div className="col-sm-12 " style={{ marginTop: "10px", display: "flex", justifyContent: "end" }}>


                                    <Button variant="secondary" onClick={handleAddMore}>
                                        Add More
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ display: "flex", justifyContent: "space-between" }}>

                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <button className="logout_btn_expense" onClick={handleSave}>
                            Save
                        </button>
                    </Modal.Footer>
                </div>
            </Modal >
        </div >
    );
};

export default BtnModalExpenses;
