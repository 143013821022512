/* eslint-disable */
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import ReactStars from "react-rating-stars-component";

const ViewReview = ({ isOpen2, selectedId, handleSubmit = () => { }, handleSubmitReply = () => { }, setSendReply, selectedRowStatus }) => {
    const [reply, setReply] = useState(false);

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            overflow: "hidden",
            top: "-30px"
        }}
        >
            <Modal
                show={isOpen2}
                onHide={handleSubmit}
                // backdrop="static"
                style={{ height: 'fit-content', top: "-20px" }}
                keyboard={false}

            >
                <Modal.Header >
                    <Modal.Title>View Review</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height: "auto", overflowY: 'scroll' }}>
                    <div className="row d-flex flex-wrap">
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <label>Overall Satisfaction</label>
                            <ReactStars
                                count={5}
                                value={selectedId?.original?.overall_satisfaction || 0}
                                size={30}
                                activeColor="#ffd700"
                                edit={false}
                            />
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <label>Cleanliness</label>
                            <ReactStars
                                count={5}
                                value={selectedId?.original?.cleanliness || 0}
                                size={30}
                                activeColor="#ffd700"
                                edit={false}
                            />
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <label>Accuracy</label>
                            <ReactStars
                                count={5}
                                value={selectedId?.original?.accuracy || 0}
                                size={30}
                                activeColor="#ffd700"
                                edit={false}
                            />
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <label>Communication</label>
                            <ReactStars
                                count={5}
                                value={selectedId?.original?.communication || 0}
                                size={30}
                                activeColor="#ffd700"
                                edit={false}
                            />
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <label>Location</label>
                            <ReactStars
                                count={5}
                                value={selectedId?.original?.location || 0}
                                size={30}
                                activeColor="#ffd700"
                                edit={false}
                            />
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <label>Check-in</label>
                            <ReactStars
                                count={5}
                                value={selectedId?.original?.checkin || 0}
                                size={30}
                                activeColor="#ffd700"
                                edit={false}
                            />
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <label>Value</label>
                            <ReactStars
                                count={5}
                                value={selectedId?.original?.value || 0}
                                size={30}
                                activeColor="#ffd700"
                                edit={false}
                            />
                        </div>
                    </div>

                    <form id="add-comment" className="add-comment  custom-form" name="rangeCalc">
                        <fieldset>

                            <div style={{ padding: "10px 30px 0px" }} className="list-single-main-item_content fl-wrap">

                                <textarea
                                    cols={40}
                                    rows={3}
                                    value={selectedId?.original?.comment || ""}
                                    placeholder="Write Your Reason:"
                                    defaultValue={""}
                                />

                            </div>
                        </fieldset>
                        <p style={{ fontSize: "11px", fontStyle: "italic", margin: 'unset' }}>Caution: Clicking No Reply is a final action; you will not be able to leave a review once it has been clicked.</p>
                        <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
                            {selectedRowStatus !== 'No Reply' && (
                                <>
                                    <Button variant="primary" onClick={() => setReply(!reply)}>Reply</Button>
                                    <Button variant="primary" tooltip onClick={handleSubmit}>No Reply</Button>
                                </>
                            )
                            }
                        </div>
                    </form>
                    {reply && selectedRowStatus !== 'No Reply' ?

                        <form id="add-comment" className="add-comment  custom-form" name="rangeCalc">
                            <fieldset>

                                <div style={{ padding: "10px 30px 0px" }} className="list-single-main-item_content fl-wrap">

                                    <textarea
                                        cols={40}
                                        rows={3}
                                        onChange={(e) => setSendReply(e.target.value)}
                                        placeholder="Write Your Reply:"
                                        defaultValue={""}
                                    />

                                </div>
                            </fieldset>
                            <p style={{ fontSize: "11px", fontStyle: "italic" }}>You can only respond to a review one time. Once you click Submit, your review cannot be retracted. You have a limit of 500 characters for your reply.</p>

                            <div style={{ display: "flex", justifyContent: "flex-end" }}>

                                <Button style={{ margin: 'unset' }} variant="primary" onClick={handleSubmitReply}>Submit</Button>
                            </div>
                        </form>

                        :
                        <>
                            <p style={{ fontSize: "16px", fontStyle: "italic" }}>You have already reviewed this.</p>


                        </>

                    }
                </Modal.Body>

            </Modal>
        </div>
    )
}

export default ViewReview