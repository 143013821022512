/* eslint-disable */
import React, { useEffect, useState, useContext, useRef } from "react";
import Layout from "../Components/layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import DatePicker from "react-datepicker";

import BASE_URL from "../Components/auth/Baseurl";
import { fetchData, ThemeContext } from "../context/ContextFile";
import { BtnLoader1 } from "../Components/BtnLoader/BtnLoader";
import { MdGroup, MdOutlineBedroomParent } from "react-icons/md";
import { FaMap, FaShower } from "react-icons/fa";
import { IoSearchOutline } from "react-icons/io5";
import Swal from "sweetalert2";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import ListingOutOverlay from "../Components/ui/ListingOutOverlay";

const Listing = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState(false);
  const [load, setLoad] = useState(true);
  const [datas, setDatas] = useState([]);
  const [handle, setHandle] = useState();
  const [sort, setSort] = useState();
  console.log(handle, "get");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  let {
    SetTheme,
    theme,
    setFilterListing,
    shuffledDatas,
    setShuffledDatas,
    setData,
  } = useContext(ThemeContext);
  const [query, setQuery] = useState("");
  console.log(sort, "sort");

  useEffect(() => {
    SetTheme("");
  }, []);
  console.log(theme, "theme");
  const [quantity, setQuantity] = useState();

  const handleChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value > 0) {
      setQuantity(value);
    } else {
      setQuantity(1);
    }
  };

  useEffect(() => {
    fetchData({ sort: sort }).then((res) => {
      setShuffledDatas(res.data.data);
      console.log(res.data.data, "data");
    });
  }, [sort]);

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp") {
      setQuantity((prevQuantity) => prevQuantity + 1);
    } else if (e.key === "ArrowDown") {
      setQuantity((prevQuantity) => Math.max(1, prevQuantity - 1));
    }
  };
  console.log(quantity, "num");
  const formData = new FormData();

  formData.append("byAddress", query);
  formData.append("byNoOfGuest", quantity);
  if (startDate) {
    const formattedArrivalDate = startDate.toISOString().split("T")[0];
    formData.append("byCheckin", formattedArrivalDate);
  }

  // Departure Date
  if (endDate) {
    const formattedDepartureDate = endDate.toISOString().split("T")[0];
    formData.append("byCheckout", formattedDepartureDate);
  }

  const isFormValid = () => {
    return quantity || query || startDate || endDate;
  };

  const GetData = () => {
    // setLoad(true)
    if (!isFormValid()) {
      console.log("Please fill out all required fields.");
      return;
    }

    formData.append("byListingType", handle);
    axios
      .post(`${BASE_URL}/listing-search`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        SetTheme(res.data);
        console.log(res.data);
        // setLoad(false)
      })
      .catch((err) => {
        SetTheme(err);
        // setLoad(false)
        console.log(err);
      });
  };

  const autoCompleteRef = useRef(null);
  let autoComplete;

  const handleScriptLoad = (updateQuery, autoCompleteRef) => {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      {
        // types: ["(cities)"],
      }
    );

    autoComplete.addListener("place_changed", () => {
      handlePlaceSelect(updateQuery);
    });
  };

  const handlePlaceSelect = async (updateQuery) => {
    const addressObject = await autoComplete.getPlace();
    const query = addressObject?.formatted_address;
    updateQuery(query);
    console.log({ query });
    const latLng = {
      lat: addressObject?.geometry?.location?.lat(),
      lng: addressObject?.geometry?.location?.lng(),
    };
    // console.log({ latLng });
    // setSelectedLocation(latLng);
  };

  useEffect(() => {
    handleScriptLoad(setQuery, autoCompleteRef);
  }, [setQuery, autoCompleteRef]);

  useEffect(() => {
    fetchData().then((res) => {
      setShuffledDatas(shuffleArray(res.data?.data));
      setLoad(false);
    });
  }, []);

  const [amenity, setAmenity] = useState([]);
  const GetData2 = () => {
    axios
      .get(`${BASE_URL}/amenity`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setAmenity(res.data?.data || []); // Ensure amenity is always an array
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    GetData2();
  }, []);

  const [facility, setFacility] = useState([]);
  const GetData1 = () => {
    axios
      .get(`${BASE_URL}/facility`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        // console.log(res.data?.data, "values");
        setFacility(res.data?.data || []);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    GetData1();
  }, []);

  const handleFav = async (id) => {
    const formData = new FormData();
    formData.append("listing_id", id);
    try {
      const response = await axios.post(
        `${BASE_URL}/add-to-favourite`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      fetchData().then((res) => {
        const shuffledIds = shuffledDatas?.map((item) => item.id);
        const shuffledArray = shuffledIds?.map((id) =>
          res?.data?.data?.find((item) => item.id === id)
        );
        setShuffledDatas(shuffledArray);
        setDatas(res.data?.data);
        setFilterListing(res.data?.data);
      });
    } catch (err) {
      Swal.fire({
        position: "center",
        html: `
            <div style="display: flex; flex-direction: column; align-items: center;">
<svg
                      viewBox="0 0 1024 1024"
                      height="4em"
                      width="4em"
                      style="margin-bottom: 10px;"
                  >
                      <!-- Outer circle with color #8ec639 -->
                      <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                      
                      <!-- Check mark with color #A2c66b -->
                      <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
                  </svg>
                <p style="margin: 0; font-size: 1.2em;">Profile update successful.</p>
            </div>
        `,
        title: err?.data?.message,
        showConfirmButton: true,
        timer: 1500,
      });
    }
  };

  const [formData2, setFormData2] = useState({
    byTypeOfSpace: "",
    byMin: "",
    byMax: "",
    byNoOfBedroom: "",
    byNoOfRoom: "",
    amenity: [],
    facility: [],
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? (checked ? 1 : 0) : value;
    setFormData2((prevState) => ({
      ...prevState,
      [name]: inputValue,
    }));
  };

  const valueGetFacilities = (e) => {
    const { name, checked } = e.target;
    setFormData2((prevState) => ({
      ...prevState,
      facility: checked
        ? [...prevState.facility, name] // Add facility if checked
        : prevState.facility.filter((item) => item !== name), // Remove facility if unchecked
    }));
  };
  const valueGetAmenities = (e) => {
    const { name, checked } = e.target;
    setFormData2((prevState) => ({
      ...prevState,
      amenity: checked
        ? [...prevState.amenity, name]
        : prevState.amenity.filter((item) => item !== name),
    }));
  };

  const value = new FormData();
  value.append("byTypeOfSpace", formData2.byTypeOfSpace);
  value.append("byMin", formData2.byMin);
  value.append("byMax", formData2.byMax);
  value.append("byNoOfBedroom", formData2.byNoOfBedroom);
  value.append("byNoOfRoom", formData2.byNoOfRoom);
  value.append("amenity", JSON.stringify(formData2.amenity));
  value.append("facility", JSON.stringify(formData2.facility));

  const isFormValid2 = () => {
    return (
      formData2.byTypeOfSpace ||
      formData2.byMin ||
      formData2.byMax ||
      formData2.byNoOfBedroom ||
      formData2.byNoOfRoom ||
      formData2.amenity ||
      formData2.facility
    );
  };
  const filterModal = () => {
    if (!isFormValid2()) {
      console.log("Please fill out all required fields.");
      return;
    }

    axios
      .post(`${BASE_URL}/listing-search`, value, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        SetTheme(res?.data);

        Swal.fire({
          position: "center",
          html: `
            <div style="display: flex; flex-direction: column; align-items: center;">
<svg
                      viewBox="0 0 1024 1024"
                      height="4em"
                      width="4em"
                      style="margin-bottom: 10px;"
                  >
                      <!-- Outer circle with color #8ec639 -->
                      <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                      
                      <!-- Check mark with color #A2c66b -->
                      <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
                  </svg>
                <p style="margin: 0; font-size: 1.2em;">Profile update successful.</p>
            </div>
        `,
          title: res?.data?.message,
          showConfirmButton: true,
          timer: 1500,
        });
        setFilters(false);
      })

      .catch((err) => {
        SetTheme(err);
        errorPopup('', err?.response?.data?.message);

      });
  };

  const hasShuffled = useRef(false);
  const shuffleArray = (array) => {
    const shuffledArray = array.slice();
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  };

  const [responseData, setResponseData] = useState(null);
  const [compareStatus, setCompareStatus] = useState({});

  const handleCompare = (id, currentCompare) => {
    const filterCompare = shuffledDatas?.filter((e) => e?.is_compare === 0);
    console.log(filterCompare, "filterCompare");
    if (filterCompare?.length <= 4) {
      handleClick(id, currentCompare);
    } else {
      Swal.fire({
        position: "center",
        html: `
            <div style="display: flex; flex-direction: column; align-items: center;">
<svg
                      viewBox="0 0 1024 1024"
                      height="4em"
                      width="4em"
                      style="margin-bottom: 10px;"
                  >
                      <!-- Outer circle with color #8ec639 -->
                      <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                      
                      <!-- Check mark with color #A2c66b -->
                      <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
                  </svg>
                <p style="margin: 0; font-size: 1.2em;">Profile update successful.</p>
            </div>
        `,
        title: "You can only compare 5 listings",
        showConfirmButton: true,
        timer: 1500,
      });
    }
  };

  const handleClick = async (id, currentCompare) => {
    try {
      const response = await axios.get(`${BASE_URL}/listing-compare/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const menuToggleCheckbox = document.getElementById("UniqueMenuToggle");
      if (!menuToggleCheckbox.checked) {
        menuToggleCheckbox.click();
      }
      if (response.status === 200) {
        setResponseData(response.data);
        setFilterListing(response.data?.data);
        console.log(response.data, "responsefilter");
        setCompareStatus((prevStatus) => ({
          ...prevStatus,
          [id]: currentCompare === 0 ? 1 : 0,
        }));
      }
      fetchData().then((res) => {
        const shuffledIds = shuffledDatas?.map((item) => item.id);
        const shuffledArray = shuffledIds?.map((id) =>
          res?.data?.data?.find((item) => item.id === id)
        );
        setShuffledDatas(shuffledArray);
        setDatas(res.data?.data);
        setFilterListing(res.data?.data);
      });
    } catch (error) {
      console.error("Error making API call:", error);
    }
  };

  const handleRemove = async (id, currentCompare) => {
    try {
      const response = await axios.get(`${BASE_URL}/listing-compare/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const menuToggleCheckbox = document.getElementById("UniqueMenuToggle");

      // Only toggle the menu open if it's not already checked
      if (!menuToggleCheckbox.checked) {
        menuToggleCheckbox.click();
      }

      if (response.status === 200) {
        setResponseData(response.data);
        setFilterListing(response.data?.data);
        console.log(response.data, "responsefilter");
        setCompareStatus((prevStatus) => ({
          ...prevStatus,
          [id]: currentCompare === 0 ? 1 : 0,
        }));
      }
      fetchData().then((res) => {
        const shuffledIds = shuffledDatas?.map((item) => item.id);
        const shuffledArray = shuffledIds?.map((id) =>
          res?.data?.data?.find((item) => item.id === id)
        );
        setShuffledDatas(shuffledArray);
        setDatas(res.data?.data);
        setFilterListing(res.data?.data);
      });
    } catch (error) {
      console.error("Error making API call:", error);
    }
  };
  console.log(responseData, "responseData");

  const increment = () => {
    setQuantity((prev) =>
      prev !== "" && Number(prev) > 0 ? Number(prev) + 1 : 1
    );
  };

  const decrement = () => {
    setQuantity((prev) =>
      prev !== "" && Number(prev) > 0 ? Number(prev) - 1 : 0
    );
  };
  return (
    <>
      <Layout>
        <div className="bg-[#F7F8F9]">
          <div className="bg-white py-4">
            <div className="grid md:grid-cols-7 grid-cols-3 bg-[#ffffffcf] rounded-lg md:gap-0 gap-2 px-4">
              <div className="main-search-input-item location autocomplete-container">
                <input
                  type="text"
                  ref={autoCompleteRef}
                  placeholder="Destination"
                  className="autocomplete-input pac-target-input"
                  onChange={(e) => setQuery(e.target.value)}
                  value={query}
                />
                <Link href="#">
                  <i className="fa fa-dot-circle-o" />
                </Link>
              </div>
              <div className="main-search-input-item">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    setEndDate(null);
                  }}
                  placeholderText="Arrival Date"
                />
              </div>
              <div className="main-search-input-item">
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  placeholderText="Departure Date"
                  minDate={startDate}
                />
              </div>
              <div className="main-search-input-item input-containers">
                <input
                  style={{ width: "100%" }}
                  type="number"
                  name="quantity"
                  placeholder="Guests"
                  className="input-field"
                  value={quantity}
                  onChange={handleChange}
                />
                <div className="custom-buttons-second">
                  <button
                    type="button"
                    style={{ borderRadius: "12px 12px 12px 12px" }}
                    className="increment"
                    onClick={increment}
                  >
                    <IoMdArrowDropup />
                  </button>
                  <button
                    type="button"
                    style={{ borderRadius: "12px 12px 12px 12px" }}
                    className="decrement"
                    onClick={decrement}
                  >
                    <IoMdArrowDropdown />
                  </button>
                </div>
              </div>
              <div className="main-search-input-item">
                <select
                  style={{ fontWeight: 800, fontSize: "13px" }}
                  data-placeholder="Dwelling Type"
                  className="nice-select chosen-select no-search-select"
                  onChange={(e) => setHandle(e.target.value)}
                >
                  <option style={{ textAlign: "left" }}>Lodging Type</option>
                  <option value={"Apt. / Condo / Loft"}>
                    Apt. / Condo / Loft
                  </option>
                  <option value={"Bed & Breakfast"}>Bed & Breakfast</option>
                  <option value={"Cabin"}>Cabin</option>
                  <option value={"Campsite"}>Campsite</option>
                  <option value={"Farm"}>Farm</option>
                  <option value={"Hangar"}>Hangar</option>
                  <option value={"Hangar Home"}>Hangar Home</option>
                  <option value={"House"}>House</option>
                  <option value={"Mansion"}>Mansion</option>
                  <option value={"Novelty"}>Novelty</option>
                  <option value={"RV"}>RV</option>
                  <option value={"RV Pad"}>RV Pad</option>
                  <option value={"Tiny Home"}>Tiny Home</option>
                </select>
              </div>
              <div className="main-search-input-item">
                <button
                  className="color2-bg mt-0 text-white bg-[#8EC639] rounded-md font-medium w-full h-full"
                  onClick={() => setFilters(!filters)}
                >
                  Filter
                </button>
              </div>
              <div className="flex justify-center items-center">
                <button
                  className="color2-bg mt-0 text-white bg-[#8EC639] rounded-md font-medium w-full h-full flex justify-center items-center md:py-0 py-3"
                  onClick={GetData}
                  disabled={!isFormValid()}
                >
                  Search
                  <IoSearchOutline />
                </button>
              </div>
            </div>
            {load ? (
              <>
                <BtnLoader1 />
              </>
            ) : (
              <>
                <div className="section-title" style={{ paddingTop: "50px" }}>
                  <h2 style={{ textAlign: "Center" }}>Stays</h2>
                </div>
                <div className="section-title flex justify-between flex-wrap md:px-12 px-4">
                  <h3 className="text-xl font-bold">
                    {shuffledDatas?.length + " "}Rentals{" "}
                  </h3>
                  <div className="flex items-center flex-wrap">
                    <h4 className="text-md text-nowrap font-bold">Sort by:</h4>
                    <div className="main-search-input-item">
                      <select
                        data-placeholder="Apartments"
                        className="nice-select chosen-select no-search-select"
                        onChange={(e) => setSort(e.target.value)}
                      >
                        <option value={"default_random"}>
                          Default (Random)
                        </option>
                        <option value={"price_lh"}>Price (Low to High)</option>
                        <option value={"price_hl"}>Price (High to Low)</option>
                        <option value={"rating"}>Rating</option>
                        <option value={"featured_first"}>Featured First</option>
                        <option value={"date_on"}>Date (Old to New)</option>
                        <option value={"date_no"}>Date (New to Old)</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="grid-item-holder gallery-items fl-wrap bg-gray">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      onClick={() => navigate("/bigmap")}
                      style={{
                        position: "fixed",
                        zIndex: "9999",
                        padding: "14px",
                        borderRadius: "26px",
                        backgroundColor: "rgb(175, 35, 34)",
                        color: "white",
                        border: "none",
                        fontSize: "15px",
                        bottom: "15px",
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Show Map <FaMap style={{ marginLeft: "12px" }} />
                    </button>
                  </div>
                  <div className="container mx-auto">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 px-4 mb-3">
                      {theme?.length === 0
                        ? shuffledDatas?.map((e) => {
                          return (
                            <>
                              <ListingOutOverlay
                                e={e}
                                handleFav={handleFav}
                                handleCompare={handleCompare}
                                handleRemove={handleRemove}
                              />
                            </>
                          );
                        })
                        : theme?.listings?.map((e) => {
                          return (
                            <>
                              <ListingOutOverlay
                                e={e}
                                handleFav={handleFav}
                                handleCompare={handleCompare}
                                handleRemove={handleRemove}
                              />
                            </>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </>
            )}

            {filters ? (
              <>
                <div
                  className="modal fade show"
                  id="gridSystemModal"
                  tabIndex={-1}
                  aria-labelledby="gridModalLabel"
                  style={{ display: "block" }}
                  aria-modal="true"
                  role="dialog"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-body">
                        <div className="container-fluid bd-example-row">
                          <div className="row">
                            <div className="col-md-3">
                              {" "}
                              <div className="dashboard-title  dt-inbox fl-wrap">
                                <h3>Size</h3>
                              </div>
                            </div>
                            <div className="col-md-3 ms-auto">
                              <div className="listsearch-input-item">
                                <select
                                  data-placeholder="Apartments"
                                  className="nice-select chosen-select no-search-select px-0"
                                  name="byNoOfBedroom"
                                  value={formData2.byNoOfBedroom}
                                  onChange={handleInputChange}
                                >
                                  <option>no of bedrooms</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                  <option value="11">11</option>
                                  <option value="12">12</option>
                                  <option value="13">13</option>
                                  <option value="14">14</option>
                                  <option value="15">15</option>
                                  <option value="16">16</option>
                                  <option value="17">17</option>
                                  <option value="18">18</option>
                                  <option value="19">19</option>
                                  <option value="20">20</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-3 ms-auto">
                              <div className="listsearch-input-item">
                                <select
                                  data-placeholder="Apartments"
                                  className="nice-select chosen-select no-search-select"
                                  name="byNoOfRoom"
                                  value={formData2.byNoOfRoom}
                                  onChange={handleInputChange}
                                >
                                  <option>no of rooms</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                  <option value="11">11</option>
                                  <option value="12">12</option>
                                  <option value="13">13</option>
                                  <option value="14">14</option>
                                  <option value="15">15</option>
                                  <option value="16">16</option>
                                  <option value="17">17</option>
                                  <option value="18">18</option>
                                  <option value="19">19</option>
                                  <option value="20">20</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-3 ms-auto">
                              <div className="listsearch-input-item">
                                <select
                                  data-placeholder="Apartments"
                                  className="nice-select chosen-select no-search-select"
                                  name="byTypeOfSpace"
                                  value={formData2.byTypeOfSpace}
                                  onChange={handleInputChange}
                                >
                                  <option value={"1"}>Space</option>

                                  <option value={"Entire Place"}>
                                    Entire Place
                                  </option>
                                  <option value={"Private Room"}>
                                    Private Room
                                  </option>
                                  <option value={"Shared Room"}>
                                    Shared Room
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3">
                              {" "}
                              <div className="dashboard-title  dt-inbox fl-wrap">
                                <h3>Price</h3>
                              </div>
                            </div>
                            <div className="col-md-3 ms-auto">
                              <div className="listsearch-input-item">
                                <select
                                  data-placeholder="Apartments"
                                  className="nice-select chosen-select no-search-select"
                                  name="byMin"
                                  value={formData2.byMin}
                                  onChange={handleInputChange}
                                >
                                  <option>Min.</option>
                                  <option value="10">$10</option>
                                  <option value="20">$20</option>
                                  <option value="30">$30</option>
                                  <option value="40">$40</option>
                                  <option value="60">$60</option>
                                  <option value="70">$70</option>
                                  <option value="80">$80</option>
                                  <option value="90">$90</option>
                                  <option value="100">$100</option>
                                  <option value="110">$110</option>
                                  <option value="120">$120</option>
                                  <option value="130">$130</option>
                                  <option value="140">$140</option>
                                  <option value="150">$150</option>
                                  <option value="160">$160</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-3 ms-auto">
                              <div className="listsearch-input-item">
                                <select
                                  data-placeholder="Apartments"
                                  className="nice-select chosen-select no-search-select"
                                  name="byMax"
                                  value={formData2.byMax}
                                  onChange={handleInputChange}
                                >
                                  <option>Max.</option>
                                  <option value="50">$50</option>
                                  <option value="100">$100</option>
                                  <option value="125">$125</option>
                                  <option value="150">$150</option>
                                  <option value="160">$160</option>
                                  <option value="200">$200</option>
                                  <option value="250">$250</option>
                                  <option value="300">$300</option>
                                  <option value="400">$400</option>
                                  <option value="500">$500</option>
                                  <option value="600">$600</option>
                                  <option value="700">$700</option>
                                  <option value="800">$800</option>
                                  <option value="900">$900</option>
                                  <option value="1000">$1000</option>
                                  <option value="1200">$1200</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 ms-auto">
                              {" "}
                              <div className="dashboard-title  dt-inbox fl-wrap">
                                <h3>Facilities</h3>
                              </div>
                            </div>
                            <div className="col-md-9 ms-auto">
                              <div className="profile-edit-container fl-wrap block_box">
                                <div className="custom-form">
                                  {/* Checkboxes */}
                                  <ul className="fl-wrap filter-tags no-list-style ds-tg">
                                    {facility.map((item, index) => (
                                      <li key={index}>
                                        <input
                                          id={`check-${index}`}
                                          type="checkbox"
                                          name={item.name}
                                          defaultChecked=""
                                          onChange={valueGetFacilities}
                                        />
                                        <label htmlFor={`check-${index}`}>
                                          {item.name}
                                        </label>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 ms-auto">
                              {" "}
                              <div className="dashboard-title  dt-inbox fl-wrap">
                                <h3>Amenity</h3>
                              </div>
                            </div>
                            <div className="col-md-9 ms-auto">
                              <div className="profile-edit-container fl-wrap block_box">
                                <div className="custom-form">
                                  {/* Checkboxes */}
                                  <ul className="fl-wrap filter-tags no-list-style ds-tg">
                                    {amenity.map((item, index) => (
                                      <li key={index}>
                                        <input
                                          id={`check-${index}`}
                                          type="checkbox"
                                          name={item.name}
                                          defaultChecked=""
                                          onChange={valueGetAmenities}
                                        />
                                        <label htmlFor={`check-${index}`}>
                                          {item.name}
                                        </label>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          onClick={() => setFilters(false)}
                          type="button"
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          onClick={filterModal}
                          type="button"
                          disabled={!isFormValid2()}
                          className="btn btn-primary"
                        >
                          Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Listing;
