import React from "react";
import Trip from "../Components/Trip";
import SidebarLayout from "../Components/sidebar/SidebarLayout";

function Trips() {
  return (
    <>
      <SidebarLayout>
        <div className="relative top-16 sm:top-16 md:top-14 ls:top-[unset]">
          <Trip />
        </div>
      </SidebarLayout>
    </>
  );
}

export default Trips;
