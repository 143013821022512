/* eslint-disable */

import React, { useState } from "react";
import "./header.css";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import TailwindHeader from "../Components/layout/TailwindHeader";
import { errorPopup } from "../helpers";
const HeaderTwo = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const [data, setdata] = useState(JSON.parse(localStorage.getItem("data")));
  console.log(data?.image, "image");
  const logOut = () => {
    localStorage.removeItem("data");
    localStorage.removeItem("token");

    Swal.fire({
      position: "center",
      html: `
              <div style="display: flex; flex-direction: column; align-items: center;">
   <svg
                      viewBox="0 0 1024 1024"
                      height="4em"
                      width="4em"
                      style="margin-bottom: 10px;"
                  >
                      <!-- Outer circle with color #8ec639 -->
                      <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                      
                      <!-- Check mark with color #A2c66b -->
                      <path fill="#A2c66b" d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
                  </svg>
                  <p style="margin: 0; font-size: 1.2em;">Logout successful.</p>
              </div>
          `,
      showConfirmButton: false,
    });
    setTimeout(() => {
      // .then((result) => {
      //   if (result.isConfirmed) {
      window.location.href = "/";
      //   }
      // })
    }, 1000);
  };

  const handleMouseEnter = () => {
    setOpen(true);
  };
  const handleMouseLeave = () => {
    setOpen(false);
  };

  const GetData = () => {
    if (!isFormValid()) {
      console.log("Please fill out all required fields.");
      return;
    }

    axios
      .post(`${BASE_URL}/listing-search`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        SetTheme(res?.data);

        // alert(res?.data)
        navigate(`/map/${query}`);
        Swal.fire({
          position: "center",
          html: `  
            <div style="display: flex; flex-direction: column; align-items: center;">
               <svg
            viewBox="0 0 1024 1024"
            height="4em"
            width="4em"
            style="margin-bottom: 10px;"
        >
            <!-- Outer circle with color #8ec639 -->
            <path fill="#8ec639" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
            
            <!-- Plus icon with color #A2c66b -->
            <path fill="#A2c66b" d="M512 274c-17.7 0-32 14.3-32 32v176H304c-17.7 0-32 14.3-32 32s14.3 32 32 32h176v176c0 17.7 14.3 32 32 32s32-14.3 32-32V546h176c17.7 0 32-14.3 32-32s-14.3-32-32-32H544V306c0-17.7-14.3-32-32-32z" />
        </svg>
                <p style="margin: 0; font-size: 1.2em;">Profile update successful.</p>
            </div>
        `,
          title: res?.data?.message,
          showConfirmButton: true,
          timer: 1500,
        });
      })
      .catch((err) => {
        SetTheme(err);
        // console.log(err);


        errorPopup('', err?.response?.data?.message);

      });
  };

  return (
    // <div className="myst">
    //   <header className="main-header">
    //     <div style={{ marginLeft: 67 }}>
    //       <Link className="logo-holder " to="/">
    //         <img
    //           src="/image/fly-inn-logo.svg"
    //           alt=""
    //           style={{ width: "100%", height: 82 }}
    //         />
    //       </Link>
    //     </div>
    //     <div className="nav-holder main-menu">
    //       <nav>
    //         <ul className="no-list-style">
    //           <li>
    //             <Link className="link" to="/">
    //               Home
    //             </Link>
    //           </li>
    //           <li>
    //             <Link className="link" to="/map">
    //               Map
    //             </Link>
    //           </li>
    //           <li>
    //             <Link className="" to="/dashboard/home">
    //               Explore <i className="fa fa-caret-down" />
    //             </Link>
    //             <ul>
    //               <li>
    //                 <Link to="/by-state-airport">By State / Airport</Link>
    //               </li>
    //               <li>
    //                 <Link to="/dashboard/home">
    //                   By Lodging
    //                   <i className="fa fa-caret-down" />
    //                 </Link>
    //                 <ul
    //                   className="specific-ul"
    //                   style={{ overflow: "hidden scroll", height: "60vh" }}
    //                 >
    //                   <li>
    //                     <Link to="/by-dwelling/details/Apartment">Apartment</Link>
    //                   </li>
    //                   <li>
    //                     <Link to="/by-dwelling/details/Bed_Breakfast">
    //                       Bed &amp; Breakfast
    //                     </Link>
    //                   </li>
    //                   <li>
    //                     <Link to="/by-dwelling/details/Beachfront">Beachfront</Link>
    //                   </li>
    //                   <li>
    //                     <Link to="/by-dwelling/details/Cabin">Cabin</Link>
    //                   </li>
    //                   <li>
    //                     <Link to="/by-dwelling/details/Campsite">Campsite</Link>
    //                   </li>
    //                   <li>
    //                     <Link to="/by-dwelling/details/Cando">Condo</Link>
    //                   </li>
    //                   <li>
    //                     <Link to="/by-dwelling/details/Farm">Farm</Link>
    //                   </li>
    //                   <li>
    //                     <Link to="/by-dwelling/details/Hangar">Hangar</Link>
    //                   </li>
    //                   <li>
    //                     <Link to="/by-dwelling/details/Hangar_Home">Hangar Home </Link>
    //                   </li>
    //                   <li>
    //                     <Link to="/by-dwelling/details/House">House </Link>
    //                   </li>
    //                   <li>
    //                     <Link to="/by-dwelling/details/Lakefront">Lakefront </Link>
    //                   </li>
    //                   <li>
    //                     <Link to="/by-dwelling/details/Loft">Loft</Link>
    //                   </li>
    //                   <li>
    //                     <Link to="/by-dwelling/details/Mansion">Mansion </Link>
    //                   </li>
    //                   <li>
    //                     <Link to="/by-dwelling/details/Novelty">Novelty </Link>
    //                   </li>
    //                   <li>
    //                     <Link to="/by-dwelling/details/RV">RV </Link>
    //                   </li>
    //                   <li>
    //                     <Link to="/by-dwelling/details/Tiny_Home">Tiny Home </Link>
    //                   </li>
    //                 </ul>
    //               </li>
    //               <li>
    //                 <Link to="/dashboard/home">
    //                   By Space
    //                   <i className="fa fa-caret-down" />
    //                 </Link>
    //                 <ul>
    //                   <li>
    //                     <Link to="/by-space/details/Entire_Place">Entire Place</Link>
    //                   </li>
    //                   <li>
    //                     <Link to="/by-space/details/Private_Room">Private Room</Link>
    //                   </li>
    //                   <li>
    //                     <Link to="/by-space/details/Shared_Room">Shared Room</Link>
    //                   </li>
    //                 </ul>
    //               </li>
    //             </ul>
    //           </li>
    //           <li>
    //             <Link className="link" to="/listing">
    //               Stays
    //             </Link>
    //           </li>
    //           <li>
    //             <Link className="" to="/dashboard/home">
    //               Info <i className="fa fa-caret-down" />
    //             </Link>
    //             <ul>
    //               <li>
    //                 <Link to="/about">About</Link>
    //               </li>
    //               <li>
    //                 <Link to="/faq" target="_blank">
    //                   FAQ
    //                 </Link>
    //               </li>
    //               <li>
    //                 <Link to="/philanthropy">Philanthropy</Link>
    //               </li>
    //               <li>
    //                 <Link to="/squawks">Squawks</Link>
    //               </li>
    //               <li>
    //                 <Link to="/agreements">
    //                   Agreements
    //                   <i className="fa fa-caret-down" />
    //                 </Link>
    //                 <ul>
    //                   <li>
    //                     <Link to="/terms-of-service">Terms of Service</Link>
    //                   </li>
    //                   <li>
    //                     <Link to="/privacy_policy">Privacy Policy</Link>
    //                   </li>
    //                 </ul>
    //               </li>
    //               <li>
    //                 <Link to="/gallery">Gallery</Link>
    //               </li>
    //               <li>
    //                 <Link to="/testimonials">Testimonials</Link>
    //               </li>
    //             </ul>
    //           </li>
    //           <li>
    //             <Link className="link" to="/contact">
    //               Contact
    //             </Link>
    //           </li>
    //           <li>
    //             <div>
    //               <Link
    //                 className="add-list color-bg "
    //                 to="/login"
    //                 style={{
    //                   color: "rgb(255, 255, 255)",
    //                   backgroundColor: "rgb(175, 35, 34)",
    //                   top: 0
    //                 }}
    //               >
    //                 Become a Host
    //                 <span style={{ paddingLeft: 0 }}>
    //                   <i
    //                     className="fal fa-layer-plus"
    //                     style={{ color: "rgb(255, 255, 255)" }}
    //                   />
    //                 </span>
    //               </Link>
    //             </div>
    //           </li>
    //         </ul>
    //       </nav>
    //     </div>
    //     <div
    //       style={{
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center"
    //       }}
    //     >
    //       <div
    //         style={{
    //           display: "flex",
    //           justifyContent: "center",
    //           alignItems: "center"
    //         }}
    //       >
    //         <div
    //           className="header-user-menu"
    //           style={{
    //             marginLeft: 0,
    //             marginRight: 35,
    //             position: "relative",
    //             top: 0
    //           }}
    //         >
    //           <div
    //             className={`header-user-namehu-menu-visdec${open ? 'hu-menu-visdec' : ''} `}
    //             style={{
    //               display: data ? "flex" : "flex",
    //               justifyContent: data ? "space-between" : "space-between",
    //               alignItems: data ? "center" : "center",
    //               width: data ? "135px" : "40px",
    //               cursor: data ? "pointer" : "pointer",
    //               paddingTop: data ? "0" : "11px",
    //               fontWeight: data ? "900" : "900",
    //             }}
    //             onMouseEnter={handleMouseEnter}
    //             onMouseLeave={handleMouseLeave}

    //           >
    //             {data?.username}
    //             <span>
    //               <img
    //                 src={data?.image}
    //                 alt=""
    //                 style={{
    //                   height: 55,
    //                   width: 55,
    //                   borderRadius: "50px",
    //                 }}
    //               />
    //             </span>
    //           </div>
    //           <ul className={open ? 'hu-menu-vis' : null}
    //             onMouseEnter={handleMouseEnter}
    //             onMouseLeave={handleMouseLeave} >
    //             <li>
    //               <Link to="/dashboard"> Dashboard</Link>
    //             </li>
    //             <li>
    //               <Link to="/profile"> Profile</Link>
    //             </li>
    //             <li>
    //               <Link to="/my_listing"> My Listings</Link>
    //             </li>
    //             <li>
    //               <Link to="/booking"> My Bookings</Link>
    //             </li>
    //             <li>
    //               <Link to="/trip"> My Trips</Link>
    //             </li>
    //             <li>
    //               <Link to="/my_wallets"> My Wallet</Link>
    //             </li>
    //             <li>
    //               <Link to="/air_mail"> AirMail   <span
    //                 style={{
    //                   fontSize: "8px",
    //                   verticalAlign: "super",
    //                   position: "relative",
    //                   top: "-5px",
    //                   marginLeft: "-5px",
    //                   color: "#3b4249",
    //                   background: "none",
    //                   fontWeight: "400",
    //                 }}
    //               >
    //                 TM
    //               </span></Link>
    //             </li>
    //             {/* <li>
    //               <Link to="/payouts"> Payouts</Link>
    //             </li> */}
    //             <li>
    //               <Link to="/invoice"> Invoices</Link>
    //             </li>
    //             <li>
    //               <Link to="/review"> Reviews</Link>
    //             </li>
    //             <li>
    //               <Link to="/favorite">My Favorites</Link>
    //             </li>
    //             <li onClick={logOut}>
    //               <Link to="/">Log out</Link>
    //             </li>

    //           </ul>
    //         </div>
    //       </div>
    //     </div>
    //   </header>
    // </div>
    <>
      <TailwindHeader />
    </>
  );
};

export default HeaderTwo;
