import Layout from "../Components/layout/Layout";

function FAQ() {
  return (
    <>
      <Layout>
          <div  className="container mx-auto md:px-12">
            <div style={{ paddingTop: '50px' }} className="section-title">
              <h2 style={{ textAlign: "left" }}>FAQ</h2>
              <p style={{ maxWidth: "none" }}>
                The FAQ is where we answer your questions in depth so the entire Fly-Inn Family can benefit! If your question isn't answered here, we would love to add it to our FAQ library of videos. Please ask us your questions by using our conact page, calling us, or sending us an email to PIC@fly-inn.com with "Suggestions" in the subject line. You SQUAWK, We WILCO.
              </p>
            </div>
            <div>
              <div className="grid-sizer" style={{ width: "25%" }}></div>
              <div className="col-sm-3 grid-item">
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/BlXa5iIUCWA?si=-5sYTjQ5Y1o9SFHz"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
                <h2
                  style={{
                    marginBottom: "12px",
                    fontSize: "20px",
                    fontFamily: "Quicksand",
                  }}
                >
                  <span>How to add a new listing on Fly-Inn.com</span>
                </h2>{" "}
              </div>
              <div className="col-sm-3 grid-item">
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/_dTY5DbOCWM?si=dcXusRnE6-dVpH7v"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
                <h2
                  style={{
                    marginBottom: "12px",
                    fontSize: "20px",
                    fontFamily: "Quicksand",
                  }}
                >
                  <span>April 11, 2024</span>
                </h2>{" "}
              </div>
              <div className="col-sm-3 grid-item">
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/tClgM28kTsU?si=BlfrFPQrQ1sGMraa"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
                <h2
                  style={{
                    marginBottom: "12px",
                    fontSize: "20px",
                    fontFamily: "Quicksand",
                  }}
                >
                  <span>How to sync Fly Inn com's iCal to other platforms</span>
                </h2>{" "}
              </div>
              <div className="col-sm-3 grid-item">
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/QfT_wgBOh3g?si=ACWbzXvwsMu3_YjV"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
                <h2
                  style={{
                    marginBottom: "12px",
                    fontSize: "20px",
                    fontFamily: "Quicksand",
                  }}
                >
                  <span>How to Register on the Fly-Inn.com Platform</span>
                </h2>{" "}
              </div>
            </div>
          </div>
      </Layout>
    </>
  );
}

export default FAQ;
