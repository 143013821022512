import React, { useState } from "react";
import { DateRange } from "react-date-range";
import moment from "moment";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

export default function CustomCalendar({ disable = [] }) {
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: moment().add(1, "month").toDate(),
      key: "selection",
    },
  ]);

  const getStatus = (date) => {
    // Check if the date is in the bookedDates array
    if (disable.some((bookedDate) => moment(bookedDate).isSame(date, "day"))) {
      return "booked";
    }
    return "available";
  };

  const renderDayContents = (day) => {
    const date = moment(day);
    const status = getStatus(date);
    const isPast = date.isBefore(moment(), "day");
    const isToday = date.isSame(moment(), "day");
    const statusStyles = {
      available: { backgroundColor: "#e4f4db" },
      pending: { backgroundColor: "#fef7da" },
      booked: { backgroundColor: "#fde2e1" },
      past: { backgroundColor: "#eef0f2" },
      today: { backgroundColor: "#52c4d7", color: "#ffffff" }, // Blue background with white text
    };
    const style = isToday ? statusStyles.today : (isPast ? statusStyles.past : statusStyles[status]);
    return (
      <div
        style={{
          ...style,
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {date.date()}
      </div>
    );
  };

  return (
    <div className="calendar-container">
      <DateRange
        ranges={range}
        onChange={(item) => setRange([item.selection])}
        showDateDisplay={false}
        months={2}
        direction="horizontal"
        minDate={new Date()}
        dayContentRenderer={renderDayContents}
      />
      <div className="legend">
        <div className="legend-item">
          <span className="legend-color available" />
          Available
        </div>
        <div className="legend-item">
          <span className="legend-color pending" />
          Pending
        </div>
        <div className="legend-item">
          <span className="legend-color booked" />
          Booked
        </div>
        
      </div>
    </div>
  );
}
