import React from "react";
import Layout from "../Components/layout/Layout";
import ContactDetails from "../Components/contact/ContactDetails";

const Contact = () => {
  return (
    <Layout>
      <ContactDetails />
    </Layout>
  );
};

export default Contact;
